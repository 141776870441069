// disabling other products for https://sightly.atlassian.net/browse/EN-14722. Will enable them in following epic
export const products = {
    ONBOARDING_HUB: 'ONBOARDING_HUB',
    BRAND_PROFILES: 'BRAND_PROFILES',
    AI_CREDITS: 'AI_CREDITS',
    BOARDS: 'BOARDS',
    QUERIES: 'QUERIES',
    REPORTING: 'REPORTING',
    MOMENTS_INTELLIGENCE: 'MOMENTS_INTELLIGENCE',
    USERS: 'USERS',
    SMARTLISTS: 'SMARTLISTS',
    SOCIAL_TUNING_INDUSTRY_NBQ: 'SOCIAL_TUNING_INDUSTRY_NBQ',
    AI_PERSONA_BUILDER: 'AI_PERSONA_BUILDER',
    TIKTOK_HASHTAGS_DASHBOARD: 'TIKTOK_HASHTAGS_DASHBOARD',
    TIKTOK_ACTIVATIONS: 'TIKTOK_ACTIVATIONS',
    BRAND_PROFILE_INFO_ONLY: 'BRAND_PROFILE_INFO_ONLY',
    TIKTOK_INTEGRATIONS: 'TIKTOK_INTEGRATIONS',
    GOOGLE_ADS_ACTIVATIONS: 'GOOGLE_ADS_ACTIVATIONS',
    KRAFT_SOCIAL_METABASE_DASHBOARD: 'KRAFT_SOCIAL_METABASE_DASHBOARD',
    LULULEMON_SOCIAL_METABASE_DASHBOARD: 'LULULEMON_SOCIAL_METABASE_DASHBOARD',
    DEMO_SOCIAL_METABASE_DASHBOARD: 'DEMO_SOCIAL_METABASE_DASHBOARD',
    NARRATIVES_INTELLIGENCE: 'NARRATIVES_INTELLIGENCE',
}

export const productNamesForUI = {
    BRAND_PROFILES: 'Brand Profiles',
    AI_CREDITS: 'AI Credits',
    BOARDS: 'Boards',
    QUERIES: 'Queries',
    REPORTING: 'Reporting',
    MOMENTS_INTELLIGENCE: 'Moments Intelligence',
    USERS: 'Users',
    SOCIAL_TUNING: 'Social Tuning'
}
export const productsWithQuota = {
    BRAND_PROFILES: 'BRAND_PROFILES',
    BOARDS: 'BOARDS',
    QUERIES: 'QUERIES',
    USERS: 'USERS',
    AI_CREDITS: 'AI_CREDITS',
}

export const userActions = {
    CREATE: 'CREATE',
    DELETE: 'DELETE',
    UPDATE: 'UPDATE',
    READ: 'READ'
}
