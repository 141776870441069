import React, { useEffect, useState } from "react";
import { UploadFile } from "./UploadFile";
import { read } from "xlsx";
import * as xlsx from "xlsx";

interface IProps {
    /** Function to handle the change of the field */
    setKeywords: Function;
    /** Keywords to display */
    keywords: string[];
}

/**
 * @prop {Function} setKeywords Function to handle the change of the field.
 * @prop {string[]} keywords Keywords to display.
 */

export const ReadFileKeywords = ({ setKeywords, keywords }: IProps) => {
    const [textareaValue, setTextareaValue] = useState(keywords.join(", "));
    const [isUploading, setIsUploading] = useState(false);

    const handleFileUpload = (file: File) => {
        setIsUploading(true);
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target?.result as ArrayBuffer);
            const workbook = read(data, { type: "array" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData: any[][] = xlsx.utils.sheet_to_json(worksheet, { header: 1 });
            const keywordsArray = jsonData.map((row) => row[0]).filter(Boolean);

            setTextareaValue((prevValue) => {
                const newKeywords = keywordsArray.join(", ");
                return prevValue ? `${prevValue}, ${newKeywords}` : newKeywords;
            });
            setIsUploading(false);
        };
        reader.readAsArrayBuffer(file);
    };

    useEffect(() => {
        setKeywordsFromTextarea();
    }, [textareaValue]);

    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextareaValue(e.target.value);
    };

    const setKeywordsFromTextarea = () => {
        const keywords = textareaValue.split(",").map(keyword => keyword.trim()).filter(Boolean);
        setKeywords('keywords', keywords);
    };


    return (
        <div data-testid="read-file-keywords-container" className="flex flex-col gap-4">
            <div data-testid="read-file-keywords-upload-file">
                <UploadFile onFileUpload={handleFileUpload} title="Upload a custom list of keywords" description="Only write in the first column of the file (CSV or XLSX)" allowedFileTypes={["text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]} isUploading={isUploading} />
            </div>
            <div data-testid="read-file-keywords-textarea">
                <label htmlFor="keywords" className="text-sm font-medium text-gray-700">Add Keywords</label>
                <textarea
                    id="keywords"
                    className="w-full h-40 border border-gray-300 rounded-md p-2 overflow-y-auto"
                    value={textareaValue}
                    onChange={handleTextareaChange}
                />
            </div>
        </div>
    );
};