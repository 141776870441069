import { useAlert } from "@/hooks/useAlert";
import { logError } from "@/utils";
import { api } from "@/views/Discover/Moments/v2/api";
import { rqKeys } from "@/views/Discover/Moments/v2/rqKeys";
import { ClusterType } from "@/views/Discover/Moments/v2/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

export const useFeedback = (moment: ClusterType, brandProfileId?: number) => {
    const { showAlert } = useAlert()
    const queryClient = useQueryClient()

    const queryKey = useMemo(
        () => (moment ? rqKeys.momentKeywordsKey(brandProfileId, moment.clusterId) : null),
        [moment, brandProfileId]
    );

    const recActionFeedback = useMutation(api.moments.feedback.recommendedActionFeedback, {
        onMutate: async (args) => {
            if (!queryKey) return
            queryClient.setQueryData(queryKey, (prevMoment: any) => {
                if (!prevMoment) return prevMoment
                return { ...prevMoment, recommendedActionFeedback: args.feedback }
            })
        },
        onError: (err) => logError(err, { info: 'error setting rec action feedback' }),
        onSuccess: () => showAlert(),
    })

    const keywordsFeedback = useMutation(api.moments.feedback.keywordFeedback, {
        onMutate: async (args) => {
            if (!queryKey) return
            queryClient.setQueryData(queryKey, (prevMoment: any) => {
                if (!prevMoment) return prevMoment
                return { ...prevMoment, keywordsFeedback: args.feedback }
            })
        },
        onError: (err) => logError(err, { info: 'error on keyword feedback mutation' }),
        onSuccess: () => showAlert(),
    })

    if (!(moment && moment.clusterId && moment.clusterId !== -1)) {
        return {
            postTrendRecActionFeedback: () => { },
            postTrendKeywordsFeedback: () => { },
            invalidateMomentsQuery: () => { },
        }
    }

    return {
        postTrendRecActionFeedback: recActionFeedback.mutate,
        postTrendKeywordsFeedback: keywordsFeedback.mutate,
        invalidateMomentsQuery: () => {
            if (queryKey) queryClient.invalidateQueries(queryKey)
        },
    }
}
