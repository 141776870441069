import { Switch } from '@headlessui/react';
import React from 'react';
import { joinClasses } from '@/utils';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface IProps {
  id: string
  isDisabled?: boolean //whether the element is readonly or not
  enabled: boolean //text to show if the element is checked
  setEnabled: (bool: boolean) => void //change checked status
  enabledText?: string //text to show if the element is checked
  disabledText?: string //text to show if the element is not checked
  additionalClassName?: string
  additionalLabelClassName?: string
  switchClass?: string
  dataTestId?: string
}

export default function SightlyToggle({
  id,
  enabled,
  setEnabled,
  enabledText,
  disabledText,
  isDisabled,
  additionalClassName,
  additionalLabelClassName,
  switchClass,
  dataTestId = 'toggle-switch'
}: IProps) {
  const bgColor = 'bg-sightlyPurple';
  const ringColor = 'ring-sightly-purple';
  return (
    <Switch.Group
      as="div"
      className={joinClasses(additionalClassName, 'flex  items-center')}
    >
      {disabledText && (
        <Switch.Label
          as="span"
          className={joinClasses('mr-3 truncate')}
        >
          <span className={joinClasses(additionalLabelClassName, 'text-sm  text-gray-900')}>{disabledText}</span>
        </Switch.Label>
      )}

      <Switch
        id={id}
        disabled={isDisabled}
        checked={enabled}
        onChange={setEnabled}
        className={classNames(
          enabled ? bgColor : 'bg-gray-200',
          `${switchClass} relative inline-flex h-6 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2  focus:${ringColor} focus:ring-offset-2`
        )}
        data-testid={dataTestId}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-4' : 'translate-x-0',
            'pointer-events-none h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out items-center flex justify-center'
          )}
        >
          {enabled ? (
            <svg
              className="w-3 h-3"
              fill={'rgb(148 163 184)'}
              viewBox="0 0 12 12"
            >
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          ) : (
            <svg
              className="w-3 h-3 p-0.5 text-gray-400"
              fill={'rgb(148 163 184)'}
              viewBox="0 0 122.878 122.88"
            >
              <g>
                <path d="M90.914,5.296c6.927-7.034,18.188-7.065,25.154-0.068 c6.961,6.995,6.991,18.369,0.068,25.397L85.743,61.452l30.425,30.855c6.866,6.978,6.773,18.28-0.208,25.247 c-6.983,6.964-18.21,6.946-25.074-0.031L60.669,86.881L30.395,117.58c-6.927,7.034-18.188,7.065-25.154,0.068 c-6.961-6.995-6.992-18.369-0.068-25.397l30.393-30.827L5.142,30.568c-6.867-6.978-6.773-18.28,0.208-25.247 c6.983-6.963,18.21-6.946,25.074,0.031l30.217,30.643L90.914,5.296L90.914,5.296z" />
              </g>
            </svg>
          )}
        </span>
      </Switch>
      {enabledText && (
        <Switch.Label
          as="span"
          className="ml-3"
        >
          <span className="text-sm font-medium text-gray-900">{enabledText}</span>
        </Switch.Label>
      )}
    </Switch.Group>
  )
}
