import React, { Children, useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Divider } from 'rsuite';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ClusterType, ReducedClusterType, RelevanceClassification } from '../../Moments/v2/types';

import { logError } from '@/utils'
import { api } from '@/views/Discover/Moments/v2/api'
import { MomentAIJustificationCard } from './MomentAIJustificationCard';
import { MomentJustificationOrigin, HistoryResponseSchema } from '@/views/Discover/Moments/v2/types';
import SightlyButton from '@/components/Sightly/SightlyButton';
import useUser from '@/hooks/useUser';
type IProps = {
    open: boolean,
    handleClose: () => void,
    header: string,
    selectedMoments: ReducedClusterType[]|ClusterType[],
    brandProfileId: number | undefined,
    openFrom: string
}

interface MomentJustificationRequestItem {
    momentTitle: string;
    momentSummary: string;
    momentRelevance: 'High' | 'Medium' | 'Low';
}

type MomentJustificationRequest = MomentJustificationRequestItem[];

interface MomentHistoryJustificationRequestItem {
    actionableInsight: string;
    brandprofileId: string;
    clusterId: string;
    clusterTitle: string;
    createdAt: string;
    marketingActionSuggestion: string;
    relevanceClassification: RelevanceClassification | undefined | null;
    relevanceSummary: string;
    feedbackRelevanceSummary: boolean | null,
    feedbackActionableInsight: boolean | null,
    feedbackMarketingActionSuggestion: boolean | null,
    uuid: string
}

const getEmptyMomentJustificationArray = (array: ReducedClusterType[]|ClusterType[]) => {
    return array.map(() => {
        return {
            relevanceSummary: "",
            actionableInsight: "",
            marketingActionSuggestion: "",
            uuid: ''
        }
    });
}

export const JustificationSlidingOver = ({ open, handleClose, header, selectedMoments, brandProfileId, openFrom }: IProps) => {

    const [momentJustifications, setMomentJustifications] = useState(getEmptyMomentJustificationArray(selectedMoments));
    const [momentHistoryJustifications, setMomentHistoryJustifications] = useState<MomentHistoryJustificationRequestItem[]>([]);
    const [error, setError] = useState(false)
    const [errorCode, setErrorCode] = useState(200)
    const [page, setPage] = useState(0)
    const [hasNextPage, setHasNext] = useState(false)
    const [loading, setLoading] = useState(true);
    const { user } = useUser();

    const fetchMomentJustifications = async (momentsToGetJustification: MomentJustificationRequest, momentIds: number[]) => {
        try {
            const response = await api.moments.justification.post(momentsToGetJustification, momentIds, brandProfileId);
            const data = 'data' in response ? response.data : response; 
            if (Array.isArray(data)) {
                const responseToType = data.map(moment => ({
                    uuid: moment.uuid,
                    relevanceSummary: moment.relevanceSummary,
                    actionableInsight: moment.actionableInsight,
                    marketingActionSuggestion: moment.marketingActionSuggestion,
                }));
                setMomentJustifications(responseToType);
            } else {
                setErrorCode(data.status)
                setError(true)
            }
        } catch (err) {
            setError(true)
            logError(err);
        }
    };

    const fetchHistoryMomentJustifications = async (momentHistoryJustifications: MomentHistoryJustificationRequestItem[], page: number) => {
        try {
            setLoading(true);
            const  { hasNext, results } = await api.moments.justification.getHistoryJustification(brandProfileId, page, user?.userId!);
            const responseToType: MomentHistoryJustificationRequestItem[] = results.map(moment => ({
                uuid: moment.uuid,
                actionableInsight: moment.actionableInsight,
                brandprofileId: moment.brandProfileId,
                clusterId: moment.clusterId,
                clusterTitle: moment.clusterTitle,
                createdAt: moment.createdAt,
                marketingActionSuggestion: moment.marketingActionSuggestion,
                relevanceClassification: moment.relevanceClassification as RelevanceClassification,
                relevanceSummary: moment.relevanceSummary,    
                feedbackRelevanceSummary: moment.feedbackRelevanceSummary,
                feedbackActionableInsight: moment.feedbackActionableInsight,
                feedbackMarketingActionSuggestion: moment.feedbackMarketingActionSuggestion
                
            }));
            setHasNext(hasNext);
            if (hasNext) {
                setPage(page + 1);
            }
            setMomentHistoryJustifications([...momentHistoryJustifications, ...responseToType]);
            setLoading(false)
        } catch (err) {
            setError(true)
            logError(err);
        }
    };

    useEffect(() => {
        if (open && openFrom == MomentJustificationOrigin.Analyze) {
            setError(false)
            setMomentJustifications([]);
            let momentIdArray: number[] = [];
            const momentsToGetJustification: MomentJustificationRequest = selectedMoments.map(m => {
                momentIdArray.push(m.clusterId);
                return {
                    momentTitle: m.clusterName,
                    momentSummary: m.clusterSummary ?? "",
                    momentRelevance: m.relevanceClassification ?? 'Low',
                };
            });

            fetchMomentJustifications(momentsToGetJustification, momentIdArray);
        } else if (open && openFrom == MomentJustificationOrigin.History) {
            setError(false);
            setHasNext(false);
            setMomentHistoryJustifications([]);
            fetchHistoryMomentJustifications([], 0);
        }
    }, [open]);

    return (
        <Transition.Root
            show={open}
            as={Fragment}
        >
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => handleClose()}
            >
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="fixed inset-y-0 right-0 flex max-w-full pointer-events-none">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="w-screen max-w-3xl pointer-events-auto">
                                    <div className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl">
                                        <div className="flex flex-col flex-1 min-h-0 pb-2">
                                            <div className="px-4 pt-6  font-black text-xl">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-lg font-mediu">
                                                        {header}
                                                    </Dialog.Title>

                                                    <div className="flex items-center ml-3 h-7">
                                                        <button
                                                            type="button"
                                                            className="text-black rounded-md"
                                                            onClick={() => handleClose()}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon
                                                                className="w-6 h-6"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider className='bg-[#D9D9D9] border' />
                                            <div className='!m-0 !p-0 !h-full !w-full overflow-y-auto'>
                                                {openFrom == MomentJustificationOrigin.Analyze
                                                    ? selectedMoments.map((moment, index) => {
                                                        return (
                                                            <MomentAIJustificationCard
                                                                key={moment.clusterId}
                                                                momentClusterName={moment.clusterName}
                                                                momentId={moment.clusterId}
                                                                momentRelevance={moment.relevanceClassification}
                                                                relevanceSummary={momentJustifications[index]?.relevanceSummary ?? ""}
                                                                actionableInsight={momentJustifications[index]?.actionableInsight ?? ""}
                                                                marketingActionSuggestion={momentJustifications[index]?.marketingActionSuggestion ?? ""}
                                                                loadingError={error}
                                                                errorCode={errorCode}
                                                                userId={user?.userId! }
                                                                brandProfileId={brandProfileId}
                                                                uuid={momentJustifications[index]?.uuid ?? ""}
                                                            />
                                                        )
                                                    })
                                                    : <div className="flex flex-col">
                                                        { loading && !error ?
                                                            <div className="flex justify-center w-full h-20 items-center">
                                                                <svg
                                                                    aria-hidden="true"
                                                                    className="w-5 h-5 mr-4 text-white-200 animate-spin dark:white fill-sightly-blue"
                                                                    viewBox="0 0 100 101"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                        fill="currentColor"
                                                                    />
                                                                    <path
                                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                        fill="currentFill"
                                                                    />
                                                                </svg>
                                                                <span>Fetching history justifications</span>
                                                            </div>
                                                            : !loading && !error && momentHistoryJustifications.length == 0
                                                                ?  <div className="flex justify-center w-full h-20 items-center">
                                                                        <span>Select Moment(s) to generate insights.</span>
                                                                    </div>
                                                                : error && <div className="flex justify-center w-full h-20 items-center">
                                                                        <span>Please try again later</span>
                                                                    </div> 

                                                        }
                                                        {momentHistoryJustifications.map((moment) => {
                                                            return (
                                                                <MomentAIJustificationCard
                                                                    key={moment.clusterId + moment.createdAt}
                                                                    momentClusterName={moment.clusterTitle}
                                                                    momentId={parseInt(moment.clusterId)}
                                                                    momentRelevance={moment.relevanceClassification}
                                                                    relevanceSummary={moment.relevanceSummary ?? ""}
                                                                    actionableInsight={moment.actionableInsight ?? ""}
                                                                    marketingActionSuggestion={moment.marketingActionSuggestion ?? ""}
                                                                    loadingError={error}
                                                                    errorCode={errorCode}
                                                                    userId={user?.userId! }
                                                                    brandProfileId={brandProfileId}
                                                                    feedbackRelevanceSummary={moment.feedbackRelevanceSummary}
                                                                    feedbackActionableInsight={moment.feedbackActionableInsight}
                                                                    feedbackMarketingActionSuggestion={moment.feedbackMarketingActionSuggestion}
                                                                    uuid={moment.uuid}
                                                                />
                                                            )
                                                        })}
                                                        {hasNextPage && (
                                                            <div className="flex justify-center w-full py-2 px-6">
                                                                <SightlyButton
                                                                    datatestid="load-more-moments-button"
                                                                    block
                                                                    id="loadmorebutton"
                                                                    text="Load More"
                                                                    handleClick={() => {
                                                                        fetchHistoryMomentJustifications(momentHistoryJustifications, page)
                                                                    }}
                                                                    loading={loading}
                                                                    disabled={loading} 
                                                                />
                                                            </div>
                                                        )}
                                                    </div>

                                                }
                                            </div>

                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )


}
