import { z } from 'zod'
import { BASIC_INFO } from '@/views/BrandProfiles/constants';

const urlRegex = require('url-regex');

export const ScenarioSchema = z.object({
    scenarioId: z.number(),
    scenarioName: z.string(),
    scenarioType: z.string(),
    scenarioResponseId: z.number().nullable()
})

export const ScenariosSchema = z
    .array(
        z.object({
            scenarioTypeName: z.string(),
            scenarios: z.array(ScenarioSchema)
        })
    )
    .min(1, 'fetch brand profile scenarios returned an empty array, at least 1 scenario is required')

export type ScenarioType = z.infer<typeof ScenarioSchema>

export const validationSchema = z.object({
    brandProfileId: z.number(),
    brandProfileName: z
      .string()
      .max(50, BASIC_INFO.VALIDATION_BRAND_PROFILE_NAME.MAX),
    brandInfoName: z.string().nullable(),
    wikiUrl: z.string(),
    aylienIndustryId: z.number().nullable(),
    websiteUrl: z.union([z.literal(""), z.string().regex(urlRegex({ exact: true, strict: false }), { message: BASIC_INFO.VALIDATION_WEBSITE })]),
    twitterProfileUrl: z.string(),
    companySummary: z
      .string()
      .max(
        BASIC_INFO.VALIDATION_SUMMARY_MAX_CHAR_LIMIT,
        BASIC_INFO.VALIDATION_SUMMARY_MAX_CHAR(BASIC_INFO.VALIDATION_SUMMARY_MAX_CHAR_LIMIT)
      )
      .optional(),
    
      // .min(1, 'Company Summary is required'), // we leave this code commented because as we discussed in the meeting this code may be required in the future
    companyMentality: z
      .string()
      .max(
        BASIC_INFO.VALIDATION_SUMMARY_MAX_CHAR_LIMIT,
        BASIC_INFO.VALIDATION_SUMMARY_MAX_CHAR(BASIC_INFO.VALIDATION_SUMMARY_MAX_CHAR_LIMIT)
      )
      .optional(),
      // .min(1, 'Company Mentality is required'), // we leave this code commented because as we discussed in the meeting this code may be required in the future
    keyTerms: z
      .string()
      .nullable()
      .optional(),
    missionStatement: z
      .string()
      .max(
        BASIC_INFO.VALIDATION_SUMMARY_MAX_CHAR_LIMIT,
        BASIC_INFO.VALIDATION_SUMMARY_MAX_CHAR(BASIC_INFO.VALIDATION_SUMMARY_MAX_CHAR_LIMIT)
      )
      .nullable()
      .optional(),
    valueStatement: z
      .string()
      .max(
        BASIC_INFO.VALIDATION_SUMMARY_MAX_CHAR_LIMIT,
        BASIC_INFO.VALIDATION_SUMMARY_MAX_CHAR(BASIC_INFO.VALIDATION_SUMMARY_MAX_CHAR_LIMIT)
      )
      .nullable()
      .optional(),
    visionStatement: z
      .string()
      .max(
        BASIC_INFO.VALIDATION_SUMMARY_MAX_CHAR_LIMIT,
        BASIC_INFO.VALIDATION_SUMMARY_MAX_CHAR(BASIC_INFO.VALIDATION_SUMMARY_MAX_CHAR_LIMIT)
      )
      .nullable()
      .optional()
  })
