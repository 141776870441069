import React, { useEffect, useState } from 'react'
import { Table, Popover, Dropdown, IconButton, Icon } from 'rsuite';
const { HeaderCell, Cell } = Table;
import { TableColumnJs } from '@/utils';
import Whisper from "rsuite/lib/Whisper";
import { accentColor } from '@/assets/jss/colorConstants';
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from '@tanstack/react-location';
import { Notification } from 'rsuite';
import { routes } from '@/routes';
import { InformationModal } from '@/views/Engage/utils/InformationModal';
import LoadingModal from '@/views/Engage/utils/LoadingModal';
import { SortOrder, SortState } from '@/views/Engage/utils/types/EngageTypes';
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';
import { perms } from '@/staticData/permissions';
import { TablePagination } from '@/views/Engage/SmartList/components/TablePagination';
import { SortableTableHeader } from '@/views/Engage/utils/SortableTableHeader';
import { smartlistController } from '@/views/Engage/SmartList/controller/SmartlistController';
import { SmartList } from '@/views/Engage/SmartList/schemas/SmartlistSchema';
import youtubeIcon from '@/assets/img/smartlist/youtube.svg';

interface IProps {
    /** Table data. */
    tableData: any,
    /** Account ID. */
    brandProfileId: number,
    /** User permissions. */
    userPermissions?: any,
    /** Function to handle table data change. */
    onTableDataChange: Function
}

/**
 * Component for displaying a table of smartlists.
 * @prop {any} tableData Table data.
 * @prop {number} brandProfileId Account ID.
 * @prop {any} userPermissions User permissions.
 * @prop {Function} onTableDataChange Function to handle table data change.
 */

const tableHeaderStyle = {
    fontWeight: 600,
    fontSize: '16px',
    color: '#000000',
    backgroundColor: '#FAFAFA'
}
const tableStyle = {
    border: '1px solid #ccc',
    borderRadius: '4px 4px 0 0'
}

export const SmartlistViewTable = ({ brandProfileId, userPermissions, onTableDataChange }: IProps) => {
    const limit = 10;

    const [page, setPage] = useState(1);
    const [sortState, setSortState] = useState<SortState>({
        column: '',
        direction: SortOrder.EMPTY
    });

    const [orderBy, setOrderBy] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
    const [loadingTitleModal, setLoadingTitleModal] = useState('');
    const [titleInfomationModal, setTitleInfomationModal] = useState('');
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const navigate = useNavigate();

    const hasPermissionUpdate = userCanPermissionProductQuota({
        requiredPermissionValue: perms.SMARTLIST_EDIT,
        userPermissions: userPermissions,
        checkType: PermissionCheckType.PERMISSION_CHECK
    })
    const hasPermissionDelete = userCanPermissionProductQuota({
        requiredPermissionValue: perms.SMARTLIST_ARCHIVE,
        userPermissions: userPermissions,
        checkType: PermissionCheckType.PERMISSION_CHECK
    })


    const { data: queryData, refetch } = useQuery(
        ['getSmartLists', brandProfileId, page, limit, orderBy, sortState.direction],
        () => smartlistController.getSmartLists({
            queryKey: ['getSmartLists', brandProfileId, page, limit, orderBy, sortState.direction]
        }),
        {
            keepPreviousData: true,
        }
    );

    const [tableData, setTableData] = useState(queryData);

    useEffect(() => {
        setTableData(queryData);
    }, [queryData]);

    const showErrorModal = (title: string, messages: string[]) => {
        setTitleInfomationModal(title);
        setErrorMessages(messages);
        setIsErrorModalVisible(true);
    }

    const isYoutubeContent = (contentTypes: string[]): boolean => {
        return contentTypes.includes('Youtube Channel') || contentTypes.includes('Youtube Video');
    };

    const formatDate = (rowData: SmartList): string => {
        const date = new Date(rowData.updatedAt);
        return `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    };

    const handleEdit = async (rowData: SmartList) => {
        const smartListId = rowData.id;
        navigate({ 
            to: '/app/smartlist/review/',
            search: {
                smartListId: smartListId
            }
        });
    };

    const handleDelete = async (rowData: SmartList) => {
        try {
            setLoadingTitleModal('Deleting Smartlist')
            setIsLoading(true)

            const result = await smartlistController.handleRemoveSmartlist(rowData.id);

            if (result) {
                Notification.open({
                    title: 'Success',
                    description: <div>The smartlist was successfully deleted</div>
                });

                const updatedTableData = tableData?.results.filter((row: any) => row.id !== rowData['id']);

                if (updatedTableData?.length === 0 && page > 1) {

                    if (tableData && tableData.totalCount !== undefined) {
                        tableData.totalCount = tableData.totalCount - 1;
                    }

                    setPage(prevPage => Math.max(prevPage - 1, 1));

                } else {

                    const result = await refetch();
                    onTableDataChange(result.data);
                }

            } else {
                showErrorModal('Error', ['Error while deleting the smartlist.'])
            }

        } catch (error) {
            showErrorModal('Error', ['Error while deleting the smartlist.'])

        } finally {
            setIsLoading(false)
        }
    }

    return (

        <div>
            <Table minHeight={500} data={tableData?.results ?? []} style={tableStyle} autoHeight={true} data-testid="smartlist-table-view">
                <TableColumnJs align="center" fixed flexGrow={1}>
                    <HeaderCell style={tableHeaderStyle} data-testid="smartlist-table-view-name-header">
                        <SortableTableHeader
                            title="Name"
                            columnName="name"
                            sortState={sortState}
                            setSortState={setSortState}
                            setOrderBy={setOrderBy}
                        />
                    </HeaderCell>
                    <Cell data-testid="smartlist-table-view-name">
                        {(rowData: SmartList) => {
                            return (
                                rowData.name
                            );
                        }}
                    </Cell>
                </TableColumnJs>

                <TableColumnJs align="center" fixed flexGrow={1}>
                    <HeaderCell style={tableHeaderStyle} data-testid="smartlist-table-view-objetive-header">
                        <SortableTableHeader
                            title="Objective"
                            columnName="objective"
                            sortState={sortState}
                            setSortState={setSortState}
                            setOrderBy={setOrderBy}
                        />
                    </HeaderCell>
                    <Cell data-testid="smartlist-table-view-objective">
                        {(rowData: SmartList) => {
                            return (
                                rowData.objective
                            );
                        }}
                    </Cell>
                </TableColumnJs>
                <TableColumnJs align="center" fixed flexGrow={1}>
                    <HeaderCell style={tableHeaderStyle} data-testid="smartlist-table-view-description-header">
                        Content
                    </HeaderCell>
                    <Cell data-testid="smartlist-table-view-modified">
                        {(rowData: SmartList) => {
                            return (
                                isYoutubeContent(rowData.contentTypes) ? <img data-testid={'card-image'} src={youtubeIcon} className='w-9 h-6-' /> : null
                            );
                        }}
                    </Cell>
                </TableColumnJs>
                <TableColumnJs align="center" fixed flexGrow={1}>
                    <HeaderCell style={tableHeaderStyle} data-testid="smartlist-table-view-modified-header">
                        <SortableTableHeader
                            title="Last Modified"
                            columnName="updatedAt"
                            sortState={sortState}
                            setSortState={setSortState}
                            setOrderBy={setOrderBy}
                        />
                    </HeaderCell>
                    <Cell data-testid="smartlist-table-view-modified">
                        {(rowData: SmartList) => {
                            return (
                                formatDate(rowData)
                            );
                        }}
                    </Cell>
                </TableColumnJs>
                <TableColumnJs width={75}>
                    <HeaderCell style={tableHeaderStyle} data-testid="smartlist-table-view-header"></HeaderCell>
                    <Cell data-testid="smartlist-table-view-actions">
                        {(rowData: any, rowIndex: number) => {
                            return (
                                <div
                                    data-testid={`div-smartlist-actions-dropdown-${rowIndex}`}
                                    className="more"
                                >
                                    { <Whisper
                                        data-testid="smartlist-table-view-whisper"
                                        placement="topEnd"
                                        trigger="click"
                                        speaker={
                                            <Popover full>
                                                <Dropdown.Menu >
                                                    <Dropdown.Item
                                                        data-testid='div-smartlist-edit-button'
                                                        onClick={() => handleEdit(rowData)}>
                                                        Edit
                                                    </Dropdown.Item>
                                                    {hasPermissionDelete && <Dropdown.Item
                                                        data-testid='div-smartlist-delete-button'
                                                        onClick={() => handleDelete(rowData)}>
                                                        Delete
                                                    </Dropdown.Item>}
                                                </Dropdown.Menu>
                                            </Popover>
                                        }
                                    >
                                        <IconButton
                                            data-testid={`smartlist-table-view-whisper-more-${rowIndex}`}
                                            size="lg"
                                            appearance="default"
                                            icon={
                                                <Icon
                                                    icon="more"
                                                    color={accentColor}
                                                />
                                            }
                                        />
                                    </Whisper>}
                                </div>
                            );
                        }}
                    </Cell>
                </TableColumnJs>
            </Table>
            <div className='mt-2 mb-2 w-full flex justify-end'>
                <TablePagination
                    activePage={page}
                    total={tableData?.totalCount ?? 0}
                    onChangePage={setPage}
                    limit={limit}
                />
            </div>
            <InformationModal title={titleInfomationModal} informationMessages={errorMessages} isInformatioModalVisible={isErrorModalVisible} setIsInformatioModalVisible={setIsErrorModalVisible} />
            <LoadingModal loading={isLoading} title={loadingTitleModal} />
        </div>
    );
}
