import React from 'react';
import { SortableTableHeaderIcon } from './SortableTableHeaderIcon';
import { SortOrder, SortState } from '@/views/Engage/utils/types/EngageTypes';

interface IProps {
    /** Title of the table header. */
    title: string;
    /** Name of the column to be sorted. */
    columnName: string;
    /** Current sort state. */
    sortState: SortState;
    /** Method to be called when the sort state is updated. */
    setSortState: Function;
    /** Method to be called when the order by menu item is selected. */
    setOrderBy: Function
}
/**
 * Component for sorting a table column header.
 * @prop {string} title Title of the table header.
 * @prop {string} columnName Name of the column to be sorted.
 * @prop {SortState} sortState Current sort state.
 * @prop {Function} setSortState Method to be called when the sort by menu item is selected.
 * @prop {Function} setOrderBy Method to be called when the sort by menu item is selected.
 */

export const SortableTableHeader = ({
    title,
    columnName,
    sortState,
    setSortState,
    setOrderBy,
}: IProps) => {

    const handleSortColumn = (tableName: string) => {
        if (sortState.column !== tableName) {
            setSortState({
                column: tableName,
                direction: SortOrder.DESC
            });
        } else {
            setSortState((prev: SortState) => ({
                column: tableName,
                direction: prev.direction === SortOrder.EMPTY || prev.direction === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC
            }));
        }
        setOrderBy(tableName);
    }
    
    return (
        <div className='flex items-center cursor-pointer flex-1 justify-start' onClick={() => handleSortColumn(columnName)}>
            {title}
            <SortableTableHeaderIcon
                sortBy={sortState.column === columnName ? sortState.direction : SortOrder.EMPTY}
            />
        </div>

    );
}; 