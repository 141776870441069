import ChartTooltip from "@/views/Discover/Boards/components/conversations/ChartTooltip";
import React from "react";
import { Area, AreaChart, CartesianGrid, Tooltip as RechartTooltip, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { IMetricsPerDay } from "@/views/Trends/types";

interface IProps {
  lineChartData: IMetricsPerDay[],
  selectedSource?: string
}

const TopicTrendTimelineChart: React.FunctionComponent<IProps> = React.memo(
  ({ lineChartData, selectedSource }) => {
    return (
      <ResponsiveContainer>
        <AreaChart
          margin={{ bottom: -10 }}
          data={lineChartData}
        >
           <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            style={{
              fontSize: '10px'
            }}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: '10px' }}
          />
          <Area
            type="linear"
            dataKey="count"
            stroke={'#651AEB'}
            fill={'transparent'}
          />
          <RechartTooltip content={<ChartTooltip showGrowth={false} tooltipTitle={selectedSource} />} allowEscapeViewBox={{ x: true, y: true }} wrapperStyle={{ zIndex: 1000 }} />
        </AreaChart>
      </ResponsiveContainer>
    )
  })

export default TopicTrendTimelineChart