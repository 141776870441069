import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { FormLabel } from '@/components/Sightly/SightlyFormElements/SightlyFormLabel';
import { FixedSizeList as List } from 'react-window';
import { SightlyInput } from '@/components/Sightly/SightlyFormElements/SightlyInput';
import { LoaderIcon } from 'react-hot-toast';

interface IProps {
    label?: string;
    showLabelStar?: boolean;
    options?: any[];
    value?: any;
    placeholder?: string;
    width?: number;
    labelKey: string;
    valueKey: string;
    onChange: (value?: any) => void;
    disabled?: boolean;
    id: string;
    loading?: boolean;
    buttonClass?: string;
    multiple?: boolean;
    searchable?: boolean;
}

const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');

const SightlySelect: React.FC<IProps> = ({
    label,
    showLabelStar,
    options = [],
    value,
    placeholder,
    width,
    labelKey,
    valueKey,
    onChange,
    disabled,
    id,
    loading,
    buttonClass,
    multiple = false,
    searchable = false
}) => {
    const [filteredOptions, setFilteredOptions] = useState<any[]>();
    const [loadingOptions, setLoadingOptions] = useState(true)

    useEffect(() => {
        if (!options) return

        setFilteredOptions(options)
        setLoadingOptions(false)
    }, [options])

    const selectedItem = useMemo(() => {
        if (!options || !value) return multiple ? [] : null;

        return multiple
            ? options.filter(option => (value as Array<number | string>).includes(option[valueKey]))
            : options.find(option => option[valueKey] === value) || null;
    }, [value, options, multiple, valueKey]);

    const handleOptionChange = (val: any) => {
        if (multiple) {
            onChange(val.map((item: any) => item[valueKey]));
        } else {
            onChange(val ? val[valueKey] : null);
        }
    };

    const handleSearch = (search: string) => {
        setLoadingOptions(true)
        const filtered = options.filter(o => o[labelKey]?.toLowerCase().includes(search.toLowerCase()))
        setFilteredOptions(filtered);
        setLoadingOptions(false)
    };

    const renderOption = ({ index, style }: { index: number; style: React.CSSProperties }) => {
        const option = filteredOptions ? filteredOptions[index] : null;
        if (!option) return null;

        return (
            <Listbox.Option
                key={option[valueKey]}
                className={({ active }) =>
                    classNames(
                        active ? 'text-white bg-sightly-blue-light' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9 text-left'
                    )
                }
                value={option}
                style={style}
            >
                {({ selected, active }) => (
                    <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                            {option[labelKey]}
                        </span>

                        {selected && (
                            <span
                                className={classNames(
                                    active ? 'text-slate-500' : 'text-slate-400',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                            >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                        )}
                    </>
                )}
            </Listbox.Option>
        );
    };

    return (
        <Listbox
            multiple={multiple}
            value={selectedItem}
            onChange={handleOptionChange}
            disabled={loading || disabled}
        >
            {({ open }) => {
                useEffect(() => {
                    if (!open) setFilteredOptions(options);
                }, [open]);

                return (
                    <div className="block">
                        {label && <FormLabel id={id} text={label} required={showLabelStar} />}
                        <div className="relative" style={{ width: width || '100%' }}>
                        <Listbox.Button
    data-testid={id}
    className={classNames(
        buttonClass || '',
        'flex form-select focus:border-sightly-blue focus:ring-sightly-blue relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm sm:text-sm disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500'
    )}
>
    <span className={classNames('block truncate', !selectedItem && placeholder ? 'text-slate-500' : '')}>
        {multiple
            ? (selectedItem as any[]).map(item => item[labelKey]).join(', ') || placeholder
            : (selectedItem as any)?.[labelKey] || placeholder}
    </span>
    
    {multiple && value.length > 1 && <div className='rounded-full bg-sightly-blue text-white px-2 mr-1'>{value.length}</div>}

    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 gap-2">
        {multiple && value?.length > 0 && (
            <>
            <button
                className="text-gray-400 bg-white pointer-events-auto hover:text-red-500 focus:outline-none"
                onClick={(e) => {
                    e.stopPropagation(); // Prevents dropdown from closing
                    handleOptionChange([]);
                }}
            >
                x
            </button>
            </>
        )}
        <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </span>
</Listbox.Button>


                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="w-full absolute z-10 mt-1 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {searchable && (
                                        <div className="p-2">
                                            <SightlyInput
                                                id={id + '-search'}
                                                onChange={handleSearch}
                                            />
                                        </div>
                                    )}

                                    {loading || loadingOptions ? (
                                        <div className="flex justify-center p-4">
                                            <LoaderIcon />
                                        </div>
                                    ) : !filteredOptions || filteredOptions.length === 0 ? (
                                        <p className="p-2 text-gray-500">No results.</p>
                                    ) : (
                                        <List
                                            height={200}
                                            itemCount={filteredOptions.length}
                                            itemSize={40}
                                            width="100%"
                                        >
                                            {renderOption}
                                        </List>
                                    )}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </div>
                );
            }}

        </Listbox>
    );
};

export default SightlySelect;
