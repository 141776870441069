import { z } from "zod";
import { SMART_LIST_CONTENT_TYPE, SMART_LIST_OBJECTIVE, SMART_LIST_TYPE } from '../types/SmartlistTypes';


export const CreateSmartListSchema = z.object({
  brandProfileId: z.number(),
  name: z.string(),
  objective: z.enum([
    SMART_LIST_OBJECTIVE.REACH,
    SMART_LIST_OBJECTIVE.BRANDING,
    SMART_LIST_OBJECTIVE.TRENDING,
    SMART_LIST_OBJECTIVE.SUITABILITY,
  ]),
  contentTypes: z.array(
    z.enum([
      SMART_LIST_CONTENT_TYPE.YOUTUBE_CHANNEL,
      SMART_LIST_CONTENT_TYPE.YOUTUBE_VIDEO,
    ]),
  ),
  listType: z.enum([SMART_LIST_TYPE.INCLUSION, SMART_LIST_TYPE.EXCLUSION]),
  segments: z.array(z.object({ id: z.number() })).optional(),
});

export const UpdateSmartlistSchema = CreateSmartListSchema.partial();

export const SmartListSchema = CreateSmartListSchema.merge(z.object({
  id: z.number(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
}));

export const GetSmartListResponseSchema = z.object({
  count: z.number(),
  totalCount: z.number(),
  currentPage: z.number(),
  nextPage: z.number().nullable(),
  lastPage: z.number(),
  results: z.array(SmartListSchema),
});


export type UpdateSmartlist = z.infer<typeof UpdateSmartlistSchema>
export type SmartList = z.infer<typeof SmartListSchema>;
export type CreateSmartList = z.infer<typeof CreateSmartListSchema>;