import { MyLocationGenerics } from '@/classes/utils';
import LikeDislikeButton from '@/components/LikeDislikeButton';
import { LoadingPage } from '@/components/LoadingPage';
import SightlyGradientCard from '@/components/Sightly/SightlyGradientCard';
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles';
import { useFeedback } from '@/hooks/moment/useFeedback';
import useUser from '@/hooks/useUser';
import { PATH_TOPIC_DETAILS, PATH_TREND_DETAILS, PATH_TRENDS_SEARCH } from '@/routes';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';
import { perms } from '@/staticData/permissions';
import { logError } from '@/utils';
import MomentArticles from '@/views/Discover/Moments/v2/MomentModal/MomentArticles';
import MomentKeywords from '@/views/Discover/Moments/v2/MomentModal/MomentKeywords';
import MomentVideos from '@/views/Discover/Moments/v2/MomentModal/MomentVideos';
import { api } from '@/views/Discover/Moments/v2/api';
import { ActionBox } from '@/views/Discover/Moments/v2/components/ActionBox';
import { BrandProfileLink } from '@/views/Discover/Moments/v2/components/BrandProfileLink';
import { CategoriesSection } from '@/views/Discover/Moments/v2/components/MomentSlideOver/CategoriesSection';
import { QueriesSection } from '@/views/Discover/Moments/v2/components/MomentSlideOver/QueriesSection';
import { ScenariosSection } from '@/views/Discover/Moments/v2/components/MomentSlideOver/ScenariosSection';
import { SideNavigation } from '@/views/Discover/Moments/v2/components/MomentSlideOver/SideNavigation';
import { UnsafeContentSection } from '@/views/Discover/Moments/v2/components/MomentSlideOver/UnsafeContentSection';
import {
  useActionJustifications,
  useArticles,
  useKeywords,
  useScenarioOptions, useScenarios,
  useVideos,
} from '@/views/Discover/Moments/v2/components/MomentSlideOver/useMoment';
import { rqKeys } from '@/views/Discover/Moments/v2/rqKeys';
import { ClusterType, MomentScenarioType } from "@/views/Discover/Moments/v2/types";
import BrandProfileSelect from '@/views/Trends/components/BrandProfileSelect';
import { useTopicTrendMomentById } from '@/views/Trends/hooks/useTopicTrendMoments';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useMatch, useNavigate, useSearch } from '@tanstack/react-location';
import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query';
import { useFlag } from '@unleash/proxy-client-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

export const MomentDetails = () => {
  const [trendsSource, setTrendsSource] = useState<string>()

  const elasticSearchEnabled = useFlag('enable_moments_elastic_search')

  const {
    params: { id }
  } = useMatch<MyLocationGenerics>()

  const {
    brandProfileId,
    redirect
  } = useSearch<MyLocationGenerics>()

  const navigate = useNavigate();

  useEffect(() => {
    const storageSource = localStorage.getItem('trendsSource')
    if (!storageSource) return

    setTrendsSource(storageSource)
  }, []);

  const limit = 5;
  const defaultActiveTab = 'Overview';

  const queryClient = useQueryClient()
  const { userPermissions } = useUser()
  const isMutating = useIsMutating()

  const dummyMoment: ClusterType = {
    clusterId: -1,
    clusterName: "clusterName",
    originalRecommendedAction: "no action",
    action: "no action",
    activationStatuses: null,
    editedFromAction: null,
    editedBy: null,
    editedDateTime: null,
    blockKeywordsCount: 5,
    targetKeywordsCount: 20,
    articleCount: 50,
    videoCount: 10,
    feedback: null,
    keywordsFeedback: null,
    recommendedActionFeedback: null,
    garmFlagged: false,
    minPublishedAt: "minPublishedAt"
  }
  const [moment, setMoment] = useState<ClusterType>(dummyMoment)
  const { momentsQuery } = useTopicTrendMomentById(Number(id), brandProfileId)

  useEffect(() => {
    if (momentsQuery.data)
      setMoment(momentsQuery.data)
  }, [momentsQuery])

  const [selectedBrandProfileId, setSelectedBrandProfileId] = useState(brandProfileId)
  const [currentBrandProfileId, setCurrentBrandProfileId] = useState(brandProfileId)

  const [adding, setAdding] = useState<boolean>(false)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [activeTab, setActiveTab] = useState<string>(defaultActiveTab)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [videos, setVideos] = useState<any[]>([])

  const [currentArtPage, setCurrentArtPage] = useState<number>(1)
  const [articles, setArticles] = useState<any[]>([])

  const {
    data: brandProfileSelectOptions
  } = useBrandProfiles({
    submittedOnly: true
  });

  const {
    postTrendRecActionFeedback,
    postTrendKeywordsFeedback,
    invalidateMomentsQuery
  } = useFeedback(moment, brandProfileId)

  // Won't be called if BP is undef
  const articlesQuery = useArticles({ brandProfileId: selectedBrandProfileId, moment, page: currentArtPage, limit })
  const keywordsQuery = useKeywords({ brandProfileId: selectedBrandProfileId, moment })
  const scenariosQuery = useScenarios({ brandProfileId: selectedBrandProfileId, moment, setHasUnsavedChanges })
  const scenariosOptionsQuery = useScenarioOptions({ brandProfileId: selectedBrandProfileId, moment })
  const actionJustifications = useActionJustifications({ brandProfileId: selectedBrandProfileId, moment })
  const videosQuery = useVideos({ brandProfileId: selectedBrandProfileId, moment, page: currentPage, limit })

  useEffect(() => {
    keywordsQuery.refetch()
  }, [moment?.action])

  // Adding the current moment as QueryData would fix https://sightly.atlassian.net/browse/EN-14723
  // The modal shouldn't close even if the recommended action is not in the action filter in the boards page
  useEffect(() => {
    moment && queryClient.setQueryData(rqKeys.momentInViewSlideOver(selectedBrandProfileId, moment?.clusterId), (prev: any) => moment)
    setVideos([]);
    setCurrentPage(1);
    setArticles([]);
    setCurrentArtPage(1);
  }, [moment]);

  useEffect(() => {
    if (videosQuery.data) {
      setVideos(prevVideos => {
        const newVideos = videosQuery.data.filter(video =>
          !prevVideos.some(prevVideo => prevVideo.videoId === video.videoId)
        );
        if (newVideos.length === 0) return prevVideos;
        return [...prevVideos, ...newVideos];
      });
    }
  }, [videosQuery.data]);

  const handleLoadMore = () => {
    if ((currentPage * videosQuery.count) < (videosQuery.total ?? 0)) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    if (currentBrandProfileId !== selectedBrandProfileId) {
      setArticles([]);
      setCurrentArtPage(1);
      setCurrentBrandProfileId(selectedBrandProfileId);
    }

    if (articlesQuery.data) {
      setArticles(prevArticles => {
        const newArticles = articlesQuery.data;
        if (newArticles.length === 0) return prevArticles;
        return [...prevArticles, ...newArticles];
      });
    }
  }, [articlesQuery.data]);

  const handleLoadMoreArticles = () => {
    if ((currentArtPage * articlesQuery.count) < (articlesQuery.total ?? 0)) {
      setCurrentArtPage(prevPage => prevPage + 1);
    }
  };


  const handleDownload = async () => {
    if (selectedBrandProfileId === undefined || moment.clusterId === -1) return

    const args = {
      brandProfileId: selectedBrandProfileId,
      clusterIds: [moment.clusterId]
    }
    try {
      await api.moments.download(args)
    } catch (err) {
      toast.error('There was an error downloading your moments.')
    }
  }

  const showUnsafeContentSection = actionJustifications.data?.categoryActionJustificationWithGarm
    && (actionJustifications.data?.categoryActionJustificationWithGarm.length > 0);

  const handleBack = () => {
    let path
    if (trendsSource === 'topic') {
      path = PATH_TOPIC_DETAILS
    } else if (trendsSource === 'trend') {
      path = PATH_TREND_DETAILS
    } else if (trendsSource === 'search') {
      path = PATH_TRENDS_SEARCH
    }
    navigate({
      to: path + (redirect ? `/${redirect}` : ''),
      search(prev?) {
        return {
          ...prev,
        }
      }
    })
  };

  if (!moment) {
    return <div>loading</div>
  }

  return (
    <div className="flex flex-col h-full divide-y divide-gray-200">

      {/*Header*/}
      <div className='flex flex-row justify-between align-center px-4 pt-6 pb-6 bg-white sm:px-6 text-lg'>
        <div className='flex flex-col gap-2 pr-4'>
          <div
            data-testid="moment-headline"
            className="w-full flex justify-between"
          >
            <div className='flex gap-4'>
              <ArrowLeftIcon stroke='bold' className='hover:text-sightlyPurple h-7 w-7 font-bold cursor-pointer'
                onClick={handleBack} />
              {moment.clusterId === -1 ? (
                <div className='!font-bold'>
                  <LoadingPage message="Moment" />
                </div>
              ) : (
                <div>
                  <span className="font-bold">Moment:</span> {moment.clusterName}
                </div>
              )}
            </div>

            <button
              className="pl-7 focus:outline-none "
              data-testid="moment-download-button"
              onClick={handleDownload}
              disabled={moment.clusterId === -1}
            >
              <ArrowDownTrayIcon
                className="w-6 h-6 hover:text-sightlyPurple"
                aria-hidden="true"
              />
            </button>
          </div>

          <div className="flex h-18">
            <ActionBox text={moment.action} />
            {!elasticSearchEnabled && selectedBrandProfileId && postTrendRecActionFeedback &&
              <div className="self-center pl-4">
                <LikeDislikeButton
                  textColor={null}
                  handleDownClick={undefined}
                  text={'What do you think about the recommended action?'}
                  buttonState={moment.recommendedActionFeedback}
                  handleClick={(val: string) => {
                    postTrendRecActionFeedback({
                      clusterId: moment.clusterId,
                      feedback: val,
                      brandProfileId: Number(selectedBrandProfileId)
                    })
                  }}
                  disabled={isMutating > 0}
                />
              </div>
            }
          </div>
        </div>

        {brandProfileSelectOptions && brandProfileSelectOptions.length > 0 &&
          <BrandProfileSelect
            value={selectedBrandProfileId}
            options={brandProfileSelectOptions}
            label={"Brand Profile"}
            labelKey="brandProfileName"
            valueKey="brandProfileId"
            onChange={setSelectedBrandProfileId}
            size='small'
          />}
      </div>

      {/*Body*/}
      <div className="flex gap-[2vw] bg-gray-100">
        <SideNavigation
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          brandProfileId={selectedBrandProfileId}
        />
        <div className="m-4 w-full">
          <div className="p-6 mb-6 overflow-auto bg-white rounded-xl w-full flex flex-col gap-[2vh]">
            {(() => {
              switch (activeTab) {
                case 'Overview':
                  return (
                    <>
                      <div className='flex flex-col gap-[2vh]'>
                        <SightlyGradientCard>
                          <p data-testid="moment-slideover-article-cluster-summary" className="text-base">
                            {moment.clusterSummary ?
                              JSON.stringify(moment.clusterSummary)
                              : <LoadingPage message='Loading summary' />
                            }
                          </p>
                        </SightlyGradientCard>
                      <div>
                        <h4 className="mb-2">Article Count</h4>
                        <div data-testid="moment-slideover-article-count-chart-div">
                          {moment.articleCountPerDay ?
                            <LineChart
                              width={700}
                              height={300}
                              data={moment.articleCountPerDay}
                              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="day" tickCount={5} />
                              <YAxis dataKey="story_count" tickCount={5} />
                              <Tooltip
                                formatter={(value, name, props) => [value, 'Article count']}
                              />
                              <Line type="monotone" dataKey="story_count" stroke="#7748f9" />
                            </LineChart>
                            : <LoadingPage message='Loading chart' />
                          }
                        </div>
                      </div>
                      </div>
                    </>);
                case 'Brand Mentality':
                  return (
                    <>
                      {showUnsafeContentSection && (
                        <UnsafeContentSection
                          brandProfileId={selectedBrandProfileId}
                          isLoading={actionJustifications.isLoading}
                          categories={actionJustifications.data?.categoryActionJustificationWithGarm}
                        />
                      )}

                      <div className="grid grid-cols-12 gap-4 pb-4">
                        <div className="col-span-5">
                          <QueriesSection
                            brandProfileId={selectedBrandProfileId}
                            isLoading={actionJustifications.isLoading}
                            queries={actionJustifications.data?.queryActionJustifications}
                          />
                        </div>
                        <div className="col-span-7">
                          <CategoriesSection
                            brandProfileId={selectedBrandProfileId}
                            isLoading={actionJustifications.isLoading}
                            categories={actionJustifications.data?.categoryActionJustificationWithoutGarm}
                          />
                        </div>
                      </div>

                      <div className="p-4 bg-white rounded-xl">
                        <div className="flex justify-between">
                          <h4 className="mb-2">Scenario Questions</h4>
                          <BrandProfileLink dataTestId="brand-profile-scenarios-link" brandProfileId={selectedBrandProfileId} section="scenarios" />
                        </div>
                        <ScenariosSection
                          isLoading={scenariosQuery.isLoading}
                          scenariosOptions={scenariosOptionsQuery.data || []}
                          adding={adding}
                          setAdding={setAdding}
                          scenarios={scenariosQuery.data || []}
                        />
                      </div>
                    </>);
                case 'Articles':
                  return (
                    <>
                      <h4 className="mb-2">Articles</h4>
                      <div className="max-h-[calc(65vh)] overflow-auto">
                        <MomentArticles
                          moment={moment}
                          articles={articles || []}
                          isLoading={articlesQuery.isLoading}
                          page={currentArtPage}
                          totalItems={articlesQuery.total || 0}
                          pageSize={limit}
                          handlePageChange={handleLoadMoreArticles}
                        />
                      </div>
                    </>)
                case 'Videos':
                  return (
                    <>
                      <h4 className="mb-2">Videos</h4>
                      <div className="max-h-[calc(65vh)] overflow-auto">
                        <MomentVideos
                          moment={moment}
                          videos={videos || []}
                          isLoading={videosQuery.isLoading}
                          page={currentPage}
                          totalItems={videosQuery.total || 0}
                          pageSize={limit}
                          handlePageChange={handleLoadMore}
                        />
                      </div>
                    </>)
                case 'Keywords':
                  if (userCanPermissionProductQuota({
                    requiredPermissionValue: perms.AYLIEN_MOMENT_KEYWORDS_READ,
                    userPermissions: userPermissions,
                    checkType: PermissionCheckType.PERMISSION_CHECK
                  })) {
                    return (
                      <>
                        <h4 className="mb-2">Keywords</h4>
                        <div className="max-h-[calc(65vh)] overflow-auto">
                          <MomentKeywords
                            moment={moment}
                            postTrendKeywordsFeedback={postTrendKeywordsFeedback}
                            keywords={keywordsQuery.data}
                            isLoading={keywordsQuery.isLoading}
                          />
                        </div></>)
                  }
                  return null;
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    </div >
  )
}
