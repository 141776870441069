import React from 'react'

interface VerticalTabsProps {
  children: React.ReactElement[]
  active: string
  onChange: (key: string) => void
  disabled?: boolean
}

const verticalTabStyle = {
  normal: `hover:text-sightlySlate text-[#696969] text-sm font-medium cursor-pointer flex flex-col`,
  normalDisabled: `text-[#696969]/50 text-sm font-medium flex flex-col`,
  active: `text-sightlySlate text-sm font-bold cursor-pointer flex flex-col shadow-[-3px_0_0_#7748F9_inset]`,
  activeDisabled: `text-sightlySlate/50 text-sm font-bold flex flex-col shadow-[-3px_0_0_#7748F9_inset]`
}

export default function VerticalTabs({ children, active, onChange, disabled }: VerticalTabsProps) {
  
  const getTabClass = (child: React.ReactElement) => {
    if (disabled) {
      return`${child.key === active ? verticalTabStyle.activeDisabled : verticalTabStyle.normalDisabled}`
    }
    return`${child.key === active ? verticalTabStyle.active : verticalTabStyle.normal}`
  }
  
  return (
    <div className="flex flex-col select-none h-14 pt-4">
      {children.map((child: React.ReactElement) => {
        if (!child) return <div />
        return (
          <div
            className="py-4 pl-4"
            key={child.key}
            onClick={() => {
              if (disabled) return

              child.key && onChange(child.key.toString())
            }}
          >
            <div className={getTabClass(child)}>{child}</div>
          </div>
        )
      })}
    </div>
  )
}
