import React from 'react'

interface IProps {
    handleClick: Function
    tabImage: React.ReactNode,
    datatestid?: string
    isActive: boolean
}

export const Tab = (props: IProps) => {
    return (
        <div className={`flex flex-row justify-center cursor-pointer mx-2 rounded-md ${props.isActive ? 'text-#2D9CDB' : 'text-black hover:text-[#2D9CDB]'}`}
            data-testid={props.datatestid} onClick={() => props.handleClick()}>
            {props.tabImage}
        </div>
    )
}
