import { stripTags } from '@/utils'
import PlatformIcon from '@/views/Discover/Boards/components/conversations/PlatformIcon'
import { ISocialPost } from '@/views/Trends/types'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import React from 'react'
dayjs.extend(localizedFormat)

interface SocialPostCardProps {
  socialPost: ISocialPost
}

const SocialPostResultCard = ({ socialPost: narrative }: SocialPostCardProps) => {
  return (
    <a
      href={narrative.url || '#'}
      target="_blank"
      rel="noopener"
    >
      <div
        className="bg-white rounded-lg shadow-md overflow-hidden p-4 w-80 h-[184px] inline-block mr-4 mb-4"
        data-testid="social-post-result-card"
      >
        <div className="flex flex-col">
          <div className="flex justify-between mb-4">
            <div className="inline-block">
              <PlatformIcon
                key={`platform-icon-${narrative.source}`}
                platformName={narrative.source}
                height="24px"
                data-testid="social-post-result-card-platform-icon"
              ></PlatformIcon>
            </div>
            <span
              className="text-gray-400 text-sm ml-1"
              data-testid="social-post-result-card-date"
            >
              {dayjs(narrative.publishedDate).format('L')}
            </span>
          </div>
          <p
            className="text-gray-600"
            data-testid="social-post-result-card-content"
          >
            <span className="line-clamp-4">{stripTags(narrative.post)}</span>
          </p>
        </div>
      </div>
    </a>
  )
}

export default SocialPostResultCard
