import useUser from '@/hooks/useUser';
import { routes } from '@/routes';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';
import { products } from '@/staticData/products';
import { ProductCountTracker } from '@/views/BrandProfiles/BrandProfile/components/ProductCountTracker';
import { UserGroupIcon, UserIcon } from '@heroicons/react/24/outline';
import { useLocation, useNavigate } from '@tanstack/react-location';
import React from 'react';
import { SettingsTab } from '@/layouts/types';

interface IProps {
    children: React.ReactElement
}

export const SettingsLayout = ({ children }: IProps) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { currentAccount, perms, userPermissions } = useUser()

    const tabs: SettingsTab[] = [
        {
            title: 'Personal Settings',
            icon: <UserIcon className='h-[4vh] w-[4vh]' />,
            children: [
                { 
                    title: 'My Profile',
                    route: routes.app.settings.profile.path
                }
            ]
        },
        ...(userCanPermissionProductQuota({
            requiredPermissionValue: perms.ACCOUNT_SETTINGS_READ,
            userPermissions: userPermissions,
            checkType: PermissionCheckType.PERMISSION_CHECK
        })
            ? [{
                title: 'Account Settings',
                icon: <UserGroupIcon className='h-[4vh] w-[4vh]' />,
                children: [
                    { 
                        title: 'Account Details',
                        route: routes.app.settings.account.path
                     },
                    { 
                        title: 'Users',
                        route: routes.app.settings.users.path
                    },
                    ...(currentAccount?.stripeSubscriptionId ? [{ title: 'Payments & Billing' }] : [])
                ]
            }]
            : [])
    ];

    return <div className='flex h-full w-full divide-x'>
        <div className='flex flex-col justify-between'>
            <div className='flex flex-col divide-y gap-[2vh] py-[1vw] px-[2vw]'>
                <h1 className='text-xl leading-9 font-extrabold text-gray-900'>Settings</h1>
                {tabs.map((tab) => (
                    <div key={tab.title} className='flex flex-col gap-[2vh] pt-[2vh]'>
                        <div className='flex gap-[1vw] items-center text-sm text-gray-500 uppercase'>
                            {tab.icon}
                            {tab.title}
                        </div>

                        {tab.children ?
                            <div className='flex flex-col gap-[1vh]'>
                                {tab.children.map((c) => (
                                    <button
                                        onClick={() => navigate({ to: c.route })}
                                        key={c.title}
                                        className={`p-[1vw] pl-[3vw] text-left ${location.current.pathname.includes(c.route!) ? 'text-sightlyBlue bg-sightlyLightBlue': 'text-gray-900 hover:bg-gray-100'}rounded-md`}>{c.title}</button>
                                ))}
                            </div>
                        : null}
                    </div>
                ))}
            </div>
            <ProductCountTracker
                productName={products.USERS}
                title='Users'
                direction='col'
                additionalStyle='p-4'
                dataTestId='product-count-tracker-users'
            />
        </div>
        <div className="grow h-[calc(100vh-57px)]">{children}</div>
    </div>
}
