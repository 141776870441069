import React from 'react'
import TagPicker from 'rsuite/lib/TagPicker'
import debounce from 'just-debounce-it'
import Input from 'rsuite/lib/Input'
import Tree from 'rsuite/lib/Tree'
import InputGroup from 'rsuite/lib/InputGroup'
import Icon from 'rsuite/lib/Icon'
import Panel from 'rsuite/lib/Panel'
import { connect } from 'react-redux'
import { CheckIcon } from '@heroicons/react/24/outline'
import { NoSymbolIcon } from '@heroicons/react/24/outline'
import Tooltip from 'rsuite/lib/Tooltip'
import Whisper from 'rsuite/lib/Whisper'
import { patchBrandProfileTopics, fetchBrandProfileTopics, setBrandProfileTopics } from '@/redux/actions/brandProfiles'
import FiltersLabel from '@/views/Engage/Lists/ListBuilder/components/FiltersLabel'
import { useMatch, useSearch } from '@tanstack/react-location'
import { LoadingPage } from '@/components/LoadingPage'
import SightlyToggle from '@/components/Sightly/SightlyFormElements/SightlyToggle'
import BrandProfileFooter from '@/views/BrandProfiles/BrandProfile/components/BrandProfileFooter'
import useSteps from '@/hooks/brandProfile/useSteps'

const actionIdOptions = [
  {
    label: 'Include',
    id: 'include'
  },
  {
    label: 'Exclude',
    id: 'exclude'
  },
  {
    label: 'No Action',
    id: 'noaction'
  }
]

const mapStateToProps = (state) => {
  return {
    brandProfile: state.brandProfileUnderEdit,
    brandProfileTopicsLoading: state.brandProfileTopicsLoading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    patchBrandProfileTopics: (data) => dispatch(patchBrandProfileTopics(data)),
    fetchBrandProfileTopics: (data) => dispatch(fetchBrandProfileTopics(data)),
    setBrandProfileTopics: (topics) => dispatch(setBrandProfileTopics(topics))
  }
}

const Node = (props) => {
  const nodeProps = props.nodeProps
  const {
    params: { brandProfileId }
  } = useMatch()

  const handleClick = (e, newValProposed) => {
    e.preventDefault()

    if (newValProposed === 2 && !nodeProps.topicExclusionEligible) return

    let newTopics = JSON.parse(JSON.stringify(props.componentTopics))
    setTopicAction(nodeProps.topicId, newValProposed, newTopics)
    props.setComponentTopics(newTopics)
    props.setBrandProfileTopics(newTopics)
    let newVal = getNewTopicsVal(newValProposed, nodeProps.topicResponseId)
    let params = {
      topics: [
        {
          topicId: nodeProps.topicId,
          topicResponseId: newVal
        }
      ],
      brandProfileId
    }
    props.patchBrandProfileTopics(params)
  }

  function getNewTopicsVal(newValProposed, oldVal) {
    if (newValProposed == oldVal) return null
    return newValProposed
  }

  function setTopicAction(topicId, value, topics) {
    for (const topic of topics) {
      markSelected(topicId, value, topic)
    }
  }

  function markSelected(topicId, value, topic) {
    if (topic.topicId == topicId) {
      if (topic.topicResponseId === value) {
        value = null
      }
      topic.topicResponseId = value
    } else {
      if (topic.children && topic.children.length > 0) {
        for (const child of topic.children) {
          markSelected(topicId, value, child)
        }
      }
    }
  }

  const notEligibleTooltip = <Tooltip>Exclusion on this topic is not supported by Google Ads</Tooltip>

  async function resizeTableWidth() {
    const startTime = Date.now();

    let virtualGridParent
    let virtualGrid
    
    while (!(virtualGridParent && virtualGrid) && Date.now() - startTime < 1000) {
      await new Promise(resolve => setTimeout(resolve, 50));
      virtualGridParent = document.querySelector('.brand-profile-topics-tree');
      virtualGrid = document.querySelector('.ReactVirtualized__Grid');
    }
  
    if (virtualGridParent && virtualGrid) {
      virtualGrid.style.width = `${virtualGridParent.clientWidth}px`;
    }
  }
  
  window.addEventListener('resize', resizeTableWidth)

  return (
    <div
      className="group w-full flex"
      data-testid="topic-row-node"
    >
      <span
        className="w-[60%] overflow-hidden text-ellipsis whitespace-nowrap"
        data-testid="topic-name"
      >
        {nodeProps.topicName}
      </span>

      {/* Inclusion button */}
      <span className="pl-4 flex w-1/5 items-center">
        <div
          className="flex items-center justify-center group-hover:border-2 group-hover:border-gray-300 rounded-md group-hover:bg-white w-6"
          data-testid="topic-include-button"
          onClick={(e) => handleClick(e, 1)}
          data-checked={nodeProps.topicResponseId === 1}
        >
          <CheckIcon
            className={`stroke-2	w-5 ${
              nodeProps.topicResponseId === 1
                ? 'text-green-500'
                : 'opacity-0 text-gray-300 group-hover:opacity-100 hover:text-gray-400'
            }`}
          />
        </div>
      </span>

      {/* Exclusion button */}
      {(nodeProps.topicExclusionEligible || nodeProps.topicResponseId !== 1) && (
        <span className="pl-7 flex w-1/5">
          <Whisper
            placement="top"
            controlId="control-id-click"
            trigger="hover"
            speaker={notEligibleTooltip}
            disabled={nodeProps.topicExclusionEligible}
          >
            <div
              data-testid="topic-exclude-button"
              className={`flex items-center justify-center rounded-md w-6 p-0.5 
                                group-hover:border-2 group-hover:border-gray-200 
                                group-hover:${nodeProps.topicExclusionEligible ? 'bg-white' : 'bg-gray-200'}`}
              onClick={(e) => handleClick(e, 2)}
              data-checked={nodeProps.topicResponseId === 2}
            >
              <NoSymbolIcon
                className={`stroke-2 w-5 
                                    ${
                                      nodeProps.topicResponseId === 2 && nodeProps.topicExclusionEligible
                                        ? 'text-red-500'
                                        : nodeProps.topicExclusionEligible
                                        ? 'opacity-0 text-gray-300 group-hover:opacity-100 hover:text-gray-400'
                                        : 'text-gray-600'
                                    }`}
              />
            </div>
          </Whisper>
        </span>
      )}
    </div>
  )
}

class Topics extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetched: false,
      cascadeUp: false,
      sort: 'asc',
      actionFilter: [],
      search: '',
      componentTopics: []
    }
  }

  componentWillMount() {
    this.fetchTopicsFunction()
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.brandProfile.topics) {
      this.setState({
        componentTopics: newProps.brandProfile.topics
      })
    }
  }

  fetchTopicsFunction = () => {
    let params = {
      brandProfileId: this.props.brandProfileId,
      sort: this.state.sort,
      filter: this.state.actionFilter,
      searchTerm: this.state.search
    }
    this.props.fetchBrandProfileTopics(params)
  }

  handleSearch = debounce((text) => {
    this.setState({ search: text }, () => {
      this.fetchTopicsFunction()
    })
  }, 700)

  handleSort = (bool) => {
    let val = bool ? 'asc' : 'desc'
    this.setState({ sort: val }, () => {
      this.fetchTopicsFunction()
    })
  }

  render() {
    return (
      <Panel header={<div className="sightlyPanelHeader">Topics</div>}>
        <div className="flex flex-col gap-8">
          <div className="descriptionText">
            Topics available from Google Ads can be marked as “Include” or “Exclude” below. These settings are available
            to internal admin users only.
          </div>

          <div>
            <FiltersLabel text="Actions Taken" />
            <TagPicker
              block
              preventOverflow
              data={actionIdOptions}
              labelKey={'label'}
              valueKey={'id'}
              value={this.state.actionFilter}
              placeholder="Filter by action"
              onChange={(val) => {
                this.setState({ actionFilter: val }, () => {
                  this.fetchTopicsFunction()
                })
              }}
            />
          </div>

          {this.state.actionFilter?.length > 0 && (
            <SightlyToggle
              id="sort"
              setEnabled={this.handleSort}
              enabled={this.state.sort === 'asc'}
              enabledText="sort a-z"
              disabledText="sort z-a"
            />
          )}
          <div>
            <InputGroup>
              <Input
                placeholder="Search..."
                onChange={(val) => this.handleSearch(val)}
              />

              <InputGroup.Button
                onClick={() => {}}
                style={{ backgroundColor: 'transparent' }}
              >
                <Icon
                  style={{ color: '#0092d1' }}
                  icon="search"
                />
              </InputGroup.Button>
            </InputGroup>

            <div className="w-full bg-gray-100 border border-gray-300 p-3 font-bold mt-2">
              <div className="w-3/5 inline-block">Topic Name</div>
              <div className="w-1/5 inline-block">Include</div>
              <div className="w-1/5 inline-block">Exclude</div>
            </div>

            {this.props.brandProfileTopicsLoading ? (
              <div style={{ height: 200 }}>
                <LoadingPage message="Fetching Topics" />
              </div>
            ) : (
              <Tree
                className="border brand-profile-topics-tree"
                expandAll={false}
                defaultExpandAll={false}
                name="topics"
                labelKey="topicName"
                valueKey="topicId"
                data={this.state.componentTopics}
                virtualized={true}
                searchable={false}
                renderTreeNode={(nodeProps) => {
                  return (
                    <Node
                      disabled={this.props.viewOnly}
                      brandProfile={this.props.brandProfile}
                      patchBrandProfileTopics={this.props.patchBrandProfileTopics}
                      brandProfileIdUnderEdit={this.props.brandProfileIdUnderEdit}
                      nodeProps={nodeProps}
                      componentTopics={this.state.componentTopics}
                      setComponentTopics={(topics) => this.setState({ componentTopics: topics })}
                      setBrandProfileTopics={this.props.setBrandProfileTopics}
                      searchTerm={this.state.search}
                    />
                  )
                }}
              />
            )}
          </div>
        </div>
        <BrandProfileFooter
          activeStep={this.props.stepProps.activeStep}
          disabled={false}
          handlePrevious={this.props.stepProps.handlePreviousClick}
          handleContinue={this.props.stepProps.handleContinueClick}
        />
      </Panel>
    )
  }
}

const TopicsWrapper = ({ ...rest }) => {
  const stepProps = useSteps()
  const {
    params: { brandProfileId }
  } = useMatch()
  const { viewOnly: viewOnlyParam } = useSearch()
  const viewOnly = viewOnlyParam || false
  return (
    <Topics
      viewOnly={viewOnly}
      brandProfileId={brandProfileId}
      stepProps={stepProps}
      {...rest}
    />
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(TopicsWrapper)
