import React from 'react';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Rectangle,
    ResponsiveContainer,
    Tooltip,
    XAxis,
} from 'recharts';

interface IData {
    date: string;
    count: number;
}

interface IProps {
    data: IData[];
}

interface ITooltipProps {
    active?: boolean;
    payload?: {
        value: number;
    }[];
    label?: string;
}

const CustomTooltip: React.FC<ITooltipProps> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: '#4B5563', padding: '5px', borderRadius: '4px' }}>
                <p style={{ color: '#FFFFFF', fontSize: '12px' }}>
                    {label}: {payload[0].value}
                </p>
            </div>
        );
    }
    return null;
};

const BarChartOverview: React.FC<IProps> = ({ data }) => {
    const renderCustomizedBar = (props: any) => {
        const { x, y, width, height, index } = props;

        return (
            <g>
                <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill="#7F56D9"
                    data-testid={`bar-${index}`} 
                />
            </g>
        );
    };

    return (
        <ResponsiveContainer width="100%" height="40%" data-testid="responsive-container">
            <BarChart margin={{ bottom: 20 }} data={data} data-testid="bar-chart">
                <CartesianGrid horizontal={true} vertical={false} data-testid="cartesian-grid" />
                <XAxis
                    dataKey="date"
                    axisLine={false}
                    tickLine={false}
                    style={{
                        fontSize: '8px',
                    }}
                    data-testid="x-axis"
                />
                <Tooltip content={<CustomTooltip />} data-testid="tooltip" />
                <Bar dataKey="count" shape={renderCustomizedBar} />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarChartOverview;
