import { Dispatch, SetStateAction } from 'react'
import { ZodType, z } from 'zod'
import { THUMBS_UP_LIMIT, THUMBS_DOWN_LIMIT } from '@/views/Discover/Boards/Narratives/constants'

export const MomentPreviewSchema = z.object({
  id: z.number(),
  image: z.string().optional(),
  name: z.string(),
  summary: z.string()
})

export type MomentPreviewType = z.infer<typeof MomentPreviewSchema>

export type ConversationSourcePlatform =
  'Blogs & Forums' |
  'YouTube' |
  'Instagram' |
  'Facebook' |
  'Reviews' |
  'Reddit' |
  'TikTok' |
  'Twitter' |
  'X' |
  'Pinterest' |
  'Truth Social' |
  'Bluesky' |
  'Threads' |
  'LinkedIn' |
  'Mastadon' |
  'News' |
  'Tumblr';

export interface ConversationPreviewType {
  id: number
  name: string
  sourceTypeList: ConversationSourcePlatform[]
}

export interface NewBoardNlpPromptFormType {
  prompt: string
  ideasToLeanInto?: string | undefined
  ideasToAvoid?: string | undefined
  thumbsUpMoments?: MomentPreviewType[] | undefined
  thumbsDownMoments?: MomentPreviewType[] | undefined
  iteration?: number | undefined
  momentsElasticSearchEnabled?: boolean | undefined
  narrativesElasticSearchEnabled?: boolean | undefined
}

export const NewBoardNlpPromptFormSchema: ZodType<NewBoardNlpPromptFormType> = z.object({
  prompt: z.string().min(1, 'Please enter a prompt').max(5000, 'Please shorten the prompt'),
  ideasToLeanInto: z.string().max(5000, 'Please shorten the feedback').optional(),
  ideasToAvoid: z.string().max(5000, 'Please shorten the feedback').optional(),
  thumbsUpMoments: z.array(MomentPreviewSchema).max(THUMBS_UP_LIMIT, 'Thumbs up feedback limit reached').optional(),
  thumbsDownMoments: z
    .array(MomentPreviewSchema)
    .max(THUMBS_DOWN_LIMIT, 'Thumbs down feedback limit reached')
    .optional(),
  iteration: z.number().optional()
})

export interface NLBoardRequest {
  boardIdea: string[]
  maxPublishedDate?: string
  minPublishedDate?: string
  maxPublishedDateMediumRange?: string
  minPublishedDateMediumRange?: string
  maxPublishedDateLongRange?: string
  minPublishedDateLongRange?: string
  moveAwayFrom?: string[]
  moveTo?: string[]
  weightAway?: number
  weightLean?: number
  threshold?: number
  resultCount?: number
  thumbsUp?: MomentPreviewType[]
  thumbsDown?: MomentPreviewType[]
}

export interface SocialConversationRequest {
  boardIdea: string[]
  maxPublishedDate?: string
  minPublishedDate?: string
  maxPublishedDateMediumRange?: string
  minPublishedDateMediumRange?: string
  maxPublishedDateLongRange?: string
  minPublishedDateLongRange?: string
  moveAwayFrom?: string[]
  moveTo?: string[]
  weightAway?: number
  weightLean?: number
  threshold?: number
  resultCount?: number
}

export const MomentPreviewsSchema = z.object({
  previews: z.array(MomentPreviewSchema),
  actualCount: z.number(),
  previewsCount: z.number(),
  previewsCountMediumRange: z.number(),
  previewsCountLongRange: z.number()
})

export type MomentPreviewsType = z.infer<typeof MomentPreviewsSchema>

export type ItemDetail = {
  id: number
  name: string
  selected?: boolean
  targeted?: boolean
  alwaysDisabled?: boolean
  description: string
  defaultType: string
  groupName?: string
  img?: string
  hoverText?: string
}
export type Item = {
  id?: number | undefined
  name: string
  items: ItemDetail[]
}

type SearchedValues = {
  id: number
  name: string
}

export type SearchResult = {
  [id: number]: SearchedValues
}

export type HandleSelectProps = {
  itemType: ItemTypes
  name: string
  id: number
}

export type IProps = {
  brandProfileId: number | undefined
  boardType: 'custom' | 'general' | 'naturalLanguage'
}

export enum ItemTypes {
  'aylienNews' = 'aylienNews',
  'aylienIndustries' = 'aylienIndustries',
  'queries' = 'queries',
  'aggregate' = 'aggregate'
}

export const itemsValidationSchema = z.array(
  z.object({
    name: z.string(),
    items: z.array(
      z.object({
        name: z.string(),
        id: z.number()
      })
    )
  })
)

export const itemsValidationWithDescriptionSchema = z.array(
  z.object({
    name: z.string(),
    items: z.array(
      z.object({
        name: z.string(),
        id: z.number(),
        description: z.string().nullable()
      })
    )
  })
)

export const SearchResultValidationSchema = z.record(
  z.string(),
  z.object({
    name: z.string(),
    id: z.number()
  })
)

export type ApiHookProps = {
  brandProfileId: number | undefined
  boardId: number | undefined
  boardType: string
  board: BoardSchemaType | undefined
  setQueries: Dispatch<SetStateAction<Item[]>>
  setAylienNews: Dispatch<SetStateAction<Item[]>>
  setSearchedAylienNews: Dispatch<SetStateAction<Item[]>>
  setAylienIndustries: Dispatch<SetStateAction<Item[]>>
  setSearchedAylienIndustries: Dispatch<SetStateAction<Item[]>>
  setAggregates: Dispatch<SetStateAction<Item[]>>
  setBoard?: Dispatch<SetStateAction<BoardSchemaType | undefined>>
  setAreQueriesLoading: Dispatch<SetStateAction<boolean>>
  setAreAylienNewsLoading: Dispatch<SetStateAction<boolean>>
  setAreAylienIndustriesLoading: Dispatch<SetStateAction<boolean>>
}

const ItemsSchema = z.array(z.number())

export const BoardSchema = z.object({
  boardId: z.number(),
  boardName: z.string(),
  defaultType: z.string().nullable(),
  boardType: z.enum(['general', 'custom', 'naturalLanguage']),
  operator: z.enum(['or', 'and']),
  news: ItemsSchema,
  industries: ItemsSchema,
  queries: ItemsSchema
})

export type BoardSchemaType = z.infer<typeof BoardSchema>

export interface INarrative {
  id: string
  name: string
  topicId: number | string
  topicName: string
  summary: string
  postCount: number
  socialPostIdList: string[]
  sourceTypeList: string[]
  themeList: string[]
  minPublishDate: string
  maxPublishDate: string
  createdDate: string
  dateRange: string
  sourceTypeCountJson: ISourceTypeCountJSON[]
  postCountPerDayJson: IPostCountPerDayJSON[]
  topPostJSON: ITopPostJSON
}

export interface IOverview {
  narrativesIdentified: number
  platformSources: number
  topPlatforms: string[]
  topThemes: string[]
  topClusters: ITopCluster[]
}

export interface ITopCluster {
  cluster: INarrative
  growth: string
}

export interface IPostCountPerDayJSON {
  postCount: number
  publishDate: string
}

export interface ITopPostJSON {
  id: string
  post: string
  publishDate: string
  sourceTypeList: string[]
  themeList: string[]
}

export interface ISourceTypeCountJSON {
  postCount: number
  sourceType: string
}
export interface IConfig {
  topicOptions: { id: number; name: string }[]
  themeOptions: { id: number; name: string }[]
  sortByOptions: { name: string; value: string }[]
}

export interface ISocialData {
  socialClusters: {
    totalCount: number
    hasNext: boolean
    result: INarrative[]
  }
  config: IConfig
}

export interface IAreaChartData {
  date: string
  posts: number
  growth: number | null
}

const SocialPostSchema = z.object({
  id: z.string(),
  name: z.string(),
  topicId: z.union([z.string(), z.number()]),
  topicName: z.string(),
  summary: z.string().optional(),
  postCount: z.number(),
  socialPostIdList: z.array(z.string()),
  sourceTypeList: z.array(z.string()),
  themeList: z.array(z.string()),
  minPublishDate: z.string(),
  maxPublishDate: z.string(),
  createdDate: z.string(),
  dateRange: z.string(),
  sourceTypeCountJson: z.array(
    z.object({
      postCount: z.number(),
      sourceType: z.string()
    })
  ),
  postCountPerDayJson: z.array(
    z.object({
      postCount: z.number(),
      publishDate: z.string()
    })
  ),
  topPostJSON: z
    .object({
      id: z.string(),
      post: z.string(),
      publishDate: z.string(),
      sourceTypeList: z.array(z.string()),
      themeList: z.array(z.string())
    })
    .nullable(),
  sort: z.array(z.union([z.number(), z.string()])).optional()
})

const ConfigSchema = z.object({
  topicOptions: z.array(
    z.object({
      id: z.union([z.string(), z.number()]),
      name: z.string()
    })
  ),
  themeOptions: z.array(
    z.object({
      id: z.number(),
      name: z.string()
    })
  ),
  sortByOptions: z.array(
    z.object({
      name: z.string(),
      value: z.string()
    })
  )
})

const SocialClustersSchema = z.object({
  totalCount: z.number(),
  hasNext: z.boolean(),
  result: z.array(SocialPostSchema),
  clusterIds: z.array(z.string()).optional()
})

export const GetSocialClusterSchema = z.object({
  socialClusters: SocialClustersSchema,
  config: ConfigSchema,
  clusterIds: z.array(z.string()).optional()
})

export const GetOverviewSocialClusterSchema = z.object({
  overviewData: z.object({
    narrativesIdentified: z.number(),
    platformSources: z.number(),
    topPlatforms: z.array(z.string()),
    // to do: this doesn't match up with IOverview through some difficult
    // to find bug or typing mismatch
    topClusters: z.array(
      z.object({
        cluster: SocialPostSchema,
        growth: z.coerce.string()
      })
    )
  })
})

export const activationStatusOptions = [
  {
    id: 'implemented',
    label: 'Implemented',
    children: [
      { id: 'keywords_target_implemented', label: 'Keywords Target' },
      { id: 'videos_target_implemented', label: 'Videos Target' },
      { id: 'keywords_block_implemented', label: 'Keywords Block' },
      { id: 'videos_block_implemented', label: 'Videos Block' }
    ]
  },
  {
    id: 'processing',
    label: 'Processing',
    children: [
      { id: 'keywords_target_processing', label: 'Keywords Target' },
      { id: 'videos_target_processing', label: 'Videos Target' },
      { id: 'keywords_block_processing', label: 'Keywords Block' },
      { id: 'videos_block_processing', label: 'Videos Block' }
    ]
  },
  {
    id: 'failed',
    label: 'Failed',
    children: [
      { id: 'keywords_target_failed', label: 'Keywords Target' },
      { id: 'videos_target_failed', label: 'Videos Target' },
      { id: 'keywords_block_failed', label: 'Keywords Block' },
      { id: 'videos_block_failed', label: 'Videos Block' }
    ]
  },
  { id: 'not_activated', label: 'Not Activated' },
  { id: 'awaiting_implementation', label: 'Awaiting Implementation' }
]

export interface Filters {
  sortBy: string
  themes: number[]
  topics: number[] | string[]
}

export interface SocialClusterRequestBody {
  brandProfileId: any
  boardIds: any
  minPublishDate: any
  maxPublishDate: any
  filters: Filters
  limit: number
  offset: number
  clusterIds?: string[]
}

export interface DownloadProps {
  brandProfileId: number
  boardIds: number[]
  clusterIds: number[]
  minPublishDate: string
  maxPublishDate: string
  sortBy: string
  themes: number[]
  topicIds: number[] | string[]
}
