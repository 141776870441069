import React, { ReactElement } from 'react'

import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, NoSymbolIcon, XMarkIcon } from '@heroicons/react/20/solid'
import {
    ArrowPathIcon,
    ChartBarIcon,
    CursorArrowRaysIcon,
    PhoneIcon,
    DocumentArrowDownIcon,
    XCircleIcon,
    DocumentChartBarIcon,
    PlayIcon,
    ShareIcon,
    ShieldCheckIcon,
    Squares2X2Icon
} from '@heroicons/react/24/outline'

const solutions = [
    {
        name: 'Block Placements',
        description: 'Create a Placements Block list in Google Ads for the selected moments',
        href: '#',
        icon: XCircleIcon
    },
    {
        name: 'Block Keywords',
        description: 'Create a Keywords Block list in Google Ads for the selected moments',
        href: '#',
        icon: XCircleIcon
    },
    {
        name: 'Download selected moments',
        description: 'Download all of the selected moments and their meta data',
        href: '#',
        icon: DocumentArrowDownIcon
    },
    {
        name: 'Download all moments',
        description: 'Download all of the moments and their meta data, according to your filters',
        href: '#',
        icon: DocumentArrowDownIcon
    },
    {
        name: 'Share selected moments',
        description: 'Send an email containing a file with all of the meta data for your selected moments',
        href: '#',
        icon: ShareIcon
    }
]

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

type OptionType = {
    name: string
    description: string
    onClick: () => void
    icon: ReactElement
    disabled?: boolean
}

export function ActionsMenu({
    buttonText,
    buttonIcon,
    buttonId,
    options,
    selectorClass
}: {
    buttonText: string
    buttonIcon: ReactElement,
    buttonId: string
    options?: OptionType[],
    selectorClass?: string
}) {
    const hoverColorClass = 'hover:brightness-95'

    const classNameVar = `
${selectorClass}
disabled:opacity-30 
disabled:cursor-not-allowed 
flex 
items-center
justify-center 
bg-white
${hoverColorClass}
hover:cursor-pointer 
text-grey-900
py-2 
px-4 
rounded 
text-center
border-grey-900 
border
whitespace-nowrap

`
    return (
        <Popover className="relative">
            {({ open }) => (
                <>
                    <Popover.Button id={buttonId} className={classNames('flex', classNameVar)}>
                        {buttonIcon}
                        {buttonText}
                    </Popover.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/4 transform px-2 sm:px-0">
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                    {options && options.map((item) => (
                                        <div
                                            key={item.name}
                                            onClick={item.disabled ? undefined : item.onClick}
                                            className={`${
                                                item.disabled ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer'
                                            }  -m-3 flex items-start rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50`}
                                        >
                                            {item.icon}

                                            <div className="ml-4">
                                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}
