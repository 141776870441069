import { LoadingPage } from '@/components/LoadingPage'
import SlideOver from '@/components/SlideOver'
import { formatDateToShortMonthDayYear } from '@/utils'
import { INarrative, ITimeline, ITimelineMoment } from '@/views/Trends/types'
import React from 'react'
import NoImageFoundPath from '@/assets/img/NoImageFound.svg';
import { InfoPage } from '@/components/InfoPage'
import { Placeholder } from 'rsuite'
import { LoadingItems } from '@/components/LoadingItems'
import { useNavigate } from '@tanstack/react-location'
import { PATH_MOMENT_DETAILS, PATH_NARRATIVE_DETAILS } from '@/routes'

interface IDetailTimelineProps {
  timelineData?: ITimeline
  timelineDataIsLoading: boolean
  timelineDataError: any
  source: string
  redirectId?: string
}

const DetailTimeline: React.FC<IDetailTimelineProps> = ({ timelineData, timelineDataIsLoading, timelineDataError, source, redirectId }) => {
  const [showSlideOver, setShowSlideOver] = React.useState(false);

  const navigate = useNavigate();

  const handleRedirect = (item: INarrative | ITimelineMoment) => {
    let path = PATH_MOMENT_DETAILS;

    if ('postCountPerDay' in item) {
        path = PATH_NARRATIVE_DETAILS;
    }
    localStorage.setItem('trendsSource', source);
    const brandProfileId = localStorage.getItem('analyzeBrandProfileId')
    const searchParams: any = { redirect: redirectId }

    if (brandProfileId) {
      searchParams.brandProfileId = parseInt(brandProfileId)
    }

    navigate({ to: `${path}/${item.id}`,
      search(prev?) {
      return {
        ...prev,
        ...searchParams
      }}
    })
};

  const sortedDates = timelineData
    ? timelineData
      .map((d) => new Date(d.maxPublishedAt))
      .sort((a, b) => a.getTime() - b.getTime())
    : []

  const datesHeader = sortedDates ? [
    { title: 'Start date', date: sortedDates[0] },
    { title: 'End date', date: sortedDates[sortedDates.length - 1] }
  ] : undefined

  const TimelineContent: React.FC<{ timelineData: ITimeline }> = ({ timelineData }) => (
    <div className="timeline">
      {timelineData!.map((item, index) => (
        <div
          key={index}
          className={`timeline-item mb-4 relative flex items-start ${index < timelineData!.length - 1 ? 'pb-6' : ''
            }`}
        >
          {/* Line connecting the images */}
          {index < timelineData!.length - 1 && (
            <div className="absolute top-[4.25rem] left-6 w-px bg-gray-300 h-full"></div>
          )}

          <div className="flex items-center cursor-pointer" onClick={() => handleRedirect(item)}>
            <div className="inline-block rounded-full ring-2 ring-white">
              <div className="rounded-full overflow-hidden w-12 h-12 bg-white">
                <img src={'imageUrl' in item ? item.imageUrl || NoImageFoundPath : NoImageFoundPath} className="w-full h-full object-cover" alt="" data-testid="timeline-item-image" />
              </div>
            </div>

            <div className="ml-4 flex flex-col gap-2">
              <h3 className="text-sm font-semibold text-left" data-testid="timeline-item-title">
                {item.name}
              </h3>
              <p className="text-xs text-left" data-testid="timeline-item-date">
                {formatDateToShortMonthDayYear(item.maxPublishedAt)}
              </p>
              {/* To do: reinstate new moment tag when product defines criteria
              <div className="flex flex-row gap-2">
                <p className="text-sm text-gray-500" data-testid="timeline-item-date">{formatDateToShortMonthDayYear(item.maxPublishedAt)}</p>
                {isRecentDate(item.maxPublishedAt) && (<span className="bg-blue-100 text-blue-600 text-xs px-2 py-1 rounded-full" data-testid="timeline-item-badge">New Moment</span>)}
              </div> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <SlideOver
        show={showSlideOver}
        onHide={() => setShowSlideOver(false)}
        headerTitle="Timeline"
        headerForegroundColor="text-black"
        headerBackgroundColor="bg-white"
        showToggleFullScreenButton={false}
        maxOffset="60%"
      >
        <div className="px-10 overflow-y-auto">
          {timelineDataError ? (
            <InfoPage message="There was an error fetching the timeline. Please contact support for help." />
          ) : timelineDataIsLoading ? (
            <LoadingPage message="Loading timeline" />
          ) : !timelineData || timelineData.length === 0 ? (
            <InfoPage message="No data for timeline." />
          ) : (
            <div className='flex flex-col gap-4'>
              {datesHeader && (
                <div className="flex flex-row justify-between">
                  {datesHeader.map((header, index) => (
                    <div key={`slideover-timeline-item-title-${index}`}>
                      <p className="text-sm text-slate-500 font-semibold" data-testid="slideover-timeline-item-title">
                        {header.title}
                      </p>
                      <p className="text-xl text-sightlyPurple font-bold" data-testid="slideover-timeline-item-date">
                        {formatDateToShortMonthDayYear(header.date)}
                      </p>
                    </div>
                  ))}
                </div>
              )}
              <TimelineContent timelineData={timelineData} />
            </div>
          )}
        </div>
      </SlideOver>

      <div className='flex justify-between'>
        <h5 className="text-gray">Timeline</h5>
        <a
          href="#"
          className="text-blue-500 font-semibold"
          onClick={(e) => {
            e.preventDefault()
            setShowSlideOver(true)
          }}
          data-testid="view-full-timeline"
        >
          View full Timeline
        </a>
      </div>
      <div className='mt-3'>
        {timelineDataError ? (
          <InfoPage message="There was an error fetching the timeline. Please contact support for help." />
        ) : timelineDataIsLoading ? (
          <>
            <LoadingPage message='Loading timeline'/>
            <LoadingItems graphShape='circle' itemsCount={2}/>
          </> 
        ) : !timelineData || timelineData.length === 0 ? (
          <InfoPage message="No data for timeline." />
        ) : (
          <TimelineContent timelineData={timelineData.slice(0, 3)} />
        )
        }
      </div>
    </div>
  )
}

export default DetailTimeline
