import { MakeGenerics } from '@tanstack/react-location';
import { MomentSortBy } from '@/views/Discover/Moments/v2/types'
import {StripePrice} from "@/views/Stripe/types";

export type Unarray<T> = T extends Array<infer U> ? U : T

export type MyLocationGenerics = MakeGenerics<{
    Params: {
        scenarioId: string
        brandProfileId: string
        themeId: string
        categoryId: string
        boardId: string
        opinionTypeId: string,
        trendId: string,
        topicId: string,
        id: string
    }
    Search: {
        aiPromptText?: string
        boardId?: number
        ideasToLeanInto?: string
        ideasToAvoid?: string
        accountId?: number
        viewOnly: boolean
        redirect?: string
        brandProfileId: number
        boardIds: number[]
        startDate?: string
        endDate?: string
        date: string
        hour: number
        searchTerm: string
        sortBy: MomentSortBy
        selectedMomentId: number
        activeMomentModalTab: '0' | '1' | '2' | '3'
        reportingView?: string
        boardsView?: string
        editingBoard: boolean
        priceId?: StripePrice
        reportId?:number
        segmentId?:number
        copy?:boolean
        tab?: string
        smartListId?: number
    }
    Error: {
        message: string
    }
}>

export const getTokenFromURL = (url: string) => {
    let formatedURL = new URL(url);
    return formatedURL.pathname.split("/").pop()?.split("#")[0];
}

export const getTokenExpirationDate = (token: string) => {
    const jwtPayload = JSON.parse(window.atob(token.split('.')[1]));
    return jwtPayload.exp * 1000;
}

export const isTokenExpired = (token: string) => {
    const jwtPayload = JSON.parse(window.atob(token.split('.')[1]))
    const isExpired = Date.now() >= jwtPayload.exp * 1000;
    return isExpired
}
