import { useQuery } from "@tanstack/react-query"
import { logError } from "@/utils"
import { api } from "@/api/api"
import { useFlag } from '@unleash/proxy-client-react';

export function useSocialCluster(brandProfileId: number, boardIds: number[], today: Date, pastDate: Date, sortBy: string, themes: number[], topicIds: number[], page: number, itemsPerPage: number, clusterIds?: string[]) {

    const formatDateyyyyMMdd = (date: Date) => {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    const formattedToday = formatDateyyyyMMdd(today)
    const formattedPastDate = formatDateyyyyMMdd(pastDate)

    const elasticSearchEnabled = useFlag('enable_narratives_elastic_search')

    const { data: socialNarrativesData, isLoading: socialNarrativesIsLoading, error: socialNarrativesError } = useQuery(
      ['socialClusters', brandProfileId, boardIds, formattedPastDate, formattedToday, sortBy, themes, topicIds, page],
      async ({ signal }) => {
        const offset = page * itemsPerPage;
        return await api.socialCluster.get(brandProfileId, boardIds, formattedPastDate, formattedToday, sortBy, themes, topicIds, itemsPerPage, offset, signal, elasticSearchEnabled, clusterIds);
      },
      {
        enabled: boardIds?.length > 0,
        retry: 1,
        cacheTime: 0,
        onError: (err) => {
          logError(err, { info: 'onError in social clusters query' })
        }
      }
    )

    const socialOverview = useQuery(
      ['socialCluster/overview', brandProfileId, boardIds, formattedPastDate, formattedToday],
      ({ signal }) => api.socialCluster.getOverview(brandProfileId, boardIds, formattedPastDate, formattedToday, signal, elasticSearchEnabled),
      {
        enabled: boardIds?.length > 0,
        retry: 1,
        cacheTime: 0,
        onError: (err) => {
          logError(err, { info: 'onError in social cluster overview query' })
        }
      }
    )

    // Download
    const handleDownloadNarratives = async (
      clusterIds: number[],
    ) => {
      try {
        const response = await api.socialCluster.download({
          brandProfileId,
          boardIds,
          clusterIds,
          minPublishDate: formattedPastDate, // '2024-04-01'
          maxPublishDate: formattedToday, // '2024-06-30'
          sortBy,
          themes,
          topicIds,
         });
      } catch (err) {
        logError(err, { info: 'onError in download social narratives query' });
      }
    }

    return {
      socialOverview,
      socialNarrativesData,
      socialNarrativesIsLoading,
      socialNarrativesError,
      handleDownloadNarratives,
    }
  }
