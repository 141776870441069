import React from 'react'
import { Checkbox, Input } from 'rsuite'

function ScheduleCustomSubjectAndMessage(props: { 
  customEmail: boolean,
  setCustomEmail: React.Dispatch<React.SetStateAction<boolean>>,
  customEmailSubject: string,
  setCustomEmailSubject: React.Dispatch<React.SetStateAction<string>>,
  customEmailMessage: string,
  setCustomEmailMessage: React.Dispatch<React.SetStateAction<string>>,
  toggleCustomEmail: () => void,
}) {
  const { customEmail, setCustomEmail, customEmailSubject, setCustomEmailSubject, customEmailMessage, setCustomEmailMessage, toggleCustomEmail } = props

  return (
    <div
      className="sm:px-1 text-grey-900"
      data-testid="custom-subject-and-message"
    >
      {
          customEmail && (
              <div
                  className="pt-2 text-grey-900"
                  data-testid="custom-subject-and-message-container"
              >
                  <Input 
                      value={customEmailSubject} 
                      onChange={setCustomEmailSubject} 
                      placeholder="Custom email subject"
                      className="w-1/2 mb-2"
                      data-testid="custom-email-subject"
                  />
                  <Input
                      value={customEmailMessage} 
                      onChange={setCustomEmailMessage} 
                      placeholder="Custom email message"
                      className="w-1/2 mb-2"
                      componentClass="textarea"
                      rows={3}
                      data-testid="custom-email-message"
                  />
              </div>
          )
      }
      <Checkbox 
        className="w-min whitespace-nowrap" 
        data-testid={'checkbox-custom-email-subject-and-message'} 
        value={customEmail} 
        checked={customEmail}
        onClick={toggleCustomEmail}
      >
          <p data-testid={'p-custom-email-subject-and-message'}>Custom email subject and message</p>
      </Checkbox>
    </div>
  )
}

export default ScheduleCustomSubjectAndMessage
