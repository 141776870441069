import { formatDateToFullMonthDayYear } from '@/utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import React, { useState } from 'react'
import { DatePicker, RadioGroup, Radio, SelectPicker, Slider } from 'rsuite'
import { TIMEZONE_OPTIONS } from '@/views/ReportGenerator/constants'
import SightlyToggle from '@/components/Sightly/SightlyFormElements/SightlyToggle';


dayjs.extend(utc);
dayjs.extend(timezone);

function ScheduleReportDateTime(props: {
  reportStartDate: Date,
  setReportStartDate: React.Dispatch<React.SetStateAction<Date>>,
  reportEndDate: Date,
  setReportEndDate: React.Dispatch<React.SetStateAction<Date>>,
  selectedTimezone: string,
  rollingStartDate: boolean,
  setRollingStartDate: React.Dispatch<React.SetStateAction<boolean>>,
  rollingEndDate: boolean,
  setRollingEndDate: React.Dispatch<React.SetStateAction<boolean>>,
}) {
  const { reportStartDate, setReportStartDate, reportEndDate, setReportEndDate, selectedTimezone, rollingStartDate, setRollingStartDate, rollingEndDate, setRollingEndDate } = props

  const utcToTimezone = (utcDate: Date): Date => {
    return dayjs.tz(utcDate, selectedTimezone).toDate();
  };

  const handleDateChange = (date: Date, referenceDate: Date, setDate: (date: Date) => void) => {
    const selectedDate = dayjs.utc(date).toDate();
    selectedDate.setHours(referenceDate.getHours());
    selectedDate.setMinutes(referenceDate.getMinutes());
    selectedDate.setSeconds(referenceDate.getSeconds());
    setDate(selectedDate);
  };

  const handleStartDateChange = (date: Date) => {
    handleDateChange(date, reportStartDate, setReportStartDate);
  };

  const handleEndDateChange = (date: Date) => {
    handleDateChange(date, reportEndDate, setReportEndDate);
  };

  return (
    <div
      className="mb-9 sm:px-1 text-grey-900 w-full"
      data-testid="report-date-time"
    >
      <div className="mb-2 font-medium" id="pendo-act-mgr-assignees-filter">
        Report Start and End Date
      </div>
      <div
        className="flex flex-row items-center gap-2"
        data-testid="report-date-time-container"
      >
        <div className="w-60">
          <DatePicker
            value={utcToTimezone(reportStartDate)}
            onChange={handleStartDateChange}
            placeholder="Select Report Start Date"
            placement="autoVerticalStart"
            renderValue={(value) => formatDateToFullMonthDayYear(value)}
            cleanable={false}
            className="w-60"
            data-testid="report-start-date-picker"
            oneTap
          />
          <SightlyToggle
            id="rolling-start-date"
            additionalClassName="mt-4"
            setEnabled={(v) => {
              setRollingStartDate(v)
            }}
            enabled={rollingStartDate}
            enabledText="Rolling start date"
            isDisabled={false}
            dataTestId="rolling-start-date-toggle"
          />
        </div>

        <div className="h-10 mx-2">
          <div className="bg-[#D4D9D9] w-6 h-1" />
        </div>

        <div >
          <DatePicker
            value={utcToTimezone(reportEndDate)}
            onChange={handleEndDateChange}
            placeholder="Select Report End Date"
            placement="autoVerticalStart"
            renderValue={(value) => formatDateToFullMonthDayYear(value)}
            cleanable={false}
            className="w-60"
            data-testid="report-end-date-picker"
            oneTap
          />
          <SightlyToggle
            id="rolling-end-date"
            additionalClassName="self-center mt-4"
            setEnabled={(v) => {
              setRollingEndDate(v)
            }}
            enabled={rollingEndDate}
            enabledText="Rolling end date"
            dataTestId="rolling-end-date-toggle"
          />
        </div>
      </div>
    </div>
  )
}

export default ScheduleReportDateTime