import React, { useEffect, useState } from 'react'
import { location } from '@/RouteContainer'
import { MyLocationGenerics } from '@/classes/utils'
import { LoadingPage } from '@/components/LoadingPage'
import NoBrandProfiles from '@/components/NoBrandProfiles'
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles'
import useUser from '@/hooks/useUser'
import { PATH_MOMENTS_V2_SETTINGS_REDESIGN } from '@/routes'
import { formatDateyyyyMMdd } from '@/utils'
import NarrativesTab from '@/views/Discover/Boards/Narratives/NarrativesTab'
import Sidebar from '@/views/Discover/Boards/Sidebar'
import useBoards, { Board } from '@/views/Discover/Boards/useBoards'
import { useMoments } from '@/views/Discover/Moments/v2/useMoments'
import { useSocialCluster } from '@/views/Discover/Boards/Narratives/useSocialCluster'
import { Navigate, useNavigate, useSearch } from '@tanstack/react-location'
import { GENERAL_BOARD_DEFAULT_TYPES } from '@/views/BrandProfiles/constants'
import { useUnleashContext } from '@unleash/proxy-client-react'
import useSessionStorage from '@/hooks/useSessionStorage'
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota'
import { JustificationSlidingOver } from '@/views/Discover/Boards/components/JustificationSlidingOver'
import dayjs from 'dayjs'
import MomentsTab from './Moments/MomentsTab'
import BoardTabs from './BoardTabs'
import { ISortBy } from './Narratives/NarrativesPanel'

const AylienBoard = () => {
    const { subscriptionQuota, perms, products, userPermissions, userProducts } = useUser()
    const [actions, setActions] = React.useState(['Target', 'Monitor', 'Block', 'No Action'])
    const updateContext = useUnleashContext()

    const canUserViewNarrativesTab = userCanPermissionProductQuota({
        requiredPermissionValue: perms.NARRATIVES_READ,
        requiredProductValue: products.NARRATIVES_INTELLIGENCE,
        userPermissions: userPermissions,
        userProducts: userProducts,
        checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
    });

    const tabs = [
        { label: 'Moments', id: 'moments', isVisible: true },
        { label: 'Narratives', id: 'narratives', isVisible: canUserViewNarrativesTab },
    ];

    updateContext({ userId: localStorage.getItem('email')?.toString() })

    const navigate = useNavigate()
    const boardsObj = useBoards()

    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0)
    const [showTargetSuccess, setShowTargetSuccess] = React.useState(false)
    const { boardsQuotaTally } = useBoards()

    const [activationStatus, setActivationStatus] = React.useState<string[]>(['not_activated'])

    const getLast24Hrs = (): [Date, Date] => {
        const now = new Date()
        const yesterday = new Date(now)
        yesterday.setTime(yesterday.getTime() - 24 * 60 * 60 * 1000)
        return [yesterday, now]
    }

    const [boardsDateRange, setBoardsDateRange] = useSessionStorage<[Date, Date]>('boardsDateRange', getLast24Hrs())

    const startingRange = boardsDateRange.map((dateVal) => new Date(dateVal))

    const DATE_FMT_YYYY_MM_DD = 'YYYY-MM-DD'
    const [startingDateString, setStartingDateString] = React.useState<string>(
        dayjs(startingRange[0]).format(DATE_FMT_YYYY_MM_DD)
    )
    const [endingDateString, setEndingDateString] = React.useState<string>(
        dayjs(startingRange[1]).format(DATE_FMT_YYYY_MM_DD)
    )

    const [startingDateObj, setStartingDateObj] = React.useState<Date>(startingRange[0])
    const [endingDateObj, setEndingDateObj] = React.useState<Date>(startingRange[1])

    React.useEffect(() => {
        const newStartDate = startingDateObj || dayjs(startingDateString, DATE_FMT_YYYY_MM_DD, true).toDate()
        const newEndDate = endingDateObj || dayjs(endingDateString, DATE_FMT_YYYY_MM_DD, true).toDate()

        const isValidDate = (date: any) => dayjs(date, DATE_FMT_YYYY_MM_DD, true).isValid()

        if (isValidDate(newStartDate) && isValidDate(newEndDate)) {
            setBoardsDateRange([newStartDate, newEndDate])
        }
    }, [startingDateString, endingDateString])

    const {
        data: brandProfiles,
        currentBrandProfile,
        isLoading: brandProfilesIsLoading
    } = useBrandProfiles({
        submittedOnly: true
    })
    const {
        brandProfileId,
        boardIds,
        startDate = startingDateString,
        endDate = endingDateString,
        sortBy = 'ranking',
        searchTerm = ''
    } = useSearch<MyLocationGenerics>()

    useEffect(() => {
        // When the URL parameters change, assign the <Date> state variable that controls calendar display
        // Separate string state vars are defined as string for API
        const [startYear, startMonth, startDay] = startDate.split('-').map(Number)
        const [endYear, endMonth, endDay] = endDate.split('-').map(Number)

        setStartingDateObj(new Date(startYear, startMonth - 1, startDay))
        setEndingDateObj(new Date(endYear, endMonth - 1, endDay))

        setStartingDateString(`${startYear}-${startMonth}-${startDay}`)
        setEndingDateString(`${endYear}-${endMonth}-${endDay}`)
    }, [startDate, endDate])

    const {
        moments,
        fetchNextPage,
        isFetchingNextPage,
        isMomentsError,
        handleSelectMoment,
        momentsIsLoading,
        setSortBy,
        handleSelectAllOnPage,
        selectAllMoments,
        allMomentsOnPageAreSelected,
        allMomentsAreSelected,
        setAllMomentsAreSelected,
        allMomentsIsFetching,
        allMoments,
        selectedMomentIds,
        postTrendRecActionFeedback,
        postTrendKeywordsFeedback,
        aylienNewsOptions,
        aylienIndustriesOptions,
        setAylienNews,
        setAylienIndustries,
        aylienIndustries,
        aylienNews,
        totalResultCount,
        hasNextPage,
        requestedPageSize,
        selectedMoments,
        resetSelectedMoments,
        handleSelectedMomentsTarget,
        getDefaultSelectedMoments,
        setSelectedMoments,
        invalidateMomentsQuery,
        invalidateAylienTaxonomiesQuery,
        renderAlert
    } = useMoments({
        showTargetSuccess,
        brandProfileId,
        boardIds: boardIds || [],
        startDate,
        endDate,
        searchTerm: searchTerm.toString(),
        sortBy,
        actions,
        activationStatus
    })

    let quotaIsAllowed = false
    if (subscriptionQuota.has(products.BOARDS)) {
        const quota = subscriptionQuota.get(products.BOARDS)
        quotaIsAllowed = quota.isAllowed
    }

    const [timeRange, setTimeRange] = useState<number>(1)
    const [page, setPage] = useState(0)
    const narrativesPerPage = 10
    const [selectedTopics, setSelectedTopics] = React.useState<number[]>([])
    const [selectedThemes, setSelectedThemes] = React.useState<number[]>([])
    const [selectedSortBy, setSelectedSortBy] = React.useState<ISortBy>({ name: 'Ranking', value: 'ranking' })
    const today = new Date()
    const daysToSubtract = timeRange * 15
    const pastDate = new Date(today)
    pastDate.setDate(today.getDate() - daysToSubtract)
    const [clusterIds, setClusterIds] = useState<string[]>([])

    const {
        socialOverview,
        socialNarrativesData,
        socialNarrativesIsLoading,
        socialNarrativesError,
        handleDownloadNarratives
    } = useSocialCluster(
        brandProfileId!,
        boardIds!,
        today,
        pastDate,
        selectedSortBy.value,
        selectedThemes,
        selectedTopics,
        page,
        narrativesPerPage,
        clusterIds,
    )

    useEffect(() => {
        if (socialNarrativesData && clusterIds.length === 0) {
            setClusterIds(socialNarrativesData?.clusterIds || [])
        }
    }, [socialNarrativesData])

    const [boardsAreSorted, setBoardsAreSorted] = React.useState(false)
    const [sortedBoards, setSortedBoards] = React.useState<Board[] | undefined>(undefined)
    const [showSlidingModal, setShowSlidingModal] = useState(false)
    const [showFromSlidingModal, setShowFromSlidingModal] = useState('')

    const setDefaultSortByWithOnlyRelevance = () => {
        const selectedBoards = boardsObj.boards?.filter((board) => boardIds?.includes(board.id))
        const areAllBoardsRelevanceType = selectedBoards?.every(
            (board) => board.defaultType === GENERAL_BOARD_DEFAULT_TYPES.RELEVANCE
        )
        setSortBy(areAllBoardsRelevanceType ? 'relevance' : 'ranking')
    }

    React.useEffect(setDefaultSortByWithOnlyRelevance, [boardsObj.boards, boardIds])

    React.useEffect(() => {
        if (currentBrandProfile) {
            navigate({
                search: (search) => ({
                    ...search,
                    brandProfileId: currentBrandProfile?.brandProfileId
                })
            })
        }
        if (boardsObj.boards && boardsObj.boardsFetched && !boardsObj.boardsIsLoading && !boardsObj.boardsIsFetching) {
            // Sort boards in specific order, return sorted boards object, set loading state to prevent FOUC
            const sortedBoards = [...boardsObj.boards].sort((a, b) => {
                const typeOrder: Record<string, number> = { trendingMoments: 0, general: 1, custom: 2, naturalLanguage: 3 }
                const { TRENDING_MOMENTS } = GENERAL_BOARD_DEFAULT_TYPES
                const typeA = a.defaultType === TRENDING_MOMENTS ? TRENDING_MOMENTS : a.type
                const typeB = b.defaultType === TRENDING_MOMENTS ? TRENDING_MOMENTS : b.type
                if (typeA === typeB) {
                    return a.name.localeCompare(b.name)
                }
                return typeOrder[typeA] - typeOrder[typeB]
            })

            setSortedBoards(sortedBoards)
            setBoardsAreSorted(true) // Set loading state to true after sorting
            navigate({
                search: (search) => {
                    const boardIdsForUrl = !Array.isArray(search?.boardIds)
                        ? sortedBoards.map((board) => board.id)
                        : Array.isArray(search?.boardIds)
                            ? search?.boardIds.filter((id: number) => sortedBoards.map((board) => board.id).includes(id))
                            : []
                    return {
                        ...search,
                        startDate: search?.startDate ?? formatDateyyyyMMdd(startingRange[0]),
                        endDate: search?.endDate ?? formatDateyyyyMMdd(startingRange[1]),
                        searchTerm: search?.searchTerm ?? '',
                        boardIds: boardIdsForUrl,
                        sortBy: search?.sortBy ?? 'ranking'
                    }
                }
            })
        }
    }, [
        currentBrandProfile,
        location.current.search,
        boardsObj.boards,
        boardsObj.boardsIsLoading,
        boardsObj.boardsFetched,
        boardsObj.boardsIsFetching
    ])

    const handleSelectedSortByChange = (sortBy: ISortBy) => {
        setSelectedSortBy(sortBy)
    }

    if (brandProfilesIsLoading) {
        return null
    }

    if (brandProfiles?.length === 0) {
        return <NoBrandProfiles />
    }

    if (boardsObj.boardsIsLoading) {
        return <LoadingPage message="Fetching boards" />
    }

    if (
        (!boardsObj.boards ||
            // only navigate if you're on the brand profile that has 0 boards
            // If the account is maxed out on their board quota,
            // and has multiple brand profiles, they should
            // still be able to see the boards of the brand profile that has them
            (boardsObj.boards.length === 0 && boardsQuotaTally?.isMaxedOut)) &&
        userCanPermissionProductQuota({
            requiredPermissionValue: perms.AYLIEN_BOARD_CREATE,
            requiredProductValue: products.BOARDS,
            userPermissions: userPermissions,
            userProducts: userProducts,
            checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
        })
    ) {
        return <Navigate to={PATH_MOMENTS_V2_SETTINGS_REDESIGN} />
    }

    const getSelectedBoardNames = () => {
        if (!boardIds || !sortedBoards) return ''

        switch (boardIds.length) {
            case 0:
                return 'No board selected'
            case 1:
                return sortedBoards
                    .filter((b) => boardIds && boardIds.includes(b.id))
                    .map((b) => b.name)
                    .join()
            default:
                return 'Boards'
        }
    }

    const resetBrandProfileIdMoments = (brandProfileId: number) => {
        setSelectedTabIndex(0)
        setSelectedMoments(getDefaultSelectedMoments(brandProfileId))
        setShowTargetSuccess(false)
    }

    const findTaxonomyCode = (items: any[], id: number): string => {
        for (const item of items) {
            if (item.id === id) {
                return item.taxonomyCode
            }
            if (item.children) {
                const result = findTaxonomyCode(item.children, id)
                if (result) {
                    return result
                }
            }
        }

        return ''
    }

    return (
        <div className="gridContainer animate-in fade-in">
            <JustificationSlidingOver
                open={showSlidingModal}
                handleClose={() => setShowSlidingModal(false)}
                header="Mentality AI"
                selectedMoments={selectedMoments}
                brandProfileId={brandProfileId}
                openFrom={showFromSlidingModal}
            />
            {boardsAreSorted && (
                <Sidebar
                    handleBrandProfileChange={(brandProfileId: number) => {
                        navigate({
                            search: (old) => {
                                return {
                                    ...old,
                                    brandProfileId
                                }
                            }
                        })
                        resetBrandProfileIdMoments(brandProfileId)
                    }}
                    resetBrandProfileIdMoments={resetBrandProfileIdMoments}
                    brandProfileId={brandProfileId}
                    handleSelectBoard={boardsObj.handleSelectBoard}
                    handleSelectAllBoards={boardsObj.handleSelectAllBoards}
                    clearSelectedBoards={boardsObj.clearSelectedBoards}
                    boards={sortedBoards || []}
                    showBackToDashboards={(boardsObj?.boards && boardsObj.boards.length > 0) || false}
                    selectedBoards={boardIds || []}
                    handleDeleteBoard={() => {
                        resetSelectedMoments(brandProfileId)
                    }}
                    hideNewBoardButton={
                        !userCanPermissionProductQuota({
                            requiredPermissionValue: perms.AYLIEN_BOARD_CREATE,
                            requiredProductValue: products.BOARDS,
                            userPermissions: userPermissions,
                            userProducts: userProducts,
                            checkType: PermissionCheckType.PRODUCT_QUOTA_PERMISSION_CHECK,
                            quota_is_allowed: quotaIsAllowed
                        })
                    }
                />
            )}
            <div className="header-aylien">
                <div className="w-full">
                    <BoardTabs
                        fixedHeadline={getSelectedBoardNames()}
                        selectedTabIndex={selectedTabIndex}
                        setSelectedTabIndex={setSelectedTabIndex}
                        tabs={tabs}
                    />
                </div>
            </div>
            <div id="moments-results-container" className="px-4 main">
                {selectedTabIndex === tabs.findIndex(tab => tab.id === 'moments' && tab.isVisible) && (
                    // Moments
                    <MomentsTab
                        moments={moments}
                        fetchNextPage={fetchNextPage}
                        isFetchingNextPage={isFetchingNextPage}
                        isMomentsError={isMomentsError}
                        handleSelectMoment={handleSelectMoment}
                        momentsIsLoading={momentsIsLoading}
                        setSortBy={setSortBy}
                        handleSelectAllOnPage={handleSelectAllOnPage}
                        selectAllMoments={selectAllMoments}
                        allMomentsOnPageAreSelected={allMomentsOnPageAreSelected}
                        allMomentsAreSelected={allMomentsAreSelected}
                        setAllMomentsAreSelected={setAllMomentsAreSelected}
                        allMomentsIsFetching={allMomentsIsFetching}
                        allMoments={allMoments}
                        selectedMomentIds={selectedMomentIds}
                        postTrendRecActionFeedback={postTrendRecActionFeedback}
                        postTrendKeywordsFeedback={postTrendKeywordsFeedback}
                        aylienNewsOptions={aylienNewsOptions}
                        aylienIndustriesOptions={aylienIndustriesOptions}
                        setAylienNews={setAylienNews}
                        setAylienIndustries={setAylienIndustries}
                        aylienIndustries={aylienIndustries}
                        aylienNews={aylienNews}
                        totalResultCount={totalResultCount}
                        hasNextPage={hasNextPage}
                        requestedPageSize={requestedPageSize}
                        selectedMoments={selectedMoments}
                        resetSelectedMoments={resetSelectedMoments}
                        handleSelectedMomentsTarget={handleSelectedMomentsTarget}
                        getDefaultSelectedMoments={getDefaultSelectedMoments}
                        setSelectedMoments={setSelectedMoments}
                        invalidateMomentsQuery={invalidateMomentsQuery}
                        invalidateAylienTaxonomiesQuery={invalidateAylienTaxonomiesQuery}
                        renderAlert={renderAlert}
                        getSelectedBoardNames={getSelectedBoardNames}
                        selectedTabIndex={selectedTabIndex}
                        setSelectedTabIndex={setSelectedTabIndex}
                        setShowSlidingModal={setShowSlidingModal}
                        setShowFromSlidingModal={setShowFromSlidingModal}
                        actions={actions}
                        setActions={setActions}
                        activationStatus={activationStatus}
                        setActivationStatus={setActivationStatus}
                    />
                )}

                {selectedTabIndex === tabs.findIndex(tab => tab.id === 'narratives' && tab.isVisible) && (
                    // Narratives
                    <NarrativesTab
                        today={today}
                        pastDate={pastDate}
                        getSelectedBoardNames={getSelectedBoardNames}
                        selectedTabIndex={selectedTabIndex}
                        setSelectedTabIndex={setSelectedTabIndex}
                        timeRange={timeRange}
                        setTimeRange={setTimeRange}
                        page={page}
                        setPage={setPage}
                        selectedTopics={selectedTopics}
                        setSelectedTopics={setSelectedTopics}
                        selectedThemes={selectedThemes}
                        setSelectedThemes={setSelectedThemes}
                        selectedSortBy={selectedSortBy}
                        setSelectedSortBy={handleSelectedSortByChange}
                        socialOverview={socialOverview}
                        socialNarrativesData={socialNarrativesData}
                        socialNarrativesIsLoading={socialNarrativesIsLoading}
                        socialNarrativesError={socialNarrativesError}
                        handleDownloadNarratives={handleDownloadNarratives}
                        clusterIds={clusterIds ? clusterIds : []}

                    />
                )}
            </div>
            <style>{`
            .gridContainer {
              display: grid;
              height: 100%;	
              grid-template-columns: 345px 1fr;	
              grid-template-rows:  0fr 0px 1fr 1fr;
              grid-template-areas:
                "sidebar header-aylien"
                "sidebar action-bar"
                "sidebar main"
                "sidebar main"	
                "sidebar footer-aylien"	
            }
           
            .header-aylien {
                grid-area: header-aylien;		
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-bottom: 2px solid #f8f8f8;
            }
            .main {
                grid-area: main;
                overflow-y: auto;
                justify-content: center;
                display: block;
                height: 100%;          
            }  
                       
          `}</style>
        </div>
    )
}

export default AylienBoard
