import { useNavigate } from '@tanstack/react-location';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React from 'react';
import toast from 'react-hot-toast';

import { api } from '@/api/api';
import defaultLoginImage from '@/assets/img/introSideImage.png';
import svgLogo from '@/assets/img/sightly-logo.svg';
import tiktokLoginImage from '@/assets/img/tiktoklogin.png';
import { SightlyModernFormInput } from '@/components/Sightly/SightlyModern/Form/SightlyModernFormInput';
import { useSightlyForm } from '@/components/Sightly/SightlyModern/Form/hooks/useSightlyForm';
import { SightlyModernButton } from '@/components/Sightly/SightlyModern/SightlyModernButton';
import { WarningModalTailwind } from '@/components/WarningModalTailwind';
import { IntroLayout } from '@/layouts/IntroLayout';
import { routes } from '@/routes';
import { isIframe } from '@/validations';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { ResetPasswordFormType, ResetPasswordSchema } from './types';



export const ResetPassword: React.FC = () => {
    const navigate = useNavigate()
    const [showGenericErrorDialog, setShowGenericErrorDialog] = React.useState(false)
    const openLoginPage = () => navigate({ to: routes.login.path })
    const isInTikTokIframe = isIframe();

    const { sightlyRegister, handleSubmit, formState: { isValid } } = useSightlyForm<ResetPasswordFormType>(ResetPasswordSchema)

    const mutation = useMutation(api.user.resetPassword, {
        onSuccess: (data) => {
            if (data.isError) {
                setShowGenericErrorDialog(true)
                return
            }

            toast.success('Reset password email sent. Check Your email.')
            navigate({ to: `/checkYourEmail/${data.result}` })
        },
        onError: (err: AxiosError<any>) => {
            if (err.response && 'Error' in err.response.data && err.response.data.Error === 'No user found with that email address.') {
                toast.error(err.response.data.Error)
                return
            }

            setShowGenericErrorDialog(true)
        }
    });

    const onSubmit = handleSubmit((data) => {
        mutation.mutate(data)
    });

    const loginImage = isInTikTokIframe ? tiktokLoginImage : defaultLoginImage;

    return (
        <IntroLayout image={loginImage}>
            <img className='h-8' src={svgLogo} alt='logo' />
            <div className='flex flex-col gap-[4vh] items-center pt-[3vh] text-center'>
                <div>
                    <h1 className='text-3xl leading-9 font-extrabold text-gray-900'>Forgot password?</h1>
                    <p className='text-sm leading-5 font-normal text-gray-600 '>
                        No worries, we’ll send your reset instructions
                    </p>
                </div>
                <form
                    onSubmit={onSubmit}
                    className='w-full flex flex-col gap-[2vh] justify-center items-center'
                >
                    <SightlyModernFormInput {...sightlyRegister('email', 'Email address')} placeholder='you@example.com' />

                    <SightlyModernButton
                        className='w-full'
                        id='submitButton'
                        type='inverted'
                        handleClick={() => undefined}
                        text='Reset Password'
                        disabled={mutation.isLoading || !isValid}
                    />

                    <SightlyModernButton
                        className='w-full'
                        id='loginButton'
                        type='secondaryblack'
                        preventDefaultOnClick
                        icon={<ArrowLeftIcon className='h-[2.5vh] w-[2.5vh]' />}
                        handleClick={openLoginPage}
                        text='Back to Login'
                    />

                    <WarningModalTailwind
                        open={showGenericErrorDialog}
                        setOpen={setShowGenericErrorDialog}
                        title='Something went wrong'
                        subtitle='Please try again, or contact support at support@sightly.com'
                    />
                </form>
            </div>
        </IntroLayout>
    );
};
