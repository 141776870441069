import SightlySelect from "@/components/Sightly/SightlyFormElements/SightlySelect";
import { ActivationReportConfiguration } from "@/views/ActivationReports/types/activation-report.types";
import React from "react";

interface IProps {
  handleOnChange: Function
  formValues: ActivationReportConfiguration
}

export const grouping = [
  { label: 'Insertion Order', value: 'insertionOrder' },
  { label: 'Report', value: 'report' },
  { label: 'Ad Format', value: 'adFormat' },
]

export default function GroupBy({ handleOnChange, formValues }: IProps) {
  return (
    <div data-testid='div-new-report-group-by-select'>
      <h5 className="mt-3 mb-2 text-base">Group By</h5>
      <div className="relative">
        <SightlySelect
          id={"new-report-group-by-select"}
          options={grouping}
          value={formValues.grouping}
          onChange={(value) => handleOnChange('grouping', value)}
          data-testid='new-report-group-by-select'
          labelKey='label'
          valueKey='value'
        />
      </div>
    </div>
  )
}
