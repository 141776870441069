import { z } from 'zod'

const areaChartDataSchema = z.object({
  date: z.string(),
  posts: z.number()
})

export type IAreaChartData = z.infer<typeof areaChartDataSchema>

export enum RelevanceClassification {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low'
}

const MetricsPerDaySchema = z.object({
  count: z.number(),
  date: z.string()
})

export type IMetricsPerDay = z.infer<typeof MetricsPerDaySchema>

const MetricsSchema = z.object({
  moment: z.object({
    count: z.number(),
    countPerDay: z.array(MetricsPerDaySchema),
    articleCount: z.number(),
    articleCountPerDay: z.array(MetricsPerDaySchema)
  }),
  narrative: z.object({
    count: z.number(),
    countPerDay: z.array(MetricsPerDaySchema),
    postCount: z.number(),
    postCountPerDay: z.array(MetricsPerDaySchema)
  }),
  trend: z.object({
    count: z.number(),
    countPerDay: z.array(MetricsPerDaySchema)
  }),
  sentimentScore: z.number()
})

export type IMetricsSchema = z.infer<typeof MetricsSchema>

export const peopleSchema = z.object({
  id: z.number(),
  name: z.string()
})

export type IPeople = z.infer<typeof peopleSchema>

export const brandsSchema = z.object({
  id: z.number(),
  name: z.string()
})

export type IBrand = z.infer<typeof brandsSchema>

const MomentArticlesOnDateSchema = z.object({
  count: z.number(),
  date: z.string()
})

const CategorySchema = z.object({
  id: z.number(),
  name: z.string(),
  taxonomyCode: z.string(),
  taxonomyLevel: z.number(),
  garmFlagged: z.boolean()
})

export const topicSchema = z.object({
  id: z.string(),
  name: z.string(),
  overview: z.string(),
  bulletsText: z.array(z.string()).optional(),
  imageUrl: z.string().optional(),
  categoryCodes: z.array(z.string()),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  metrics: MetricsSchema,
  relevance: z
    .enum([RelevanceClassification.High, RelevanceClassification.Medium, RelevanceClassification.Low])
    .optional(),
  growth: z.number().optional(),
  people: z.any().array().optional(),
  brands: z.any().array().optional(),
  maxPublishedAt: z.string().datetime(),
  minPublishedAt: z.string().datetime()
})

export type ITopic = z.infer<typeof topicSchema>

export const trendSchema = z.object({
  id: z.string(),
  name: z.string(),
  overview: z.string(),
  bulletsText: z.array(z.string()).optional(),
  imageUrl: z.string().optional(),
  categoryCodes: z.array(z.string()),
  createdAt: z.date(),
  updatedAt: z.date(),
  metrics: MetricsSchema,
  relevance: z
    .enum([RelevanceClassification.High, RelevanceClassification.Medium, RelevanceClassification.Low])
    .optional(),
  growth: z.number().optional(),
  people: z.any().array().optional(),
  brands: z.any().array().optional(),
  maxPublishedAt: z.string().datetime(),
  minPublishedAt: z.string().datetime()
})

export type ITrend = z.infer<typeof trendSchema>

export const slideoverTrendSchema = z.object({
  id: z.string(),
  name: z.string(),
})

export type ISlideOverTrend = z.infer<typeof trendSchema>

export const momentSchema = z.object({
  id: z.number(),
  name: z.string(),
  summary: z.string().nullable().optional(),
  articleCount: z.number(),
  videoCount: z.number(),
  keywordCount: z.number(),
  minPublishedAt: z.string(),
  maxPublishedAt: z.string(),
  imageUrl: z.string().nullable(),
  articleCountPerDay: z.array(MomentArticlesOnDateSchema).optional(),
  categories: z.array(CategorySchema).optional(),
  updatedAt: z.string().optional()
});

export type IMoment = z.infer<typeof momentSchema>

export const momentTimelineSchema = z.object({
  id: z.number(),
  name: z.string(),
  summary: z.string(),
  articleCount: z.number(),
  videoCount: z.number(),
  keywordCount: z.number().optional(),
  minPublishedAt: z.string(),
  maxPublishedAt: z.string(),
  imageUrl: z.string().nullable(),
  articleCountPerDay: z.array(MomentArticlesOnDateSchema).optional(),
  categories: z.array(CategorySchema).optional(),
  updatedAt: z.string().optional()
});

export type ITimelineMoment = z.infer<typeof momentTimelineSchema>

const metricSchema = z.object({
  type: z.string(),
  totalCount: z.number(),
  countByDay: z.array(z.object({ day: z.string(), count: z.number() })),
  createdAt: z.string(),
  updatedAt: z.string()
})

export const trendMetricSchema = metricSchema.extend({
  trendId: z.number()
})

export type ITrendMetric = z.infer<typeof trendMetricSchema>

export const topicMetricSchema = metricSchema.extend({
  topicId: z.number()
})

export type ITopicMetric = z.infer<typeof topicMetricSchema>

const NarrativeTopPostSchema = z.object({
  id: z.string(),
  post: z.string(),
  publishedDate: z.string().refine((date) => !isNaN(Date.parse(date)), { message: 'Invalid date format' }),
  source: z.string()
})

const NarrativePostCountPerDaySchema = z.object({
  count: z.number(),
  date: z.string().refine((date) => !isNaN(Date.parse(date)), { message: 'Invalid date format' })
})

export type IPostCountPerDay = z.infer<typeof NarrativePostCountPerDaySchema>

const NarrativePostCountPerSourceSchema = z.object({
  count: z.number(),
  source: z.string()
})

export const narrativeSchema = z.object({
  id: z.string(),
  name: z.string(),
  sources: z.array(z.string()),
  minPublishedAt: z.string(),
  maxPublishedAt: z.string(),
  topPost: NarrativeTopPostSchema,
  postCount: z.number(),
  postCountPerDay: z.array(NarrativePostCountPerDaySchema),
  postCountPerSource: z.array(NarrativePostCountPerSourceSchema),
  updatedAt: z.string().optional(),
  themesList: z.array(z.string())
});

export const narrativesSchema = z.array(narrativeSchema)

export type INarrative = z.infer<typeof narrativeSchema>

const totalPostsOverTimeSchema = z.object({
  post_count: z.number(),
  publish_date: z.string(),
});

const postSchema = z.object({
  id: z.string(),
  platformName: z.string(),
  post: z.string(),
  url: z.string(),
  publishedDate: z.string(),
});

const platformSchema = z.object({
  platformName: z.string(),
  postCount: z.number(),
  score: z.number(),
});

const themeSchema = z.object({
  theme: z.string(),
  platforms: z.array(z.string()),
  postCount: z.number(),
});

const overviewSchema = z.object({
  id: z.string(),
  name: z.string(),
  summary: z.string(),
  minPublishedDate: z.string(),
  maxPublishedDate: z.string(),
  postCount: z.number(),
  platformCount: z.number(),
  themeCount: z.number(),
  totalPostsOverTime: z.array(totalPostsOverTimeSchema),
});
export type INarrativeOverview = z.infer<typeof overviewSchema>

export const narrativeDetailsSchema = z.object({
  id: z.string(),
  name: z.string(),
  summary: z.string(),
  postCount: z.number(),
  minPublishedDate: z.string(),
  maxPublishedDate: z.string(),
  overview: overviewSchema,
  posts: z.array(postSchema),
  platforms: z.array(platformSchema),
  themes: z.array(themeSchema),
}).partial({
  id: true,
  name: true,
  summary: true,
  postCount: true,
  minPublishedDate: true,
  maxPublishedDate: true,
  overview: true,
});

const socialPostSchema = z.object({
  id: z.string(),
  post: z.string(),
  url: z.string().url(),
  publishedDate: z.string().refine((date) => !isNaN(Date.parse(date)), {
    message: "Invalid date format",
  }),
  source: z.string(),
});

export type ISocialPost = z.infer<typeof socialPostSchema>

export const socialPostArraySchema = z.array(socialPostSchema);

export const timelineSchema = z.array(
  z.union([narrativeSchema, momentTimelineSchema])
)

export type ITimeline = (INarrative | ITimelineMoment)[]

export const articleSchema = z.object({
  id: z.number(),
  author: z.string().nullable(),
  imageUrl: z.string().nullable(),
  title: z.string(),
  sourceDomain: z.string(),
  sourceName: z.string(),
  publishedAt: z.string(),
  url: z.string()
})

export type IArticle = z.infer<typeof articleSchema>

export interface metaData {
  total: number
  page?: number
}

export interface ArticleResultsResponse {
  articles: IArticle[]
  meta: metaData
}

export interface SocialPostResultsResponse {
  socialPosts: ISocialPost[]
  meta: metaData
}

export interface ICategory {
  id: number
  name: string
  codes?: string[]
  exclude?: string[]
}

export type AnalyzeTrendParams = {
  brandName: string;
  title: string;
  overview: string;
  bulletPoints: string[];
  industryCategory: string;
  brandBusinessSummary: string;
  brandMentalitySummary: string;
  missionStatement: string;
  valueStatement: string;
  visionStatement: string;
  entityList: string[];
  keyTerms: string;
  callAnalyze: boolean;
};

export enum TopicTrendEnum {
  Topic = 'topic',
  Trend = 'trend'
}
export enum SearchResultEnum {
  Topic = 'topic',
  Trend = 'trend',
  TopicTrendMoment = 'moment',
  Narrative = 'narrative'
}
export type ISearchResult = ITopic | ITrend | INarrative | IMoment

export interface ICurrentTabData {
  tab: SearchResultEnum
  data: ISearchResult[]
}

export enum DataTypes {
  Narratives = "narrative",
  TopicTrendMoment = "moment"
}

export const graphDataSchema = z.object({
  momentNodes: z.array(
    z.object({
      name: z.string(),
      type: z.string()
    })
  ),
  momentLinks: z.array(
    z.object({
      source: z.number(),
      target: z.number(),
      value: z.number()
    })
  ),
  narrativeNodes: z.array(
    z.object({
      name: z.string(),
      type: z.string()
    })
  ),
  narrativeLinks: z.array(
    z.object({
      source: z.number(),
      target: z.number(),
      value: z.number()
    })
  )
});

export type ISanKeyGraph = z.infer<typeof graphDataSchema>

export const graphDataTopicSchema = z.array(
  z.object({
    name: z.string(),
    totalMoments: z.number(),
    totalNarratives: z.number(),
    trends: z.array(
      z.object({
        name: z.string(),
        items: z.array(
          z.object({
            name: z.string(),
            value: z.number(),
            videosCount: z.number().optional(),
            articlesCount: z.number().optional(),
            postCount: z.number().optional(),
            platforms: z.number().optional(),
            type: z.string(),
          })
        )
      })
    )
  })
);

export const graphDataTrendSchema = z.object({
  name: z.string(),
  totalMoments: z.number(),
  totalNarratives: z.number(),
  items: z.array(
    z.object({
      name: z.string(),
      videosCount: z.number().optional(),
      articlesCount: z.number().optional(),
      postCount: z.number().optional(),
      platforms: z.number().optional(),
      value: z.number(),
      type: z.string(),
    })
  )
});


export type IBubbleTrendGraph = z.infer<typeof graphDataTrendSchema>
export type IBubbleTopicGraph = z.infer<typeof graphDataTopicSchema>

export type TrendsSearchResultSortBy = 'similarityRecommended' | 'createdAtDesc'

export const allCategories = { id: -1, name: 'All Categories', codes: [] };
export const categories: ICategory[] = [
  allCategories,
  {
    id: 0,
    name: 'Business & Finance',
    codes: ['ay.biz', 'ay.econ', 'ay.fin']
  },
  {
    id: 1,
    name: 'Fashion & Beauty',
    codes: ['ay.lifesoc.fashion']
  },
  {
    id: 2,
    name: 'Health & Wellness',
    codes: ['ay.haw']
  },
  {
    id: 3,
    name: 'Life & Society',
    codes: ['ay.lifesoc'],
    exclude: ['ay.lifesoc.vidgames', 'ay.lifesoc.fashion']
  },
  {
    id: 4,
    name: 'Music',
    codes: ['ay.culture.music']
  },
  {
    id: 5,
    name: 'Politics',
    codes: ['ay.pol']
  },
  {
    id: 6,
    name: 'Science & Tech',
    codes: ['ay.appsci']
  },
  {
    id: 7,
    name: 'Sports',
    codes: ['ay.sports']
  },
  {
    id: 8,
    name: 'Pop Culture',
    codes: ['ay.culture'],
    exclude: ['ay.culture.music', 'ay.culture.television', 'ay.culture.film']
  },
  {
    id: 9,
    name: 'TV & Film',
    codes: ['ay.culture.television', 'ay.culture.film']
  },
  {
    id: 10,
    name: 'Food and Beverage',
    codes: ['ay.lifesoc.fooddine']
  }
];