import React, { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

interface CarouselProps {
    /** List of items to be displayed in the carousel. */
    items: React.ReactNode[];
    /** Number of items to show in each slide. */
    itemsPerSlide?: number;
}
/**
 * Carousel Component
 *
 * A reusable carousel component that displays a specified number of items per slide.
 *
 * @param {React.ReactNode[]} items - An array of React nodes to be displayed in the carousel.
 * @param {number} [itemsPerSlide=4] - The number of items to display per slide. Default is 4.
 *
 * @example
 * import Carousel from './Carousel';
 * 
 * const Example = () => {
 *     const items = [
 *         <div>Item 1</div>,
 *         <div>Item 2</div>,
 *         <div>Item 3</div>,
 *         <div>Item 4</div>,
 *         <div>Item 5</div>,
 *     ];
 * 
 *     return <Carousel items={items} itemsPerSlide={3} />;
 * };
 *
 * @returns {JSX.Element} The rendered carousel component.
 */

const Carousel = ({ items, itemsPerSlide = 4 }: CarouselProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const totalSlides = Math.ceil(items.length / itemsPerSlide);

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : totalSlides - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex < totalSlides - 1 ? prevIndex + 1 : 0));
    };

    const visibleItems = items.slice(
        currentIndex * itemsPerSlide,
        currentIndex * itemsPerSlide + itemsPerSlide
    );

    const showChevrons = items.length > itemsPerSlide;

    return (
        <div data-testid="carousel" className="relative flex items-center w-full">
            {showChevrons && <button
                data-testid="carousel-prev"
                className="p-2 bg-gray-200 rounded-full"
                onClick={handlePrevious}
                aria-label="Previous slide"
                disabled={currentIndex === 0}
            >
                <ChevronLeftIcon className="h-6 w-6" />
            </button>}
            <div className="flex overflow-hidden mx-4">
                {visibleItems.map((item, index) => (
                    <div key={index} data-testid={`carousel-item`}>
                        {item}
                    </div>
                ))}
            </div>
            {showChevrons && <button
                data-testid="carousel-next"
                className="p-2 bg-gray-200 rounded-full"
                onClick={handleNext}
                aria-label="Next slide"
                disabled={currentIndex === totalSlides - 1}
            >
                <ChevronRightIcon className="h-6 w-6" />
            </button>}
        </div>
    );
};

export default Carousel;

