import React from 'react';

type PageProps = {
  headerName: string
  headerCount?: number
  children: React.ReactElement
  disableScroll?: boolean
}

export const ConversationPanelSectionLayout = ({ headerName, headerCount, children, disableScroll }: PageProps) => {
  return (
    <div className={`p-6 h-full ${disableScroll ? 'overflow-auto' : ''}`}>
      <div className="flex justify-between mb-4">

        <h3>
          {headerName}
        </h3>

        {(headerCount === 0 || headerCount) &&
          <div className="flex-col">
            <h5>
              Total {headerName}
            </h5>
            <h1 className="font-bold text-sightlyPurple">
              {headerCount}
            </h1>
          </div>
        }

      </div>

      {children}
    </div>
  )
}
