import React from 'react';
import MomentPreviewCard from '@/views/Discover/Boards/components/MomentPreviewCard';
import { MomentPreviewType } from '@/views/Discover/Boards/types';
import { LoadingItems } from '@/components/LoadingItems'
import {TotalPreviewsCard} from "@/views/Discover/Boards/components/TotalPreviewsCard";
import NoFoundMessage from './NoFoundMessage';

interface IProps {
  previewMoments: MomentPreviewType[];
  isLoading?: boolean;
  previewsCount: number;
  previewsCountLongRange: number;
  previewsCountMediumRange: number;
  setMomentsThumbUp: Function,
  momentsThumbUp: []
  setMomentsThumbDown: Function,
  momentsThumbDown: []
  thumbUpLimitReached: Boolean
  thumbDownLimitReached: Boolean
}

const MomentPreviewList: Function = ({
  previewMoments = [],
  isLoading,
  previewsCount,
  previewsCountLongRange,
  previewsCountMediumRange,
  setMomentsThumbUp,
  momentsThumbUp,
  setMomentsThumbDown,
  momentsThumbDown,
  thumbUpLimitReached,
  thumbDownLimitReached
}: IProps) => {
  const hasMoments = !!previewMoments.length;
  const filteredMoments = previewMoments.filter((preview) => !!preview);
  const containerHeightClass = 'h-[calc(100%-48px)]';
  const heightClass = 'max-h-[calc(200px - 12px)]';

  if (isLoading) {
    return <div className={`flex flex-col justify-center grow mt-5 ${containerHeightClass}`}>
      <div className="h-screen">
        <LoadingItems />
      </div>
    </div>
  }

  return (
    <div data-testid="preview-moment-list" className={`${containerHeightClass} bg-slate-50 flex flex-col`}>
      <TotalPreviewsCard
        title={'Total Moments'}
        isLoading={isLoading}
        previewsCount={previewsCount}
        previewsCountMediumRange={previewsCountMediumRange}
        previewsCountLongRange={previewsCountLongRange}
      />

      {(hasMoments && <div data-testid="nl-preview-moments-list" className={`overflow-scroll bg-slate-50 h-full ${heightClass}`}>
        {filteredMoments.map((momentPreview) => {
          return <MomentPreviewCard
            key={momentPreview.name}
            momentPreview={momentPreview}
            momentsThumbUp={momentsThumbUp}
            setMomentsThumbUp={setMomentsThumbUp}
            momentsThumbDown={momentsThumbDown}
            setMomentsThumbDown={setMomentsThumbDown}
            thumbUpLimitReached={thumbUpLimitReached}
            thumbDownLimitReached={thumbDownLimitReached}
          />
        })}
        <p className="my-4">
          Displaying {
            filteredMoments.length >= 30
              ? 30
              : filteredMoments.length
          } moments
        </p>
      </div>)}
      {(!hasMoments && <div id="no-moments-found-display" className="flex h-screen mr-8 justify-center">
        <NoFoundMessage
          title="No moments found"
          subtitle="Please try updating with a different board prompt or adding ideas to lean into"
        />
      </div>)}
    </div>
  )
}

export default MomentPreviewList;
