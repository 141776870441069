import { ArrowLeftIcon, CheckCircleIcon } from '@heroicons/react/20/solid';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';

import { api } from '@/api/api';
import defaultLoginImage from '@/assets/img/introSideImage.png';
import svgLogo from '@/assets/img/sightly-logo.svg';
import tiktokLoginImage from '@/assets/img/tiktoklogin.png';
import { SightlyModernFormInput } from '@/components/Sightly/SightlyModern/Form/SightlyModernFormInput';
import { useSightlyForm } from '@/components/Sightly/SightlyModern/Form/hooks/useSightlyForm';
import { SightlyModernButton } from '@/components/Sightly/SightlyModern/SightlyModernButton';
import { WarningModalTailwind } from '@/components/WarningModalTailwind';
import { IntroLayout } from '@/layouts/IntroLayout';
import { routes } from '@/routes';
import { isIframe } from '@/validations';
import { ChangePasswordFormSchema, ChangePasswordFormType } from './types';

export const ChangePassword: React.FC = () => {
    const {
        params: { userId, token }
    } = useMatch()
    const navigate = useNavigate()
    const [showGenericErrorDialog, setShowGenericErrorDialog] = React.useState(false)
    const [showSuccessfulDetais, setShowSuccessfulDetails] = React.useState(false)
    const openLoginPage = () => navigate({ to: routes.login.path })
    const isInTikTokIframe = isIframe();

    const { sightlyRegister, handleSubmit, formState: { isValid }, trigger, watch, getFieldState } = useSightlyForm<ChangePasswordFormType>(ChangePasswordFormSchema)
    // This is needed due to controller RHF fields not having the deps property: https://github.com/react-hook-form/react-hook-form/issues/9685#issuecomment-1369813795
    useEffect(() => {
        if (getFieldState('confirmPassword').isTouched) {
            trigger(['confirmPassword'])
        }
    }, [trigger, watch('password')]);

    const changePasswordOverride = (changePasswordDetails: ChangePasswordFormType) => api.user.changePassword(userId, token, changePasswordDetails)
    const mutation = useMutation(changePasswordOverride, {
        onSuccess: (data) => {
            if (data.isError) {
                setShowGenericErrorDialog(true)
                return
            }

            setShowSuccessfulDetails(true)
        },
        onError: (_) => {
            setShowGenericErrorDialog(true)
        }
    });

    const onSubmit = handleSubmit((data) => {
        mutation.mutate(data)
    });

    const successDetails =
        <div className='flex flex-col gap-[4vh] justify-center'>
            <div className='flex justify-center'><CheckCircleIcon className="h-12 text-green-400 text-center" /></div>
            <div className='flex flex-col gap-[2vh] justify-center'>
                <h1 className='text-3xl leading-9 font-extrabold text-gray-900'>Password reset</h1>

                <div>
                    <p className='text-sm leading-5 font-normal text-gray-600 '>
                        Your password has been successfully reset.
                    </p>
                    <p className='text-sm leading-5 font-normal text-gray-600 '>
                        Click below to login.
                    </p>
                </div>
            </div>

            <SightlyModernButton
                className='w-full'
                id='continueButton'
                type='inverted'
                preventDefaultOnClick
                handleClick={openLoginPage}
                text='Continue'
            />

            <WarningModalTailwind
                open={showGenericErrorDialog}
                setOpen={setShowGenericErrorDialog}
                title='Something went wrong'
                subtitle='Please try again, or contact support at support@sightly.com'
            />
        </div>

    const formDetails = <>
        <img className='h-8' src={svgLogo} alt='logo' />
        <div className='flex flex-col gap-[4vh] items-center pt-[3vh] text-center'>
            <div>
                <h1 className='text-3xl leading-9 font-extrabold text-gray-900'>Set new password</h1>
                <p className='text-sm leading-5 font-normal text-gray-600 '>
                    Your new password must be different to previously used passwords
                </p>
            </div>
            <form
                onSubmit={onSubmit}
                className='w-full flex flex-col gap-[2vh] justify-center items-center'
            >
                <SightlyModernFormInput {...sightlyRegister('password', 'Password')} showAllErrors type='password' />
                <SightlyModernFormInput {...sightlyRegister('confirmPassword', 'Confirm password')} type='password' />

                <SightlyModernButton
                    className='w-full'
                    id='submitButton'
                    type='inverted'
                    handleClick={() => undefined}
                    text='Reset Password'
                    disabled={mutation.isLoading || !isValid}
                />

                <SightlyModernButton
                    className='w-full'
                    id='loginButton'
                    type='secondaryblack'
                    preventDefaultOnClick
                    icon={<ArrowLeftIcon className='h-[2.5vh] w-[2.5vh]' />}
                    handleClick={openLoginPage}
                    text='Back to Login'
                />

                <WarningModalTailwind
                    open={showGenericErrorDialog}
                    setOpen={setShowGenericErrorDialog}
                    title='Something went wrong'
                    subtitle='Please try again, or contact support at support@sightly.com'
                />
            </form>
        </div>
    </>

    const loginImage = isInTikTokIframe ? tiktokLoginImage : defaultLoginImage;

    return (
        <IntroLayout image={loginImage}>
            {
                showSuccessfulDetais
                    ? successDetails
                    : formDetails
            }
        </IntroLayout>
    );
};
