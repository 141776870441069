import { DateRangeType, getLast24Hrs, getLast7Days, getLast30Days, getLast60Days } from "@/utils/dateRanges";

export const getAllTime = (): [Date, Date] => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    const startOf1970 = new Date(1970, 0, 1);

    return [startOf1970, yesterday]
}

export const ranges: DateRangeType[] = [
    {
        label: 'Last 24 Hrs',
        value: () => getLast24Hrs()
    },
    {
        label: 'Last 7 Days',
        value: () => getLast7Days()
    },
    {
        label: 'Last 30 Days',
        value: () => getLast30Days()
    },
    {
        label: 'Last 60 Days',
        value: () => getLast60Days()
    },
    {
        label: 'All times',
        value: () => getAllTime()
    },
    
]