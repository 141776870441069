import React from 'react';
import '@/components/Navbars/index.css';
import { HorizontalNavAccountItem } from '@/components/Navbars/Horizontal/HorizontalNavAccountItem';
import useUser from '@/hooks/useUser';

export const HorizontalNavBar = () => {
    const { currentAccount } = useUser();

    return (
        <div className="bg-white border-b border-gray-200 px-4 flex justify-between">
            <HorizontalNavAccountItem accountName={currentAccount?.accountName} />
        </div>
    )
}
