import { AgeReport, AGE_DIMENSION, baseAgeReport, ageRangeReportTemplate } from "@/views/ActivationReports/reports/age.report";
import { AudienceReport, AUDIENCE_DIMENSION, baseAudienceReport, personaAudienceReportTemplate } from "@/views/ActivationReports/reports/audience.report";
import { baseCreativeReport, CreativeReport, CREATIVE_DIMENSION } from "@/views/ActivationReports/reports/creative.report";
import { adGroupReportTemplate, baseDeviceReport, campaignSummaryTemplate, DeviceReport, DEVICE_DIMENSION, performanceDetailTemplate, personaReportTemplate } from "@/views/ActivationReports/reports/device.report";
import { baseDmaRegionReport, DmaRegionReport, DMA_REGION_DIMENSION } from "@/views/ActivationReports/reports/dma-region.report";
import { baseGenderReport, GenderReport, GENDER_DIMENSION, genderReportTemplate } from "@/views/ActivationReports/reports/gender.report";
import { baseYoutubePlacementReport, GOOGLE_PLACEMENT, YoutubePlacementReport } from "@/views/ActivationReports/reports/google-placement.report";
import { baseHouseholdIncomeReport, HouseholdIncomeReport, HOUSEHOLD_INCOME_DIMENSION } from "@/views/ActivationReports/reports/household-income.report";
import { baseIndustryCategoryReport, IndustryCategoryReport, INDUSTRY_CATEGORY_DIMENSION } from "@/views/ActivationReports/reports/industry-category.report";
import { baseKeywordReport, personaKeywordReportTemplate, KEYWORD_DIMENSION, KeywordReport } from "@/views/ActivationReports/reports/keyword.report";
import { baseMomentKeywordReport, MomentKeywordReport, MOMENT_KEYWORD_DIMENSION } from "@/views/ActivationReports/reports/moment-keyword.report";
import { baseMomentScenarioReport, MomentScenarioReport, MOMENT_SCENARIO_DIMENSION, MOMENT_SCENARIO_METRIC } from "@/views/ActivationReports/reports/moment-scenario.report";
import { baseMomentReport, MomentReport, MOMENT_DIMENSION, MOMENT_METRIC } from "@/views/ActivationReports/reports/moment.report";
import { baseNewsCategoryReport, NewsCategoryReport, NEWS_CATEGORY_DIMENSION } from "@/views/ActivationReports/reports/news-category.report";
import { baseParentalStatusReport, ParentalStatusReport, PARENTAL_STATUS_DIMENSION, parentalStatusReportTemplate } from "@/views/ActivationReports/reports/parental-status.report";
import { baseTopicReport, personaTopicReportTemplate, TopicReport, TOPIC_DIMENSION } from "@/views/ActivationReports/reports/topic.report";
import { GENERIC_DIMENSION, GenericDimensionOption, METRIC, MetricOption } from '@/views/ActivationReports/types/activation-report.types';


export type AllReportTemplates =
  | DeviceReport
  | TopicReport
  | AudienceReport
  | KeywordReport
  | AgeReport
  | GenderReport
  | ParentalStatusReport
  | CreativeReport
  | DmaRegionReport
  | YoutubePlacementReport
  | HouseholdIncomeReport
  | IndustryCategoryReport
  | MomentReport
  | MomentKeywordReport
  | NewsCategoryReport
  | MomentScenarioReport;


export const allTemplateReports: AllReportTemplates[] = [
  performanceDetailTemplate,
  campaignSummaryTemplate,
  adGroupReportTemplate,
  personaReportTemplate,
  personaTopicReportTemplate,
  personaAudienceReportTemplate,
  personaKeywordReportTemplate,
  ageRangeReportTemplate,
  genderReportTemplate,
  parentalStatusReportTemplate,
  baseAgeReport,
  baseGenderReport,
  baseParentalStatusReport,
  baseCreativeReport,
  baseDeviceReport,
  baseAudienceReport,
  baseDmaRegionReport,
  baseYoutubePlacementReport,
  baseHouseholdIncomeReport,
  baseIndustryCategoryReport,
  baseKeywordReport,
  baseMomentReport,
  baseMomentKeywordReport,
  baseMomentScenarioReport,
  baseNewsCategoryReport,
  baseTopicReport
];

export type Option<T> = {
  [key: string]: T;
}

export interface CustomDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | AGE_DIMENSION | AUDIENCE_DIMENSION | CREATIVE_DIMENSION | DEVICE_DIMENSION | DMA_REGION_DIMENSION | GENDER_DIMENSION | GOOGLE_PLACEMENT | HOUSEHOLD_INCOME_DIMENSION | INDUSTRY_CATEGORY_DIMENSION | KEYWORD_DIMENSION | MOMENT_KEYWORD_DIMENSION | MOMENT_SCENARIO_DIMENSION | MOMENT_DIMENSION | NEWS_CATEGORY_DIMENSION | PARENTAL_STATUS_DIMENSION | TOPIC_DIMENSION
}
export interface CustomMetricOption extends Omit<MetricOption, 'value'> {
  name: string
  value: METRIC | MOMENT_SCENARIO_METRIC | MOMENT_METRIC
} 

export const dimensionOptions: Option<CustomDimensionOption> = {
  [AGE_DIMENSION.AGE_RANGE]: {
    name: 'Age Range',
    value: AGE_DIMENSION.AGE_RANGE,
    description: `AGE_RANGE_18_24,
                  AGE_RANGE_25_34,
                  AGE_RANGE_35_44,
                  AGE_RANGE_45_54,
                  AGE_RANGE_55_64,
                  AGE_RANGE_65_UP,
                  AGE_RANGE_UN`
  },
  [AGE_DIMENSION.DEVICE]: {
    name: 'Device',
    value: AGE_DIMENSION.DEVICE,
    description: `CONNECTED_TV,
                  DESKTOP,
                  MOBILE,
                  TABLET`
  },
  [AUDIENCE_DIMENSION.AUDIENCE_NAME]: {
    name: 'Audience Name',
    value: AUDIENCE_DIMENSION.AUDIENCE_NAME,
    description: 'The name assigned to the audience segment.' // Generated description
  },
  [AUDIENCE_DIMENSION.AUDIENCE_TYPE]: {
    name: 'Audience Type',
    value: AUDIENCE_DIMENSION.AUDIENCE_TYPE,
    description: 'The classification of the audience.' // Generated description
  },
  [CREATIVE_DIMENSION.AD_ID]: {
    name: 'Ad Id',
    value: CREATIVE_DIMENSION.AD_ID,
    description: 'Unique identifier for the advertisement.' // Generated description
  },
  [CREATIVE_DIMENSION.CLIENT_CREATIVE_ID]: {
    name: 'Client Creative Id',
    value: CREATIVE_DIMENSION.CLIENT_CREATIVE_ID,
    description: 'Identifier for the creative.' // Generated description
  },
  [CREATIVE_DIMENSION.CLIENT_CREATIVE_NAME]: {
    name: 'Client Creative Name',
    value: CREATIVE_DIMENSION.CLIENT_CREATIVE_NAME,
    description: 'Name of the creative.' // Generated description
  },
  [CREATIVE_DIMENSION.DEVICE]: {
    name: 'Device',
    value: CREATIVE_DIMENSION.DEVICE,
    description: `CONNECTED_TV,
                  DESKTOP,
                  MOBILE,
                  TABLET`
  },
  [CREATIVE_DIMENSION.VIDEO_ID]: {
    name: 'Video Id',
    value: CREATIVE_DIMENSION.VIDEO_ID,
    description: 'Unique identifier for the video' // Generated description
  },
  [CREATIVE_DIMENSION.VIDEO_TITLE]: {
    name: 'Video Title',
    value: CREATIVE_DIMENSION.VIDEO_TITLE,
    description: 'Title of the video' // Generated description
  },
  [CREATIVE_DIMENSION.VIDEO_DURATION]: {
    name: 'Video Duration',
    value: CREATIVE_DIMENSION.VIDEO_DURATION,
    description: 'Length of the video' // Generated description
  },
  [DEVICE_DIMENSION.AD_ID]: {
    name: 'Ad Id',
    value: DEVICE_DIMENSION.AD_ID,
    description: 'Unique identifier for the advertisement.' // Generated description
  },
  [DEVICE_DIMENSION.AD_NAME]: {
    name: 'Ad Name',
    value: DEVICE_DIMENSION.AD_NAME,
    description: 'Name of the ad' // Generated description
  },
  [DEVICE_DIMENSION.AD_TYPE]: {
    name: 'Ad Type',
    value: DEVICE_DIMENSION.AD_TYPE,
    description: 'Type of the ad' // Generated description
  },
  [DEVICE_DIMENSION.CLIENT_CREATIVE_ID]: {
    name: 'Client Creative Id',
    value: DEVICE_DIMENSION.CLIENT_CREATIVE_ID,
    description: 'Identifier for the creative.' // Generated description
  },
  [DEVICE_DIMENSION.CLIENT_CREATIVE_NAME]: {
    name: 'Client Creative Name',
    value: DEVICE_DIMENSION.CLIENT_CREATIVE_NAME,
    description: 'Name of the creative.' // Generated description
  },
  [DEVICE_DIMENSION.DEVICE]: {
    name: 'Device',
    value: DEVICE_DIMENSION.DEVICE,
    description: `CONNECTED_TV,
                  DESKTOP,
                  MOBILE,
                  TABLET`
  },
  [DMA_REGION_DIMENSION.DEVICE]: {
    name: 'Device',
    value: DMA_REGION_DIMENSION.DEVICE,
    description: `CONNECTED_TV,
                  DESKTOP,
                  MOBILE,
                  TABLET`
  },
  [DMA_REGION_DIMENSION.DMA_REGION]: {
    name: 'DMA Region',
    value: DMA_REGION_DIMENSION.DMA_REGION,
    description: 'Represents different designated market areas' // Generated description
  },
  [GENDER_DIMENSION.DEVICE]: {
    name: 'Device',
    value: GENDER_DIMENSION.DEVICE,
    description: `CONNECTED_TV,
                  DESKTOP,
                  MOBILE,
                  TABLET`
  },
  [GENDER_DIMENSION.GENDER]: {
    name: 'Gender Range',
    value: GENDER_DIMENSION.GENDER,
    description: `FEMALE,
                  MALE,
                  UNDETERMINED`
  },
  [GOOGLE_PLACEMENT.DEVICE]: {
    name: 'Device',
    value: GOOGLE_PLACEMENT.DEVICE,
    description: `CONNECTED_TV,
                  DESKTOP,
                  MOBILE,
                  TABLET`
  },
  [GOOGLE_PLACEMENT.PLACEMENT_TYPE]: {
    name: 'Placement Type',
    value: GOOGLE_PLACEMENT.PLACEMENT_TYPE,
    description: 'Represents the type of placement' // Generated description
  },
  [GOOGLE_PLACEMENT.YOUTUBE_PLACEMENT_ID]: {
    name: 'Youtube Placement Id',
    value: GOOGLE_PLACEMENT.YOUTUBE_PLACEMENT_ID,
    description: 'Unique identifier for a specific placement' // Generated description
  },
  [GOOGLE_PLACEMENT.YOUTUBE_PLACEMENT_NAME]: {
    name: 'Youtube Placement Name',
    value: GOOGLE_PLACEMENT.YOUTUBE_PLACEMENT_NAME,
    description: 'Name associated with a specific placement' // Generated description
  },
  [HOUSEHOLD_INCOME_DIMENSION.DEVICE]: {
    name: 'Device',
    value: HOUSEHOLD_INCOME_DIMENSION.DEVICE,
    description: `CONNECTED_TV,
                  DESKTOP,
                  MOBILE,
                  TABLET`
  },
  [HOUSEHOLD_INCOME_DIMENSION.INCOME_RANGE]: {
    name: 'Household Income',
    value: HOUSEHOLD_INCOME_DIMENSION.INCOME_RANGE,
    description: 'Represents different income levels' // Generated description
  },
  [INDUSTRY_CATEGORY_DIMENSION.BRAND_PROFILE_NAME]: {
    name: 'Brand Profile Name',
    value: INDUSTRY_CATEGORY_DIMENSION.BRAND_PROFILE_NAME,
    description: 'Name associated with a brand profile' // Generated description
  },
  [INDUSTRY_CATEGORY_DIMENSION.INDUSTRY_CATEGORY]: {
    name: 'Industry Category',
    value: INDUSTRY_CATEGORY_DIMENSION.INDUSTRY_CATEGORY,
    description: 'Represents different industry sectors' // Generated description
  },
  [KEYWORD_DIMENSION.DEVICE]: {
    name: 'Device',
    value: KEYWORD_DIMENSION.DEVICE,
    description: `CONNECTED_TV,
                  DESKTOP,
                  MOBILE,
                  TABLET`
  },
  [KEYWORD_DIMENSION.KEYWORD]: {
    name: 'Keyword',
    value: KEYWORD_DIMENSION.KEYWORD,
    description: 'Represents a term or phrase used for targeting' // Generated description
  },
  [MOMENT_KEYWORD_DIMENSION.CLUSTER_ID]: {
    name: 'Cluster Id',
    value: MOMENT_KEYWORD_DIMENSION.CLUSTER_ID,
    description: 'Unique identifier for a specific moment' // Generated description
  },
  [MOMENT_KEYWORD_DIMENSION.CLUSTER_NAME]: {
    name: 'Cluster Name',
    value: MOMENT_KEYWORD_DIMENSION.CLUSTER_NAME,
    description: 'Name given to a particular moment' // Generated description
  },
  [MOMENT_KEYWORD_DIMENSION.DEVICE]: {
    name: 'Device',
    value: MOMENT_KEYWORD_DIMENSION.DEVICE,
    description: `CONNECTED_TV,
                  DESKTOP,
                  MOBILE,
                  TABLET`
  },
  [MOMENT_KEYWORD_DIMENSION.KEYWORD]: {
    name: 'Keyword',
    value: MOMENT_KEYWORD_DIMENSION.KEYWORD,
    description: 'Represents a term or phrase used for targeting' // Generated description
  },
  [MOMENT_SCENARIO_DIMENSION.ACTION]: {
    name: 'Action',
    value: MOMENT_SCENARIO_DIMENSION.ACTION,
    description: 'Indicates a specific user interaction or event' // Generated description
  },
  [MOMENT_SCENARIO_DIMENSION.BRAND_PROFILE_NAME]: {
    name: 'Brand Profile Name',
    value: MOMENT_SCENARIO_DIMENSION.BRAND_PROFILE_NAME,
    description: 'Name associated with a brand profile' // Generated description
  },
  [MOMENT_SCENARIO_DIMENSION.CLUSTER_ID]: {
    name: 'Moment Id',
    value: MOMENT_SCENARIO_DIMENSION.CLUSTER_ID,
    description: 'Unique identifier for a specific moment' // Generated description
  },
  [MOMENT_SCENARIO_DIMENSION.CLUSTER_NAME]: {
    name: 'Moment Name',
    value: MOMENT_SCENARIO_DIMENSION.CLUSTER_NAME,
    description: 'Name given to a particular moment' // Generated description
  },
  [MOMENT_SCENARIO_DIMENSION.MAX_PUBLISHED_AT]: {
    name: 'Max Published At',
    value: MOMENT_SCENARIO_DIMENSION.MAX_PUBLISHED_AT,
    description: 'Latest publication timestamp for the moment' // Generated description
  },
  [MOMENT_SCENARIO_DIMENSION.MIN_PUBLISHED_AT]: {
    name: 'Min Published At',
    value: MOMENT_SCENARIO_DIMENSION.MIN_PUBLISHED_AT,
    description: 'Earliest publication timestamp for the moment' // Generated description
  },
  [MOMENT_SCENARIO_DIMENSION.PERSONAS]: {
    name: 'Personas',
    value: MOMENT_SCENARIO_DIMENSION.PERSONAS,
    description: 'Profiles representing different audience segments' // Generated description
  },
  [MOMENT_SCENARIO_DIMENSION.SCENARIO_NAME]: {
    name: 'Scenario Name',
    value: MOMENT_SCENARIO_DIMENSION.SCENARIO_NAME,
    description: 'Name of the scenario being analyzed' // Generated description
  },
  [MOMENT_SCENARIO_DIMENSION.SCENARIO_RESPONSE]: {
    name: 'Scenario Response',
    value: MOMENT_SCENARIO_DIMENSION.SCENARIO_RESPONSE,
    description: 'Outcome or feedback from a scenario' // Generated description
  },
  [MOMENT_SCENARIO_DIMENSION.STORY_TITLES]: {
    name: 'Story Titles',
    value: MOMENT_SCENARIO_DIMENSION.STORY_TITLES,
    description: 'Titles of stories associated with the moment' // Generated description
  },
  [MOMENT_DIMENSION.ACTION]: {
    name: 'Action',
    value: MOMENT_DIMENSION.ACTION,
    description: 'Indicates a specific user interaction or event' // Generated description
  },
  [MOMENT_DIMENSION.BRAND_PROFILE_NAME]: {
    name: 'Brand Profile Name',
    value: MOMENT_DIMENSION.BRAND_PROFILE_NAME,
    description: 'Name associated with a brand profile' // Generated description
  },
  [MOMENT_DIMENSION.CATEGORY_ID_LIST]: {
    name: 'Category Ids',
    value: MOMENT_DIMENSION.CATEGORY_ID_LIST,
    description: 'List of identifiers for various categories' // Generated description
  },
  [MOMENT_DIMENSION.CATEGORY_LABEL_LIST]: {
    name: 'Category Labels',
    value: MOMENT_DIMENSION.CATEGORY_LABEL_LIST,
    description: 'Labels assigned to different categories' // Generated description
  },
  [MOMENT_DIMENSION.CLUSTER_ID]: {
    name: 'Moment Id',
    value: MOMENT_DIMENSION.CLUSTER_ID,
    description: 'Unique identifier for a specific moment' // Generated description
  },
  [MOMENT_DIMENSION.CLUSTER_NAME]: {
    name: 'Moment Name',
    value: MOMENT_DIMENSION.CLUSTER_NAME,
    description: 'Name given to a particular moment' // Generated description
  },
  [MOMENT_DIMENSION.MAX_PUBLISHED_AT]: {
    name: 'Max Published At',
    value: MOMENT_DIMENSION.MAX_PUBLISHED_AT,
    description: 'Latest publication timestamp for the moment' // Generated description
  },
  [MOMENT_DIMENSION.MIN_PUBLISHED_AT]: {
    name: 'Min Published At',
    value: MOMENT_DIMENSION.MIN_PUBLISHED_AT,
    description: 'Earliest publication timestamp for the moment' // Generated description
  },
  [MOMENT_DIMENSION.PERSONAS]: {
    name: 'Personas',
    value: MOMENT_DIMENSION.PERSONAS,
    description: 'Profiles representing different audience segments' // Generated description
  },
  [MOMENT_DIMENSION.STORY_TITLES]: {
    name: 'Story Titles',
    value: MOMENT_DIMENSION.STORY_TITLES,
    description: 'Titles of stories associated with the moment' // Generated description
  },
  [NEWS_CATEGORY_DIMENSION.BRAND_PROFILE_NAME]: {
    name: 'Brand Profile Name',
    value: NEWS_CATEGORY_DIMENSION.BRAND_PROFILE_NAME,
    description: 'Name associated with a brand profile' // Generated description
  },
  [NEWS_CATEGORY_DIMENSION.NEWS_CATEGORY]: {
    name: 'News Category',
    value: NEWS_CATEGORY_DIMENSION.NEWS_CATEGORY,
    description: 'Labels assigned to different categories' // Generated description
  },
  [PARENTAL_STATUS_DIMENSION.DEVICE]: {
    name: 'Device',
    value: PARENTAL_STATUS_DIMENSION.DEVICE,
    description: `CONNECTED_TV,
                  DESKTOP,
                  MOBILE,
                  TABLET`
  },
  [PARENTAL_STATUS_DIMENSION.PARENTAL_STATUS]: {
    name: 'Parental Status',
    value: PARENTAL_STATUS_DIMENSION.PARENTAL_STATUS,
    description: `NOT_A_PARENT,
                  PARENT,
                  UNKNOWN`
  },
  [TOPIC_DIMENSION.TOPIC_NAME]: {
    name: 'Topic Name',
    value: TOPIC_DIMENSION.TOPIC_NAME,
    description: 'Name of the topic' // Generated description
  },
  [TOPIC_DIMENSION.DEVICE]: {
    name: 'Device',
    value: TOPIC_DIMENSION.DEVICE,
    description: `CONNECTED_TV,
                  DESKTOP,
                  MOBILE,
                  TABLET`
  },
}

export const metricsOptions: Option<CustomMetricOption> = {
  [METRIC.STAT_DATE]: {
    name: 'Date',
    value: METRIC.STAT_DATE,
    description: 'The date for which the data is reported (Daily or Aggregated for the Flight)'
  },
  [METRIC.IMPRESSIONS]: {
    name: 'Impressions',
    value: METRIC.IMPRESSIONS,
    description: 'Number of times your ad was displayed'
  },
  [METRIC.VIEWS]: {
    name: 'Views',
    value: METRIC.VIEWS,
    description: 'Percentage of impressions that resulted in a view'
  },
  [METRIC.VIEW_RATE]: {
    name: 'View Rate',
    value: METRIC.VIEW_RATE,
        description: 'Percentage of impressions that resulted in a view'
  },
  [METRIC.VCR]: {
    name: 'VCR',
    value: METRIC.VCR,
    description: 'Measures the percentage of viewers who watch a video ad in its entirety'
  },
  [METRIC.CLICKS]: {
    name: 'Clicks',
    value: METRIC.CLICKS,
    description: 'Number of times viewers clicked on your ad'
  },
  [METRIC.CPM]: {
    name: 'CPM',
    value: METRIC.CPM,
    description: 'Number of times viewers watched part or all of your ad'
  },
  [METRIC.CPV]: {
    name: 'CPV',
    value: METRIC.CPV,
    description: 'Cost per video view'
  },
  [METRIC.CPC]: {
    name: 'CPC',
    value: METRIC.CPC,
    description: 'Cost per ad click'
  },
  [METRIC.CTR]: {
    name: 'CTR',
    value: METRIC.CTR,
    description: 'Percentage of viewers who clicked on your ad'
  },
  [METRIC.COST]: {
    name: 'Cost',
    value: METRIC.COST,
    description: 'Total cost of the campaign for the specified date range'
  },
  [METRIC.MARGIN]: {
    name: 'Margin',
    value: METRIC.MARGIN,
    description: 'The difference between ad revenue and ad costs'
  },
  [METRIC.QUARTILE_25]: {
    name: 'Video Quartile 25%',
    value: METRIC.QUARTILE_25,
    description: 'Percentage of viewers who watched at least 25% of your ad'
  },
  [METRIC.QUARTILE_50]: {
    name: 'Video Quartile 50%',
    value: METRIC.QUARTILE_50,
    description: 'Percentage of viewers who watched at least 50% of your ad'
  },
  [METRIC.QUARTILE_75]: {
    name: 'Video Quartile 75%',
    value: METRIC.QUARTILE_75,
    description: 'Percentage of viewers who watched at least 75% of your ad'
  },
  [METRIC.QUARTILE_100]: {
    name: 'Video Quartile 100%',
    value: METRIC.QUARTILE_100,
    description: 'Percentage of viewers who watched your entire ad'
  },
  [MOMENT_SCENARIO_METRIC.STORY_COUNT]: {
    name: 'Story Count',
    value: MOMENT_SCENARIO_METRIC.STORY_COUNT,
    description: 'Number of stories viewed from an ad'
  },
  [MOMENT_SCENARIO_METRIC.VIDEO_COUNT]: {
    name: 'Video Count',
    value: MOMENT_SCENARIO_METRIC.VIDEO_COUNT,
    description: 'Total number of times a video ad is viewed or interacted with'
  },
}

export const templateCustomDimensionOptions: CustomDimensionOption[] = Object.keys(dimensionOptions).map(key => dimensionOptions[key])
export const templateCustomMetricsOptions: CustomMetricOption[] = Object.keys(metricsOptions).map(key => metricsOptions[key])
