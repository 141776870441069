import React from 'react'
import { Panel } from 'rsuite';
import { YouTubeChannelType } from '@/views/Engage/SmartList/types/SmartlistTypes';
import { getTimeDifference, formatNumber } from '@/views/Engage/utils/Functions';

interface IProps {
    /** Channel to show in the card. */
    channel: YouTubeChannelType
}

/**
 * Channel Card.
 * @prop {YouTubeChannelType} channel Channel to show in the card.
 */

export const ChannelCard = ({ channel }: IProps) => {

    //See if the date is in GMT-0 or in another timezone
    const lastModified = getTimeDifference(new Date(channel.lastModifiedAt));

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div data-testid={'card-' + channel.title} onClick={() => {
            openInNewTab(`https://www.youtube.com/channel/${channel.id}`);
        }}>
            <Panel
                className={`inline-block w-[304px] h-[220px] cursor-pointer`}
                shaded
                bordered
                bodyFill
            >
                <div className='flex flex-col p-3' data-testid={'card-container'}>
                    <div className='flex flex-row'>
                        <img data-testid={'card-thumbnail'} src={channel.thumbnailUrl} className='w-16 h-16 my-4' />
                        <div className='flex flex-col ml-2'>
                            <div className='text-lg font-semibold' data-testid={'card-title'}>
                                {channel.title}
                            </div>
                            <div className='text-sm font-semibold mt-1' data-testid={'card-username'}>
                                @{channel.title}
                            </div>
                            <div className='text-xs mt-2' data-testid={'card-subscriber-count'}>
                                {formatNumber(channel.subscriberCount)} <span className='font-bold'>Subscribers</span> • {formatNumber(channel.videoCount)} <span className='font-bold'>Videos</span>
                            </div>
                            <div className='text-xs mt-2' data-testid={'card-view-count'}>
                                {formatNumber(channel.viewCount)} <span className='font-bold'>Views</span> • {formatNumber(channel.engagement)} <span className='font-bold'>Engagement</span>
                            </div>
                        </div>
                    </div>
                    <div className='text-xs font-normal mt-3 line-clamp-3' data-testid={'card-description'}>
                        {channel.description.length > 115 ? (
                            <>
                                {channel.description.slice(0, 115)}
                                <span className='text-blue-500 font-semibold'>...more</span>
                            </>
                        ) : (
                            channel.description
                        )}
                    </div>
                    <div className='text-xs font-normal mt-4' data-testid={'card-last-video-uploaded'}>
                        Last video uploaded: {lastModified}
                    </div>
                </div>
            </Panel>
        </div>
    )
}
