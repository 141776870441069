import { SightlyInput } from '@/components/Sightly/SightlyFormElements/SightlyInput';
import SightlySelect from '@/components/Sightly/SightlyFormElements/SightlySelect';
import React, { useEffect, useState } from 'react';
import { Modal, Button, CheckPicker, RadioGroup, Radio, Icon } from 'rsuite';
import { SMART_LIST_TYPE, SMART_LIST_OBJECTIVE, SMART_LIST_CONTENT_TYPE } from '../types/SmartlistTypes';
import { Notification } from 'rsuite';
import { routes } from '@/routes';
import { useNavigate } from '@tanstack/react-location';
import { InformationModal } from '@/views/Engage/utils/InformationModal';
import LoadingModal from '@/views/Engage/utils/LoadingModal';
import { CreateSmartList } from '@/views/Engage/SmartList/schemas/SmartlistSchema';
import { smartlistController } from '@/views/Engage/SmartList/controller/SmartlistController';

interface IProps {
    /** Brand profile id. */
    brandProfileId: number;
    /** Account id. */
    accountId: number;
    /** Is open create modal. */
    isOpenCreateModal: boolean;
    /** Set is open create modal. */
    setIsOpenCreateModal: (isOpen: boolean) => void;
    /** Function to update view data. */
    updateViewData: Function;
}
/**
 * Create Smart List Modal.
 * @prop {number} brandProfileId Brand profile id.
 * @prop {number} accountId Account id.
 * @prop {boolean} isOpenCreateModal Is open create modal.
 * @prop {Function} setIsOpenCreateModal Set is open create modal.
 * @prop {Function} updateViewData Update view data.
 */

export default function CreateModal({ brandProfileId, accountId, isOpenCreateModal, setIsOpenCreateModal, updateViewData }: IProps) {
    const navigate = useNavigate()

    const [isCreatingSmartList, setIsCreatingSmartList] = useState(false);
    const [isErrorModalVisible, setIsInformationModalVisible] = useState(false);
    const [titleInfomationModal, setTitleInfomationModal] = useState('');
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const [smartListName, setSmartListName] = useState('');
    const [contentTypes] = useState([
        {
            label: SMART_LIST_CONTENT_TYPE.YOUTUBE_CHANNEL,
            value: SMART_LIST_CONTENT_TYPE.YOUTUBE_CHANNEL,
            type: 'Youtube'
        },
        {
            label: SMART_LIST_CONTENT_TYPE.YOUTUBE_VIDEO,
            value: SMART_LIST_CONTENT_TYPE.YOUTUBE_VIDEO,
            type: 'Youtube'
        },
    ]);
    const [objective, setObjective] = useState<SMART_LIST_OBJECTIVE | undefined>(undefined);
    const [contentTypesValue, setContentTypesValue] = useState<SMART_LIST_CONTENT_TYPE[]>([]);
    const [listType, setListType] = useState<SMART_LIST_TYPE | null>(null);
    const objectiveOptions = Object.values(SMART_LIST_OBJECTIVE).map((objective) => ({
        label: objective,
        value: objective,
    }));

    const [formValues, setFormValues] = useState<CreateSmartList>({
        brandProfileId: brandProfileId,
        name: '',
        objective: SMART_LIST_OBJECTIVE.REACH,
        contentTypes: [],
        listType: SMART_LIST_TYPE.INCLUSION,
        segments: [],
    })

    useEffect(() => {
        setFormValues((prevValues) => ({
            ...prevValues,
            brandProfileId: brandProfileId
        }))
    }, [brandProfileId])

    const handleCancel = (): void => {
        handleCleanForm();
        setIsOpenCreateModal(false);
    }

    const handleCreateSmartList = async (): Promise<void> => {
        const error = smartlistController.validateForm(formValues);
        if (error.length > 0) {
            showErrorModal('Error', error)
        } else {
            try {
                setIsCreatingSmartList(true)
                const result = await smartlistController.handleCreateSmartList(formValues, accountId!)
                Notification.open({
                    title: 'Success',
                    description: <div>The smartlist was successfully created</div>
                });
                handleNavigate(result.id);
            } catch (error) {
                showErrorModal('Error', ['Error while creating the smart list.'])
            } finally {
                handleCleanForm();
                setIsCreatingSmartList(false)
            }
        }
    }

    const showErrorModal = (title: string, messages: string[]) => {
        setTitleInfomationModal(title);
        setErrorMessages(messages)
        setIsInformationModalVisible(true)
    }

    const handleNavigate = (id: number) => {
        updateViewData()
        setIsOpenCreateModal(false);
        // navigate({
        //     to: routes.app.smartLists.viewSmartLists.path,
        //     search: (prev) => {
        //         return { accountId: prev!.accountId }
        //     }
        // })
    };

    const handleCleanForm = () => {
        setSmartListName('');
        setObjective(undefined);
        setContentTypesValue([]);
        setListType(null);
        setFormValues((prevValues) => ({
            ...prevValues,
            name: '',
            objective: SMART_LIST_OBJECTIVE.REACH,
            contentTypes: [],
            listType: SMART_LIST_TYPE.INCLUSION,
            segments: []
        }))
    }

    return (
        <div>
            <Modal show={isOpenCreateModal}>
                <Modal.Header onHide={handleCancel}>
                    <Modal.Title>New Smart List</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-2'>
                    <div className='pb-4' data-testid={'create-smart-list-name'}>
                        <SightlyInput
                            id={'create-smart-list-name'}
                            data-testid={'create-smart-list-name'}
                            label="SmartList Name"
                            onChange={(val: any) => {
                                setSmartListName(val);
                                setFormValues((prevValues) => ({
                                    ...prevValues,
                                    name: val
                                }))
                            }}
                        />
                    </div>

                    <div className='pb-4' data-testid={'create-smart-list-objective'}>
                        <SightlySelect
                            id={'create-smart-list-objective'}
                            data-testid={'create-smart-list-objective'}
                            label='Objective'
                            options={objectiveOptions}
                            onChange={(val: SMART_LIST_OBJECTIVE) => {
                                setObjective(val);
                                setFormValues((prevValues) => ({
                                    ...prevValues,
                                    objective: val
                                }))
                            }}
                            value={objective}
                            labelKey={'label'}
                            valueKey={'value'}
                            placeholder='Select Objective'
                        />
                    </div>

                    <div className='pb-4' data-testid={'create-smart-list-content-types'}>
                        <label className='text-sm font-medium'>Content Types</label>
                        <CheckPicker
                            id={'create-smart-list-content-types'}
                            data-testid={'create-smart-list-content-types'}
                            labelKey={'label'}
                            valueKey={'value'}
                            groupBy={'type'}
                            data={contentTypes}
                            value={contentTypesValue}
                            onChange={(contentTypesList: SMART_LIST_CONTENT_TYPE[]) => {
                                setContentTypesValue(contentTypesList);
                                setFormValues((prevValues) => ({
                                    ...prevValues,
                                    contentTypes: contentTypesList
                                }))
                            }}
                            onClean={() => {
                                setContentTypesValue([]);
                                setFormValues((prevValues) => ({
                                    ...prevValues,
                                    contentTypes: []
                                }))
                            }}
                            placeholder='Content Type'
                            searchable={false}
                            renderMenu={(menu: React.ReactNode) => {
                                if (!contentTypes) {
                                    return (
                                        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                            <Icon
                                                icon="spinner"
                                                spin
                                            />{' '}
                                            Loading...
                                        </p>
                                    );
                                }
                                return menu;
                            }}
                        />
                    </div>

                    <RadioGroup data-testid={'create-smart-list-list-type'} 
                        
                        onChange={(value: SMART_LIST_TYPE) => {
                        setListType(value);
                        setFormValues((prevValues) => ({
                            ...prevValues,
                            listType: value
                        }))
                    }} className='flex flex-row gap-4 pb-[2vh]'>
                        <Radio data-testid={'create-smart-list-inclusion'} value={SMART_LIST_TYPE.INCLUSION}>
                            <span>Inclusion</span>
                        </Radio>
                        <Radio data-testid={'create-smart-list-exclusion'} value={SMART_LIST_TYPE.EXCLUSION}>
                            <span>Exclusion</span>
                        </Radio>
                    </RadioGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        id='cancel-create-smart-list-button'
                        data-testid={'cancel-create-smart-list-button'}
                        className='rounded-md w-[129px] h-[40px]'
                        appearance="default"
                        style={{ color: '#7B61FF' }}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        id='create-smart-list-button'
                        data-testid={'create-smart-list-button'}
                        className='rounded-md w-[129px] h-[40px]'
                        appearance="primary"
                        style={{ backgroundColor: '#7B61FF' }}
                        onClick={handleCreateSmartList}
                        disabled={!objective || contentTypesValue.length == 0 || !listType || !smartListName}
                    >
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
            <InformationModal title={titleInfomationModal} informationMessages={errorMessages} isInformatioModalVisible={isErrorModalVisible} setIsInformatioModalVisible={setIsInformationModalVisible} />
            <LoadingModal loading={isCreatingSmartList} title='Creating Smart List' />
        </div>
    );
}
