import { IAccount } from '@/schemas/schemas.js'

export const ACCOUNT_STATUSES = {
  ACTIVE: 'Active',
  TRIAL: 'Trial',
  INACTIVE: 'Inactive'
};

/**
 * This service checks if an account has properly paid and thus has access to the platform.
 * If the account's trial is active, the account has access
 * If the subscription package is paid
 */

export const accountHasAccess = (account: IAccount | undefined) => {
  return account
    ? account.accountStatus !== ACCOUNT_STATUSES['INACTIVE']
    : false
}
