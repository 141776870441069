import { InfoPage } from '@/components/InfoPage';
import { isDateBetween } from '@/utils';
import TopicTrendCard from '@/views/Trends/components/TopicTrendCard';
import { ICategory, ITopic, ITrend, RelevanceClassification, TopicTrendEnum } from '@/views/Trends/types';
import React, { useMemo, useState, useEffect } from 'react';
import { Placeholder } from 'rsuite';
import dayjs from 'dayjs';

interface IProps {
  cardType: TopicTrendEnum;
  data: any;
  isLoading: boolean;
  category: ICategory;
  startDate: Date;
  endDate: Date;
}

const TopicTrendCardList = ({ cardType, data, isLoading, category, startDate, endDate }: IProps) => {
  const [isCalculatingFinalData, setIsCalculatingFinalData] = useState(true);
  const [time, setTime] = useState(0);

  useEffect(() => {
    setIsCalculatingFinalData(true);
    setTime(performance.now())
  }, [data, cardType, startDate, endDate, category]);

  const filterByCategory = (selectedCategory: ICategory, data: ITopic | ITrend) => {
    return data.categoryCodes.some((category) =>
      selectedCategory.codes?.includes(category) &&
       !(selectedCategory.exclude && selectedCategory.exclude.includes(category))
    );
 }

 const finalData = useMemo(() => {
  if (data) {
    const res = data
      .filter((item: ITopic | ITrend) => {
        const isCategoryValid = category.id === -1 || filterByCategory(category, item);
        const isCreatedAtValid = isDateBetween(item.maxPublishedAt, startDate, endDate);
        return isCategoryValid && isCreatedAtValid;
      })
      .map((item: ITopic | ITrend) => ({
        ...item,
        contentCount: item.metrics.moment.articleCount + item.metrics.narrative.postCount,
      }))
      .sort((a: { maxPublishDate: string; }, b: { maxPublishDate: string; }) => dayjs(b.maxPublishDate).valueOf() - dayjs(a.maxPublishDate).valueOf());
    return res;
  }
  return [];
}, [data, startDate, endDate, category]);

  useEffect(() => {
    if (performance.now() - time < 2000) {
      const timer = setTimeout(() => setIsCalculatingFinalData(false), 250);
      return () => clearTimeout(timer);
    } else {
      setIsCalculatingFinalData(false)
    }
  }, [finalData]);

  if (isLoading || isCalculatingFinalData) {
    return (
      <div className="grid grid-flow-row gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4">
        {[...Array(6)].map((_, index) => (
          <Placeholder.Graph active key={index} />
        ))}
      </div>
    );
  }

  if (finalData.length === 0) {
    return <InfoPage message={`There are no ${cardType}s for your filters`} />;
  }

  return (
    <div className="grid grid-flow-row gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4">
      {finalData.map((d: ITopic | ITrend) => (
        <TopicTrendCard
          key={`topic-trend-card-${d.id}`}
          type={cardType}
          title={d.name}
          description={d.overview}
          relevance={d.metrics.sentimentScore > 0
            ? RelevanceClassification.High
            : d.metrics.sentimentScore < 0
              ? RelevanceClassification.Low
              : RelevanceClassification.Medium}
          date={d.maxPublishedAt}
          image={d.imageUrl}
          lazy={true} 
          id={d.id}        
          />
      ))}
    </div>
  );
};

export default TopicTrendCardList;
