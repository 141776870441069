import React, { useState, useEffect, useRef } from 'react'
import toast from 'react-hot-toast';
import duplicateIcon from '@/assets/img/AI/justification/duplicate.svg'

import { RelevanceClassificationBadge } from '../../Moments/v2/components/RelevanceClassificationBadge'
import { RelevanceClassification } from '../../Moments/v2/types'
import { ThumsActions } from './thumbs/ThumsActions';
import { api } from '@/views/Discover/Moments/v2/api'
import { logError } from '@/utils'

type IProps = {
    momentClusterName: string,
    momentId: number,
    momentRelevance: RelevanceClassification | undefined | null,
    relevanceSummary: string,
    actionableInsight: string,
    marketingActionSuggestion: string,
    loadingError: boolean,
    errorCode: number,
    userId: number,
    brandProfileId: number | undefined,
    feedbackRelevanceSummary?: boolean | null,
    feedbackActionableInsight?: boolean | null,
    feedbackMarketingActionSuggestion?: boolean | null,
    uuid: string,
}

function _formatResponse(positiveFeedback: boolean | null, negativeFeedback: boolean | null): boolean | null {
    if (positiveFeedback) {
        return true;
    } else if (negativeFeedback) {
        return false;
    } else {
        return null;
    }
}

export const MomentAIJustificationCard = ({
    momentClusterName,
    momentId,
    momentRelevance,
    relevanceSummary: relevance_summary,
    actionableInsight: actionable_insight,
    marketingActionSuggestion: marketing_action_suggestion,
    loadingError,
    errorCode,
    userId,
    brandProfileId,
    feedbackRelevanceSummary = null,
    feedbackActionableInsight = null,
    feedbackMarketingActionSuggestion = null,
    uuid }: IProps) => {

    const [positiveFeedBackRelevanceSummary, setPositiveFeedBackRelevanceSummary] = useState<boolean | null>(null)
    const [negativeFeedBackRelevanceSummary, setNegativeFeedBackRelevanceSummary] = useState<boolean | null>(null)
    const [positiveFeedBackActionableInsight, setPositiveFeedBackActionableInsight] = useState<boolean | null>(null)
    const [negativeFeedBackActionableInsight, setNegativeFeedBackActionableInsight] = useState<boolean | null>(null)
    const [negativeFeedBackMarketingActionSuggestion, setNegativeFeedBackMarketingActionSuggestion] = useState<boolean | null>(null)
    const [positiveFeedBackMarketingActionSuggestion, setPositiveFeedBackMarketingActionSuggestion] = useState<boolean | null>(null)
    const isInitialMount = useRef(true);
    const [updateFeedback, setUpdateFeedback] = useState(false);

    const fireUserUpdate = () => {
        setUpdateFeedback(!updateFeedback)
    }

    var errorMessage = 'Please try later';

    if (errorCode == 400) {
        errorMessage = 'Please complete your brand profile and try later';
    }

    const updateMomentFeedback = async (feedBackRelevanceSummary: boolean | null, feedBackActionableInsight: boolean | null, feedBackMarketingActionSuggestion: boolean | null) => {
        try {
            await api.moments.justification.updateFeedback(uuid, brandProfileId, userId, momentId, feedBackRelevanceSummary, feedBackActionableInsight, feedBackMarketingActionSuggestion);

        } catch (err) {
            logError(err);
        }
    };


    useEffect(() => {

        if (isInitialMount.current) {
            setPositiveFeedBackRelevanceSummary(feedbackRelevanceSummary === true ? true : null);
            setNegativeFeedBackRelevanceSummary(feedbackRelevanceSummary === false ? true : null);
            setPositiveFeedBackActionableInsight(feedbackActionableInsight === true ? true : null);
            setNegativeFeedBackActionableInsight(feedbackActionableInsight === false ? true : null);
            setPositiveFeedBackMarketingActionSuggestion(feedbackMarketingActionSuggestion === true ? true : null);
            setNegativeFeedBackMarketingActionSuggestion(feedbackMarketingActionSuggestion === false ? true : null);
            isInitialMount.current = false;
            return;
        }

        const feedBackRelevanceSummary = _formatResponse(positiveFeedBackRelevanceSummary, negativeFeedBackRelevanceSummary);
        const feedBackActionableInsight = _formatResponse(positiveFeedBackActionableInsight, negativeFeedBackActionableInsight);
        const feedBackMarketingActionSuggestion = _formatResponse(positiveFeedBackMarketingActionSuggestion, negativeFeedBackMarketingActionSuggestion);

        updateMomentFeedback(feedBackRelevanceSummary, feedBackActionableInsight, feedBackMarketingActionSuggestion)

    }, [updateFeedback])



    const copyTextToClipBoard = async (text: string) => {
        try {
            await navigator.clipboard.writeText(text);
            toast.success('Text copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    return (
        <div className=' bg-[#F6FAFE] px-6 py-4 mx-6 mb-8' data-testid="justification-card" key={momentId}>
            <div className=' ' data-testid="header">
                <h3 className='font-semibold text-lg mb-2'>{momentClusterName}</h3>
                <div className='justify-between flex w-full'>
                    <div className='flex-row flex items-center w-1/2'>
                        <h3 className='font-semibold text-base mr-2 text-[#333D47]'>Strategic Insights</h3>
                        <p className='font-normal text-[10px] text-[#6B7280] underline'>AI Generated</p>
                    </div>
                    <div className='flex-row flex items-center justify-end w-1/2'>
                        <h3 className='font-normal text-sm text-[#333D47] opacity-75 mr-2'>Overall Relevance:</h3>
                        <RelevanceClassificationBadge relevanceClassification={momentRelevance} />
                    </div>
                </div>
            </div>
            <div className='' data-testid="body">
                <div className='bg-white w-full border border-[#E5E7EB] rounded-lg p-6 mt-3' data-testid="relevance_summary">
                    <h3 className='font-medium text-base mr-2 mb-4 text-[#111827]' data-testid="relevance_summary_title" >How this moment is relevant to your brand?</h3>
                    {
                        relevance_summary !== ""
                            ? <h3 className='text-gray-500 font-normal text-sm' data-testid="relevance_summary_body">{relevance_summary}</h3>
                            : loadingError
                                ? <h3 className='text-gray-500 font-normal text-sm'>{errorMessage}</h3>
                                : <div className='bg-gray-300 w-full h-20 shimmer rounded-md '></div>
                    }

                    <div className='flex-row flex mt-4'>
                        <button data-testid="relevance_summary_copy_to_clipboard"
                            onClick={() => copyTextToClipBoard(relevance_summary)}
                        >
                            <img src={duplicateIcon} className='w-6 h-6 mr-4' />
                        </button>
                        <ThumsActions
                            setNegativeFeedBack={setNegativeFeedBackRelevanceSummary}
                            negativeFeedBack={negativeFeedBackRelevanceSummary}
                            positiveFeedBack={positiveFeedBackRelevanceSummary}
                            updateUserFeedback={fireUserUpdate}
                            setPositiveFeedBack={setPositiveFeedBackRelevanceSummary} 
                            disable={ relevance_summary == "" }/>
                    </div>
                </div>
                <div className='bg-white w-full border border-[#E5E7EB] rounded-lg p-6 mt-3' data-testid="actionable_insight">
                    <h3 className='font-medium text-base mr-2 mb-4 text-[#111827]' data-testid="actionable_insight_title">What are the key insights of this moment?</h3>
                    {
                        actionable_insight !== ""
                            ? <h3 className='text-gray-500 font-normal text-sm' data-testid="actionable_insight_body">{actionable_insight}</h3>
                            : loadingError
                                ? <h3 className='text-gray-500 font-normal text-sm'>{errorMessage}</h3>
                                : <div className='bg-gray-300 w-full h-20 shimmer rounded-md '></div>
                    }

                    <div className='flex-row flex mt-4'>
                        <button data-testid="actionable_insight_title_copy_to_clipboard"
                            onClick={() => copyTextToClipBoard(actionable_insight)}
                        >
                            <img src={duplicateIcon} className='w-6 h-6 mr-4' />
                        </button>
                        <ThumsActions
                            setNegativeFeedBack={setNegativeFeedBackActionableInsight}
                            negativeFeedBack={negativeFeedBackActionableInsight}
                            positiveFeedBack={positiveFeedBackActionableInsight}
                            updateUserFeedback={fireUserUpdate}
                            setPositiveFeedBack={setPositiveFeedBackActionableInsight} 
                            disable={ actionable_insight == "" }/>
                    </div>
                </div>
                <div className='bg-white w-full border border-[#E5E7EB] rounded-lg p-6 mt-3' data-testid="marketing_action_suggestion">
                    <h3 className='font-medium text-base mr-2 mb-4 text-[#111827]' data-testid="marketing_action_suggestion_title">What can you do to act on this moment ?</h3>
                    {
                        marketing_action_suggestion !== ""
                            ? <h3 className='text-gray-500 font-normal text-sm' data-testid="marketing_action_suggestion_body">{marketing_action_suggestion}</h3>
                            : loadingError
                                ? <h3 className='text-gray-500 font-normal text-sm'>{errorMessage}</h3>
                                : <div className='bg-gray-300 w-full h-20 shimmer rounded-md '></div>
                    }

                    <div className='flex-row flex mt-4'>
                        <button data-testid="marketing_action_suggestion_copy_to_clipboard"
                            onClick={() => copyTextToClipBoard(marketing_action_suggestion)}
                        >
                            <img src={duplicateIcon} className='w-6 h-6 mr-4' />
                        </button>
                        <ThumsActions
                            setNegativeFeedBack={setNegativeFeedBackMarketingActionSuggestion}
                            negativeFeedBack={negativeFeedBackMarketingActionSuggestion}
                            positiveFeedBack={positiveFeedBackMarketingActionSuggestion}
                            updateUserFeedback={fireUserUpdate}
                            setPositiveFeedBack={setPositiveFeedBackMarketingActionSuggestion} 
                            disable={ marketing_action_suggestion == "" }/>
                    </div>
                </div>
            </div>
        </div>
    )
}
