import { listBuilderAxios } from "@/axiosInstances"
import { INarrative, narrativeSchema, narrativesSchema } from "@/views/Trends/types"

export const narratives = {
    get: async (search?: string, signal?: AbortSignal): Promise<INarrative[]> => {
      let url = `/trend-analysis/narratives${search ? `?prompt=${search}`: ''}`

      const result = listBuilderAxios.get(url, { signal })
      return narrativesSchema.parse((await result).data)
    },
    getById: async (narrativeId: number): Promise<INarrative> => {
      const url = `/trend-analysis/narratives/${narrativeId}`
      const result = await listBuilderAxios.get(url)
      return narrativeSchema.parse(result.data)
    }
}
