import React, { useRef } from "react";
import { ArrowUpTrayIcon } from "@heroicons/react/24/solid";
import { Icon } from "rsuite";

interface IProps {
    /** Function to handle the file upload */
    onFileUpload: (file: File) => void;
    /** Allowed file types */
    allowedFileTypes?: string[];
    /** Title of the file upload */
    title?: string;
    /** Description of the file upload */
    description?: string;
    /** Is uploading */
    isUploading: boolean;
}
/**
 * Component to upload a file.
 * @param {Function} onFileUpload Function to handle the file upload.
 * @param {string[]} allowedFileTypes Allowed file types.
 * @param {string} title Title of the file upload.
 * @param {string} description Description of the file upload.
 * @param {boolean} isUploading Is uploading.
 */

export const UploadFile = ({ onFileUpload, allowedFileTypes, title,  description, isUploading }: IProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            onFileUpload(file);
        }
    };

    return (
        <div>
            <article data-testid="file-upload-modal" aria-label="File Upload Modal" className="relative h-full flex flex-col">
                <section data-testid="file-upload-modal-content" className="h-full overflow-auto w-full h-full flex flex-col">
                    <header data-testid="file-upload-modal-header" className="border-dashed border-2 border-gray-300 rounded-md py-10 flex flex-col justify-center items-center bg-gray-50">
                        <button data-testid="file-upload-modal-button" onClick={() => fileInputRef.current?.click()} className="rounded-xl bg-sightly-purple">
                            {isUploading ? (
                                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                    <Icon
                                        icon="spinner"
                                        spin
                                    />{' '}
                                    Uploading...
                                </p>
                            ) : (
                                <ArrowUpTrayIcon className="w-8 h-8 m-4 text-white" />
                            )}
                        </button>
                        <input data-testid="file-upload-modal-input" id="hidden-input" type="file" accept={allowedFileTypes?.join(",")} multiple className="hidden" ref={fileInputRef} onChange={handleFileChange} />
                        {title && <p className="mt-4 font-bold text-gray-900 flex flex-wrap justify-center">{title}</p>}                     
                        {description && <p className="font-normal text-gray-400 flex flex-wrap justify-center">{description}</p>}
                    </header>
                </section>
            </article>
        </div>
    );
};

