import React from 'react';

interface TimepickerProps {
  handleTimeChange: (time: Date) => void;
  value: Date;
}

const TimepickerComponent: React.FC<TimepickerProps> = ({ handleTimeChange, value }) => {
  return (
    <form className="max-w-[8rem] mx-auto">
      <div className="relative">
        <input
          type="time"
          id="time"
          className="h-9 w-32 border leading-none border-gray-300 rounded-md text-sightlyPurple text-sm block p-2.5 "
          min="00:00"
          max="12:00"
          required
          onChange={(e) => handleTimeChange(new Date(`1970-01-01T${e.target.value}`))}
          value={value.getHours().toString().padStart(2, '0') + ':' + value.getMinutes().toString().padStart(2, '0')}
          data-testid="time-picker"
        />
      </div>
    </form>
  );
};

export default TimepickerComponent;
