import React, { useEffect, useRef, useState } from 'react'
import Input from 'rsuite/lib/Input';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

interface IProps {
    /** Title of the item */
    title: string
    /** Function to handle the change of the field */
    onChange: Function
}

/**
 * Component to display the segment estimate description.
 * @prop {string} title Title of the item.
 * @prop {Function} onChange Function to handle the change of the field.
 */

export const SegmentEstimateDescription = ({ title, onChange }: IProps) => {

    const [field, setField] = useState(title);
    const [activeEdit, setActiveEdit] = useState(false);
    const inputRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        setField(title)
    }, [title]);

    useEffect(() => {
        if (activeEdit && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [activeEdit]);


    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            setActiveEdit(false);
        }
    };

    const handleOnFieldChange = (value: string) => {
        setField(value)
        onChange('description', value)
    }

    return (
        <div className='flex w-full mt-6' data-testid="create-segment-form-segment-description">
            {
                activeEdit ?
                    <textarea
                        ref={inputRef}
                        rows={4}
                        className='custom-input-editable-segment-description w-full'
                        id="segment-description"
                        defaultValue={field}
                        onChange={(e) => handleOnFieldChange(e.target.value)}
                        placeholder="Description"
                        onBlur={() => setActiveEdit(false)}
                        onKeyDown={handleKeyDown}
                        style={{ borderColor: 'white'}}
                    />
                    : <div className='mr-2 text-xl font-medium break-words max-w-[20vw] h-[140px] overflow-hidden text-ellipsis'>
                        {field}
                    </div>
            }
            {
                !activeEdit &&
                <div className='min-w-[24px] mr-2 inline-block align-top cursor-pointer'>
                    <PencilSquareIcon data-testid="edit-segment-description-icon" onClick={() => setActiveEdit(true)} />

                </div>
            }
        </div>
    )
}
