import React, { useEffect, useState } from 'react'
import SlideOver from '@/components/SlideOver'
import { useSearch } from '@tanstack/react-location'
import { MyLocationGenerics } from '@/classes/utils'
import { segmentsController } from '../Segments/controller/SegmentController'
import SightlyButton from '@/components/Sightly/SightlyButton'
import { smartlistController } from './controller/SmartlistController'
import { SegmentId } from '../Segments/types/SegmentsTypes'
import toast from 'react-hot-toast'
import { GetSegmentsResponse, Segment } from '../Segments/schemas/SegmentSchema'
import { SmartList, UpdateSmartlist } from './schemas/SmartlistSchema'


interface ISmartListSegmentSlideoverProps {
  showSlideOver: boolean
  setShowSlideOver: Function
}

const SmartListSegmentSlideover: React.FC<ISmartListSegmentSlideoverProps> = ({ showSlideOver, setShowSlideOver }) => {
  
  if (!showSlideOver) {
    return null
  }

  const { accountId, smartListId } = useSearch<MyLocationGenerics>();

  const [selectedRows, setSelectedRows] = useState<boolean[]>([])
  const [segmentsData, setSegmentsData] = useState<GetSegmentsResponse | undefined>(undefined);
  const [smartListData, setSmartListData] = useState<SmartList | undefined>(undefined);

  const ACCOUNT_ID = accountId;
  const SEGMENTS_PAGE_NUMBER = 1;
  const SEGMENTS_PAGE_SIZE = 100;
  const SEGMENTS_ORDER_BY = 'name';
  const SEGMENTS_SORT_BY = 'asc';

  const handleCheckboxChange = (index: number) => {
    setSelectedRows((prev) => {
      const updatedRows = [...prev];
      updatedRows[index] = !updatedRows[index];
      return updatedRows;
    });
  };

type GetSegmentsQueryKey = [string, number | undefined, number | undefined, number | undefined, string | undefined, string | undefined];

    const getSegmentsData = async (accountId: number | undefined, page: number | undefined, pageSize: number | undefined, orderBy: string | undefined, sortBy: string | undefined) => {
        if (smartListId === undefined) {
            throw new Error('smartListId is undefined');
        }    
        const queryKey: GetSegmentsQueryKey = ['segments', accountId, page, pageSize, orderBy, sortBy];
        const result: GetSegmentsResponse = await segmentsController.getSegments({ queryKey });
        return result;
    };
 
    useEffect(() => {
      const fetchSegments = async () => {
          const data = await getSegmentsData(
            ACCOUNT_ID,
            SEGMENTS_PAGE_NUMBER,
            SEGMENTS_PAGE_SIZE,
            SEGMENTS_ORDER_BY,
            SEGMENTS_SORT_BY
          );
          setSegmentsData(data);
          
          // Initialize checked boxes based on smartListData
          if (smartListData?.segments) {
              const smartListSegmentIds = new Set(smartListData.segments.map((segment: SegmentId) => segment.id));
              const initialSelectedRows = data.results.map((segment: Segment) => smartListSegmentIds.has(segment.id));
              setSelectedRows(initialSelectedRows);
          }
      };
      
      fetchSegments();
  }, [smartListData]);

  const handleUpdateSmartlist = async () => {
    if (smartListId === undefined) {
      throw new Error('smartListId is undefined');
    }  
    if (!segmentsData?.results) {
      throw new Error('segments data is undefined');
    }

    const updateData: UpdateSmartlist = {
      segments: segmentsData.results
        .filter((_, index) => selectedRows[index])
        .map((segment) => ({ id: segment.id }))
    };
    
    const result = await smartlistController.updateSmartList(smartListId, updateData);

    setTimeout(() => {
      toast.success('Saved');
    }, 500);

    setShowSlideOver(false);
  }

    const getSmartListData = async (smartListId: number | undefined) => {
        if (smartListId === undefined) {
            throw new Error('smartListId is undefined');
        }    
        const result = await smartlistController.getSmartListById(smartListId);
        return result;
    };

    useEffect(() => {
        const fetchSmartListData = async () => {
            const result = await getSmartListData(smartListId);
            setSmartListData(result);
        };
        
        fetchSmartListData();
    }, [smartListId]);


  return (
    <div className="relative h-full">
      <SlideOver
        show={showSlideOver}
        onHide={() => setShowSlideOver(false)}
        headerTitle="Select Segments"
        headerForegroundColor="text-black"
        headerBackgroundColor="bg-white"
        showToggleFullScreenButton={false}
        maxOffset="40%"
      >
        <div className="flex flex-col h-full">
            <div className="table-container flex-grow overflow-auto max-h-[calc(100vh-200px)]">
                <table className="w-full">
                    <thead className="sticky top-0 bg-white">
                    <tr className="bg-[#F3F4F6] h-[50px]">
                        <th className="w-20">
                        </th>
                        <th className='text-left'>Segment Name</th>
                        <th className='text-left'>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    {segmentsData?.results.map((item: Segment, index: number) => (
                        <tr key={index} className="h-[70px] border-b-2 border-[#F3F4F6]">                        
                          <td className="align-middle text-center">
                              <input
                              type="checkbox"
                              checked={!!selectedRows[index]}
                              onChange={() => handleCheckboxChange(index)}
                              className="rounded border-gray-300 text-[#00aeef] focus:ring-[#00aeef]"
                              />
                          </td>
                          <td>{item.name}</td>
                          <td>{item.description}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <div className="w-full h-[70px] bg-gray-100 mt-auto">
                <div className="float-right mr-4 mt-4">
                  <SightlyButton
                    text='Apply'
                    type='purple'
                    handleClick={handleUpdateSmartlist}                  
                  />
                </div>

                <p
                    data-testid="smartlist-review-cancel"
                    className="float-right mr-4 mt-6 text-purple-700 text-md cursor-pointer"
                    onClick={() => (setShowSlideOver(false))}
                >
                    Cancel
                </p>                            
            </div>
        </div>
      </SlideOver>
    </div>
  )
}

export default SmartListSegmentSlideover
