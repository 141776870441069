import { useIsMutating } from '@tanstack/react-query'
import React from 'react'
import SelectPicker from 'rsuite/lib/SelectPicker'
import { MomentScenarioType } from '../../types'

export const ScenarioSelect = ({
    index,
    scenarios,
}: {
    index: number
    scenarios: MomentScenarioType[]
}) => {
    const isMutating = useIsMutating()

    return (
        <div
            style={{
                height: 100,
                margin: '10px 0px',
                marginLeft: 10,
                position: 'relative',
                display: 'flex',
                borderBottom: '1px solid rgb(212, 217, 217, 0.5)'
            }}
        >
            <div
                style={{
                    fontWeight: 700,
                    width: 63,
                    fontSize: 16,
                    lineHeight: '19.36px',
                    color: '#5F7284',
                    alignSelf: 'center'
                }}
            >
                Q{index}
            </div>

            <div
                style={{
                    fontWeight: 600,
                    width: 600,
                    fontSize: 16,
                    lineHeight: '24px',
                    color: '#333D47',
                    alignSelf: 'center'
                }}
            >
                {scenarios.length > 0 && (
                    <SelectPicker
                        data-testid="moment-scenario-select"
                        preventOverflow
                        labelKey={'scenarioName'}
                        valueKey={'scenarioId'}
                        placeholder={'Search for a relevant scenario question or select none apply'}
                        data={scenarios}
                        onSelect={(val: MomentScenarioType['scenarioId']) => (val)}
                        cleanable={false}
                        width={600}
                        searchable={true}
                        disabled={isMutating > 0}
                    />
                )}
            </div>
        </div>
    )
}
