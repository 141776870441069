import React, { useState, useEffect } from "react";
import { multiSelectValues } from "@/views/ActivationReports/ActivationReportsNewReport";
import { ActivationReportConfiguration } from "@/views/ActivationReports/types/activation-report.types";
import SightlySelect from "@/components/Sightly/SightlyFormElements/SightlySelect";

interface IProps {
  insertionOrders: multiSelectValues[];
  handleOnChange: Function;
  formValues: ActivationReportConfiguration;
}

export default function InsertionOrder({ insertionOrders = [], handleOnChange, formValues }: IProps) {
  const [insertionOrderIds, setInsertionOrderIds] = useState<number[]>([]);

  useEffect(() => {
    const ids = formValues.insertionOrders.map(io => io.id);
    setInsertionOrderIds(ids);
  }, [formValues.insertionOrders]);

  return (
    <div data-testid="div-report-generator-select-insertion-orders">
      <h5 className="mt-3 mb-2 text-base">Insertion Orders</h5>
      <div className="mb-4 custom-tag-picker-color custom-tag-picker-color-checked max-w-[30vw]">
        <SightlySelect
          id="new-report-insertion-order-select"
          options={insertionOrders}
          value={insertionOrderIds}
          onChange={(values) => {
            const selectedInsertionOrders = insertionOrders
              .filter((io) => (values as number[])?.includes(io.value))
              .map((io) => ({ name: io.label, id: io.value }));
            handleOnChange("insertionOrders", selectedInsertionOrders);
          }}
          data-testid="new-report-insertion-order-select"
          labelKey="label"
          valueKey="value"
          multiple={true}
          placeholder="Select Insertion Orders"
          searchable={true}
        />
      </div>
    </div>
  );
}
