import { z } from "zod";

export const SegmentSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  keywords: z.array(z.string()),
  accountId: z.number(),
  iabCategories: z.array(
    z.object({
      id: z.string(), 
    })
  ).optional(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
});

export const SegmentPaginatedResponseSchema = z.object({
  count: z.number(),
  totalCount: z.number(),
  currentPage: z.number(),
  nextPage: z.number().nullable(),
  lastPage: z.number(),
  results: z.array(SegmentSchema),
});

const IABCategorySchema: z.ZodType<any> = z.lazy(() =>
  z.object({
    id: z.string(),
    name: z.string(), 
    parentId: z.string().optional(),
    tier: z.number(),
    disregardLeafNode: z.boolean(),
    children: z.array(IABCategorySchema).optional(), 
  })
);

export const IABCategoriesResponseSchema = z.array(
  IABCategorySchema
);


export type Segment = z.infer<typeof SegmentSchema>;
export type GetSegmentsResponse = z.infer<typeof SegmentPaginatedResponseSchema>;
export type IABCategory = z.infer<typeof IABCategorySchema>;
