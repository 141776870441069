import React, { useEffect, useRef, useState } from 'react'
import TopicTrendTimelineChart from '@/views/Trends/components/TopicTrendTimelineChart'
import { Placeholder, SelectPicker } from 'rsuite'
import { IMetricsPerDay } from '@/views/Trends/types'
import { LoadingPage } from '@/components/LoadingPage'
import { InfoPage } from '@/components/InfoPage'

interface IDetailTimeChartProps {
  lineChartData?: {
    socialPosts: IMetricsPerDay[],
    articles: IMetricsPerDay[]
  },
}

const TimelineChartWrapper: React.FC<IDetailTimeChartProps> = ({ lineChartData }) => {
  // Data
  const [filteredMetrics, setFilteredMetrics] = useState<IMetricsPerDay[]>([])
  const [loadingFilteredMetrics, setLoadingFilteredMetrics] = useState(true)

  // selectedSource filter
  const sourceOptions = ['Social Posts', 'Articles']
  const [selectedSource, setSelectedSource] = React.useState(sourceOptions[1])

  const tooltipOptionsMap: Record<string, string> = {
    'Social Posts': 'Posts',
    'Articles': 'Articles'
  };
  const selectedTooltip = tooltipOptionsMap[selectedSource];

  // Time filter
  const timeRangeOptions = [
    { id: 1, label: 'Last 24 hrs' },
    { id: 2, label: 'Last 7 days' },
    { id: 3, label: 'Last 30 days' },
    { id: 4, label: 'Last 60 days' },
    { id: 5, label: 'Custom Range' }
  ]
  const [selectedTimeRange, setSelectedTimeRange] = useState<number>(4)

  // Handle filters changes
  useEffect(() => {
    if (!lineChartData) return

    let metrics = []
    switch (selectedSource) {
      case sourceOptions[0]: // Social Posts
        metrics = lineChartData.socialPosts || []
        break;
      case sourceOptions[1]: // Articles
        metrics = lineChartData.articles || []
        break;
      default: // Use any source with data
        metrics = lineChartData.socialPosts || lineChartData.articles || []
        break;
    }

    const now = new Date()
    const startingRange = new Date(now)

    switch (selectedTimeRange) {
      case 1:
        startingRange.setDate(startingRange.getDate() - 1)
        break
      case 2:
        startingRange.setDate(startingRange.getDate() - 7)
        break
      case 3:
        startingRange.setDate(startingRange.getDate() - 30)
        break
      case 4:
        startingRange.setDate(startingRange.getDate() - 60)
        break
      default:
        break
    }

    metrics = metrics.filter((metric) => {
      const timestamp = new Date(metric.date)
      return timestamp >= startingRange
    })

    setFilteredMetrics(metrics)
    setLoadingFilteredMetrics(false)
  }, [lineChartData, selectedSource, selectedTimeRange])

  return (
    <div className='h-full'>
      <div className='flex flex-col h-full gap-[4vh] max-h-[315px]'>
        <div className='flex flex-row gap-2 justify-between'>
          <SelectPicker
            data={sourceOptions.map(option => ({ label: option, value: option }))}
            value={selectedSource}
            style={{ width: 160 }}
            cleanable={false}
            searchable={false}
            disabled={loadingFilteredMetrics}
            onChange={(val) => setSelectedSource(val)}
            data-testid="source-select"
          />
          <SelectPicker
            defaultValue={selectedTimeRange}
            data-testid="time-range-select"
            preventOverflow
            labelKey="label"
            valueKey="id"
            value={selectedTimeRange}
            data={timeRangeOptions}
            onSelect={(val) => setSelectedTimeRange(val)}
            width={600}
            cleanable={false}
            searchable={false}
            disabled={loadingFilteredMetrics}
          />
        </div>
        {loadingFilteredMetrics ?
          <div className='flex flex-col'>
            <LoadingPage message='Loading chart' />
            <Placeholder.Graph height={300} />
          </div>
          :
          <div className='flex grow items-center'>
            <div className='flex h-full w-full'>
              {filteredMetrics.length === 0 ?
                <InfoPage message='No data for your selected filters' />
                : <TopicTrendTimelineChart lineChartData={filteredMetrics} selectedSource={selectedTooltip}/>
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default TimelineChartWrapper
