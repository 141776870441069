import { useMatch, useNavigate } from '@tanstack/react-location';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { MouseEventHandler } from 'react';
import toast from 'react-hot-toast';

import { api } from '@/api/api';
import defaultLoginImage from '@/assets/img/introSideImage.png';
import svgLogo from '@/assets/img/sightly-logo.svg';
import tiktokLoginImage from '@/assets/img/tiktoklogin.png';
import { SightlyModernButton } from '@/components/Sightly/SightlyModern/SightlyModernButton';
import { WarningModalTailwind } from '@/components/WarningModalTailwind';
import { IntroLayout } from '@/layouts/IntroLayout';
import { routes } from '@/routes';
import { isIframe } from '@/validations';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';

export const CheckYourEmail = () => {
    const navigate = useNavigate()
    const {
        params: { email }
    } = useMatch()
    const [showGenericErrorDialog, setShowGenericErrorDialog] = React.useState(false)
    const openLoginPage = () => {
        navigate({ to: routes.login.path })
    }
    const isInTikTokIframe = isIframe();

    const mutation = useMutation(api.user.resetPassword, {
        onSuccess: () => {
            toast.success('Reset password email sent. Check Your email.')
        },
        onError: (err: AxiosError<any>) => {
            if (err.response && 'Error' in err.response.data && err.response.data.Error === 'No user found with that email address.') {
                toast.error(err.response.data.Error)
                return
            }

            setShowGenericErrorDialog(true)
        }
    });

    const onSubmit: MouseEventHandler = (event) => {
        event.preventDefault()
        mutation.mutate({ email: email })
    };

    const loginImage = isInTikTokIframe ? tiktokLoginImage : defaultLoginImage;

    return (
        <IntroLayout image={loginImage}>
            <img className='h-8' src={svgLogo} alt='logo' />
            <div className='flex flex-col gap-[4vh] items-center pt-[3vh] text-center'>
                <div>
                    <h1 className='text-3xl leading-9 font-extrabold text-gray-900'>Check your email</h1>
                    <p className='text-sm leading-5 font-normal text-gray-600 '>
                        We've sent a password reset link to {email}
                    </p>
                </div>

                <p className='text-sm leading-5 font-normal text-gray-600 '>
                    Didn’t receive the email? <button className='text-sightlyBlue' onClick={onSubmit}>Click to resend</button>
                </p>
                <SightlyModernButton
                    className='w-full'
                    id='loginButton'
                    type='secondaryblack'
                    preventDefaultOnClick
                    icon={<ArrowLeftIcon className='h-[2.5vh] w-[2.5vh]' />}
                    handleClick={openLoginPage}
                    text='Back to Login'
                />

                <WarningModalTailwind
                    open={showGenericErrorDialog}
                    setOpen={setShowGenericErrorDialog}
                    title='Something went wrong'
                    subtitle='Please try again, or contact support at support@sightly.com'
                />
            </div>
        </IntroLayout>
    );
};
