import BoardTabs from '@/views/Discover/Boards/BoardTabs';
import React, { useEffect, useState } from 'react';
import { FingerPrintIcon } from '@heroicons/react/24/outline';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { ChannelCard } from './components/ChannelCard';
import { MockChannels } from '@/tests/views/Engage/SmartList/components/MockChannels';
import { MyLocationGenerics } from '@/classes/utils';
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles';
import { smartlistController } from './controller/SmartlistController';
import { ReactComponent as SegmentIcon } from '@/assets/img/SegmentIcon.svg';
import SmartListSegmentSlideover from './SmartListSegmentSlideover';
import { SmartList } from './schemas/SmartlistSchema';

const ReviewSmartlist = () => {

    const tabs = [
        { label: 'YouTube Channels', id: 'youtube_channels', isVisible: true },
        { label: 'YouTube Videos', id: 'youtube_videos', isVisible: true },
    ];

    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0)
    const [showSegmentSlideover, setShowSegmentSlideover] = React.useState<boolean>(false)
    const [smartListData, setSmartListData] = useState<SmartList | undefined>(undefined);

    const navigate = useNavigate();

    const { accountId, brandProfileId, smartListId } = useSearch<MyLocationGenerics>();

    const { data: brandProfiles, currentBrandProfile } = useBrandProfiles({
            submittedOnly: true
        })

    const brandProfileName = currentBrandProfile?.brandProfileName

    const getSmartListData = async (smartListId: number | undefined) => {
        if (smartListId === undefined) {
            throw new Error('smartListId is undefined');
        }    
        const result = await smartlistController.getSmartListById(smartListId);
        return result;
    };

    useEffect(() => {
        const fetchSmartListData = async () => {
            const result = await getSmartListData(smartListId);
            setSmartListData(result);
        };
        
        fetchSmartListData();
    }, [smartListId]);


    return (
        <div data-testid="smartlist-segment-slideover" className="flex flex-col bg-gray-100 min-h-screen">
            {/* Slideover */}
            <SmartListSegmentSlideover
                showSlideOver={showSegmentSlideover}
                setShowSlideOver={setShowSegmentSlideover}
            />

            {/* Header */}
            <div
                className="bg-white w-full flex flex-col justify-between px-4 pt-2 relative"
                style={{ height: '120px' }}
            >
                <div className="sightlyPanelHeader text-left">
                    <p className="font-bold pt-2">{smartListData?.name || ''}</p>
                </div>
    
                <div className="flex justify-between items-end">
                    <div className="flex space-x-4 pb-2">
                        <div className="flex flex-col">
                            <p className="text-xs text-gray-500">Brand Profile</p>
                            <div className="flex items-center space-x-2 pt-1 pb-2">
                                <FingerPrintIcon className="h-6 w-6 text-purple-500" />
                                <p className="text-sm font-bold">{brandProfileName}</p>
                            </div>
                        </div>
                        <div className="flex flex-col">                            
                            <p className="text-xs text-gray-500">Objective</p>
                                <div className="flex items-center space-x-2 pt-1">
                                    <FingerPrintIcon className="h-6 w-6 text-purple-500" />
                                    <p className="text-sm font-bold">{smartListData?.objective || ''}</p>
                                </div>
                        </div>
                    </div>

                    {/* Cancel button */}
                    <div>
                        <p
                            data-testid="smartlist-review-cancel"
                            className="top-2 right-40 top-10 text-purple-700 text-md cursor-pointer absolute"
                            onClick= {() => navigate({to:'/app/smartlist'})}
                        >
                            Cancel
                        </p>
                    </div>
                </div>
            </div>

            {/* Gap */}
            <div className="h-4" />

            {/* Segments, Moments Etc. Buttons */}
            <div                
                className="bg-white w-full flex flex-col justify-center px-6 relative"
                style={{ height: '120px' }}
            >
                {/* Segments Button */}
                <div
                    data-testid="smartlist-review-segment-button"
                    className="inline-block w-[80px] h-[80px] cursor-pointer relative bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-shadow"
                    onClick={() => {setShowSegmentSlideover(true)}}
                >
                    <SegmentIcon className="absolute top-2 left-1/2 transform -translate-x-1/2 h-10 w-10 text-xs text-gray-800"></SegmentIcon>
                    <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-xs text-gray-800">
                        Segment
                    </p>
                </div>
            </div>
    
            {/* Gap */}
            <div className="h-4" />
    
            {/* Tabs */}
            <div              
              className="bg-white rounded-lg p-2 w-[98%] mx-auto relative flex-grow"
              style={{ minHeight: '100vh' }}
            >
                <div data-testid="smartlist-review-tabs" className="border-b border-gray-300 pb-2">
                    <BoardTabs
                        selectedTabIndex={selectedTabIndex}
                        setSelectedTabIndex={setSelectedTabIndex}
                        tabs={tabs}
                    />
                </div>

                <div data-testid="smartlist-review-channel-tab" className="px-4 main pt-6">
                    {selectedTabIndex === tabs.findIndex(tab => tab.id === 'youtube_channels' && tab.isVisible) && (
                        <div className="flex flex-wrap gap-4">
                            {MockChannels.map(channel => (
                                <ChannelCard key={channel.id} channel={channel} />
                            ))}
                        </div>
                    )}
                </div>
                
                {selectedTabIndex === tabs.findIndex(tab => tab.id === 'youtube_videos' && tab.isVisible) && (
                    <div data-testid="smartlist-review-video-tab" className='text-3xl'>Videos</div>
                )}
            </div>
        </div>           
    );
};

export default ReviewSmartlist;
