import React from 'react'
import { ScenarioSelect } from './ScenarioSelect'
import MomentScenarioComponent from '../../MomentModal/MomentScenarioComponent'
import { NoneApplyId } from '@/services/trends_ts'
import { ClusterType, MomentScenarioType } from '../../types'
import { LoadingPage } from '@/components/LoadingPage'

interface MomentScenariosProps {
    isLoading: boolean
    scenarios: MomentScenarioType[]
    adding: boolean
    setAdding: any
    scenariosOptions: MomentScenarioType[]
}

export const ScenariosSection = ({
    isLoading,
    scenarios,
    scenariosOptions,
    adding,
}: MomentScenariosProps) => {
    const _visibleScenarioOptions = React.useMemo(() => {
        if (!scenarios || !scenariosOptions) return []
        const scenarioIds = scenarios.map((_s: MomentScenarioType) => {
            return _s.scenarioId
        })

        let final = scenariosOptions.filter((_scen: MomentScenarioType) => {
            return !scenarioIds.includes(_scen.scenarioId)
        })

        if (scenarioIds.length > 0) {
            //remove none apply option
            final = final.filter((t: MomentScenarioType) => t.scenarioId !== NoneApplyId)
        }

        return final
    }, [scenarios, scenariosOptions])


    return (
        <div className="p-4 overflow-auto">
            {isLoading ? (
                <div style={{ height: 150 }}>
                    <LoadingPage message="Loading" />
                </div>
            ) : (
                <div>
                    {scenarios.length > 0 &&
                        scenarios.slice(0, 5).map((_scenario, index) => {
                            return (
                                <MomentScenarioComponent
                                    key={_scenario.scenarioId}
                                    scenario={_scenario}
                                    index={index + 1}
                                />
                            )
                        })}
                    {adding && (
                        <ScenarioSelect
                            scenarios={_visibleScenarioOptions}
                            index={scenarios.length + 1}
                        />
                    )}
                </div>
            )}
        </div>
    )
}
