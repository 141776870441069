import React from 'react'
import { DaysOfWeekEnumType } from '../reportGenerator-types'
const DAYS_OF_WEEK_OPTIONS = [
    { label: 'M', value: 'monday' },
    { label: 'T', value: 'tuesday' },
    { label: 'W', value: 'wednesday' },
    { label: 'T', value: 'thursday' },
    { label: 'F', value: 'friday' },
    { label: 'S', value: 'saturday' },
    { label: 'S', value: 'sunday' }
]

function ScheduleOnDay(props: { 
  daysOfWeek: DaysOfWeekEnumType[],
  setDaysOfWeek: React.Dispatch<React.SetStateAction<DaysOfWeekEnumType[]>>,
}) {
  const { daysOfWeek, setDaysOfWeek } = props

  return (
    <div
        className="sm:px-1 text-grey-900 w-full flex flex-row items-center"
        data-testid="on-day"
    >
        <div className="mb-2 mr-4 font-medium" id='pendo-act-mgr-assignees-filter'>On day(s)</div>

        {DAYS_OF_WEEK_OPTIONS.map((option) => (
            <button 
                key={option.value} 
                className={`w-10 h-10 mr-4 rounded-full font-semibold ${daysOfWeek.includes(option.value as DaysOfWeekEnumType) ? 'bg-purple-600 hover:bg-purple-700 text-white' : 'bg-gray-200 hover:bg-gray-300'}`} 
                onClick={() => {
                    if (daysOfWeek.includes(option.value as DaysOfWeekEnumType)) {
                        setDaysOfWeek(daysOfWeek.filter((day) => day !== option.value as DaysOfWeekEnumType))
                    } else {
                        setDaysOfWeek([...daysOfWeek, option.value as DaysOfWeekEnumType])
                    }
                }}
                data-testid={`on-day-${option.value}-button`}
            >
                {option.label}
            </button>
        ))}
    </div>
  )
}

export default ScheduleOnDay
