import { listBuilderAxios, userAccountAxios } from '@/axiosInstances';
import { BrandProfile, TargetviewIo } from '@/classes/brandProfile';
import { NoneApplyId } from '@/services/trends_ts';
import {
    ClusterActivationType,
    MomentActionsEnum,
    MomentArticlesSchema,
    MomentKeywordsSchema,
    MomentScenariosSchema,
    MomentScenarioType,
    MomentVideosSchema,
    TaxonomiesSchema,
    MomentActionJustificationsSchema,
    MomentsJustificationResponseSchema,
    HistoryResponseSchema
} from '@/views/Discover/Moments/v2/types';
import {NLBoardRequest, SocialConversationRequest} from '@/views/Discover/Boards/types';
import { TargetListType } from '@/views/Discover/Moments/v2/TargetMoment/index';

var fileDownload = require('js-file-download');

interface ClusterSearch {
    brandProfileId: number | undefined
    clusterId: number | undefined
}

interface CreateBlockListProps {
    brandProfileId: BrandProfile['brandProfileId']
    clusters: ClusterActivationType[]
    ioIds: TargetviewIo['ioId'][]
}

interface CreateBlockListElasticsearchProps {
    brandProfileId: BrandProfile['brandProfileId']
    clusters: ClusterActivationType[]
    blockListType: string
}

interface DownloadProps {
    brandProfileId: number
    clusterIds: number[]
}

interface DownloadByFilterProps {
    brandProfileId: number
    boardIds: number[]
    startDate?: string
    endDate?: string
    actions: string[]
    sortBy: string
    searchTerm: string
    aylienNews: string[]
    aylienIndustries: string[]
    activationStatus: string[]
    elasticSearchEnabled: boolean
}

interface ShareProps {
    brandProfileId: number
    clusterIds: number[]
    emails: string[]
    keywords: boolean
    placements: boolean
    message: string
}

interface GetScenariosProps {
    clusterId: number | undefined
    brandProfileId: number | undefined
    momentAction?: MomentActionsEnum
}
interface GetScenariosPaginatedProps {
    clusterId: number | undefined
    brandProfileId: number | undefined
    momentAction?: MomentActionsEnum
    page: number
    limit: number
}interface GetScenariosPaginatedProps {
    clusterId: number | undefined
    brandProfileId: number | undefined
    momentAction?: MomentActionsEnum
    page: number
    limit: number
}

export interface RecActionFeedbackProps {
    clusterId: number
    brandProfileId: number
    feedback: string
}

export interface ScenarioFeedbackProps {
    clusterId: number
    feedback: string | null
    clusterHeadline:string,
    scenarioId: number,
    question: string
    brandProfileId: number
}

export interface VideoFeedbackProps {
    videoId: string
    videoRelatedToTrend?: boolean
    languageIsEnglish?: boolean
    dateRelevancyIsInvalid?: boolean
    clusterId: number
    brandProfileId: number
}

export interface KeywordFeedbackProps {
    clusterId: number
    brandProfileId: number
    feedback: string
}

export interface PatchMomentScenariosProps {
    brandProfileId: number
    clusterId: number
    scenarios: MomentScenarioType[] | undefined
}

export interface GetTaxonomiesProps {
    signal: AbortSignal | undefined
    startDate?: string
    endDate?: string
    boardIds: number[]
    actions: string[] | undefined
    searchTerm: string
    brandProfileId: number | undefined
    activationStatus: string[]
}

export interface TargetListProps {
    brandProfileId: number
    clusters: { id: number; personas: string[]; clusterName: string }[]
    ioIds: number[]
    assignees: string[]
    message: string
}

export type TargetListPropsV2 = {
    brandProfileId: number
    targetList: Map<number, TargetListType>
    assignees: string[]
    message: string
}

export type targetListElasticsearchProps = {
    brandProfileId: number
    clusters: {
        clusterId: number
        hasKeywords: boolean
        hasPlacements: boolean
    }[]
    targetListType: string
}

export interface GetMomentPreviewsProps {
    nlBoardRequest: NLBoardRequest
}

export interface UpdateActivationStatusProps {
    brandProfileId: number
    clusterIds: number[]
    activationStatus: 'active' | 'processing' | 'pending' | 'failed' | 'not_activated' | 'awaiting_implementation' | 'implemented'
    activationType: 'block' | 'target'
    activationListType: 'placements' | 'keywords' | 'unknown'
}

export const api = {
    moments: {
        createPlacementsBlockList: async ({ brandProfileId, clusters, ioIds }: CreateBlockListProps) => {
            const url = `/brand-profile/${brandProfileId}/moment-clusters/block-list`
            return await listBuilderAxios.post(url, {
                clusters,
                ioIds,
                blockListType: 'placements'
            })
        },
        createKeywordsBlockList: async ({ brandProfileId, clusters, ioIds }: CreateBlockListProps) => {
            const url = `/brand-profile/${brandProfileId}/moment-clusters/block-list`
            return await listBuilderAxios.post(url, {
                clusters,
                ioIds,
                blockListType: 'keywords'
            })
        },
        createTargetList: async ({ brandProfileId, clusters, ioIds, assignees, message }: TargetListProps) => {
            const url = `/brand-profile/${brandProfileId}/moment-clusters/target-list`
            return await listBuilderAxios.post(url, {
                clusters,
                ioIds,
                assignees,
                message
            })
        },
        createTargetListV2: async ({ brandProfileId, targetList, assignees, message }: TargetListPropsV2) => {
            const url = `/brand-profile/${brandProfileId}/moment-clusters/target-list/v2`
            return await listBuilderAxios.post(url, {
                targetList,
                assignees,
                message
            })
        },
        updateActivationStatusElasticSearch: async ({ 
            brandProfileId, 
            clusterIds, 
            activationStatus,
            activationType,
            activationListType
        }: UpdateActivationStatusProps) => {
            const url = `/v1/boards/moments/activation-status`
            return await userAccountAxios.patch(url, {
                brandProfileId,
                clusterIds,
                activationStatus,
                activationType,
                activationListType
            })
        },
        download: async ({ brandProfileId, clusterIds }: DownloadProps) => {
            const url = `/brand-profile/${brandProfileId}/moment-clusters/download`
            let info = { clusterIds }
            const result = await listBuilderAxios({
                method: 'POST',
                url: url,
                responseType: 'blob',
                data: info
            })
            if (!result) {
                return Promise.reject('Unknown download error')
            }
            fileDownload(result.data, `currentMoments.xlsx`)
        },
        downloadByFilter: async ({
            brandProfileId,
            boardIds,
            startDate,
            endDate,
            actions,
            sortBy,
            searchTerm,
            aylienNews,
            aylienIndustries,
            activationStatus,
            elasticSearchEnabled
        }: DownloadByFilterProps) => {
            if (elasticSearchEnabled) {
                const locale = navigator.language;
                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const url = `/v1/boards/moments/all?brandProfileId=${brandProfileId}`
                let info = {
                    boardIds,
                    startDate,
                    endDate,
                    locale,
                    actions,
                    timeZone,
                    sortBy,
                    searchTerm,
                    aylienNews,
                    aylienIndustries,
                    activationStatus,
                    size: 1000
                }

                const result = await userAccountAxios({
                    method: 'POST',
                    url: url,
                    data: info
                })

                if (!result) {
                    return Promise.reject('Unknown download error')
                }

                const downloadUrl = `/brand-profile/${brandProfileId}/moment-clusters/download`

                const downloadResult = await listBuilderAxios({
                    method: 'POST',
                    url: downloadUrl,
                    responseType: 'blob',
                    data: result.data 
                })

                if (!downloadResult) {
                    return Promise.reject('Unknown download error')
                }

                fileDownload(downloadResult.data, `currentMoments.xlsx`)
            } else {
                const url = `/brand-profile/${brandProfileId}/moment-clusters/download-by-filter`
                let info = {
                    boardIds,
                    startDate,
                    endDate,
                    actions,
                    sortBy,
                    searchTerm,
                    aylienNews,
                    aylienIndustries,
                    activationStatus
                }

                const result = await listBuilderAxios({
                    method: 'POST',
                    url: url,
                    responseType: 'blob',
                    data: info
                })
                if (!result) {
                    return Promise.reject('Unknown download error')
                }
                fileDownload(result.data, `currentMoments.xlsx`)
            }
        },
        getMomentPreviews: async (data: NLBoardRequest, elasticSearchEnabled: boolean) => {
            const url = elasticSearchEnabled ? '/v1/boards/moments/nl-moments-preview' : '/nl-board/nl-board-preview';
            return (elasticSearchEnabled ? userAccountAxios : listBuilderAxios).post(url,data)
        },
        getSocialConversationPreviews: async (data: SocialConversationRequest, elasticSearchEnabled: boolean) => {
            const url = elasticSearchEnabled ? '/v1/boards/narrative-clusters/nl-narrative-preview' : '/nl-board/social-conversation-previews';
            return  (elasticSearchEnabled ? userAccountAxios : listBuilderAxios).post(url, data)
        },
        share: async ({ brandProfileId, clusterIds, emails, keywords, placements, message }: ShareProps) => {
            const url = `/brand-profile/${brandProfileId}/moment-clusters/implement`
            return listBuilderAxios({
                method: 'POST',
                url: url,
                data: {
                    clusterIds,
                    emails,
                    keywords,
                    placements,
                    message
                }
            })
        },
        scenariosOptions: {
            get: async ({ clusterId, brandProfileId }: GetScenariosProps) => {
                const url = `/brand-profile/${brandProfileId}/moment-clusters/${clusterId}/scenarios/related`
                const { data } = await listBuilderAxios.get(url)
                const parsedScenariosOptions = MomentScenariosSchema.parse(data)
                return parsedScenariosOptions.length === 0
                    ? parsedScenariosOptions
                    : parsedScenariosOptions.concat({
                          scenarioId: NoneApplyId,
                          scenarioName: 'None Apply',
                          scenarioResponseId: 4,
                          feedback: null
                      })
            }
        },
        scenarios: {
            get: async ({ clusterId, brandProfileId }: GetScenariosProps) => {
                if (clusterId === undefined || brandProfileId === undefined) return Promise.reject('invalid input')
                const url = `/brand-profile/${brandProfileId}/moment-clusters/${clusterId}/scenarios/classified`
                const { data } = await listBuilderAxios.get(url)
                return MomentScenariosSchema.parse(data)
            },
            patch: async ({ brandProfileId, clusterId, scenarios }: PatchMomentScenariosProps) => {
                if (!scenarios) return Promise.reject('scenarios is required')
                const url = `/brand-profile/${brandProfileId}/moment-clusters/${clusterId}/scenarios`

                const response = await listBuilderAxios
                    .patch<{ status: number, data: {message:string, recommendedAction: string} }>(url, {
                        scenarios: scenarios.map((s: MomentScenarioType) => {
                            return {
                                scenarioId: s.scenarioId,
                                scenarioName: s.scenarioName,
                                scenarioResponseId: s.scenarioResponseId
                            }
                        })
                    })
                    .catch((err) => {
                        return { status: 500, data: {message:'Error Occured!', recommendedAction: ''}}
                    })

                return { status: 200, data: response}
            }
        },
        articles: {
            get: async ({ clusterId, brandProfileId }: GetScenariosProps) => {
                if (clusterId === undefined || brandProfileId === undefined) return
                const url = `/brand-profile/${brandProfileId}/moment-clusters/${clusterId}/articles`
                const { data } = await listBuilderAxios.get(url)
                return MomentArticlesSchema.parse(data)
            }
        },
        articlesPaginated: {
            get: async ({ clusterId, brandProfileId, page, limit }: GetScenariosPaginatedProps) => {
              if (clusterId === undefined || brandProfileId === undefined) return;
              const url = `/brand-profile/${brandProfileId}/moment-clusters/${clusterId}/articles`;

              const { data } = await listBuilderAxios.get(url, {
                params: {
                  page,
                  pageSize: limit
                }
              });
              return MomentArticlesSchema.parse(data)
            }
        },
        paginatedVideos: {
            get: async ({ clusterId, brandProfileId, page, limit }: GetScenariosPaginatedProps) => {
              if (clusterId === undefined || brandProfileId === undefined) return;
              const url = `/brand-profile/${brandProfileId}/moment-clusters/${clusterId}/videos`;

              const { data } = await listBuilderAxios.get(url, {
                params: {
                  page,
                  pageSize: limit
                }
              });
              return MomentVideosSchema.parse(data);
            }
          },
          videos: {
            get: async ({ clusterId, brandProfileId }: GetScenariosProps) => {
                if (clusterId === undefined || brandProfileId === undefined) return
                const url = `/brand-profile/${brandProfileId}/moment-clusters/${clusterId}/videos`
                const { data } = await listBuilderAxios.get(url)
                return MomentVideosSchema.parse(data)
            }
        },
        keywords: {
            get: async ({ clusterId, brandProfileId, momentAction }: GetScenariosProps) => {
                const url = `/brand-profile/${brandProfileId}/moment-clusters/${clusterId}/keywords`
                const { data } = await listBuilderAxios.get(url,{
                    params: {
                        action: momentAction
                    }
                })
                return MomentKeywordsSchema.parse(data)
            }
        },
        actionJustification: {
            get: async ({ clusterId, brandProfileId }: ClusterSearch) => {
                const url = `/brand-profile/${brandProfileId}/moment-clusters/${clusterId}/action-justifications`
                const { data } = await listBuilderAxios.get(url)
                return MomentActionJustificationsSchema.parse(data)
            }
        },
        feedback: {
            recommendedActionFeedback: async (args: RecActionFeedbackProps) => {
                const url = `/brand-profile/${args.brandProfileId}/moment-clusters/${args.clusterId}/feedback-recommended-action`
                return listBuilderAxios.post(url, {
                    feedback: args.feedback
                })
            },
            scenarioFeedback: async (args: ScenarioFeedbackProps) => {
                const url = `/brand-profile/${args.brandProfileId}/moment-clusters/${args.clusterId}/feedback-question`
                return listBuilderAxios.post(url, {
                    scenarioId: args.scenarioId,
                    clusterHeadline: args.clusterHeadline,
                    feedback: args.feedback,
                    actionJustificationQuestion: args.question
                })
            },
            videoFeedback: async (args: VideoFeedbackProps) => {
                const url = `/brand-profile/${args.brandProfileId}/moment-clusters/${args.clusterId}/feedback-video`
                const _args = (({ clusterId, brandProfileId, ...o }) => o)(args) //removes clusterId and brandProfileId from args
                return listBuilderAxios.post(url, _args)
            },
            keywordFeedback: async (args: KeywordFeedbackProps) => {
                const url = `brand-profile/${args.brandProfileId}/moment-clusters/${args.clusterId}/feedback-keywords`
                return await listBuilderAxios.post(url, {
                    feedback: args.feedback
                })
            }
        },
        taxonomies: {
            get: async ({ signal, startDate, endDate, boardIds, actions, searchTerm, brandProfileId, activationStatus }: GetTaxonomiesProps) => {
                const url = `/brand-profile/${brandProfileId}/moment-clusters/aylien-taxonomies`
                const res = await listBuilderAxios.post(
                    url,
                    {                        
                        startDate,
                        endDate,
                        boardIds,
                        actions,
                        searchTerm,
                        activationStatus
                    },
                    {
                        signal
                    }
                )
                return TaxonomiesSchema.parse(res.data)
            }
        },
        justification: {
            post: async ( momentsToGetJustification : any, momentId: number[], brandProfileId: number | undefined) => {
                const url = `/moment-justification/generate-moment-justification`

                const res = await listBuilderAxios.post(
                    url,
                    {
                        moments: momentsToGetJustification,
                        momentId: momentId,
                        brandProfileId,
                    }
                )
                if(res.data.status == 400) {
                    return res;
                }
                return MomentsJustificationResponseSchema.parse(res.data);
            },
            getHistoryJustification : async ( brandProfileId: number | undefined, page: number, userId: number) => {
                const url = `/moment-justification/history-moment-justification`
                const limit = 10;

                const res = await listBuilderAxios.post(
                    url,
                    {
                        brandProfileId,
                        userId,
                        limit,
                        offset: page * limit
                    }
                )
                return HistoryResponseSchema.parse(res.data)
            },
            updateFeedback : async (uuid: string,brandProfileId: number | undefined, userId: number, clusterId: number, feedBackRelevanceSummary: boolean | null, feedBackActionableInsight: boolean | null, feedBackMarketingActionSuggestion: boolean | null ) => {
                const url = `/moment-justification/feedback-moment-justification`

                const res = await listBuilderAxios.post(
                    url,
                    {
                        uuid,
                        brandProfileId,
                        userId,
                        clusterId,
                        feedBackRelevanceSummary,
                        feedBackActionableInsight,
                        feedBackMarketingActionSuggestion
                    }
                )

                return res.data

            }
        }
    }
}
