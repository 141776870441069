import SightlySelect from "@/components/Sightly/SightlyFormElements/SightlySelect";
import { ActivationReportConfiguration } from "@/views/ActivationReports/types/activation-report.types";
import { granularity } from '@/views/ReportGenerator/reportGenerator-types';
import React from "react";

interface IProps {
    handleOnChange: Function
    formValues: ActivationReportConfiguration
}

export default function Granularity({ handleOnChange, formValues } : IProps) {
    return (
      <div data-testid='div-new-report-granularity-select'>
        <h5 className="mt-3 mb-2 text-base">Granularity</h5>
        <div className="relative">
          <SightlySelect
            id={"new-report-granularity-select"}
            options={granularity}
            value={formValues.granularity}
            onChange={(value) => handleOnChange('granularity', value)}
            data-testid='new-report-granularity-select' 
            labelKey='label' 
            valueKey='value' 
          />
        </div>
      </div>
    )
}
