
export enum SMART_LIST_OBJECTIVE {
  REACH = 'Reach & Awareness',
  BRANDING = 'Branding',
  TRENDING = 'New & Trending',
  SUITABILITY = 'Suitability',
}

export enum SMART_LIST_CONTENT_TYPE {
  YOUTUBE_CHANNEL = 'Youtube Channel',
  YOUTUBE_VIDEO = 'Youtube Video',
}

export enum SMART_LIST_TYPE {
  INCLUSION = 'Inclusion',
  EXCLUSION = 'Exclusion',
}

export type YouTubeChannelType = {
  id: string
  title: string
  videoCount: number
  subscriberCount: number
  viewCount: number
  engagement: number
  description: string
  thumbnailUrl: string
  lastModifiedAt: string
}

export type YouTubeVideoType = {
  id: string
  title: string
  viewsCount: number
  likesCount: number
  commentsCount: number
  thumbnailUrl: string
  iabCategories: string[]
  publishedDate: Date
}
