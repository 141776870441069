import React, { ReactNode } from "react";
import { useBodyOverflowYAuto } from "@/components/Sightly/hooks/useBodyOverflowYAuto";
import { useBodyOverflowXHidden } from "@/components/Sightly/hooks/useBodyOverflowXHidden";

export interface IProps {
  children: ReactNode;
  image: string;
}

export const IntroLayout = ({ children, image }: IProps) => {
  useBodyOverflowXHidden();
  useBodyOverflowYAuto();

  return (
    <div className="flex min-h-screen w-screen flex-col md:flex-row">
      <div className="m-12 overflow-auto md:m-12 md:w-2/5 lg:m-12">
        {children}
      </div>
      <div className="h-screen bg-gradient-to-b from-[#CFFAFE] via-[#26AAE141] to-[#26AAE206] md:flex md:w-3/5 md:flex-col md:justify-between md:gap-16 md:sticky md:top-0 md:overflow-hidden">
        <img
          className="h-full w-auto"
          src={image}
          alt="image depicting someone sighting something in the distance"
        />
      </div>
    </div>
  );
};
