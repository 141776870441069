import { userAccountAxios } from "@/axiosInstances";
import { IABCategoriesResponseSchema, SegmentPaginatedResponseSchema, SegmentSchema } from '@/views/Engage/Segments/schemas/SegmentSchema';
import { SegmentConfiguration } from '@/views/Engage/Segments/types/SegmentsTypes';


type GetSegmentsQueryKey = [string, number | undefined, number | undefined, number | undefined, string | undefined, string | undefined];


export const segmentsController = {
    validateForm: (formValues: SegmentConfiguration) => {
        let errorMessages: string[] = []

        if (formValues.name === '') {
            errorMessages.push('Name cannot be empty.')
        }
        if (formValues.description === '') {
            errorMessages.push('Description cannot be empty.')
        }

        return errorMessages
    },

    getSegments: async ({ queryKey }: { queryKey: GetSegmentsQueryKey }) => {
        try {
            const [ , accountId, page, pageSize, orderBy, sortBy] = queryKey;

            let url = `/v1/smart-lists/segments?accountId=${accountId}&page=${page}&pageSize=${pageSize}`;

            if (orderBy) url += `&orderBy=${orderBy}`
            if (sortBy) url += `&sort=${sortBy}`

            const { data } = await userAccountAxios.get(url);

            const resultData = SegmentPaginatedResponseSchema.parse(data);

            return resultData;
        } catch (error) {
            console.error(error)
            throw error
        }
    },
    getSegmentById: async ( id :number ) => {
        try {
            const url = `/v1/smart-lists/segments/${id}`

            const { data } = await userAccountAxios.get(url)

            const resultData = SegmentSchema.parse(data)

            return resultData

        } catch (error) {
            console.error(error)
            throw error
        }
    },
    getIABCategories: async () => {
        const url = `v1/admin/iab-categories/trees`
    
        try {
            const { data } = await userAccountAxios.get(url)

            const parsedData = IABCategoriesResponseSchema.parse(data);

            return parsedData
        } catch (error) {
            console.error(error)
            throw error
        }
    },
    handleRemoveSegment: async (id: number) => {
        try {
            const url = `/v1/smart-lists/segments/${id}`

            const response = await userAccountAxios.delete(url)

            if (response.status == 204) {
                return true;
            }
            return false;
        } catch (error) {
            console.error(error)
            throw error
        }
    },
    handleCreateSegment: async (formValues: SegmentConfiguration, accountId: number) => {

        const dataToSend = {
            accountId,
            name: formValues.name,
            description: formValues.description,
            keywords: formValues.keywords,
            iabCategories: formValues.iabCategories,
        }
        try {
            const url = `/v1/smart-lists/segments/`

            const response = await userAccountAxios.post(url, dataToSend);

            return response;

        } catch (error) {
            console.error(error)
            throw error;

        }

    },
    handleUpdateSegment: async (formValues: SegmentConfiguration, accountId: number, segmentId: number) => {
        const dataToSend = {
            accountId,
            name: formValues.name,
            description: formValues.description,
            keywords: formValues.keywords,
            iabCategories: formValues.iabCategories,
        }
        try {
            const url = `/v1/smart-lists/segments/${segmentId}`

            const response = await userAccountAxios.patch(url, dataToSend);

            return response;

        } catch (error) {
            console.error(error)
            throw error;

        }

    },



}

