import React from 'react';
import { HiOutlineEye } from 'react-icons/hi';
import PlatformIcon from './PlatformIcon';
import Chip from './Chip';

interface StatsComponentProps {
  narrativesIdentified: number;
  platformSources: number;
  topPlatforms: { name: string; url: string; icon: JSX.Element }[];
  topThemes: string[];
  setIsViewMoreClicked:React.Dispatch<React.SetStateAction<boolean>>;
}

const StatsComponent: React.FC<StatsComponentProps> = ({
  narrativesIdentified = 0,
  platformSources = 0,
  topPlatforms = [],
  topThemes = [],
  setIsViewMoreClicked
}) => {
  return (
    <div className="flex flex-col gap-y-4 xl:gap-y-0 xl:flex-row pb-6 xl:justify-between xl:items-center bg-white w-full border-b h-fit-content" data-testid="conversation-statscomponent">
      <div className="flex flex-col pr-4 pb-2 xl:pb-0 border-b xl:border-b-0 xl:pr-0" data-testid="conversation-statscomponent-narratives-identified">
        <span className="text-xs xl:text-sm font-bold pb-4">Narratives Identified</span>
        <span className="text-md xl:text-5xl font-bold text-gray-800">{narrativesIdentified}</span>
      </div>
      <div className="flex flex-col pr-4 pb-2 xl:pb-0 xl:pl-8 border-b xl:border-b-0 xl:border-l" data-testid="conversation-statscomponent-platform-sources">
        <span className="text-xs xl:text-sm font-bold pb-4">Platform Sources</span>
        <span className="text-md xl:text-5xl font-bold text-gray-800">{platformSources}</span>
      </div>
      <div className="flex flex-col pr-4 pb-2 xl:pb-0 xl:pl-8 border-b xl:border-b-0 xl:border-l" data-testid="conversation-statscomponent-top-platforms">
        <span className="text-xs xl:text-sm font-bold">Top Platforms</span>
        <div className="flex space-x-2 mt-4">
          {topPlatforms.map((platform, index) => (
            (
              <div key={index} className="flex flex-row gap-4">
                <div className="place-self-center">
                  <PlatformIcon platformName={platform.name} height='2.25rem'></PlatformIcon>
                </div>
              </div>
            )
          ))}
        </div>
      </div>
      <div className="flex flex-col pr-4 pb-2 xl:pb-0 xl:pl-8 xl:border-l" data-testid="conversation-statscomponent-top-themes">
        <span className="text-xs xl:text-sm font-bold">Top Themes</span>
        <div className='flex justify-between'>
          <div className="flex space-x-2 mt-5">
            {topThemes.map((theme, index) => (
              <Chip key={theme}
                data-testid={`conversation-statscomponent-theme-${index}`}
                text={theme}></Chip>
            ))}
          </div>
        </div>
      </div>
      <div className="flex items-center ml-5">
        <a
          href="#"
          onClick={() => setIsViewMoreClicked(() => true)}
          className="flex items-center text-xs xl:text-sm text-blue-500"
          data-testid="conversation-statscomponent-view-more"
        >
          <HiOutlineEye className="w-4 h-4 mr-1" />
          View more
        </a>
      </div>
    </div>
  );
}

export default StatsComponent;
