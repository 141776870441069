export const TIMEZONE_OPTIONS = [
    { label: '(GMT-4:00) Eastern Time', value: 'America/New_York' },
    { label: '(GMT-5:00) Central Time', value: 'America/Chicago' },
    { label: '(GMT-6:00) Mountain Time', value: 'America/Denver' },
    { label: '(GMT-7:00) Pacific Time', value: 'America/Los_Angeles' }
]

export const REPEAT_FREQUENCY_OPTIONS = [
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' }
]