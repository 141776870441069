/**
 * Get the time difference between the current date and the date of the last video uploaded.
 * @param {Date} date - The date of the last video uploaded.
 * @returns {string} The time difference between the current date and the date of the last video uploaded.
 * @example
 * getTimeDifference(new Date('2025-03-03T10:36:27.256Z')) // "1 day ago"
 * getTimeDifference(new Date('2025-03-02T10:36:27.256Z')) // "2 days ago"
 * getTimeDifference(new Date('2025-03-03T09:36:27.256Z')) // "1 hour ago"
 * getTimeDifference(new Date('2025-03-03T08:36:27.256Z')) // "2 hours ago"
 * getTimeDifference(new Date('2025-03-03T07:36:27.256Z')) // "1 minute ago"
 * getTimeDifference(new Date('2025-03-03T06:36:27.256Z')) // "2 minutes ago"
 * getTimeDifference(new Date('2025-03-03T05:36:27.256Z')) // "just now"
 */
export const getTimeDifference = (date: Date): string => {
    const now = new Date();

    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    const days = Math.floor(diffInSeconds / (3600 * 24));
    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;

    const hours = Math.floor(diffInSeconds / 3600);
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;

    const minutes = Math.floor(diffInSeconds / 60);
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;

    return 'just now';
};

/**
 * Format a number to a more readable format.
 * @param {number} num - The number to format.
 * @returns {string} The formatted number.
 * @example
 * formatNumber(1000) // "1K"
 * formatNumber(1000000) // "1M"
 * formatNumber(1000000000) // "1B"
 * formatNumber(1234567890) // "1.2B"
 */
export const formatNumber = (num: number): string => {
    if (num >= 1_000_000_000) {
        return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1_000_000) {
        return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1_000) {
        return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num.toString();
};