import { YouTubeChannelType } from '@/views/Engage/SmartList/types/SmartlistTypes';

export const MockChannels: YouTubeChannelType[] = [
    {
        id: 'UC_x5XG1OV2P6uZZ5FSM9Ttw',
        title: 'Google Developers',
        thumbnailUrl: 'https://picsum.photos/200/300',
        subscriberCount: 13600000,
        videoCount: 45300,
        viewCount: 11100000000,
        engagement: 1300000,
        description: 'The Google Developers channel features talks from events, educational series, best practices, and more.',
        lastModifiedAt: '2025-03-03T12:00:00Z'
    },
    {
        id: 'UC_w9F4SY5HkOLoRFv6vFwqg',
        title: 'Retro Arcade',
        thumbnailUrl: 'https://picsum.photos/201/301',
        subscriberCount: 542000,
        videoCount: 150,
        viewCount: 45000000,
        engagement: 12500,
        description: 'Gaming nostalgia from the golden age of arcades and consoles.',
        lastModifiedAt: '2025-03-10T14:30:00Z'
    },
    {
        id: 'UC_nature365',
        title: 'Nature 365',
        thumbnailUrl: 'https://picsum.photos/202/302',
        subscriberCount: 1800000,
        videoCount: 800,
        viewCount: 720000000,
        engagement: 95000,
        description: 'A daily dose of breathtaking landscapes and wildlife.',
        lastModifiedAt: '2025-02-27T09:20:00Z'
    },
    {
        id: 'UC_futureTech',
        title: 'Future Tech Insights',
        thumbnailUrl: 'https://picsum.photos/203/303',
        subscriberCount: 890000,
        videoCount: 340,
        viewCount: 125000000,
        engagement: 54000,
        description: 'Exploring cutting-edge innovations shaping tomorrow.',
        lastModifiedAt: '2025-03-05T11:45:00Z'
    },
    {
        id: 'UC_cinephiles123',
        title: 'Cinephile Club',
        thumbnailUrl: 'https://picsum.photos/204/304',
        subscriberCount: 340000,
        videoCount: 210,
        viewCount: 15000000,
        engagement: 23000,
        description: 'A haven for movie lovers celebrating cult classics and underrated gems.',
        lastModifiedAt: '2025-03-01T17:00:00Z'
    },
    {
        id: 'UC_zenzone01',
        title: 'Zen Zone',
        thumbnailUrl: 'https://picsum.photos/205/305',
        subscriberCount: 500000,
        videoCount: 120,
        viewCount: 30000000,
        engagement: 15500,
        description: 'Relaxation, meditation, and mindfulness for a peaceful life.',
        lastModifiedAt: '2025-02-20T06:10:00Z'
    },
    {
        id: 'UC_quantumNews',
        title: 'Quantum News Network',
        thumbnailUrl: 'https://picsum.photos/206/306',
        subscriberCount: 970000,
        videoCount: 580,
        viewCount: 278000000,
        engagement: 48000,
        description: 'Updates and insights into quantum computing and parallel worlds.',
        lastModifiedAt: '2025-03-08T10:00:00Z'
    },
    {
        id: 'UC_mysteryArch',
        title: 'Mystery Archives',
        thumbnailUrl: 'https://picsum.photos/207/307',
        subscriberCount: 430000,
        videoCount: 670,
        viewCount: 155000000,
        engagement: 32000,
        description: 'Unsolved mysteries, chilling tales, and conspiracies unraveled.',
        lastModifiedAt: '2025-03-11T22:15:00Z'
    },
    {
        id: 'UC_galacticCook',
        title: 'Galactic Recipes',
        thumbnailUrl: 'https://picsum.photos/208/308',
        subscriberCount: 620000,
        videoCount: 310,
        viewCount: 86000000,
        engagement: 27000,
        description: 'Interstellar cooking tips and recipes from across the galaxy.',
        lastModifiedAt: '2025-03-09T19:00:00Z'
    },
    {
        id: 'UC_legendsUnleashed',
        title: 'Legends Unleashed',
        thumbnailUrl: 'https://picsum.photos/209/309',
        subscriberCount: 450000,
        videoCount: 230,
        viewCount: 96000000,
        engagement: 31000,
        description: 'Epic tales, folklore, and fantasy lore brought to life.',
        lastModifiedAt: '2025-02-25T15:00:00Z'
    }
];
