import React, { useEffect, useState } from 'react'
import { CheckTreePicker } from 'rsuite'
import { SegmentConfiguration } from '@/views/Engage/Segments/types/SegmentsTypes';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { IABCategory } from '@/views/Engage/Segments/schemas/SegmentSchema';
import Tooltip from '@/components/TailwindTooltip';

interface IProps {
  /** IAB Categories to be displayed in the component. */
  iabCategories: IABCategory[],
  /** Function to handle the change of the form values. */
  handleOnChange: Function,
  /** Form values to be displayed in the component. */
  formValues: SegmentConfiguration,
}

/**
 * Component to display the IAB Categories.
 * @prop {IABCategory[]} iabCategories IAB Categories to be displayed in the component.
 * @prop {Function} handleOnChange Function to handle the change of the form values.
 * @prop {SegmentConfiguration} formValues Form values to be displayed in the component.
 */

export const IABCategories = ({ iabCategories, handleOnChange, formValues}: IProps) => {

  const [iabCategoryIds, setIabCategoryIds] = useState<string[]>(formValues.iabCategories.map(io => io.id));

  useEffect(() => {
    const ids = formValues.iabCategories.map(io => io.id);
    setIabCategoryIds(ids);
  }, [formValues.iabCategories])


  const findCategories = (categories: IABCategory[], ids: string[]): IABCategory[] => {
    return categories.reduce((acc: IABCategory[], category: IABCategory) => {
      if (ids.includes(String(category.id))) {
        acc.push({ name: category.name, id: category.id });
      }
      if (category.children) {
        acc.push(...findCategories(category.children, ids));
      }
      return acc;
    }, []);
  };

  return (
    <div data-testid='div-segments-select-iab-categories' className='flex flex-row w-full justify-between'>
      <div className='flex flex-row items-start'>
        <div className='text-base font-medium mr-2'>IAB Categories</div>
        <Tooltip content={<div>IAB Categories</div>}>
          <InformationCircleIcon className="w-6 inline-block mr-2 cursor-help" data-testid="edit-segment-name-icon" />
        </Tooltip>
      </div>
      <div className='mb-4 custom-tag-picker-color custom-tag-picker-color-checked'>
        <CheckTreePicker
          placeholder='Select IAB Categories'
          value={iabCategoryIds}
          data={iabCategories}
          labelKey="name"
          valueKey="id"
          onChange={(values) => {
            const selectedIABCategories = findCategories(iabCategories, values);
            handleOnChange('iabCategories', selectedIABCategories);
          }}
          style={{ width: 350 }}
          cascade={false}
          preventOverflow
          virtualized
          data-testid='iab-categories-picker'
        />
      </div>
    </div>
  )
}
