import React from 'react'
import _ from 'lodash'
import { Placeholder } from 'rsuite'
import { TrashIcon } from '@heroicons/react/24/outline'
import { TargetedActivationListType } from '@/views/Discover/Activations/v2/ActivationsManager/index'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import Tooltip from '@/components/TailwindTooltip'
import { useInsertionOrderHierarchy } from '@/views/Discover/Activations/v2/hooks/activations-hooks'
import {
  ActivationAdGroupsResultType,
  ActivationAdGroupsSchemaType,
  ActivationCampaignsSchemaType
} from '@/views/Discover/Activations/v2/activations-types'
import {
  SelectorGroup,
  AddSelectorGroupRow
} from '@/views/Discover/Activations/v2/ActivationsManager/ActivationTable/SelectorGroup'
import { TARGETED_ACTIVATION_ACTIONS } from '@/views/Discover/Activations/v2/ActivationsManager/reducers/activationManager.reducer'
import { useTargetedActivationList } from '@/views/Discover/Activations/v2/ActivationsManager/ActivationManagerContext'
import { TableColGroup } from '@/views/Discover/Activations/v2/ActivationsManager/ActivationTable/ActivationsTable'

export const CampaignAdGroupRow = (props: {
  keyVersion: React.MutableRefObject<number>
  persona: string
  activation: TargetedActivationListType
  selectedActivations: Map<string, Set<number>>
  handleSelectActivation: (checked: boolean, persona: string, activation: TargetedActivationListType) => void
  setActivationToBeDeleted: React.Dispatch<React.SetStateAction<[string, number] | null>>
  insertionOrderId: number
}) => {
  const { activation, persona, selectedActivations, handleSelectActivation, setActivationToBeDeleted, insertionOrderId, keyVersion } = props
  const adGroupCampaignsQuery = useInsertionOrderHierarchy({ ioIds: [ insertionOrderId.toString() ] })

  const targetedActivationList = useTargetedActivationList()

  const [campaignOptions, adGroupOptions] = React.useMemo(() => getCampaignAdGroupOptions(adGroupCampaignsQuery.data), [adGroupCampaignsQuery.data])

  let keyCounter = React.useRef(0)
  const firstRowKey = `${persona}-${activation.activationItemId}-0`

  const initialValueKeyToCampaignId = React.useMemo(() => {
    const initValue: Record<string, number> = {}
    let rowNum = 0
    activation.campaignsToAdGroups.forEach((adGroups, campaignId) => {
      const key = `${persona}-${activation.activationItemId}-${rowNum}`
      initValue[key] = campaignId
      rowNum++
    })
    keyCounter.current = rowNum
    return initValue
  },[adGroupOptions, activation.campaignsToAdGroups])

  React.useEffect(() => {
    activation.campaignsToAdGroups.forEach((adGroups, campaignId) => {
      if(adGroupOptions && !adGroupOptions.has(campaignId)) {
        targetedActivationList.dispatch({
          type: TARGETED_ACTIVATION_ACTIONS.REMOVE_CAMPAIGN,
          payload: {
            persona,
            campaignId,
            currentActivationItemId: activation.activationItemId
          }
        })
        keyVersion.current += 1
      }
    })
  }, [adGroupOptions, activation.campaignsToAdGroups, persona, activation.activationItemId, targetedActivationList])

  const [keyToCampaignIdMapper, setKeyToCampaignIdMapper] = React.useState<Record<string, number>>(
    initialValueKeyToCampaignId
  )

  const handleCampaignChange = (
    elementKey: string,
    persona: string,
    currentActivationItemId: number,
    nextCampaignId: number,
    prevCampaignId: number,
    setDoesValueExists: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (prevCampaignId !== nextCampaignId && activation.campaignsToAdGroups.has(nextCampaignId)) {
      setDoesValueExists(() => true)
      return
    }
    targetedActivationList.dispatch({
      type: TARGETED_ACTIVATION_ACTIONS.ADD_CAMPAIGN,
      payload: {
        persona,
        currentActivationItemId,
        nextCampaignId,
        prevCampaignId
      }
    })
    setKeyToCampaignIdMapper((prev) => {
      const next = { ...prev }
      next[elementKey] = nextCampaignId
      return next
    })
    setDoesValueExists(() => false)
  }

  const handleAddRow = () =>
    setKeyToCampaignIdMapper((prev) => {
      const newValue = keyCounter.current + 1
      const next = { ...prev, [`${persona}-${activation.activationItemId}-${newValue}`]: newValue }
      keyCounter.current = newValue
      return next
    })

  const handleSelectorsRemoveRow = (
    elementKey: string,
    persona: string,
    currentActivationItemId: number,
    campaignId: number
  ) => {
    setKeyToCampaignIdMapper((prev) => {
      const next = { ...prev }
      delete next[elementKey]
      return next
    })
    targetedActivationList.dispatch({
      type: TARGETED_ACTIVATION_ACTIONS.REMOVE_CAMPAIGN,
      payload: {
        persona,
        campaignId,
        currentActivationItemId
      }
    })
  }

  const getAdGroupValue = (campaignId: number, type: 'videoAdGroups' | 'keywordAdGroups') => {
    const campaignAdGroup = activation.campaignsToAdGroups.get(campaignId)
    const adGroup: number[] = (campaignAdGroup && Array.from(campaignAdGroup[type] ?? [])) ?? []
    return adGroup.length ? adGroup : undefined
  }

  if (adGroupCampaignsQuery.isLoading) {
    return (
      <tr>
        <td colSpan={6}>
          <Placeholder.Grid
            rows={1}
            columns={6}
            rowHeight={20}
            active
            className='p-1'
          />
        </td>
      </tr>
    )
  }

  return (
    <tr>
      <td colSpan={6}>
        <table
          data-testid="activation-group"
          id="activation-group"
          className="w-full table-fixed"
        >
          <TableColGroup />
          <tbody 
          data-testid="campaign-adgroup-row-with-activation"
          id="campaign-adgroup-row-with-activation"
          >
            <tr
              key={`${persona}-${activation.activationItemId}_campaign_adgroup_row`}
              className="campaignAdGroupRow"
            >
              <td
                colSpan={2}
                headers="activations-activation"
                data-testid="act-mgr-activations-table-activation"
                id="act-mgr-activations-table-activation"
                className="w-full px-3 pl-16 text-sm font-medium text-gray-900"
              >
                <div className="flex items-start w-full gap-2">
                  <SightlyCheckbox
                    id="act-mgr-activations-checkbox-id"
                    dataTestId="act-mgr-activations-checkbox"
                    checked={selectedActivations.get(persona)?.has(activation.activationItemId) || false}
                    handleChange={(checked: boolean) => {
                      handleSelectActivation(checked, persona, activation)
                    }}
                  />
                  <Tooltip
                    content={
                      <div
                        data-testid="act-mgr-activations-cluster-name-tooltip"
                        id="act-mgr-activations-cluster-name-tooltip"
                        className="text-left"
                      >
                        {activation.clusterName}
                      </div>
                    }
                  >
                    <div
                      data-testid="act-mgr-activations-activation-clusterName"
                      id="act-mgr-activations-activation-clusterName"
                      className="truncate"
                    >
                      {activation.clusterName}
                    </div>
                  </Tooltip>
                </div>
              </td>
              <SelectorGroup
                {...{
                  key: firstRowKey,
                  elementKey: firstRowKey,
                  persona,
                  campaignOptions,
                  adGroupOptions,
                  activationItemId: activation.activationItemId,
                  campaignValue: keyToCampaignIdMapper[firstRowKey],
                  handleCampaignChange,
                  keywordAdGroupValue: getAdGroupValue(keyToCampaignIdMapper[firstRowKey], 'keywordAdGroups'),
                  videoAdGroupValue: getAdGroupValue(keyToCampaignIdMapper[firstRowKey], 'videoAdGroups')
                }}
              />
              <td
                headers="activations-action"
                data-testid="act-mgr-activations-action"
                id="act-mgr-activations-action"
                className="px-1"
              >
                <TrashIcon
                  data-testid="act-mgr-action-delete-activation-btn"
                  id="act-mgr-action-delete-activation-btn"
                  onClick={() => {
                    setActivationToBeDeleted(() => [persona, activation.activationItemId])
                  }}
                  className="w-6 h-6 rounded hover:cursor-pointer hover:bg-slate-100"
                />
              </td>
            </tr>
          </tbody>
          {Array.from(Object.entries(keyToCampaignIdMapper)).map(([elementKey, campaignId], index) => {
            if (index === 0) return
            return (
              <AddSelectorGroupRow key={elementKey}>
                <SelectorGroup
                  {...{
                    key: elementKey,
                    elementKey,
                    persona,
                    campaignOptions,
                    adGroupOptions,
                    activationItemId: activation.activationItemId,
                    campaignValue: campaignId,
                    keywordAdGroupValue: getAdGroupValue(campaignId, 'keywordAdGroups'),
                    videoAdGroupValue: getAdGroupValue(campaignId, 'videoAdGroups'),
                    isRemovable: true,
                    handleRemoveRow: (persona: string, currentActivationItemId: number, campaignId: number) => {
                      handleSelectorsRemoveRow(elementKey, persona, currentActivationItemId, campaignId)
                    },
                    handleCampaignChange
                  }}
                />
              </AddSelectorGroupRow>
            )
          })}
          <tbody>
            <AddButtonRow
              key={`${persona}_${activation.activationItemId}_AddRow`}
              handleClick={handleAddRow}
            />
            <style>{`
              .campaignAdGroupRow td{
                padding-top:6px;
              }
            `}</style>
          </tbody>
        </table>
      </td>
    </tr>
  )
}

const AddButtonRow = (props: { handleClick: () => void }) => {
  return (
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td className="text-right">
        <div
          data-testid="act-mgr-campaign-to-ad-group-row-add-btn"
          id="act-mgr-campaign-to-ad-group-row-add-btn"
          className="p-1 pr-12 font-medium cursor-pointer text-sightlyBlue"
          onClick={props.handleClick}
        >
          Add +
        </div>
      </td>
      <td></td>
    </tr>
  )
}

const getCampaignAdGroupOptions = (adGroupCampaignsQueryData: ActivationAdGroupsResultType|undefined): [ActivationCampaignsSchemaType|undefined, Map<number, ActivationAdGroupsSchemaType>|undefined] => {
  let campaignOptionsList: ActivationCampaignsSchemaType = [],
    adGroupOptionsList: Map<number, ActivationAdGroupsSchemaType> = new Map()

  if (!adGroupCampaignsQueryData) return [undefined, undefined]

  campaignOptionsList = _.cloneDeep(adGroupCampaignsQueryData).map((mcc) => {
    const { id, name, searchName } = mcc
    const children = mcc.children.map((cid) => {
      const { id, name, searchName } = cid
      const children = cid.children.map((campaign) => {
        adGroupOptionsList.set(campaign.id, campaign.children)
        const { id, isCampaign, name, searchName } = campaign
        return { id, isCampaign, name, searchName }
      })
      return { id, name, searchName, children }
    })
    return { id, name, searchName, children }
  })
  return [campaignOptionsList, adGroupOptionsList]
}