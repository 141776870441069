import React from 'react'
import { CheckTreePicker, Icon, Panel } from 'rsuite'

import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import { ActionsMenu } from '@/views/Discover/Moments/v2/ActionsMenu'
import SocialNarrativeCard from '@/views/Discover/Boards/components/conversations/SocialNarrativeCard'
import { SortBy } from '@/views/Discover/Boards/components/conversations/SortBy'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import { INarrative } from "@/views/Discover/Boards/types"
import { useMutation } from '@tanstack/react-query'
import SightlyButton from '@/components/Sightly/SightlyButton'
import toast from 'react-hot-toast'
import { MessageTailwind } from '@/components/MessageTailwind'
import { LoadingPage } from '@/components/LoadingPage'
import { useFlag } from '@unleash/proxy-client-react'

export interface ITopic {
    id: number | string,
    name: string
}

export interface ITheme {
    id: number,
    name: string
}

export interface ISortBy {
    value: string,
    name: string
}

interface INarrativesProps {
    narratives: INarrative[]
    topicOptions: ITopic[]
    themeOptions: ITheme[]
    sortByOptions: ISortBy[]
    topics: number[]
    setTopics: Function
    themes: number[]
    setThemes: Function
    selectedSortBy: ISortBy
    setSelectedSortBy: Function
    handleDownloadNarratives: Function
    isLoading: boolean
    hasNext: boolean
    totalCount: number
    page: number
    setPage: Function
    setPreviousPagesNarratives: Function
    parseAreaChartData: Function
    toggleSocialNarrativePanel: Function
    clusterIds?: string[]
}

export default function Narratives({
    narratives,
    topicOptions,
    themeOptions,
    sortByOptions,
    topics,
    setTopics,
    themes,
    setThemes,
    selectedSortBy,
    setSelectedSortBy,
    handleDownloadNarratives,
    hasNext,
    totalCount,
    isLoading,
    page,
    setPage,
    setPreviousPagesNarratives,
    parseAreaChartData,
    toggleSocialNarrativePanel,
    clusterIds=[]
}: INarrativesProps) {
    const [selectedNarratives, setSelectedNarratives] = React.useState<INarrative[]>([])
    const [downloadingNarratives, setDownloadingNarratives] = React.useState<boolean>(false)
    const [isLoadingMore, setIsLoadingMore] = React.useState<boolean>(false)
    const narrativesElasticSearchEnabled = useFlag('enable_narratives_elastic_search')

    /* Download */
    const handleDownload = async (all: boolean) => {
        setDownloadingNarratives(true)

        const startTime = Date.now()

        if (selectedNarrativesIds.length > 1000) {
            setDownloadingNarratives(false)
            return
        }

        try {
            const allClusterIds = narrativesElasticSearchEnabled ? clusterIds : []
            await handleDownloadNarratives(
                all ? allClusterIds : selectedNarrativesIds,
            )
        } catch (err) {
            toast.error('There was an error downloading your narratives.')
        }

        /* Show downloading message for at least two seconds */
        const elapsedTime = Date.now() - startTime
        const remainingTime = 2000 - elapsedTime
        if (remainingTime > 0) {
            await new Promise(resolve => setTimeout(resolve, remainingTime))
        }

        setDownloadingNarratives(false)
    }

    /* Narratives selection */
    const selectedNarrativesIds = React.useMemo(() => {
        if (!selectedNarratives.length) return []
        return selectedNarratives.map((narrative) => narrative.id);
    }, [selectedNarratives])

    React.useEffect(() => {
        setSelectedNarratives([])
    }, [narratives]);

    const allNarrativesOnPageAreSelected = React.useMemo(() => {
        if (selectedNarrativesIds.length === 0) {
            return false
        }

        const notIncludedInSelectedItems = narratives
            ?.map((n) => n.id)
            ?.filter((id) => !selectedNarrativesIds.includes(id))
        if (notIncludedInSelectedItems === undefined) {
            return true
        }
        if (notIncludedInSelectedItems.length > 0) {
            return false
        }
        return true
    }, [narratives, selectedNarrativesIds])

    const handleSelectNarrative = (bool: boolean, narrative: INarrative) => {
        setSelectedNarratives((prev: INarrative[]) => {
            return bool ? prev.concat(narrative) : prev.filter((n) => n.id !== narrative.id)
        })
    }

    const selectAllOnPage = useMutation(
        () => {
            return Promise.resolve(true)
        },
        {
            onMutate: async (isSelected: boolean) => {
                if (isSelected) {
                    setSelectedNarratives(narratives)
                } else {
                    setSelectedNarratives([])
                }
            }
        }
    )

    /* Loading more message */
    React.useEffect(() => {
        setIsLoadingMore(false)
    }, [narratives]);

    return (
        <Panel
            data-testid="anticipation-boards-narratives-panel"
            header={<div className='border-b w-full pb-4'><span className="font-bold text-xl">Narratives Feed</span></div>}
            collapsible
            defaultExpanded={true}
            className='min-w-fit'
        >
            <div data-testid="anticipation-boards-narratives-panel-filters-panel" className='flex flex-wrap items-center justify-between bg-gray-100 w-full py-4' >
                <div className="flex px-4 gap-4 w-full xl:w-fit">
                    <div className="h-fit w-fit place-self-center">
                        <SightlyCheckbox
                            data-testid="select-all-moments-checkbox"
                            id="selectAllCheckbox"
                            indeterminate={selectedNarrativesIds.length > 0 && !allNarrativesOnPageAreSelected}
                            checked={allNarrativesOnPageAreSelected}
                            handleChange={(checked: boolean) => {
                                selectAllOnPage.mutate(checked)
                            }}
                            label={
                                selectedNarrativesIds.length > 0 ? (
                                    <div className="text-xs text-sightly-slate">
                                        {selectedNarrativesIds.length} item(s) selected
                                    </div>
                                ) : (
                                    ''
                                )
                            }
                        />
                    </div>
                    {/* <div data-testid="anticipation-boards-narratives-panel-topic-dropdown">
                        <CheckTreePicker
                            id="topicDropdown"
                            placeholder="Topic"
                            style={{ width: 162 }}
                            preventOverflow
                            data={topicOptions}
                            labelKey="name"
                            valueKey="id"
                            onChange={(_topics) => _topics.length > 0 && setTopics(_topics)}
                            value={topics}
                            cleanable={false}
                        />
                    </div> */}

                    <div className='flex justify-between w-full xl:gap-4 xl:justify-normal xl:w-fit'>
                        <div data-testid="anticipation-boards-narratives-panel-theme-dropdown">
                            <CheckTreePicker
                                id="themeDropdown"
                                placeholder="Theme"
                                style={{ width: 240 }}
                                preventOverflow
                                data={themeOptions}
                                labelKey="name"
                                valueKey="name"
                                onChange={(_themes) => {
                                    setPage(0);
                                    setThemes(_themes)

                                }}
                                value={themes}
                                cleanable={true}
                            />
                        </div>
                        <div data-testid="anticipation-boards-narratives-panel-download">
                            <ActionsMenu
                                buttonId="download"
                                buttonText="Download"
                                buttonIcon={
                                    <ArrowDownTrayIcon
                                        className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
                                        aria-hidden="true"
                                    />
                                }
                                options={[
                                    {
                                        disabled: selectedNarrativesIds.length < 1 || narratives.length === 0 || downloadingNarratives,
                                        name: 'Download selected narratives',
                                        description: 'Download all of the selected narratives and their meta data',
                                        onClick: () => handleDownload(false),
                                        icon: (
                                            <ArrowDownTrayIcon
                                                className="flex-shrink-0 w-6 h-6 text-indigo-600"
                                                aria-hidden="true"
                                            />
                                        )
                                    },
                                    {
                                        disabled: narratives.length === 0 || downloadingNarratives,
                                        name: 'Download all narratives',
                                        description:
                                            'Download all of the narratives and their meta data, according to your filters',
                                        onClick: () => handleDownload(true),
                                        icon: (
                                            <ArrowDownTrayIcon
                                                className="flex-shrink-0 w-6 h-6 text-indigo-600"
                                                aria-hidden="true"
                                            />
                                        )
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex px-6 justify-between w-full xl:justify-normal xl:w-fit">
                    {!isLoading && narratives && narratives.length > 0 && (
                        <div
                            data-testid="showing-moments-count"
                            className="flex flex-col justify-center mr-5 text-xs text-sightly-slate"
                        >
                            {`Showing ${narratives.length} of ${totalCount}`}
                        </div>
                    )}
                    <div
                        data-testid="anticipation-boards-narratives-panel-sort-by"
                    >
                        <SortBy
                            selected={selectedSortBy}
                            onChange={(sortBy: boolean) => {
                                setPage(0);
                                setSelectedSortBy(sortBy);
                            }}
                            options={sortByOptions}
                        />
                    </div>
                </div>
            </div >

            <div className="flex flex-col w-full gap-2 px-0">
                <MessageTailwind
                    show={downloadingNarratives}
                    message="Downloading narratives"
                    type="loading"
                />
            </div>

            {(isLoading && !isLoadingMore) && (
                <div className="flex flex-col w-full gap-2 px-0 h-80">
                    <LoadingPage message={'Fetching narratives'} />
                </div>
            )}

            {(!isLoading || isLoadingMore) && (<div
                data-testid="anticipation-boards-narratives-panel-narratives"
                className="flex flex-col gap-4 mt-4">
                <div className="grid grid-flow-row auto-rows-[minmax(0,_2fr)] gap-4">
                    {narratives.map((narrative, index) => {
                        return (
                            <SocialNarrativeCard
                                key={`narrative_${index}`}
                                narrative={narrative}
                                areaChartData={parseAreaChartData(narrative.postCountPerDayJson)}
                                narrativeIsLoading={false}
                                checked={selectedNarrativesIds.includes(narrative.id)}
                                handleSelectNarrative={handleSelectNarrative}
                                toggleSocialNarrativePanel={toggleSocialNarrativePanel}
                            />
                        )
                    })}
                </div>
                {hasNext && (
                    <div className="flex justify-center w-full py-2">
                        <SightlyButton
                            datatestid="load-more-moments-button"
                            block
                            id="loadmorebutton"
                            text="Load More"
                            type="sightlyPurple"
                            handleClick={() => {
                                setPreviousPagesNarratives(narratives);
                                setPage(page + 1);
                                setIsLoadingMore(true);
                            }}
                            loading={isLoading}
                            disabled={isLoading}
                        />
                    </div>
                )}
                {isLoadingMore && (
                    <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                        <Icon
                            icon="spinner"
                            spin
                        />{' '}
                        Loading...
                    </p>
                )}
            </div>)}
        </Panel>
    )
}
