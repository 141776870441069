import React, { useEffect, useState } from 'react'
import Message from 'rsuite/lib/Message'
import Placeholder from 'rsuite/lib/Placeholder'
import SlideOver from '@/components/SlideOver'
import { useAnalyzeTrend } from '../hooks/useTrends'
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles'
import BoardSelectSearch from '@/views/Discover/Boards/components/BoardSelectSearch'
import { ITrend, ITopic } from '../types'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import toast from 'react-hot-toast'

interface IAnalyzeSlideoverProps {
  showSlideOver: boolean
  setShowSlideOver: Function
  entityData: ITrend | ITopic | undefined
  callAnalyze: boolean
}

const AnalyzeSlideover: React.FC<IAnalyzeSlideoverProps> = ({ showSlideOver, setShowSlideOver, entityData, callAnalyze }) => {
  const { data: brandProfiles } = useBrandProfiles({
    submittedOnly: true
  })

  // TODO: This is a temporary solution to persist the brand profile across multiple Trend Analysis pages. A better solution is being designed but is not yet ready to be implemented.
  const analyzeBrandProfileId = parseInt(localStorage.getItem('analyzeBrandProfileId')!)

  const [brandProfileId, setBrandProfileId] = useState<number>(analyzeBrandProfileId || -1)

  const brandProfile = brandProfiles?.find((brandProfile) => brandProfile.brandProfileId === brandProfileId)

  const handleChangeBrandProfile = (brandProfileId: number) => {
    setBrandProfileId(brandProfileId)
    localStorage.setItem('analyzeBrandProfileId', brandProfileId.toString());
  }

  const { analyzeTrendQuery } = useAnalyzeTrend({
      brandName: brandProfile?.brandProfileName || '',
      title: entityData?.name || '',
      overview: entityData?.overview || '',
      bulletPoints: entityData?.bulletsText || [],
      industryCategory: '',
      brandBusinessSummary: brandProfile?.companySummary || '',
      brandMentalitySummary: brandProfile?.companyMentality || '',
      missionStatement: brandProfile?.missionStatement || '',
      valueStatement: brandProfile?.valueStatement || '',
      visionStatement: brandProfile?.visionStatement || '',
      entityList: brandProfile?.aiSuggestedKeyTerms
        ? brandProfile.aiSuggestedKeyTerms.split(',')
        : [],
      keyTerms: brandProfile?.keyTerms || '',
      callAnalyze
  });

  useEffect(() => {
    if (brandProfiles && brandProfiles.length > 0 && !analyzeBrandProfileId) {
      handleChangeBrandProfile(brandProfiles[0].brandProfileId)
    }
  }, [brandProfiles])

  if (!showSlideOver) {
    return null
  }

  return (
    <div className="relative h-full">
      <SlideOver
        show={showSlideOver}
        onHide={() => setShowSlideOver(false)}
        headerTitle="Mentality AI"
        headerForegroundColor="text-black"
        headerBackgroundColor="bg-white"
        showToggleFullScreenButton={false}
        maxOffset="50%"
      >
        <div className="px-5 h-4/5">
          <div className="absolute top-4 right-16">
            <BoardSelectSearch
              value={brandProfileId}
              options={brandProfiles}
              label={'Brand Profile'}
              labelKey="brandProfileName"
              valueKey="brandProfileId"
              onChange={(val: number) => {
                handleChangeBrandProfile(val)
              }}
              width={300}
            />
          </div>
          {!brandProfile && (
            <Message
              className="mt-3 mx-5"
              showIcon
              type="info"
              description="Please select a brand profile for further analysis"
            />
          )}
          <div data-testid='strategic-insight-main' className="bg-[#e9f5fe]/30 p-3 mt-5 h-full">
            <h4 data-testid='strategic-insight-name' className="text-xl font-semibold text-[#2e4052]">{entityData?.name}</h4>
            <p className="mt-5 mb-3">
              <span className="text-lg font-semibold text-[#2e4052]">Strategic Insights</span>{' '}
              <span className="text-xs underline align-baseline">AI Generated</span>
            </p>
            <div className="h-full overflow-y-scroll">
              <div data-testid="strategic-insights-item" className="rounded-md border border-gray-300 bg-[#fafafa] p-4">
                <h3 data-testid='strategic-insights-item-title' className="text-lg text-black mb-3">How this trend is relevant to your brand?</h3>
                {analyzeTrendQuery?.isLoading ? (
                  <Placeholder.Paragraph rows={2} />
                ) : (
                  <p data-testid='strategic-insight-item-description'>{analyzeTrendQuery?.data?.data?.relevance_summary || 'No data available'}</p>
                )}
                <button
                  data-testid='strategic-insight-item-copy-button'
                  onClick={() => {
                    navigator.clipboard.writeText(
                      analyzeTrendQuery?.data?.data?.relevance_summary || 'No data available'
                    )
                    toast.success('Copied to clipboard')
                  }}
                >
                  <DocumentDuplicateIcon
                    className="h-6 w-6 text-black mt-5"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div
                data-testid="strategic-insights-item"
                className="rounded-md border border-gray-300 bg-[#fafafa] p-4 mt-5"
              >
                <h3
                  data-testid="strategic-insights-item-title"
                  className="text-lg text-black mb-3"
                >
                  What are the key insights of this trend?
                </h3>
                {analyzeTrendQuery?.isLoading ? (
                  <Placeholder.Paragraph rows={2} />
                ) : (
                  <p data-testid="strategic-insight-item-description">
                    {analyzeTrendQuery?.data?.data?.actionable_insight || 'No data available'}
                  </p>
                )}
                <button
                  data-testid="strategic-insight-item-copy-button"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      analyzeTrendQuery?.data?.data?.actionable_insight || 'No data available'
                    )
                    toast.success('Copied to clipboard')
                  }}
                >
                  <DocumentDuplicateIcon
                    className="h-6 w-6 text-black mt-5"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div data-testid="strategic-insights-item" className="rounded-md border border-gray-300 bg-[#fafafa] p-4 mt-5">
                <h3
                  data-testid="strategic-insights-item-title"
                  className="text-lg text-black mb-3"
                >
                  What can you do to act on this trend?
                </h3>
                {analyzeTrendQuery?.isLoading ? (
                  <Placeholder.Paragraph rows={2} />
                ) : (
                  <p data-testid="strategic-insight-item-description" >{analyzeTrendQuery?.data?.data?.marketing_action_suggestion || 'No data available'}</p>
                )}
                <button 
                  data-testid="strategic-insight-item-copy-button"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      analyzeTrendQuery?.data?.data?.marketing_action_suggestion || 'No data available'
                    )
                    toast.success('Copied to clipboard')
                  }}
                >
                  <DocumentDuplicateIcon
                    className="h-6 w-6 text-black mt-5"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div data-testid="strategic-insights-item" className="rounded-md border border-gray-300 bg-[#fafafa] p-4 mt-5">
                <h3 data-testid="strategic-insights-item-title" className="text-lg text-black mb-3">What are the potential risks of acting on this trend?</h3>
                {analyzeTrendQuery?.isLoading ? (
                  <Placeholder.Paragraph rows={2} />
                ) : (
                  <p data-testid="strategic-insight-item-description">{analyzeTrendQuery?.data?.data?.potential_risks || 'No data available'}</p>
                )}
                <button 
                  data-testid="strategic-insight-copy-item-button"
                  onClick={() => {
                    navigator.clipboard.writeText(analyzeTrendQuery?.data?.data?.potential_risks || 'No data available')
                    toast.success('Copied to clipboard')
                  }}
                >
                  <DocumentDuplicateIcon
                    className="h-6 w-6 text-black mt-5"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideOver>
    </div>
  )
}

export default AnalyzeSlideover
