import React, { useState } from 'react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import Chip, { ChipSize } from '@/views/Discover/Boards/components/conversations/Chip';
import { IBrand, IPeople } from '@/views/Trends/types';
import { delimitNumber } from '@/utils';

interface IDetailOverviewProps {
  overviewText: string
  overviewBullets: string[]
  totalMoments: number
  totalNarratives: number
  totalArticles: number
  totalSocialPosts: number
  sentimentScore: number
  sentimentType: string
  topBrands: IBrand[]
  topPeople: IPeople[]
}

const DetailOverview: React.FC<IDetailOverviewProps> = ({
  overviewText,
  overviewBullets,
  totalMoments,
  totalNarratives,
  totalArticles,
  totalSocialPosts,
  sentimentScore,
  sentimentType,
  topBrands,
  topPeople
}) => {
  const [brandsToShow, setBrandsToShow] = useState(4)
  const [peopleToShow, setPeopleToShow] = useState(4)

  const hasMoreBrands = brandsToShow < topBrands.length
  const hasMorePeople = peopleToShow < topPeople.length

  const handleViewMore = () => {
    setBrandsToShow(topBrands.length)
  }

  const handleViewLess = () => {
    setBrandsToShow(4)
  }

  const handleViewMorePeople = () => {
    setPeopleToShow(topPeople.length)
  }

  const handleViewLessPeople = () => {
    setPeopleToShow(4)
  }

  return (
    <div className='h-full flex flex-col divide-y justify-between'>
      <div className='h-full flex flex-col gap-5'>
        <div>
          <p className="text-xl font-bold">Overview</p>
          <div className="mt-3 text-base" data-testid="details-overview">{overviewText}</div>
        </div>
        <div className="px-4">
          <ul className="list-disc list-inside space-y-2">
            {overviewBullets.map((bullet, index) => (
              <li key={index} className="text-base">
                <span className='-ml-[10px]'>{bullet}</span>
              </li>
            ))}
          </ul>

        </div>
      </div>
      <div className="flex flex-col gap-10 pt-6 mb-6">
        <div className='flex flex-row gap-10'>
          <div className="flex flex-col shrink-0 grow w-40">
            <div className="text-lg font-bold">Total Moments</div>
            <span className="text-xl font-semibold leading-[35px] text-purple-600" data-testid="total-moments">{delimitNumber(totalMoments)}</span>
          </div>
          <div className="flex flex-col shrink-0 grow w-40">
            <div className="text-lg font-bold">Total Narratives</div>
            <span className="text-xl font-semibold leading-[35px] text-purple-600" data-testid="total-narratives">{delimitNumber(totalNarratives)}</span>
          </div>
        </div>
        <div className='flex flex-row gap-10'>
          <div className="flex flex-col shrink-0 grow w-40">
            <div className="text-lg font-bold">Total Articles</div>
            <span className="text-xl font-semibold leading-[35px] text-purple-600" data-testid="total-articles">{delimitNumber(totalArticles)}</span>
          </div>
          <div className="flex flex-col shrink-0 grow w-40">
            <div className="text-lg font-bold">Total Social Posts</div>
            <span className="text-xl font-semibold leading-[35px] text-purple-600" data-testid="total-social-posts">{delimitNumber(totalSocialPosts)}</span>
          </div>
          {/* To do: reinstate sentiment when data is available
          <div className="flex flex-col shrink-0 grow w-40">
            <div className="text-lg font-bold">Sentiment Score</div>
            <div className="flex flex-row gap-2">
              <span className="text-xl font-semibold leading-[35px]" data-testid="sentiment-score">{sentimentScore}%</span>
              <span className="text-xl font-semibold leading-[35px] text-lime-500" data-testid="sentiment-type">{sentimentType}</span>
            </div>
          </div> */}
        </div>
      </div>

      {(topPeople.length > 0 || topBrands.length > 0) && (
        <div className="flex flex-row mb-4 gap-16 mt-5 pt-6">
          <div className="flex flex-col gap-2 w-80">
            <div className="text-lg font-bold">Top Brands</div>
            <div
              data-testid="top-brands"
              className="flex flex-row flex-wrap gap-2"
            >
              {topBrands.slice(0, brandsToShow).map((brand: IBrand, index: number) => (
                <Chip
                  key={index}
                  dataTestId={`top-brands-item`}
                  text={brand.name}
                  size={ChipSize.MEDIUM}
                />
              ))}
              {topBrands.length < 1 && <p className="italic">No top brands</p>}

              {hasMoreBrands && (
                <Chip
                  key="view-more"
                  dataTestId="top-brands-item-view-more"
                  text={`View ${topBrands.length - brandsToShow} more`}
                  size={ChipSize.MEDIUM}
                  onClick={handleViewMore}
                  pointer="cursor-pointer"
                />
              )}

              {!hasMoreBrands && topBrands.length !== 0 && (
                <Chip
                  key="view-less"
                  dataTestId="top-brands-item-view-less"
                  text={`View less`}
                  size={ChipSize.MEDIUM}
                  onClick={handleViewLess}
                  pointer="cursor-pointer"
                />
              )}
            </div>
          </div>

          <div className="flex flex-col gap-2 w-80">
            <div className="text-lg font-bold">Top People</div>
            <div
              data-testid="top-people"
              className="flex flex-row flex-wrap gap-2"
            >
              {topPeople.slice(0, peopleToShow).map((people, index) => (
                <Chip
                  key={index}
                  dataTestId={`top-people-item`}
                  text={people.name}
                  size={ChipSize.MEDIUM}
                  textColor="text-blue-400"
                  bgColor="bg-blue-100"
                  borderColor="border-blue-400"
                />
              ))}

              {topPeople.length < 1 && <p className="italic">No top people</p>}

              {hasMorePeople && (
                <Chip
                  key="view-more"
                  dataTestId="top-people-item-view-more"
                  text={`View ${topPeople.length - peopleToShow} more`}
                  size={ChipSize.MEDIUM}
                  onClick={handleViewMorePeople}
                  textColor="text-blue-400"
                  bgColor="bg-blue-100"
                  borderColor="border-blue-400"
                  pointer="cursor-pointer"
                />
              )}

              {!hasMorePeople && topPeople.length !== 0 && (
                <Chip
                  key="view-less"
                  dataTestId="top-people-item-view-less"
                  text={`View less`}
                  size={ChipSize.MEDIUM}
                  onClick={handleViewLessPeople}
                  pointer="cursor-pointer"
                  textColor="text-blue-400"
                  bgColor="bg-blue-100"
                  borderColor="border-blue-400"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DetailOverview
