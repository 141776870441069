import { listBuilderAxios } from "@/axiosInstances"
import { AnalyzeTrendParams, IArticle, IBrand, IMoment, ITimeline, INarrative, IPeople, ITrend, ITrendMetric, articleSchema, brandsSchema, momentSchema, timelineSchema, narrativeSchema, peopleSchema, socialPostArraySchema, trendMetricSchema, graphDataTrendSchema, IBubbleTrendGraph, ISanKeyGraph, graphDataSchema } from "@/views/Trends/types"

export const trends = {
    get: async (search?: string, signal?: AbortSignal) => {
      const url = `/trend-analysis/trends${search ? `?prompt=${search}` : ''}`;
      return listBuilderAxios.get<ITrend[]>(url, { signal });
    },

    getById: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}`
      return listBuilderAxios.get<ITrend>(url)
    },

    getPeople: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}/people`
      const result = await listBuilderAxios.get<IPeople[]>(url)
      return result.data.map(person => peopleSchema.parse(person))
    },

    getBrands: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}/brands`
      const result = await listBuilderAxios.get<IBrand[]>(url)
      return result.data.map(brand => brandsSchema.parse(brand)) },

    getNarratives: async (trendId: string, search?: string, page?: number, pageSize?: number) => {
      let url = `/trend-analysis/trends/${trendId}/narratives`
      if (page !== undefined && pageSize !== undefined) {
        url += `?page=${page}&pageSize=${pageSize}`
      }
      if (search !== undefined) {
        url += `&search=${encodeURIComponent(search)}`
      }
      const result = await listBuilderAxios.get<{ data: INarrative[], totalItems: number, totalPages: number }>(url)

      return {
        data: result.data.data.map(narrative => narrativeSchema.parse(narrative)),
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages
      }
    },

    getMoments: async (trendId: string, search?: string, page?: number, pageSize?: number) => {
      let url = `/trend-analysis/trends/${trendId}/moments`
      if (search || page || pageSize) {
        const queryParams = [];
      
        if (search) queryParams.push(`search=${encodeURIComponent(search)}`);
        if (page && pageSize) {
          queryParams.push(`page=${page}`);
          queryParams.push(`pageSize=${pageSize}`);
        }
      
        url += '?' + queryParams.join('&');
      }
      const result = await listBuilderAxios.get<{ data: IMoment[], totalItems: number, totalPages: number }>(url)
      
      return {
        data: result.data.data.map(moment => momentSchema.parse(moment)),
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages
      }
    },

    getArticles: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}/moment-articles`
      const result = await listBuilderAxios.get<IArticle[]>(url)
      return result.data.map(article => articleSchema.parse(article))
    },
    
    getBubbleTrendData: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}/graph`
      const result = await listBuilderAxios.get<IBubbleTrendGraph>(url)
      return graphDataTrendSchema.parse(result.data)
    },

    getSankeyData: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}/sankey`
      const result = await listBuilderAxios.get<ISanKeyGraph>(url)
      return graphDataSchema.parse(result.data)
    },

    getMomentTimeline: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}/cluster-timeline`
      const result = await listBuilderAxios.get<ITimeline>(url)
      return timelineSchema.parse(result.data)
    },

    getSocialPosts: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}/narrative-posts`
      const result = await listBuilderAxios.get(url)
      return socialPostArraySchema.parse(result.data)
    },

    getArticlesMetrics: async (trendId: string) => {
      const url = `/trends/${trendId}/metrics?type=articles`
      const result = await listBuilderAxios.get<ITrendMetric[]>(url)
      return result.data.map(trendMetric => trendMetricSchema.parse(trendMetric))
    },

    getSentimentScore: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}/sentiment-score`
      const result = await listBuilderAxios.get<Number>(url)
      return result.data;
    },

    analyzeTrend: async (params: AnalyzeTrendParams) => {
      const url = `/analysis/analyze`
      return listBuilderAxios.post(url, params)
    }
}