declare type EnvironmentConfig = {
    environment: string
    api: {
        userAccountUrl: string
        listBuilderUrl: string
    }
    featureFlag: {
        signup: boolean,
        socialListening: boolean,
        aiProfiles: boolean,
    }
}

const local: EnvironmentConfig = {
    environment: 'local',
    api: {
        userAccountUrl: 'http://localhost:4000',
        listBuilderUrl: 'http://localhost:4200'
    },
    featureFlag: {
        signup: true,
        socialListening: true,
        aiProfiles: true,
    }
}

const staging: EnvironmentConfig = {
    environment: 'staging',
    api: {
        userAccountUrl: 'https://api-cont-intell-user-account-staging.sightly.com',
        listBuilderUrl: 'https://api-cont-intell-list-builder-staging.sightly.com'
    },
    featureFlag: {
        signup: true,
        socialListening: true,
        aiProfiles: true,
    }
}

const development: EnvironmentConfig = {
    environment: 'development',
    api: {
        userAccountUrl: 'https://api-cont-intell-user-account-dev.sightly.com',
        listBuilderUrl: 'https://api-cont-intell-list-builder-dev.sightly.com'
    },
    featureFlag: {
        signup: true,
        socialListening: true,
        aiProfiles: true,
    }
}

const qa1: EnvironmentConfig = {
    environment: 'qa1',
    api: {
        userAccountUrl: 'https://api-cont-intell-user-account-qa1.sightly.com',
        listBuilderUrl: 'https://api-cont-intell-list-builder-qa1.sightly.com'
    },
    featureFlag: {
        signup: true,
        socialListening: true,
        aiProfiles: true,
    }
}

const production: EnvironmentConfig = {
    environment: 'production',
    api: {
        userAccountUrl: 'https://api-cont-intell-user-account-prod-zu7blp7gxa-uw.a.run.app',
        listBuilderUrl: 'https://api-cont-intell-list-builder-prod-zu7blp7gxa-uw.a.run.app'
    },
    featureFlag: {
        signup: false,
        socialListening: false,
        aiProfiles: true,
    }
}
var config: EnvironmentConfig = production
switch (process.env.REACT_APP_STAGE) {
    case 'local':
        config = local
        break
    case 'staging':
        config = staging
        break
    case 'development':
        config = development
        break
    case 'qa1':
        config = qa1
        break
    default:
        config = production
}

/*--------------------------------------------------------------------*
 * Introduce the ability to configure the service urls through using  *
 * environment variables if the application has NOT been compiled for *
 * production.                                                        *
 *--------------------------------------------------------------------*/

if (process.env.REACT_APP_USER_ACCOUNT_URL && process.env.REACT_APP_LIST_BUILDER_URL) {
    config.api.listBuilderUrl=process.env.REACT_APP_LIST_BUILDER_URL
    config.api.userAccountUrl=process.env.REACT_APP_USER_ACCOUNT_URL
}


declare type FullEnvironmentConfig = EnvironmentConfig & {
    listbuilderIncrement: number
}

const listbuilderIncrement = 10

const fullConfig: FullEnvironmentConfig = {
    listbuilderIncrement,
    ...config
}

export default fullConfig
