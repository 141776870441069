import SearchBox from '@/components/SearchBox';
import DateRangeSection from '@/components/Sightly/SightlyFormElements/DateRangeSection';
import { ISocialPost } from '@/views/Trends/types';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Carousel from './Carousel';
import SocialPostResultCard from './SocialPostResultCard';
import { getAllTime, ranges } from '@/views/Trends/Utils/Ranges';

interface SocialPostListProps {
  socialPosts: ISocialPost[]
  searchKeyword: string;
  setSearchKeyword: (keyword: string) => void 
}

const SocialPostResultsList = ({ socialPosts, searchKeyword, setSearchKeyword }: SocialPostListProps) => {
  const [filteredSocialPosts, setFilteredSocialPosts] = useState(socialPosts);

  useEffect(() => {
    const lowerKeyword = searchKeyword.toLowerCase();
    const newFilteredSocialPosts = socialPosts.filter((socialPost) => 
      socialPost.post.toLowerCase().includes(lowerKeyword.toLowerCase())
    );
    setFilteredSocialPosts(newFilteredSocialPosts);
  }, [socialPosts, searchKeyword]);

  const handleSearch = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const sourceTypes = filteredSocialPosts
    .map((socialPost) => socialPost.source)
    .flatMap((sourceType) => (Array.isArray(sourceType) ? sourceType : [sourceType]))
    .filter((sourceType, index, arr) => arr.indexOf(sourceType) === index);

  const startingRange = getAllTime();

  const [startingDateObj, setStartingDateObj] = React.useState<Date>(startingRange[0]);
  const [endingDateObj, setEndingDateObj] = React.useState<Date>(startingRange[1]);

  const handleChangeDateRange = (selectedRange: any) => {
    setStartingDateObj(selectedRange[0]);
    setEndingDateObj(selectedRange[1]);

    // Filter when range is updated
    // set filtered socialPosts by how many are within the date range
    const newFilteredArticles = socialPosts.filter((socialPost) => {
      const timestamp = dayjs(socialPost.publishedDate);
      const startTime = dayjs(selectedRange[0]);
      const endTime = dayjs(selectedRange[1]);
      return timestamp.isAfter(startTime) && timestamp.isBefore(endTime);
    });

    setFilteredSocialPosts(newFilteredArticles);
  };

  return (
    <div>
      <div className="w-full flex justify-between">
        <div>
          <SearchBox
            dataTestId="social-post-result-list-search-box"
            handleSearch={handleSearch}
            placeholder="Search Social Posts"
            className='mb-3'
            showClearButton={true}
            value={searchKeyword}
          />
        </div>

        <div className="flex items-center">
          <DateRangeSection
            onSelectDateRange={handleChangeDateRange}
            rangeValue={[startingDateObj, endingDateObj]}
            data-testid="social-post-date-range"
            selectableRanges={ranges}
          />
        </div>
      </div>
      <div className="w-full flex justify-end py-2" data-testid="social-post-result-list-count">
        Showing {filteredSocialPosts.length} of {socialPosts.length}
      </div>
      {sourceTypes.map((sourceType, index) => {
        const filteredSocialPostsForSourceType = filteredSocialPosts.filter((socialPost) =>
          socialPost.source === sourceType
        );
        const cards = filteredSocialPostsForSourceType.map((post) => (
          <SocialPostResultCard key={post.id} socialPost={post} />
        ));
        return (
          <div
            key={`source-type-map-key-${index}`}
            className="flex flex-col mt-4"
            data-testid={`source-type-container-${sourceType}`}
          >
            <h3 className="mb-2" data-testid={`source-type-header-${sourceType}`}>
              {sourceType}
            </h3>
            <div data-testid={`source-type-posts-${sourceType}`}>
              <Carousel items={cards} itemsPerSlide={4} />
            </div>
          </div>
        );

      })}
    </div>
  )
};

export default SocialPostResultsList;