import React, { useState } from 'react'
import { useNavigate, useSearch } from '@tanstack/react-location'
import { useQuery } from "@tanstack/react-query";
import { MyLocationGenerics } from "@/classes/utils";
import SightlyButton from '../../components/Sightly/SightlyButton'
import { activationReportController } from '@/views/ActivationReports/controller/ActivationReportController';
import { routes } from '@/routes';
import { ReactComponent as ReportingImage } from '../../assets/img/reporting-tool.svg'
import { ReportViewTable } from '@/views/ActivationReports/components/ReportViewTable';
import { LoadingComponent } from '@/components/LoadingComponent';


export const ActivationReportMainView = () => {
    const navigate = useNavigate()
    const { accountId } = useSearch<MyLocationGenerics>()

    const page = 1
    const pageSize = 20

    const orderBy = 'updatedAt';
    const sortBy = 'desc';
    const enableScheduleReport = true;
    

    const {
        data: tableData
    } = useQuery(['getReportConfigurations', accountId, page, pageSize, orderBy, sortBy, enableScheduleReport], activationReportController.getReportConfigurations)
    const hasTableData = tableData != undefined ? tableData['results'].length : 0

    return (
        <>
            <div className="flex justify-between m-8 pb-0" data-testid="activation-reports-table">

                <div className="sightlyPanelHeader" data-testid="activation-reports-table-header">
                    <div className="flex flex-row">
                        Activation Reports
                    </div>
                </div>

                {tableData === undefined ? (
                    null
                ) :

                    hasTableData ? (
                        <div>
                            <SightlyButton
                                datatestid={'button-report-generator-create-new-report'}
                                id="createReportButton"
                                text="New Report"
                                type='purple'
                                disabled={false}
                                handleClick={() => {
                                    navigate({
                                        to: routes.app.activationReport.new.path,
                                    })
                                }}
                            />
                        </div>
                    ) : null}

            </div>

            {tableData === undefined ? (
                <div className="flex justify-center items-center h-52">
                    <LoadingComponent message="Loading Activations Reports" />
                </div>
            ) :
                hasTableData
                    ?
                    <div className="mx-8" data-testid="activation-reports-table-component">
                        <ReportViewTable tableData={tableData} accountId={accountId!} />
                    </div>
                    :
                    <div className="w-full flex items-center flex-col" data-testid="activation-reports-table-create">
                        <div className="flex items-center flex-col pt-32">
                            <div className="p-4">
                                <ReportingImage />
                            </div>
                            <p>
                                <b>No reports</b>
                            </p>
                            <p>Get started by creating a new report</p>
                            <div className="p-4">
                                <SightlyButton
                                    datatestid={'button-report-generator-new-report'}
                                    id="createReportGenerator"
                                    text="+ New Report"
                                    disabled={false}
                                    type='purple'
                                    handleClick={() => {
                                        navigate({
                                            to: routes.app.activationReport.new.path,
                                            search: (search) => ({ accountId: search?.accountId })
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>

            }
        </>
    )
}
