import { formatDateToFullMonthDayYear } from '@/utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import React, { useEffect } from 'react'
import { DatePicker, RadioGroup, Radio, SelectPicker } from 'rsuite'
import { TIMEZONE_OPTIONS } from '@/views/ReportGenerator/constants'
import TimepickerComponent from '../../../components/TimepickerComponent';


dayjs.extend(utc);
dayjs.extend(timezone);

function ScheduleDateTime(props: { 
  startDate: Date,
  setStartDate: React.Dispatch<React.SetStateAction<Date>>,
  selectedTimezone: string,
  setSelectedTimezone: React.Dispatch<React.SetStateAction<string>>,
  meridian: 'AM' | 'PM',
  setMeridian: React.Dispatch<React.SetStateAction<'AM' | 'PM'>>
}) {
  const { startDate, setStartDate, selectedTimezone, setSelectedTimezone, meridian, setMeridian } = props

  useEffect(() => {
  const updatedDate = new Date(startDate);
  if (meridian === 'PM' && updatedDate.getHours() < 12) {
    updatedDate.setHours(updatedDate.getHours() + 12);
  } else if (meridian === 'AM' && updatedDate.getHours() >= 12) {
    updatedDate.setHours(updatedDate.getHours() - 12);
  }
  setStartDate(updatedDate);
  }, [meridian])

  const utcToTimezone = (utcDate: Date): Date => {
    return dayjs.tz(utcDate, selectedTimezone).toDate();
  };

  const handleDateRangeChange = (date: Date) => {
    const selectedDate = dayjs.utc(date).toDate();
    selectedDate.setHours(startDate.getHours());
    selectedDate.setMinutes(startDate.getMinutes());
    selectedDate.setSeconds(startDate.getSeconds());
    
    setStartDate(selectedDate)
  }

  const handleTimeChange = (time: Date) => {
    const updatedDate = dayjs(startDate)
        .hour(time.getHours())
        .minute(time.getMinutes())
        .second(time.getSeconds())
        .tz(selectedTimezone)
        .toDate();

    updatedDate.setDate(startDate.getDate());
    updatedDate.setMonth(startDate.getMonth());
    updatedDate.setFullYear(startDate.getFullYear());

    setStartDate(updatedDate);
    
    const newMeridian = updatedDate.getHours() >= 12 ? 'PM' : 'AM';
    setMeridian(newMeridian);
  }

  const handleTimezoneChange = (newTimezone: string) => {
    setSelectedTimezone(newTimezone);
  }

  return (
    <div
      className="mb-9 sm:px-1 text-grey-900 w-full"
      data-testid="schedule-date-time"
    >
      <div className="mb-2 font-medium" id="pendo-act-mgr-assignees-filter">
        Start Date and Time
      </div>
      <div
        className="flex flex-row items-center gap-2 h-10"
        data-testid="date-time-container"
      >
        <div className="h-10">
          <DatePicker
            value={utcToTimezone(startDate)}
            onChange={handleDateRangeChange}
            placeholder="Select Date"
            placement="autoVerticalStart"
            renderValue={(value) => formatDateToFullMonthDayYear(value)}
            cleanable={false}
            className="mr-2 w-60"
            showMeridian
            data-testid="date-picker"
            ranges={[]}
            oneTap
          />
        </div>

        <TimepickerComponent 
          value={startDate}
          handleTimeChange={handleTimeChange}
        />

        <SelectPicker
          className="w-full ml-2"
          cleanable={false}
          data={TIMEZONE_OPTIONS}
          placeholder="Select Timezone"
          placement="autoVerticalStart"
          onChange={handleTimezoneChange}
          value={selectedTimezone}
          data-testid="timezone-picker"
        />
      </div>
    </div>
  )
}

export default ScheduleDateTime