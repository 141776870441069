import DateRangeSection from '@/components/Sightly/SightlyFormElements/DateRangeSection';
import { PATH_TRENDS_SEARCH } from '@/routes';
import { formatDateyyyyMMdd } from '@/utils';
import { getLast60Days } from '@/utils/dateRanges';
import CategoryFilter from '@/views/Trends/components/CategoryFilter';
import SearchBar from '@/views/Trends/components/SearchBar';
import TopicTrendCardList from '@/views/Trends/components/TopicTrendCardList';
import { useTopic } from '@/views/Trends/hooks/useTopics';
import { useTrend } from '@/views/Trends/hooks/useTrends';
import { ICategory, TopicTrendEnum, allCategories, categories } from '@/views/Trends/types';
import { useNavigate, useLocation } from '@tanstack/react-location';
import { useFlag } from '@unleash/proxy-client-react';
import { subDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Placeholder, RadioGroup } from 'rsuite';
import { capitalizeFirstLetter } from '@/utils';

const Trends = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const trendsEnabled = useFlag('enable_trends_feature');
  const topicsEnabled = useFlag('enable_explore_topics');
  if (!trendsEnabled) navigate({ to: '/app/discover/moments/v2' });

  const [category, setCategory] = useState<ICategory>(allCategories);
  const [filteringEnabled, setFilteringEnabled] = useState(false);
  const [cardType, setCardType] = useState<TopicTrendEnum>(TopicTrendEnum.Trend);
  const [dateRange, setDateRange] = useState(getLast60Days());
  const [availableTypes, setAvailableTypes] = useState<string[]>(['trend']) 

  const endDate = dateRange ? dateRange[1] : new Date();
  const startDate = dateRange ? dateRange[0] : subDays(endDate, 60);

  const { topicsData, isLoadingTopics } = useTopic();
  const { trendsData, isLoadingTrends } = useTrend();

  useEffect(() => {
    if (topicsEnabled) setAvailableTypes(['topic', 'trend']);
  }, [topicsEnabled])

  useEffect(() => {
    const params = new URLSearchParams(location.current.searchStr);

    const categoryId = params.get('category');
    const cardTypeParam = params.get('cardType');
    const startDateParam = params.get('startDate');
    const endDateParam = params.get('endDate');

    if (categoryId) {
      const selectedCategory = categories.find(cat => cat.id.toString() === categoryId) || allCategories;
      setCategory(selectedCategory);
    }

    if (cardTypeParam) {
      setCardType(cardTypeParam as TopicTrendEnum);
    }

    if (startDateParam && endDateParam) {

      const [startYear, startMonth, startDay] = startDateParam.split('-').map(Number)
      const [endYear, endMonth, endDay] = endDateParam.split('-').map(Number)

      const startDate = new Date(startYear, startMonth - 1, startDay)
      const endDate = new Date(endYear, endMonth - 1, endDay)

      setDateRange([startDate, endDate]);
    }

  }, []);

  useEffect(() => {
    const topicsLoaded = cardType === TopicTrendEnum.Topic && !isLoadingTopics;
    const trendsLoaded = cardType === TopicTrendEnum.Trend && !isLoadingTrends;
    if (topicsLoaded || trendsLoaded) {
      setFilteringEnabled(true);
    }
  }, [cardType, isLoadingTopics, isLoadingTrends]);

  const handleSearch = (searchTerm: string) => {
    navigate({
      to: PATH_TRENDS_SEARCH,
      search: (prev) => ({ ...prev, accountId: prev!.accountId, searchTerm })
    });
  };

  const handleCategorySelect = (selectedCategory: ICategory) => {
    const setCondition = topicsEnabled
      ? !(isLoadingTopics && isLoadingTrends)
      : !isLoadingTrends;
    if (setCondition) {
      setCategory(selectedCategory);
      updateUrl(selectedCategory, cardType, dateRange);
    }
  };

  const handleChangeCardsType = (selectedCardType: TopicTrendEnum) => {
    if (selectedCardType === cardType) return;
    setCardType(selectedCardType);
    updateUrl(category, selectedCardType, dateRange);
  };

  const handleChangeDateRange = (selectedRange: [Date, Date]) => {
    if (selectedRange === dateRange) return;
    setDateRange(selectedRange);
    updateUrl(category, cardType, selectedRange);
  };

  const updateUrl = (category: ICategory, cardType: TopicTrendEnum, dateRange: [Date, Date]) => {
    const params: Record<string, string> = {};

  if (category) params.category = String(category.id);
  if (cardType) params.cardType = String(cardType);
  if (dateRange) {
    const startDate = formatDateyyyyMMdd(dateRange[0])
    const endDate = formatDateyyyyMMdd(dateRange[1])
    params.startDate = startDate;
    params.endDate = endDate;
  }

    navigate({
      to: window.location.pathname,
      search: params,
    });
  };

  return (
    <div className="p-2 h-full bg-white">
      <div className="flex flex-col h-full">
        <SearchBar
          label={'Explore what’s trending, now'}
          height={'20vh'}
          onSearch={handleSearch}
        />
        <div className="h-full overflow-hidden flex pt-4">
          <div className="w-1/5 h-full">
            {categories ? (
              <CategoryFilter
                defaultCategory={category}
                categories={categories}
                onCategorySelect={handleCategorySelect}
                disabled={!filteringEnabled}
              />
            ) : (
              <Placeholder.Graph
                className="h-[100%!important] w-[100%!important]"
                active
              />
            )}
          </div>
          <div className="w-4/5 pt-4 px-4 bg-gray-50 flex flex-col gap-4">
            <div className="flex flex-row gap-[2vw] justify-between items-center">
              <RadioGroup name="radio-group-inline" inline defaultValue={cardType}>
                <div className="ml-2 flex gap-4">
                {[...topicsEnabled ? [TopicTrendEnum.Topic] : [], TopicTrendEnum.Trend].map((value) => (
                  <div
                    data-testid={`trend-topic-overview-select-${value}`}
                    key={`trend-topic-overview-select-${value}`}
                    className={`rounded-full py-3 px-5 cursor-pointer ${
                      cardType === value ? 'text-white bg-sightlyPurple' : 'text-gray-400 bg-gray-200 hover:text-gray-500 hover:bg-gray-300'
                    }`}
                    onClick={() => handleChangeCardsType(value)}
                  >
                    <span className="font-extrabold">{capitalizeFirstLetter(value)}</span>
                  </div>
                ))}
                </div>
              </RadioGroup>
              <span data-testid="topic-trend-overview-date-range-picker-wrapper">
                <DateRangeSection
                  onSelectDateRange={handleChangeDateRange}
                  rangeValue={dateRange}
                />
              </span>
            </div>
            <div className="overflow-y-auto">
              {category && (
                cardType === TopicTrendEnum.Topic ? (
                  <TopicTrendCardList
                    cardType={TopicTrendEnum.Topic}
                    data={topicsData}
                    isLoading={isLoadingTopics}
                    category={category}
                    startDate={startDate}
                    endDate={endDate}
                  />
                ) : (
                  <TopicTrendCardList
                    cardType={TopicTrendEnum.Trend}
                    data={trendsData}
                    isLoading={isLoadingTrends}
                    category={category}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trends;

