import { useQuery } from "@tanstack/react-query";
import { api } from "@/views/Discover/Moments/v2/api";
import { logError } from "@/utils";
import { ClusterType, MomentActionsEnum } from "@/views/Discover/Moments/v2/types";
import { rqKeys } from "@/views/Discover/Moments/v2/rqKeys";

interface IProps {
    brandProfileId?: number
    moment: ClusterType,
}

interface IPaginatedProps extends IProps {
    page?: number
    limit?: number
}

interface ScenariosIProps extends IProps {
    setHasUnsavedChanges: Function
}

export const useArticles = ({brandProfileId, moment, page = 0, limit = 5}: IPaginatedProps) => {
    const { data, isLoading, error, refetch } = useQuery(
        ['momentArticles', { brandProfileId, moment, page }],
        async () => {
            if (moment.clusterId === -1) return;
            try {
                return api.moments.articlesPaginated.get({ brandProfileId, clusterId: moment.clusterId, page, limit });
            } catch (err) {
                console.error('Error fetching articles:', err);
                throw err;
            }
        },
        {
            enabled: !!brandProfileId,
        }
    );

    return {
        data: data?.articles || [],
        total: data?.total,
        count: data?.count || 0,
        limit,
        isLoading,
        error,
        refetch
    }
};

export const useKeywords = ({brandProfileId, moment}: IProps) => {
    const getRecommendedActionText = (action:string):MomentActionsEnum | undefined => {
        const actionLowerCase = action.toLowerCase()
        if (['No Action', 'no action','noaction','no_action','dont monitor','dont_monitor'].includes(actionLowerCase)) return 'noaction'
        if(actionLowerCase === 'target') return 'target'
        if(actionLowerCase === 'block') return 'block'
        if(actionLowerCase === 'monitor') return 'monitor'
        return
    }

    return useQuery(
        rqKeys.momentKeywordsKey(brandProfileId, moment.clusterId),
        () => api.moments.keywords.get({ clusterId: moment.clusterId, brandProfileId, momentAction: getRecommendedActionText(moment.action)}),
        {
            enabled: !!brandProfileId,
            onError: (err) => {
                logError(err, { info: 'keywordsQuery' })
            }
        }
    )
}

export const useVideos = ({ brandProfileId, moment, page = 0, limit = 5 }: IPaginatedProps) => {
    const { data, isLoading, error, refetch } = useQuery(
        ['videos', { brandProfileId, moment, page }],
        async () => {
            try {
                return api.moments.paginatedVideos.get({ brandProfileId, clusterId: moment.clusterId, page, limit });
            } catch (err) {
                console.error('Error fetching videos:', err);
                throw err;
            }
        },
        {
            enabled: !!brandProfileId
        }
    );
    return {
        data: data?.videos || [],
        total: data?.total,
        count: data?.count || 0,
        limit,
        isLoading,
        error,
        refetch
    }
};


export const useScenarios = ({brandProfileId, moment, setHasUnsavedChanges}: ScenariosIProps) => {
    return useQuery(
        rqKeys.momentScenariosKey(brandProfileId, moment.clusterId),
        () => api.moments.scenarios.get({ clusterId: moment.clusterId, brandProfileId }),
        {
            enabled: !!brandProfileId,
            onError: (err) => {
                logError(err, { info: 'scenariosQuery' })
            },
            onSettled: () => {
                setHasUnsavedChanges(false)
            }

        }
    )
}

export const useScenarioOptions = ({brandProfileId, moment}: IProps) => {
    return useQuery(
        rqKeys.momentScenariosOptionsKey(brandProfileId, moment.clusterId),
        async () => {
            if (moment.clusterId === -1) return;
            return api.moments.scenariosOptions.get({ clusterId: moment.clusterId, brandProfileId })},
        {
            enabled: !!brandProfileId,
            onError: (err) => {
                logError(err, { info: 'scenariosOptionsQuery' })
            }
        }
    )
}
export const useActionJustifications = ({brandProfileId, moment}: IProps) => {
    return useQuery(
        rqKeys.momentActionJustificationsKey(brandProfileId, moment.clusterId),
        () => api.moments.actionJustification.get({ clusterId: moment.clusterId, brandProfileId }),
        {
            enabled: !!brandProfileId,
            onError: (err) => {
                logError(err, { info: 'actionJustificationsQuery' })
            }
        }
    )
}
