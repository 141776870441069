import React from 'react';
import { permissionView } from '@/utils';
import useUser from '@/hooks/useUser';
import { api } from '@/api/api';
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox';
import { MyProfileFormType, MyProfileFormTypeSchema } from '@/views/Settings/MyProfile/types';
import { useSightlyForm } from '@/components/Sightly/SightlyModern/Form/hooks/useSightlyForm';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ApiErrorResponse } from '@/api/apiTypes';
import { SightlyModernFormInput } from '@/components/Sightly/SightlyModern/Form/SightlyModernFormInput';
import { SightlyModernButton } from '@/components/Sightly/SightlyModern/SightlyModernButton';
import { WarningModalTailwind } from '@/components/WarningModalTailwind';
import { UserType } from '@/schemas/schemas';
import { SettingsLayout } from '@/layouts/SettingsLayout';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

export const MyProfile: React.FC = () => {
    const { user, perms, userPermissions } = useUser()
    const { sightlyRegister, handleSubmit, formState: { isValid }, trigger, watch, getFieldState } = useSightlyForm<MyProfileFormType>(MyProfileFormTypeSchema, {
        defaultValues: {
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
        }
    })
    const [showGenericErrorDialog, setShowGenericErrorDialog] = React.useState(false)

    const handleViewPermissionsModeClick = (checked: boolean) => {
        if (checked) {
            localStorage.setItem('permissionView', '1')
        } else {
            localStorage.removeItem('permissionView')
        }
        window.location.reload()
    }

    const mutation = useMutation(async (data: Partial<UserType>) => await api.user.updateUser({ ...user, ...data }), {
        onSuccess: (_) => {
        },
        onError: (_: AxiosError<ApiErrorResponse>) => {
            setShowGenericErrorDialog(true)
        }
    });

    const onSubmit = handleSubmit((data) => {
        mutation.mutate(data)
    });

    return (
        <SettingsLayout>
            <div className='flex flex-col justify-between w-full h-[90vh] p-[2vw]'>
                <div className='flex flex-col gap-[2.5vh]'>
                    <div className="w-full text-xl font-semibold text-[#333D47]">My Profile</div>
                    <form className='flex flex-col gap-[2vh]' onSubmit={onSubmit}>
                        <div className='flex justify-between gap-[2vw]'>
                            <SightlyModernFormInput {...sightlyRegister('firstName', 'First name')} />
                            <SightlyModernFormInput {...sightlyRegister('lastName', 'Last name')} />
                        </div>
                        <SightlyModernFormInput {...sightlyRegister('email', 'Work email address')} placeholder='you@example.com' />

                        <div>
                            <SightlyModernButton
                                id='submitButton'
                                type='primary'
                                handleClick={() => undefined}
                                text='Save'
                                disabled={mutation.isLoading || !isValid}
                            />
                        </div>

                        <WarningModalTailwind
                            open={showGenericErrorDialog}
                            setOpen={setShowGenericErrorDialog}
                            title='Something went wrong'
                            subtitle='Please try again, or contact support at support@sightly.com'
                        />
                    </form>
                </div>
                {userCanPermissionProductQuota({
                    requiredPermissionValue: perms.ADMIN_READ,
                    userPermissions: userPermissions,
                    checkType: PermissionCheckType.PERMISSION_CHECK
                }) && (
                        <div className='w-full flex justify-end'>
                            <SightlyCheckbox
                                id="viewPermissionsMode"
                                checked={permissionView}
                                handleChange={handleViewPermissionsModeClick}
                                label={'View permissions mode'}
                            />
                        </div>
                    )}
            </div>

        </SettingsLayout>
    )
}
