import { useNavigate, useSearch } from '@tanstack/react-location';
import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'rsuite';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

import Input from 'rsuite/lib/Input';
import { routes } from '@/routes';
import { MyLocationGenerics } from '@/classes/utils';
import { SegmentEstimateItem } from './components/SegmentEstimateItem';
import { SegmentEstimateDescription } from './components/SegmentEstimateDescription';
import { ExpandSection } from '@/views/Engage/Segments/components/ExpandSection';
import { SegmentConfiguration } from '@/views/Engage/Segments/types/SegmentsTypes';
import { segmentsController } from '@/views/Engage/Segments/controller/SegmentController';
import LoadingModal from '@/views/Engage/utils/LoadingModal';
import { InformationModal } from '@/views/Engage/utils/InformationModal';
import { IABCategories } from '@/views/Engage/Segments/components/IABCategories';
import { useQuery } from '@tanstack/react-query';
import { ReadFileKeywords } from '@/views/Engage/Segments/components/ReadFileKeywords';

export const CreateSegment = () => {

    const navigate = useNavigate()
    const { accountId } = useSearch<MyLocationGenerics>()
    
    const [formValues, setFormValues] = useState<SegmentConfiguration>({
        name: '',
        description: '',
        keywords:[],
        iabCategories: [],
    })
    const [segmentName, setSegmentName] = useState('New Segment Name');
    const [activeEditName, setActiveEditName] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [isCreatingSegment, setIsCreatingSegment] = useState(false)
    const [isErrorModalVisible, setIsInformationModalVisible] = useState(false);
    const [titleInfomationModal, setTitleInfomationModal] = useState('');
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const {
        data: iabCategories
    } = useQuery(['getIABCategories'], segmentsController.getIABCategories, {
        staleTime: 24 * 60 * 60 * 1000,
    });


    useEffect(() => {
        const parentDiv = document.querySelector('.sc-hRJfrW')
        if (parentDiv && parentDiv instanceof HTMLElement) {
          parentDiv.style.backgroundColor = '#F6FAFE'
        }
    
        return () => {
          if (parentDiv && parentDiv instanceof HTMLElement) {
            parentDiv.style.backgroundColor = 'white'
          }
        }
      }, []);

      useEffect(() => {
        if (activeEditName && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [activeEditName]);

    const handleOnSegmentNameChange = (val: string) => {
        setSegmentName(val);
        handleOnChangeFields('name', val)
    }

    const handleOnChangeFields = (field: string, value: any) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [field]: value
        }))
    }
    const handleBack = () => {
        navigate({
            to: routes.app.segments.viewSegments.path,
            search: (prev) => {
                return { accountId: prev!.accountId }
            }
        })
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            setActiveEditName(false); 
        }
    };

    const handleCreateSegment = async () => {
        const error = segmentsController.validateForm(formValues);
        if (error.length > 0) {
            showErrorModal('Error', error)
        } else {
            try {
                setIsCreatingSegment(true)
                await segmentsController.handleCreateSegment(formValues, accountId!)
                handleBack();
            } catch (error) {
                showErrorModal('Error', ['Error while creating the segment.'])
            } finally {
                setIsCreatingSegment(false)
            }
        }
    }

    const showErrorModal = (title: string, messages: string[]) => {
        setTitleInfomationModal(title);
        setErrorMessages(messages)
        setIsInformationModalVisible(true)
    }

    return (
        <div className='h-full relative flex flex-col bg-[#F6FAFE]' data-testid="create-segment-form">
            <div className='flex w-full h-[84px] bg-white py-4'>
                <div className='flex flex-row w-full items-center justify-between' data-testid="create-segment-form-header">
                    <div className='flex flex-row' data-testid="create-segment-form-segment-name">
                        {
                            activeEditName ? <Input
                                inputRef={inputRef}
                                className='ml-6 custom-input-editable-segment-name min-w-[30vw]'
                                id="segment-name"
                                defaultValue={segmentName}
                                onChange={handleOnSegmentNameChange}
                                placeholder="New Segment Name"
                                onBlur={() => setActiveEditName(false)} 
                                onKeyDown={handleKeyDown} 
                                style={{ borderColor: 'white' }}
                            />
                                : <div className='ml-6 mr-2 text-2xl font-medium overflow-hidden text-ellipsis whitespace-nowrap max-w-[40vw]'>
                                    {segmentName}
                                </div>
                        }
                        {
                            !activeEditName &&
                            <PencilSquareIcon className="w-6 inline-block mr-2 cursor-pointer" data-testid="edit-segment-name-icon" onClick={() => setActiveEditName(true)} />
                        }
                    </div>

                    <div className='flex flex-row mr-16' data-testid="segment-form-navigation-buttons">
                        <Button
                            id='cancel-segment-button'
                            data-testid={'cancel-segment-button'}
                            className='rounded-md w-[129px] h-[40px] mr-8'
                            appearance="primary"
                            style={{ backgroundColor: '#FFFFFF', color: '#7B61FF' }}
                            onClick={handleBack}
                        >
                            Cancel
                        </Button>
                        <Button
                            id='create-segment-button'
                            data-testid={'create-segment-button'}
                            className='rounded-md w-[129px] h-[40px]'
                            appearance="primary"
                            style={{ backgroundColor: '#7B61FF' }}
                            onClick={handleCreateSegment}
                            disabled={false}
                        >
                            Create Segment
                        </Button>

                    </div>
                </div>

            </div>
            <div className='flex flex-row w-full px-10 pt-9'>
                <div className='flex flex-col w-2/3 h-11 mr-14'>
                    <ExpandSection title='Keywords' description='Words to describe the segments' children={<ReadFileKeywords setKeywords={handleOnChangeFields} keywords={[]}/>}/>
                    <div className='mt-1'>
                        <ExpandSection title='Categories' description='Iab categories'>
                            <div className='flex flex-row w-full mt-6'>
                                <IABCategories formValues={formValues} handleOnChange={handleOnChangeFields} iabCategories={iabCategories ?? []}/>
                            </div>
                        </ExpandSection>
                    </div>
                </div>
                <div className='flex flex-col w-1/3 bg-white p-5'>
                    <div className='text-2xl font-medium ml-1'>
                        Segment Estimate
                    </div>
                    <div className='flex flex-row w-full mt-6'>
                        <div className='w-1/2'>
                            <SegmentEstimateItem title='Video Count' value='----' />
                        </div>
                        <div className='w-1/2'>
                            <SegmentEstimateItem title='Channel Count' value='----' />
                        </div>
                    </div>
                    <div className='flex w-full mt-9'>
                        <SegmentEstimateItem title='Total Engagements' value='----' />
                    </div>
                    <div className='flex w-full mt-9'>
                        <SegmentEstimateItem title='Totals Views' value='----' />
                    </div>
                    <div className='flex w-full mt-9'>
                        <SegmentEstimateItem title='Total Subscribers' value='----' />
                    </div>
                    <div className='flex w-full mt-9 border-t border-gray-200' />
                    <div className='flex w-full h-[160px]'>
                        <SegmentEstimateDescription title='Description' onChange={handleOnChangeFields} />
                    </div>
                </div>

            </div>
            <InformationModal title={titleInfomationModal} informationMessages={errorMessages} isInformatioModalVisible={isErrorModalVisible} setIsInformatioModalVisible={setIsInformationModalVisible} />
            <LoadingModal loading={isCreatingSegment} title='Creating Segment' />
        </div>
    )
}
