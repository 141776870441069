import { userAccountAxios } from "@/axiosInstances";
import { CreateSmartList, GetSmartListResponseSchema, SmartListSchema, UpdateSmartlist } from '@/views/Engage/SmartList/schemas/SmartlistSchema';




type GetSegmentsQueryKey = [string, number | undefined, number | undefined, number | undefined, string | undefined, string | undefined];


export const smartlistController = {

    validateForm: (formValues: CreateSmartList) => {
        let errorMessages: string[] = []

        if (formValues.name === '') {
            errorMessages.push('Name cannot be empty.')
        }
        if (formValues.objective === undefined) {
            errorMessages.push('Objective cannot be empty.')
        }
        if (formValues.contentTypes.length === 0) {
            errorMessages.push('Content Types cannot be empty.')
        }
        if (formValues.listType === undefined) {
            errorMessages.push('List Type cannot be empty.')
        }

        return errorMessages
    },

    handleCreateSmartList: async (formValues: CreateSmartList, accountId: number) => {
        const dataToSend = {
            accountId,
            brandProfileId: formValues.brandProfileId,
            name: formValues.name,
            objective: formValues.objective,
            contentTypes: formValues.contentTypes,
            listType: formValues.listType,
            segments: formValues.segments,
        }
        try {
            const url = `/v1/smart-lists/`

            const response = await userAccountAxios.post(url, dataToSend);

            const resultData = SmartListSchema.parse(response.data);

            return resultData;

        } catch (error) {
            console.error(error)
            throw error;

        }

    },

    getSmartLists: async ({ queryKey }: { queryKey: GetSegmentsQueryKey }) => {
        try {
            const [ , brandProfileId, page, pageSize, orderBy, sortBy] = queryKey;

            if (!brandProfileId) {
                return undefined
            }

            let url = `/v1/smart-lists/?brandProfileId=${brandProfileId}&page=${page}&pageSize=${pageSize}`;

            if (orderBy) url += `&orderBy=${orderBy}`
            if (sortBy) url += `&sort=${sortBy}`

            const { data } = await userAccountAxios.get(url);

            const resultData = GetSmartListResponseSchema.parse(data);

            return resultData;
        } catch (error) {
            console.error(error)
            throw error
        }
    },
    getSmartListById: async ( id :number ) => {
        try {
            const url = `/v1/smart-lists/${id}`

            const { data } = await userAccountAxios.get(url)



            return data

        } catch (error) {
            console.error(error)
            throw error
        }
    },

    updateSmartList: async (id: number, data: UpdateSmartlist) => {
        try {
            const url = `/v1/smart-lists/${id}`

            const response = await userAccountAxios.patch(url, data)

            return response

        } catch (error) {
            console.error(error)
            throw error
        }
    },

    handleRemoveSmartlist: async (id: number) => {
        try {
            const url = `/v1/smart-lists/${id}`

            const response = await userAccountAxios.delete(url)

            if (response.status == 204) {
                return true;
            }
            return false;
        } catch (error) {
            console.error(error)
            throw error
        }
    },

}

