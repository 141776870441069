import DateRangeSection from '@/components/Sightly/SightlyFormElements/DateRangeSection'
import { getLast60Days } from '@/utils/dateRanges'
import { SortBy } from '@/views/Trends/components/SortBy'
import { TrendsSearchResultSortBy, categories } from '@/views/Trends/types'
import React, { useState } from 'react'
import { SelectPicker } from 'rsuite'

interface IProps {
  activeTab: string
  category: number
  setCategory: (category: number) => void
  theme: string
  setTheme: (theme: string) => void
  themeOptions: { label: string; value: string}[]
  timeRange: [Date, Date]
  setTimeRange: (timeRange: [Date, Date]) => void
  sortBy: TrendsSearchResultSortBy
  setSortBy: (category: TrendsSearchResultSortBy) => void
}

export const TabsFilters = ({ activeTab, category, setCategory, theme, setTheme, themeOptions, timeRange, setTimeRange, sortBy, setSortBy }: IProps) => {
  
  const categoriesOptions = categories.map(c => (
    {
      label: c.name,
      value: c.id,
      codes: c.codes,
      exclude: c.exclude
    }
  ));

  const showCategories = activeTab !== 'narrative'

  return (
    <div className='flex flex-col lg:flex-row gap-4 lg:gap-8 justify-between'>
      <div className='flex gap-6'>
        <div className='flex gap-2 items-center'>
          <span className="text-sm">{showCategories ? 'Category' : 'Theme'}</span>
          <SelectPicker
            data={showCategories ? categoriesOptions : themeOptions}
            value={showCategories ? category : theme}
            onChange={showCategories ? setCategory : setTheme}
            searchable={true}
            cleanable={false}
            appearance='subtle'
            data-testid={showCategories ? 'category-picker' : 'theme-picker'}
          />
        </div>
        <div className='flex gap-2 items-center'>
          <span className="text-sm">Date Range</span>
          <DateRangeSection
            onSelectDateRange={setTimeRange}
            rangeValue={timeRange}
          />
        </div>
      </div>
      <div className='w-full flex justify-end lg:w-fit'>
        <SortBy selected={sortBy} onChange={setSortBy} />
      </div>
    </div>
  )
}
