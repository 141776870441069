import { BrandProfile } from '@/classes/brandProfile';
import { InfoPage } from '@/components/InfoPage';
import { LoadingItems } from '@/components/LoadingItems';
import { LoadingPage } from '@/components/LoadingPage';
import SightlyButton from '@/components/Sightly/SightlyButton';
import { PATH_MOMENT_DETAILS, PATH_NARRATIVE_DETAILS, PATH_TOPIC_DETAILS, PATH_TO_ONBOARDING_HUB, PATH_TREND_DETAILS } from '@/routes';
import ResultCard from '@/views/Trends/SearchResults/components/ResultCard';
import ResultMomentCard from '@/views/Trends/SearchResults/components/ResultMomentCard';
import ResultSocialNarrativeCard from '@/views/Trends/SearchResults/components/ResultNarrativeCard';
import { IMoment, INarrative, ISearchResult, ITopic, ITrend, SearchResultEnum } from '@/views/Trends/types';
import { useNavigate } from '@tanstack/react-location';
import React, { useEffect, useState } from 'react';

interface IProps {
  results?: ISearchResult[];
  error: any;
  isLoading: boolean;
  type: SearchResultEnum;
  brandProfileId: number
}

const ResultCardList = ({ results, error, isLoading, type, brandProfileId }: IProps) => {

  const navigate = useNavigate()

  const pageSize = 10
  const [page, setPage] = useState(1);
  const [paginatedResults, setPaginatedResults] = useState<ISearchResult[]>();
  
  useEffect(() => {
    if (!results) return
    
    setPaginatedResults(results.slice(0, page * pageSize))
  }, [results, page])

  const handleLoadMore = () => {
      setPage((prevPage) => prevPage + 1);
  };

  const handleResultSelect = (result: ISearchResult, tab?: string) => {
    const pathMap: Record<string, string> = {
      topic: PATH_TOPIC_DETAILS,
      trend: PATH_TREND_DETAILS,
      moment: PATH_MOMENT_DETAILS,
      narrative: PATH_NARRATIVE_DETAILS,
    };

    const path = pathMap[type] ? `${pathMap[type]}/${result.id}` : PATH_TO_ONBOARDING_HUB;

    let params: Record<string, string | undefined> = {};

    switch (type) {
      case 'narrative':
        params = { tab };
        break;
      case 'moment':
        params = { brandProfileId: String(brandProfileId) };
        break;
    }

    localStorage.setItem('trendsSource', 'search');
    navigate({ 
      to: path, 
      search: (prev) => ({ ...prev, ...params }) 
    });  
  };

  const renderResults = (): React.ReactNode => {
    switch (type) {
      case SearchResultEnum.Topic:
      case SearchResultEnum.Trend:
        const parsedResults = paginatedResults as (ITopic | ITrend)[];
        return parsedResults.map((result) => (
          <ResultCard
            key={`search-${type}-result-${result.id}`}
            result={result}
            handleSelect={handleResultSelect}
          />
        ));
      case SearchResultEnum.TopicTrendMoment:
        return (paginatedResults as IMoment[]).map((result) => (
          <ResultMomentCard
            key={`search-moment-result-${result.id}`}
            moment={result}
            handleSelect={handleResultSelect}
          />
        ));
      case SearchResultEnum.Narrative:
        return (paginatedResults as INarrative[]).map((result) => (
          <ResultSocialNarrativeCard
            key={`search-narrative-result-${result.id}`}
            narrative={result}
            handleSelect={handleResultSelect}
          />
        ));
    }
  };

  if (isLoading) {
    return (
      <div className='flex flex-col w-full'>
        <LoadingPage message={`Fetching ${type}s`} />
        <LoadingItems />
      </div>
    )
  }

  if (error || !results) {
    return (
      <InfoPage message={`There was an unknown error fetching ${type}s. Please refresh the page to try again. If the problem persists, please contact support.`} />
    )
  }

  if (results.length === 0) {
    return (
      <InfoPage message={`There are no ${type}s for your filters`} />
    )
  } else {
    return (
      <div className="flex flex-col gap-4">
        {paginatedResults && renderResults()}
        {
          paginatedResults && results.length > paginatedResults?.length && (
            <div className="flex justify-center h-10">
              <SightlyButton
                datatestid="load-more-trend-result-button"
                block
                id="loadmorebutton"
                text="Load More"
                handleClick={handleLoadMore}
                loading={isLoading}
              />
            </div>
          )
        }
      </div>
    )
  };
};

export default ResultCardList;
