import React from 'react'
import { perms } from '@/staticData/permissions';
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';
import { Tooltip, Whisper } from 'rsuite';

interface IProps {
  activeTab: string
  setActiveTab: (activeTab: string) => void
  brandProfileId?: number
}
interface NavigationListItem {
  name: string,
  disabled?: boolean
}

export const SideNavigation: React.FC<IProps> = ({ activeTab, setActiveTab, brandProfileId }: IProps) => {
  const { userPermissions } = useUser();
  const disabled = !brandProfileId

  let listItems: NavigationListItem[] = [
    { name: 'Overview', disabled: false },
    { name: 'Brand Mentality', disabled },
    { name: 'Articles', disabled },
    { name: 'Videos', disabled }
  ]

  if (userCanPermissionProductQuota({
    requiredPermissionValue: perms.AYLIEN_MOMENT_KEYWORDS_READ,
    userPermissions: userPermissions,
    checkType: PermissionCheckType.PERMISSION_CHECK
  })) {
    listItems.push({ name: 'Keywords', disabled });
  }

  return (
    <nav data-testid="moment-navbar" className="py-4 h-screen">
      <ul role="list" className="-mx-2 space-y-6">
        {listItems.map((option) => (
          <Whisper
            delayShow={200}
            delayHide={0}
            trigger="hover"
            placement={'top'}
            speaker={<Tooltip>Select a Brand Profile to access this tab</Tooltip>}
          >
            <li key={option.name}>
              <div
                data-testid={`moment-navbar-tab-${option.name}`}
                onClick={() => { if (!disabled) setActiveTab(option.name) }}
                className={`${activeTab === option.name ? 'font-bold' : 'font-medium'} ${option.disabled ? 'text-gray-300' : 'hover:font-bold cursor-pointer text-gray-700'} py-2 pl-10 text-lg leading-6`}
              >
                {option.name}
              </div>
            </li>
          </Whisper>
        ))}
      </ul>
    </nav>
  )
}
