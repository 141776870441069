import React from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import {routes} from "@/routes";
import {STRIPE_PRICES, StripeSubscription} from "@/views/Stripe/types";
import {useNavigate} from "@tanstack/react-location";
import SightlyButton from "@/components/Sightly/SightlyButton";

const getStripePriceId = (subscriptionType: StripeSubscription) => {
  // TODO: Works only with test products
  switch (subscriptionType) {
    case 'base':
      return STRIPE_PRICES['TEST_BASE_PER_MONTH']
    case 'premium':
      return STRIPE_PRICES['TEST_PREMIUM_PER_MONTH']
  }
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export function StripePricing() {
  const navigate = useNavigate()

  const tiers = [
    {
      name: 'Base',
      dataTestId: 'tier-base',
      handleClick: () =>{
        navigate({
          to:routes.stripe.checkout.path,
          search: (old = {}) => {
            return {
              ...old,
              priceId: getStripePriceId('base')
            }
          }
        })
      },
      price: { value: '$29.99', frequency: '/month', },
      description: 'Allows advertisers to easily identify and learn about trending hashtag data. See the latest top 200 hashtags by category with associated metrics to make informed campaign decisions',
      buttonText: 'Select Plan',
      features: [
        'TikTok Hashtag Dashboard'
      ],
      mostPopular: false,
    },
    {
      name: 'Premium',
      dataTestId: 'tier-premium',
      handleClick: () =>{
        navigate({
          to:routes.stripe.checkout.path,
          search: (old = {}) => {
            return {
              ...old,
              priceId: getStripePriceId('premium')
            }
          }
        })
      },
      price: { value: '$499', frequency: '/month', },
      description: 'Upgrade to premium to apply hashtags to live campaigns all in the Brand Mentality interface.',
      buttonText: 'Select Plan',
      features: [
        'TikTok Hashtag Dashboard',
        'Import TikTok Campaigns',
        'TikTok Trend Targeting',
      ],
      mostPopular: true,
    },
    {
      name: 'Enterprise',
      dataTestId: 'tier-enterprise',
      handleClick: () => window.open('https://www.sightly.com/contact-us/', '_blank'),
      price: { value: 'Custom', frequency: '', },
      description: 'Dedicated support and infrastructure for your company.',
      buttonText: 'Schedule a time',
      features: [
        'Everything in Premium',
        'Intelligence features for other social platforms',
        'Professional Services for campaign management',
      ],
      mostPopular: false,
    },
  ]

  return (
    <div className="bg-white py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Pricing plans for teams of&nbsp;all&nbsp;sizes
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Choose an affordable plan that’s packed with the best features for engaging your audience, creating customer
          loyalty, and driving sales.
        </p>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier, index) => (
            <div
              key={index}
              data-testid={tier.dataTestId}
              className={classNames(
                tier.mostPopular ? 'ring-2 text-indigo-600 ring-sightlyPurple' : 'ring-1 ring-gray-200',
                'rounded-3xl p-8 xl:p-10',
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h2
                  className={classNames(
                    tier.mostPopular ? 'text-sightlyPurple' : 'text-gray-900',
                    'text-xl font-semibold leading-8',
                  )}
                >
                  {tier.name}
                </h2>
                {tier.mostPopular &&
                  <p className="rounded-full bg-purple-100 px-2.5 py-1 text-xs font-semibold leading-5 text-sightlyPurple">
                    Most popular
                  </p>
                }
              </div>
              <p className="mt-4 h-32 text-sm leading-6 text-gray-600">{tier.description}</p>

                <p className="h-10 mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">{tier.price?.value}</span>
                  {
                    tier.price &&
                    <div className="text-sm font-semibold leading-6 text-gray-600">{tier.price?.frequency}</div>
                  }
                </p>

              <button
                onClick={() => tier.handleClick()}
                className={classNames(
                  tier.mostPopular
                    ? 'bg-sightlyPurple shadow-sm text-white'
                    : 'ring-gray-200 ring-1 ring-inset',
                  'w-full mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outtext-indigo-600',
                )}
              >
                {tier.buttonText}
              </button>
              <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-sightlyPurple" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
