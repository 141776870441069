import { useQuery } from '@tanstack/react-query'
import { logError } from '@/utils'
import { listBuilderAxios } from '@/axiosInstances'
import { z } from 'zod'
import dayjs from 'dayjs'

const AggregatedOverviewSchema = z.object({
  narrativesOverview: z.array(
    z.object({
      name: z.string(),
      maxPublishedDate: z.string(),
      postCount: z.number()
    })
  ),
  platformsOverview: z.array(
    z.object({
      platformName: z.string(),
      score: z.number(),
      postCount: z.number()
    })
  ),
  themesOverview: z.array(
    z.object({
      theme: z.string(),
      platforms: z.array(z.string()),
      postCount: z.number()
    })
  )
})

export type AggregatedOverviewType = z.infer<typeof AggregatedOverviewSchema>

type OverviewProps = { brandProfileId: number; boardIds: number[]; minPublishDate: Date; maxPublishDate: Date }

export const getSocialClustersAggregatedOverview = async ({
  brandProfileId,
  boardIds,
  minPublishDate,
  maxPublishDate
}: OverviewProps) => {
  const url = '/social-clusters/overview/aggregated'

  const { data } = await listBuilderAxios.post(url, {
    brandProfileId,
    boardIds,
    minPublishDate: dayjs(minPublishDate).format('YYYY-MM-DD'),
    maxPublishDate: dayjs(maxPublishDate).format('YYYY-MM-DD'),
  })
  return AggregatedOverviewSchema.parse(data)
}

export const useOverview = ({ brandProfileId, boardIds, minPublishDate, maxPublishDate }: OverviewProps) => {
  const { data: aggregatedOverviewQuery, isLoading, refetch } = useQuery(
    ['socialClustersAggregatedOverview', brandProfileId, boardIds] as const,
    () => getSocialClustersAggregatedOverview({ brandProfileId, boardIds, minPublishDate, maxPublishDate }),
    {
      enabled: Boolean(brandProfileId && boardIds?.length),
      onError: (err) => {
        logError(err, { info: 'aggregatedOverviewQuery' })
      }
    }
  )
  return { aggregatedOverviewQuery, isLoading, refetch }
}
