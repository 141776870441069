import React, { useEffect, useState } from 'react'
import SightlyButton from '@/components/Sightly/SightlyButton';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { routes } from '@/routes';
import { MyLocationGenerics } from '@/classes/utils';
import { useQuery } from '@tanstack/react-query';
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';
import { perms } from '@/staticData/permissions';
import { smartlistController } from './controller/SmartlistController';
import BoardSelectSearch from '../../Discover/Boards/components/BoardSelectSearch';
import useBrandProfiles from '../../../hooks/brandProfile/useBrandProfiles';
import { SmartlistViewTable } from '@/views/Engage/SmartList/components/SmartlistViewTable';
import CreateModal from '@/views/Engage/SmartList/components/CreateModal';
import { LoadingComponent } from '@/components/LoadingComponent';


const ViewSmartlist = () => {
    const navigate = useNavigate();
    const { userPermissions } = useUser();
    const { accountId, brandProfileId } = useSearch<MyLocationGenerics>();

    const [brandProfileSelected, setBrandProfileSelected] = useState(brandProfileId);
    const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);


    const page = 1;
    const pageSize = 10;

    const orderBy = 'updatedAt';
    const sortBy = 'desc';

    const {
        data: tableData,
        refetch: refetchTableData
    } = useQuery(['getSmartLists', brandProfileSelected, page, pageSize, orderBy, sortBy], smartlistController.getSmartLists)

    const [currentTableData, setCurrentTableData] = useState(tableData);

    const { data: brandProfiles, currentBrandProfile } = useBrandProfiles({
        submittedOnly: true
    })

    useEffect(() => {
        setBrandProfileSelected(currentBrandProfile?.brandProfileId)
    }, [currentBrandProfile])

    useEffect(() => {
        setCurrentTableData(tableData);
    }, [tableData]);

    const handleTableDataChange = (newData: any) => {
        setCurrentTableData(newData);
    };

    const UpdateViewData = () => {
        refetchTableData();
    };


    const hasTableData = currentTableData != undefined ? currentTableData['results'].length : 0


    return (
        <>
            <div className="flex justify-between mt-8 mx-8 mb-2 pb-0" data-testid="smartlist-table">

                <div className="sightlyPanelHeader" data-testid="smartlist-table-header">
                    <div className="flex flex-row">
                        SmartLists
                    </div>
                </div>

            </div>

            <div className="flex justify-between mx-8 mb-4 pb-0" data-testid="smartlist-table">

                <div className='w-60'>
                    <BoardSelectSearch
                        value={brandProfileSelected}
                        options={brandProfiles}
                        label={"Brand Profile"}
                        labelKey="brandProfileName"
                        valueKey="brandProfileId"
                        onChange={(brandProfileId: number) => {
                            setBrandProfileSelected(brandProfileId)
                        }}
                    />
                </div>
                {hasTableData ? (
                    <div>
                        <SightlyButton
                            datatestid={'button-smart-list-create-new-smartlist'}
                            id="createSmartlistButton"
                            text="Create SmartList"
                            type='purple'
                            disabled={!userCanPermissionProductQuota({
                                requiredPermissionValue: perms.SMARTLIST_CREATE,
                                userPermissions: userPermissions,
                                checkType: PermissionCheckType.PERMISSION_CHECK
                            })}
                            handleClick={() => {
                                setIsOpenCreateModal(true)
                            }}
                        />
                    </div>
                ) : null}

            </div>
            {currentTableData === undefined ? (
                <div className="flex justify-center items-center h-52">
                    <LoadingComponent message="Loading SmartLists" />
                </div>
            ) : 
            hasTableData
                ?
                <div className="mx-8" data-testid="smartlist-table-component">
                    <SmartlistViewTable tableData={tableData} brandProfileId={brandProfileSelected!} userPermissions={userPermissions} onTableDataChange={handleTableDataChange} />
                </div>
                :
                <div className="w-full flex items-center flex-col" data-testid="smartlist-table-create">
                    <div className="flex items-center flex-col pt-32">
                        <p>
                            <b>No SmartLists</b>
                        </p>
                        <p>Get started by creating a new SmartList</p>
                        <div className="p-4">
                            <SightlyButton
                                datatestid={'button-smart-list-create-new-smartlist'}
                                id="createSmartlistButton"
                                text="+ New SmartList"
                                disabled={!userCanPermissionProductQuota({
                                    requiredPermissionValue: perms.SMARTLIST_CREATE,
                                    userPermissions: userPermissions,
                                    checkType: PermissionCheckType.PERMISSION_CHECK
                                })}
                                type='purple'
                                handleClick={() => {
                                    setIsOpenCreateModal(true)
                                }}
                            />
                        </div>
                    </div>
                </div>


            }
            <CreateModal  brandProfileId={brandProfileSelected!} accountId={accountId!} isOpenCreateModal={isOpenCreateModal} setIsOpenCreateModal={setIsOpenCreateModal} updateViewData={UpdateViewData}/>
        </>
    )
}

export default ViewSmartlist
