export const rqKeys = {
    momentScenariosKey: (brandProfileId: number | undefined, clusterId: number) =>
        ['momentScenarios', brandProfileId, clusterId] as const,
    momentVideosKey: (brandProfileId: number | undefined, clusterId: number) =>
        ['momentVideos', brandProfileId, clusterId] as const,
    momentKeywordsKey: (brandProfileId: number | undefined, clusterId: number) =>
        ['momentKeywords', brandProfileId, clusterId] as const,
    momentScenariosOptionsKey: (brandProfileId: number | undefined, clusterId: number) =>
        ['momentScenariosOptions', brandProfileId, clusterId] as const,
    momentActionJustificationsKey: (brandProfileId: number | undefined, clusterId: number) =>
        ['momentActionJustifications', brandProfileId, clusterId] as const,
    momentInViewSlideOver: (brandProfileId: number | undefined, clusterId: number) => 
        ['momentInViewSlideOver', brandProfileId, clusterId] as const,
    momentSuggestionsFeedback: (brandProfileId: number | undefined, clusterId: number) => 
        ['momentSuggestionsFeedback', brandProfileId, clusterId] as const,
}
