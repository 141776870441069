import { api } from '@/api/api';
import { InfoPage } from '@/components/InfoPage';
import useRoles from '@/hooks/useRoles';
import useUser from '@/hooks/useUser';
import { UserType } from '@/schemas/schemas';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';
import { joinClasses, logError } from '@/utils';
import { isValidEmail } from '@/validations';
import RolesInfoFullScreen from '@/views/Users/RolesInfoFullScreen.js';
import { filteredRolesPermissionsInfo } from '@/views/Users/userUtils';
import { useMatch } from '@tanstack/react-location';
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { CheckPicker } from "rsuite";
import Button from 'rsuite/lib/Button';
import CheckTreePicker from 'rsuite/lib/CheckTreePicker';
import FormGroup from 'rsuite/lib/FormGroup';
import Icon from 'rsuite/lib/Icon';
import IconButton from 'rsuite/lib/IconButton';
import Input from 'rsuite/lib/Input';
import Tooltip from 'rsuite/lib/Tooltip';
import Whisper from 'rsuite/lib/Whisper';

const EditUser = () => {    
    const { data: roles } = useRoles()
    const { params } = useMatch()
    const userId = Number(params.userId)

    const { accounts, user: userProfile, perms, userPermissions } = useUser()
    const [userCanAccessAllAccountsOfTargetUser, setUserCanAccessAllAccountsOfTargetUser] = useState(false)
    const canAccessAllAccountsOfTargetUser = useCanAccessAllAccountsOfTargetUser(userId)

    const [loading, setLoading] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    const [user, setUser] = useState<Partial<UserType>>()
    const [userAccounts, setUserAccounts] = useState<number[]>([])

    const [userFetchError, setUserFetchError] = useState(false)

    useEffect(() => {
        const proc = async () => {
            setUserFetchError(false)
            setLoading(true)
            try {
                const responseUser = await api.user.get(userId)
                setUser(responseUser)
                setUserAccounts(responseUser.accounts.map((a) => a.accountId))
                setLoading(false)
            } catch (err) {
                setUserFetchError(true)
            }
        }

        proc()
    }, [])

    const handleSaveClick = () => {
        if (!user) return

        api.user.updateUser(user)

        if (!(user.userId && user.accounts)) return

        const newAccounts = userAccounts.map((a) => { return { accountId: a} })
        api.user.updateAccounts(user.userId, newAccounts)
    }

    const handleDialog = (value: boolean) => {
        setOpenDialog(value)
    }

    const isClean = useMemo(() => {
        return [
            user?.firstName?.length,
            user?.lastName?.length,
            user?.email && isValidEmail(user.email),
            user?.accounts?.length,
            user?.roles?.length,
            user?.company?.length,
        ].every(Boolean);
    }, [user]);
    

    const handleChange = (property: string, value: string | number[] ) => {
        if (property === 'accounts') {
            setUserAccounts(value as number[])
            return
        }
        setUser((prevState) => {
            return ({
                ...prevState,
                [property]: value,
            })
        })
    };

    const roleOptions = useMemo(() => {
        if (!roles || !userProfile) return [];
    
        const isExternalUser = userProfile.userType === 'External';
        const isSightlyEmail = user?.email?.toLowerCase().includes('@sightly.com');
        const isAdmin = userProfile.roles.some(role => role.id === 1);
    
        if (!isSightlyEmail || isExternalUser) {
            return roles.filter((role: { userType: string; }) => role.userType === 'External');
        }
    
        return isAdmin ? roles : roles.filter((role: { id: number; userType: string; }) => role.id !== 1 && role.userType === 'Internal');
    }, [roles, userProfile, user?.email]);
    
    const canUserUpdate = userCanPermissionProductQuota({
        requiredPermissionValue: perms.USER_UPDATE,
        userPermissions: userPermissions,
        checkType: PermissionCheckType.PERMISSION_CHECK
    });

    useEffect(() => {
        canAccessAllAccountsOfTargetUser.refetch().then(({ status }) => {
            if (status !== 'error') setUserCanAccessAllAccountsOfTargetUser(true)
        })
    }, [])

    if (userFetchError) {
        return <InfoPage message="This user is not available" />
    }

    return (
        <>
            <div
                style={{
                    width: 500,
                    margin: 'auto'
                }}
            >
                <FormGroup>
                    <div
                        className={joinClasses('input-label', 'isRequired')}
                        style={{ marginTop: 24 }}
                    >
                        Company
                    </div>
                    {loading ? (
                        <Skeleton height={36} />
                    ) : (
                        <Input
                            disabled={!canUserUpdate}
                            value={user?.company}
                            onChange={(val) => handleChange('company', val)}
                        />
                    )}
                </FormGroup>

                <FormGroup>
                    <div
                        className={joinClasses('input-label', 'isRequired')}
                        style={{ marginTop: 24 }}
                    >
                        Email
                    </div>

                    {loading ? (
                        <Skeleton height={36} />
                    ) : (
                        <Input
                            disabled
                            value={user?.email}
                            onChange={(val) => handleChange('email', val)}
                        />
                    )}
                </FormGroup>

                <FormGroup>
                    <div
                        className={joinClasses('input-label', 'isRequired')}
                        style={{ marginTop: 24 }}
                    >
                        First Name
                    </div>
                    {loading ? (
                        <Skeleton height={36} />
                    ) : (
                        <Input
                            disabled={!canUserUpdate}
                            value={user?.firstName}
                            onChange={(val) => handleChange('firstName', val)}
                        />
                    )}
                </FormGroup>
                <FormGroup>
                    <div
                        className={joinClasses('input-label', 'isRequired')}
                        style={{ marginTop: 24 }}
                    >
                        Last Name
                    </div>
                    {loading ? (
                        <Skeleton height={36} />
                    ) : (
                        <Input
                            disabled={!canUserUpdate}
                            value={user?.lastName}
                            onChange={(val) => handleChange('lastName', val)}
                        />
                    )}
                </FormGroup>

                <FormGroup style={{ marginTop: 27 }}>
                    <div
                        className={joinClasses('input-label', 'isRequired')}
                        style={{ marginTop: 24 }}
                    >
                        Account
                    </div>

                    {loading ? (
                        <Skeleton height={36} />
                    ) : (
                        <CheckTreePicker
                            block
                            disabledItemValues={user?.email?.toLowerCase().includes('@sightly.com') ? [] : [1]}
                            disabled={!canUserUpdate || !userCanAccessAllAccountsOfTargetUser}
                            virtualized={true}
                            defaultExpandAll
                            value={userAccounts}
                            data={accounts || []}
                            labelKey={'accountName'}
                            valueKey={'accountId'}
                            onChange={(val) => handleChange('accounts', val as number[])}
                            cascade={true}
                            preventOverflow={true}
                        />
                    )}
                </FormGroup>

                <FormGroup>
                    <div
                        className={joinClasses('input-label', 'isRequired')}
                        style={{ marginTop: 24 }}
                    >
                        Role
                    </div>
                    <div
                        style={{
                            position: 'relative'
                        }}
                    >
                        {loading || roleOptions.length < 1 ? (
                            <Skeleton height={36} />
                        ) : (
                            <CheckPicker
                                disabled={!canUserUpdate}
                                id={'role'}
                                labelKey={'name'}
                                valueKey={'id'}
                                placeholder={'Roles'}
                                value={user?.roles?.map(role => role.id)}
                                data={roleOptions}
                                onChange={(values) => {
                                    const selectedRoles = roleOptions.filter((role: { id: number; }) => values.includes(role.id));
                                    handleChange('roles', selectedRoles)
                                }}
                                cleanable={false}
                                style={{ width: '100%' }}
                                preventOverflow={true}
                            />
                        )}

                        <div style={{ position: 'absolute', right: -60, top: -10 }}>
                            <Whisper
                                delayOpen={700}
                                delayClose={0}
                                placement="right"
                                trigger="hover"
                                speaker={<Tooltip>More about Roles/Permissions</Tooltip>}
                            >
                                <IconButton
                                    icon={<Icon icon="info" />}
                                    circle
                                    size="md"
                                    appearance="ghost"
                                    onClick={() => {
                                        handleDialog(true)
                                    }}
                                    style={{ margin: '10px' }}
                                />
                            </Whisper>
                        </div>
                    </div>
                </FormGroup>
                <FormGroup style={{ marginTop: 27 }}>
                    <Button
                        disabled={!isClean}
                        onClick={handleSaveClick}
                    >
                        Save
                    </Button>
                </FormGroup>
            </div>

            <RolesInfoFullScreen
                show={openDialog}
                title="Roles and Permissions"
                handleDialog={(value: boolean) => {
                    handleDialog(value)
                }}
                data={filteredRolesPermissionsInfo(userProfile && userProfile.userType, roles)}
                userType={userProfile && userProfile.userType}
            />
        </>
    )
}

const useCanAccessAllAccountsOfTargetUser = (targetUserId: number) => {
    const query = useQuery([targetUserId], () => api.user.canAccessAllAccountsOfTargetUser(targetUserId),
        {
            enabled: false,
            retry: 1,
            onError: (err) => {
                logError(err)
            },
        })
    return query
}

export default EditUser
