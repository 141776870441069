import React from 'react';
import {ConversationPreviewType, ConversationSourcePlatform} from '@/views/Discover/Boards/types';
import blogsAndForums from "@/assets/img/platforms/blogs-and-forums.svg";
import reviews from "@/assets/img/platforms/reviews.svg";
import youtube from "@/assets/img/platforms/youtube.svg";
import facebook from "@/assets/img/platforms/facebook.svg";
import instagram from "@/assets/img/platforms/instagram.svg";
import reddit from "@/assets/img/platforms/reddit.svg";
import tiktok from "@/assets/img/platforms/tiktok.svg";
import x from "@/assets/img/platforms/x.svg";
import pinterest from "@/assets/img/platforms/pinterest.svg";
import truthSocial from "@/assets/img/platforms/truth_social.svg";
import blueSky from "@/assets/img/platforms/bluesky.svg";
import threads from "@/assets/img/platforms/threads.svg";
import linkedin from "@/assets/img/platforms/linkedin.svg";
import mastadon from "@/assets/img/platforms/mastadon.svg";
import tumblr from "@/assets/img/platforms/tumblr.svg";
import Whisper from "rsuite/lib/Whisper";
import Popover from "rsuite/lib/Popover";

interface IProps {
  preview: ConversationPreviewType,
}

const VISIBLE_ICON_COUNT = 3

export const ConversationPreviewCard: Function = ({ preview }: IProps) => {
  const visibleIcons = preview.sourceTypeList.slice(0, VISIBLE_ICON_COUNT)
  const whisperIcons = preview.sourceTypeList.slice(VISIBLE_ICON_COUNT)

  const getPlatformIcon = (platformName: ConversationSourcePlatform) => {
    switch (platformName) {
      case 'Blogs & Forums':
        return <img key="blogsAndForums" src={blogsAndForums} alt={platformName} title={platformName}/>
      case 'YouTube':
        return <img key="youtube" src={youtube} alt={platformName} title={platformName}/>
      case 'Instagram':
        return <img key="instagram" src={instagram} alt={platformName} title={platformName}/>
      case 'Facebook':
        return <img key="facebook" src={facebook} alt={platformName} title={platformName}/>
      case 'Reviews':
        return <img key="reviews" src={reviews} alt={platformName} title={platformName}/>
      case 'Reddit':
        return <img key="reddit" src={reddit} alt={platformName} title={platformName}/>
      case 'Twitter':
      case 'X':
        return <img key="x" src={x} alt={platformName} title={platformName}/>
      case 'TikTok':
        return <img key="tiktok" src={tiktok} alt={platformName} title={platformName}/>
      case 'Pinterest':
        return <img key="pinterest" src={pinterest} alt={platformName} title={platformName} width={40} height={40}/>
      case 'Truth Social':
        return <img key="truthSocial" src={truthSocial} alt={platformName} title={platformName} width={30} height={30}/>
      case 'Bluesky':
        return <img key="blueSky" src={blueSky} alt={platformName} title={platformName} width={40} height={40}/>
      case 'Threads':
        return <img key="threads" src={threads} alt={platformName} title={platformName} width={30} height={30}/>
      case 'LinkedIn':
        return <img key="linkedin" src={linkedin} alt={platformName} title={platformName} width={40} height={40}/>
      case 'Mastadon':
        return <img key="mastadon" src={mastadon} alt={platformName} title={platformName} width={40} height={40}/>
      case 'Tumblr':
        return <img key="tumblr" src={tumblr} alt={platformName} title={platformName} width={40} height={40}/>
    }
  }

  return (
    <div
      data-testid="preview-conversation-card"
      className="flex justify-between rounded-md border my-4 h-24 p-8 shadow bg-white hover:bg-gray-50 mr-4"
    >
      <div className="flex justify-evenly w-full">

          <div
            id={`conversation-title-${preview.id}`}
            className="self-center pr-8 text-sm font-semibold text-gray-900 break-words line-clamp-2  w-5/6"
          >
            {preview.name}
          </div>

          <div className={'flex justify-start w-1/6 gap-1 h-8'}>
            {visibleIcons.map((platformName) => getPlatformIcon(platformName))}

            {whisperIcons.length > 0 && (
              <Whisper
                delayShow={0}
                delayHide={0}
                trigger='hover'
                placement='topEnd'
                speaker={
                  <Popover full>
                    <div className='flex p-4 gap-2 rounded-md border bg-white w-full h-full'>
                      {whisperIcons.map((platformName) => getPlatformIcon(platformName))}
                    </div>
                  </Popover>
                }
              >
                <div
                  className="flex-col self-center p-4 cursor-default rounded-full bg-purple-200 font-semibold text-sightlyPurple"
                >
                  +{whisperIcons.length}
                </div>
              </Whisper>)}

          </div>

        </div>
    </div>
  )
}
