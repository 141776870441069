import { useEffect, useRef, useState } from "react";
import React from "react";
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HCMore from 'highcharts/highcharts-more';
import { LoadingPage } from "@/components/LoadingPage";
import { DataTypes, IBubbleTopicGraph, IBubbleTrendGraph } from "../types";
import TrendMomentViewSlideOver from "./TrendSlideover";
import { useSearch } from "@tanstack/react-location";
import TopicMomentViewSlideOver from "./TopicSlideover";
import { InfoPage } from "@/components/InfoPage";
import SlideOver from "@/components/SlideOver";
import { capitalizeFirstLetter } from "@/utils";
import { CameraIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { BookOpenIcon } from "@heroicons/react/24/outline";

if (typeof Highcharts === "function") {
  HCMore(Highcharts);
}

interface PackedBubbleChartProps {
  data?: IBubbleTopicGraph | IBubbleTrendGraph;
  loading: boolean;
  trendId?: string,
  topicId?: string,
}

const PackedBubbleChart: React.FC<PackedBubbleChartProps> = ({ data, loading, trendId, topicId }) => {
  const search = useSearch();
  const [chartOptions, setChartOptions] = useState({});
  const [filterType, setFilterType] = useState<'all' | 'moment' | 'narrative'>('all');
  const [selectedType, setSelectedType] = useState<DataTypes>(DataTypes.TopicTrendMoment);
  const [showSlideOver, setShowSlideOver] = React.useState(false)

  const bgBlue = '#00AEEF80';
  const bgViolet = '#7748F980';
  const bgBlueLight = '#00AEEF1A';
  const bgVioletLight = '#7748F91A';
  const bgSeriesGreen = '#1B9F8D40';

  const hasData = data && Object.keys(data).length > 0;

  const handleTypeChange = (type: React.SetStateAction<DataTypes>) => {
    if (loading) return
    setSelectedType(type);
    setShowSlideOver(true)
  };

  const chartRef = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (chartRef.current?.chart) {
        chartRef.current.chart.redraw();
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [chartOptions]);

  React.useEffect(() => {
    if (search.slideover === 'trend' && trendId || search.slideover === 'topic' && topicId) {
      setShowSlideOver(true);
    } else {
      setShowSlideOver(false);
    }

    if (search.selectedType) {
      setSelectedType(search.selectedType as DataTypes);
    }
  }, [search.slideover]);

  React.useEffect(() => {
    if (showSlideOver) {
      window.history.replaceState(null, '', `${window.location.pathname}?slideover=${trendId ? 'trend' : 'topic'}&selectedType=${selectedType}`);
    } else {
      window.history.replaceState(null, '', `${window.location.pathname}`);
    }
  }, [showSlideOver]);

  useEffect(() => {
    if (!data) return;

    let seriesData = []

    const filterData = (items: any[]) => {
      if (filterType === 'all') return items;
      return items.filter(item => item.type === filterType);
    };

    if (Array.isArray(data)) {
      seriesData = data.flatMap((topic) =>
        topic.trends.map((trend) => ({
          type: "packedbubble",
          name: trend.name,
          color: bgSeriesGreen,
          data: filterData(trend.items).map((item) => ({
            name: item.name,
            color: item.type === "moment" ? bgBlue : bgViolet,
            value: item.value,
            articles: item.articlesCount,
            videos: item.videosCount,
            postCount: item.postCount,
            platforms: item.platforms,
            type: item.type,
          })),
        }))
      );
    } else {
      seriesData = [{
        name: data.name,
        color: "rgba(255, 255, 255, 0)",
        data: filterData(data.items).map((item) => ({
          name: item.name,
          color: item.type === "moment" ? bgBlue : bgViolet,
          value: item.value,
          articles: item.articlesCount,
          videos: item.videosCount,
          postCount: item.postCount,
          platforms: item.platforms,
          type: item.type,
        }))
      }]
    }

    setChartOptions({
      chart: {
        type: "packedbubble",
        height: "500px",
        redraw: false,
      },
      title: {
        text: '',
      },
      tooltip: {
        formatter: function (): string {
          const point = this as any
          if (!point) return "";

          let tooltipContent = '<b>' + point.series.name + '</b><br>';

          if (point.type === "moment") {
            return (
              '<b><span style="color: ' + bgBlue + ';">' + capitalizeFirstLetter(point.type) + '</span></b><br><br>' +
              '<b>' + point.name + '</b><br><br>' +
              'Articles: ' + point.articles + ' | ' + 'Videos: ' + point.videos
            );
          } else if (point.type === "narrative") {
            return (
              '<b><span style="color: ' + bgViolet + ';">' + capitalizeFirstLetter(point.type) + '</span></b><br><br>' +
              '<b>' + point.name + '</b><br><br>' +
              'Social Post: ' + point.postCount + ' | ' + 'Platforms: ' + point.platforms
            );
          }
          return tooltipContent;
        },
        shared: false,
      },
      accessibility: { enabled: false },
      plotOptions: {
        packedbubble: {
          minSize: "30%",
          maxSize: "100%",
          layoutAlgorithm: {
            enableSimulation: false,
            gravitationalConstant: 0.01,
            splitSeries: true,
            initialPositions: "circle",
          },
          draggable: false,
          dataLabels: {
            enabled: false,
            format: "{point.name}",
            style: { color: "#000", textOutline: "none", fontWeight: "bold" },
          },
        },
      },
      credits: { enabled: false },
      legend: { enabled: false },
      series: seriesData,
    });



  }, [data]);

  return (
    <div style={{ width: "100%", height: "500px" }}>
      {loading && (
        <>
          <LoadingPage message={'Loading'} />
        </>
      )}
      {!loading && !hasData && (
        <div className="w-full h-64 flex items-center justify-center">
          <InfoPage message="No data available for visualization." />
        </div>
      )}
      {!loading && hasData && (
        <>
          <div className="flex gap-[2vh] justify-center w-full" >
            <div
              className="cursor-pointer flex flex-row p-4 rounded-md justify-between w-[300px]"
              style={{ backgroundColor: bgBlueLight }}
              onClick={() => handleTypeChange(DataTypes.TopicTrendMoment)}>
              <div className="flex flex-row">
                <CameraIcon width={34} color={bgBlue} className="mr-4" />
                <div className="flex flex-col">
                  <span data-testid='moments-label'>Moments</span>
                  <span data-testid='moments-count-label' className="text-xl">
                    {Array.isArray(data) ? data[0]?.totalMoments ?? 0 : data.totalMoments ?? 0}
                  </span>
                </div>
              </div>
              <div data-testid='moments-arrow' className="flex">
                <ChevronRightIcon width={20} />
              </div>

            </div>

            <div
              className="cursor-pointer flex flex-row p-4 rounded-md justify-between w-[300px]"
              style={{ backgroundColor: bgVioletLight }}
              onClick={() => handleTypeChange(DataTypes.Narratives)}>
              <div className="flex flex-row">
                <BookOpenIcon width={34} color={bgViolet} className="mr-4" />
                <div className="flex flex-col">
                  <span data-testid='narratives-label'>Narratives</span>
                  <span data-testid='narratives-count-label' className="text-xl">
                    {Array.isArray(data) ? data[0]?.totalNarratives ?? 0 : data.totalNarratives ?? 0}
                  </span>
                </div>
              </div>
              <div data-testid='narratives-arrow' className="flex">
                <ChevronRightIcon width={20} />
              </div>
            </div>
          </div>
          {chartOptions && data && <HighchartsReact
            key={JSON.stringify(data)}
            highcharts={Highcharts}
            options={chartOptions}
            ref={chartRef}
            containerProps={{ style: { width: "100%", height: "500px" } }} />}

          {/* This will be implemented in the near future: filter by moments, narratives, or both. */}
          {/* <div className="flex justify-center space-x-5">
            <div className="flex items-center space-x-2 cursor-pointer" onClick={() => setFilterType('all')}>
              <div className={`w-4 h-4 rounded-full`} style={{ backgroundColor: bgBlue }} />
              <span>{'Show All'}</span>
            </div>
            <div className="flex items-center space-x-2 cursor-pointer" onClick={() => setFilterType('moment')}>
              <div className={`w-4 h-4 rounded-full`} style={{ backgroundColor: bgBlue }} />
              <span>{'Moments'}</span>
            </div>
            <div className="flex items-center space-x-2 cursor-pointer" onClick={() => setFilterType('narrative')}>
              <div className={`w-4 h-4 rounded-full`} style={{ backgroundColor: bgViolet }} />
              <span>{'Narratives'}</span>
            </div>
          </div> */}

          {/* These are the tables that will be implemented in the near future */}
          {/* <div className="px-5 mt-10">
            {trendId ? (
              <div className="flex gap-10">
                <TrendMomentViewSlideOver bgColor={bgBlue} disableDivider trendId={trendId} dataType={DataTypes.TopicTrendMoment} />
                <TrendMomentViewSlideOver bgColor={bgViolet} disableDivider trendId={trendId} dataType={DataTypes.Narratives} />
              </div>
            ) : (
              <div className="flex gap-10">
                <TopicMomentViewSlideOver bgColor={bgBlue} disableDivider topicId={topicId} dataType={DataTypes.TopicTrendMoment} />
                <TopicMomentViewSlideOver bgColor={bgViolet} disableDivider topicId={topicId} dataType={DataTypes.Narratives} />
              </div>

            )}
          </div> */}
        </>
      )}

      <SlideOver
        show={showSlideOver}
        onHide={() => setShowSlideOver(false)}
        headerTitle={`View ${selectedType}s`}
        headerForegroundColor="text-black"
        headerBackgroundColor="bg-white"
        showToggleFullScreenButton={false}
        maxOffset="60%">

        <div className="px-5 h-full">
          {trendId ? (
            <TrendMomentViewSlideOver trendId={trendId} dataType={selectedType} />
          ) : (
            <TopicMomentViewSlideOver topicId={topicId} dataType={selectedType} />
          )}
        </div>
      </SlideOver>
    </div>
  );
};

export default PackedBubbleChart;
