import { z } from 'zod'
import { Unarray } from '@/classes/utils'

// the point of zod is to not have to define the interface and the schema twice.
// But since this is a recursive type (children), this is the only way to do it here

const SubscriptionPackageSchema = z.object({
  id: z.number().optional(),
  name: z.string().optional(),
  description: z.string().optional(),
  type: z.string().optional(),
  created_date: z.string().optional(),
  last_modified_date: z.string().optional(),
})

const SubscriptionProductSchema = z.array(z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  created_date: z.string(),
  last_modified_date: z.string(),
  quota: z.string().nullable()
}))

interface AccountSchema {
  accountId: number
  accountName: string
  accountLevelId: number
  accountTypeId: number
  parentAccountName: string
  contactName: string
  contactEmail: string
  children?: AccountSchema[]
  parentAccountId: number | null
  accountLevelName: string
  accountMargin?: number
  accountStatus: string
  stripeSubscriptionId?: string | null
  packageId?: number | null
  package?: z.infer<typeof SubscriptionPackageSchema>
  products?: z.infer<typeof SubscriptionProductSchema> | []
  packageStartDate?: string | null
  packageEndDate?: string | null
  trialStartDate?: string | null
  trialEndDate?: string | null
  brandProfiles?: []
}

const AccountSchema: z.ZodType<AccountSchema> = z.lazy(() =>
  z.object({
    accountId: z.number(),
    accountLevelId: z.number(),
    accountLevelName: z.string(),
    accountMargin: z.number(),
    accountName: z.string(),
    accountTypeId: z.number(),
    parentAccountId: z.number().nullable(),
    parentAccountName: z.string(),
    accountTypeName: z.string(),
    contactEmail: z.string(),
    contactName: z.string(),
    packageId: z.number().nullable().optional(),
    package: SubscriptionPackageSchema.optional(),
    products: SubscriptionProductSchema.min(0).optional(),
    children: z.array(AccountSchema).optional(),
    packageStartDate: z.string().nullable().optional(),
    packageEndDate: z.string().nullable().optional(),
    trialStartDate: z.string().nullable().optional(),
    trialEndDate: z.string().nullable().optional(),
    accountStatus: z.string(),
    stripeSubscriptionId: z.string().nullable().optional()
  })
) 
export type IAccount = z.infer<typeof AccountSchema>;

export const RoleSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  userType: z.enum(['Internal', 'External']),
})

export const UserSchema = z.object({
  id: z.number(),
  userId: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  company: z.string(),
  roles: z.array(RoleSchema),
  userType: z.enum(['Internal', 'External']),
  accounts: z.array(AccountSchema),
  permissions: z.array(z.string()),
  userName: z.string(), //not used
  phoneNumber: z.string() //not used
})

export type AccountType = Unarray<z.infer<typeof UserSchema>['accounts']>
export type UserType = z.infer<typeof UserSchema>
export const UserCompletionStepSchema = z.object({
  userId: z.number(),
  id: z.number(),
  videoId: z.string(),
  createdAt: z.string()
});
export type UserCompletionStep = z.infer<typeof UserCompletionStepSchema>;

export const userCompletionStepsArr = z.array(UserCompletionStepSchema);

export const RolesAndPermissionsSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    description: z.string(),
    userType: z.string(),
    permissions: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
        description: z.string(),
      })
    ),
  })
).min(1, { message: 'No roles associated with account' });

export const UserLoginSchema = z.object({
  userId: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  company: z.string(),
  userType: z.string(),
  roles: z.array(z.unknown()).optional(),
  accounts: z.array(z.unknown()).optional(),
});

export const UsersSchema = z.array(
  z.object({
    company: z.string().optional(),
    email: z.string().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    phoneNumber: z.string().optional(),
    roleId: z.number().optional(),
    userId: z.number().optional(),
    userName: z.string().optional(),
    userType: z.string().optional(),
  })
);

export const BrandScenariosSchema = z.array(
  z.object({
    archived: z.boolean(),
    scenarioId: z.number(),
    scenarioName: z.string(),
  })
);

export const AdminScenariosSchema = z.object({
  archived: z.boolean(),
  scenarioId: z.number(),
  scenarioName: z.string(),
  dataSegmentIds: z.array(z.number()).optional(),
  scenarioTypeIds: z.array(z.number()).optional(),
  labelId: z.number().optional(),
});

export const BrandOpinionsSchema = z.array(
  z.object({
    archived: z.boolean(),
    opinionId: z.number(),
    question: z.string(),
    opinionTypeIds: z.array(z.number()).optional(),
    opinionResponseId: z.string().optional(),
  })
);

export const BrandTopicsSchema = z.array(
  z.object({
    children: z.array(
        z.object({
          parentIds: z.array(z.unknown()).nonempty(),
          topicId: z.number(),
          topicName: z.string(),
          topicResponseId: z.number(),
        })
      ).optional(),
    parentIds: z.array(z.unknown()).nonempty(),
    topicId: z.number(),
    topicName: z.string(),
    topicResponseId: z.number(),
  })
);


export const ExcludedContentThemesSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
  })
);

export const SensitiveContentCategoriesSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
  })
);

export const AylienAdminsSchema = z.array(
  z.object({
    id: z.number(),
    taxonomyCode: z.string(),
    taxonomyLevel: z.number(),
    taxonomyName: z.string(),
  })
);

export const AylienIndustriesSchema = z.array(
  z.object({
    id: z.number(),
    taxonomyCode: z.string(),
    taxonomyPath: z.string(),
  })
);

export const AdminIabCategoriesSchema = z.array(
  z.object({
    disregardLeafNode: z.boolean(),
    iabCategoryId: z.string(),
    iabCategoryName: z.string(),
    keywords: z.array(
      z.object({
        keyword: z.string(),
        archived: z.boolean(),
      })
    ),
  })
).min(1, { message: 'should have at least one iab cat' });
