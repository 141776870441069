import React from 'react'

interface IProps {
    /** Title of the item */
    title: string
    /** Value of the item */
    value: string
}

/**
 * Component to display the segment estimate item.
 * @prop {string} title Title of the item.
 * @prop {string} value Value of the item.
 */

export const SegmentEstimateItem = ({ title, value }: IProps) => {
    return (
        <div>
            <div className='text-sm	font-medium ml-1'>
               { title }
            </div>
            <div className='text-sm	font-medium ml-1'>
                { value }
            </div>
        </div>
    )
}
