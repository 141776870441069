import React from 'react'
import LikeDislikeButton from '../../../../../components/LikeDislikeButton'
import { accentColor, purpleSecondaryColor, brandPinkColor } from '../../../../../assets/jss/colorConstants'
import { ScenarioResponseEnum, getScenarioResponse } from '../../../../../classes/trend'
import { NoneApplyId } from '../../../../../services/trends_ts'
import { MomentScenarioType } from './../types'
import { useIsMutating } from '@tanstack/react-query'

interface iProps {
    scenario: MomentScenarioType
    index: number
}

const TrendScenarioComponent = ({ scenario, index  }: iProps) => {
    const _bgColor = React.useMemo(() => {
        if (scenario.scenarioResponseId === ScenarioResponseEnum.target.id) return accentColor
        if (scenario.scenarioResponseId === ScenarioResponseEnum.monitor.id) return purpleSecondaryColor
        if (scenario.scenarioResponseId === ScenarioResponseEnum.block.id) return brandPinkColor
        if (scenario.scenarioResponseId === ScenarioResponseEnum.dontmonitor.id) return 'lightgrey'
        if (scenario.scenarioResponseId === ScenarioResponseEnum.noresponse.id) return 'slategrey'
        return 'white'
    }, [scenario])

    const isMutating = useIsMutating()
    return (
        <div
            data-testid="moment-modal-scenarios-component" 
            style={{
                height: 100,
                margin: '10px 0px',
                marginLeft: 10,
                position: 'relative',
                display: 'flex',
                borderBottom: '1px solid rgb(212, 217, 217, 0.5)'
            }}
        >
            <div
                data-testid="moment-modal-scenarios-question" 
                style={{
                    fontWeight: 700,
                    width: 63,
                    fontSize: 16,
                    lineHeight: '19.36px',
                    color: '#5F7284',
                    paddingTop: 3
                }}
            >
                Q{index}
            </div>

            <div
                data-testid="moment-modal-scenarios-name" 
                style={{
                    fontWeight: 600,
                    width: 600,
                    fontSize: 16,
                    lineHeight: '24px',
                    color: '#333D47'
                }}
            >
                {scenario.scenarioName}
            </div>
            {scenario.scenarioId != NoneApplyId && (
                <>
                    <div
                        data-testid="moment-modal-scenarios-response" 
                        style={{
                            margin: "24px 0 0 65px",
                            minWidth: 115,
                            padding: "0 10px",
                            fontWeight: 600,
                            height: 40,
                            backgroundColor: _bgColor,
                            fontSize: scenario.scenarioResponseId === ScenarioResponseEnum.dontmonitor.id ? 10 : 15,
                            lineHeight: '27.5px',
                            color: 'white',
                            textTransform: 'capitalize',
                            borderRadius: 3.75,
                            paddingTop: 6.25,
                            textAlign: 'center'
                        }}
                    >   
                        {scenario.scenarioResponseId === ScenarioResponseEnum.dontmonitor.id
                            ? `Do not Monitor`
                            : getScenarioResponse(scenario.scenarioResponseId)}
                    </div>
                </>
            )}
        </div>
    )
}

export default TrendScenarioComponent
