import React, { useEffect, useState } from 'react';
import { isAfter, isBefore, subDays } from 'date-fns';
import DateRangeSection from "@/components/Sightly/SightlyFormElements/DateRangeSection";
import { capitalizeFirstLetter, formatDateyyyyMMdd } from "@/utils";
import BarChartOverview from '@/views/Trends/SearchResults/components/BarChartOverview';
import TotalsOverview from '@/views/Trends/SearchResults/components/TotalsOverview';
import { IMoment, ISearchResult, SearchResultEnum } from '@/views/Trends/types';
import { getLast60Days } from '@/utils/dateRanges';
import { useFlag } from '@unleash/proxy-client-react';

interface IProps {
    currentTabData: {
        tab: SearchResultEnum;
        data: ISearchResult[];
    };
    tabsCountData: { tab: SearchResultEnum; count: number }[];
}

const Overview = ({ currentTabData, tabsCountData }: IProps) => {
    const enableTrendsSearchGraph = useFlag('enable_trends_search_graph');
    const [currentTabCountPerDay, setCurrentTabCountPerDay] = useState<{ date: string; count: number }[]>([]);
    const [dateRange, setDateRange] = useState(getLast60Days());

    const endDate = dateRange ? dateRange[1] : new Date();
    const startDate = dateRange ? dateRange[0] : subDays(endDate, 60);

    const handleChangeDateRange = (selectedRange: [Date, Date]) => {
        if (selectedRange === dateRange) return;
        setDateRange(selectedRange);
    };

    useEffect(() => {
        const postCountsInRange: Record<string, number> = {};

        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            const formattedDate = formatDateyyyyMMdd(currentDate);
            postCountsInRange[formattedDate] = 0;
            currentDate.setDate(currentDate.getDate() + 1);
        }

        currentTabData.data.forEach((result) => {
            let date: Date;
            const isTopicOrTrendData = currentTabData.tab === 'topic' || currentTabData.tab === 'trend';

            // Topic and trend data will use the range from min published at to max published at
            const dateRange: string[] = [];

            if ((result as IMoment).maxPublishedAt) {
                date = new Date((result as IMoment).maxPublishedAt);
            } else {
                date = result.updatedAt ? new Date(result.updatedAt) : new Date();
            }

            if (isTopicOrTrendData) {
                let activeDate = new Date((result as IMoment).minPublishedAt);
                while (activeDate <= date) {
                    dateRange.push(activeDate.toISOString().split('T')[0]); // Format as YYYY-MM-DD
                    incrementDateCount(postCountsInRange, activeDate, startDate, endDate);
                    activeDate.setDate(activeDate.getDate() + 1);
                }
            } else {
                incrementDateCount(postCountsInRange, date, startDate, endDate);
            }

        });

        const formattedData = Object.entries(postCountsInRange).map(([date, count]) => ({
            date,
            count,
        }));

        setCurrentTabCountPerDay(formattedData);
    }, [dateRange, currentTabData]);

    return (
        <div className="w-full h-full flex flex-col justify-between">
            {/* Date range filter */}
            <div className="flex flex-col gap-2 pb-4" data-test-id="overview-date-range">
                <span className="text-sm">{capitalizeFirstLetter(currentTabData.tab)}s by day</span>
                <DateRangeSection
                    onSelectDateRange={handleChangeDateRange}
                    rangeValue={dateRange}
                />
            </div>

            {enableTrendsSearchGraph && <BarChartOverview data={currentTabCountPerDay} />}

            <TotalsOverview tabsCountData={tabsCountData} />
        </div>
    );
};

export default Overview;

const incrementDateCount =  (postCountsInRange:Record<string, number>, activeDate:Date, startDate:Date, endDate:Date) => {
    if (isAfter(activeDate, startDate) && isBefore(activeDate, endDate)) {
        const formattedDate = formatDateyyyyMMdd(activeDate);
        postCountsInRange[formattedDate] = (postCountsInRange[formattedDate] || 0) + 1;
    }
}