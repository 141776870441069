import { MyLocationGenerics } from '@/classes/utils'
import { InfoPage } from '@/components/InfoPage'
import { LoadingPage } from '@/components/LoadingPage'
import VerticalTabs from '@/components/VerticalTabs'
import { PATH_TOPIC_DETAILS, PATH_TREND_DETAILS, PATH_TRENDS, PATH_TRENDS_SEARCH } from '@/routes'
import PlatformsOverview from '@/views/Discover/Boards/Narratives/OverviewSidePanel/PlatformsOverview'
import ThemesOverview from '@/views/Discover/Boards/Narratives/OverviewSidePanel/ThemesOverview'
import SocialOverviewTabContents from '@/views/Discover/Boards/Narratives/SocialNarrativePanel/SocialOverviewTabContents'
import SocialPostsTabContents from '@/views/Discover/Boards/Narratives/SocialNarrativePanel/SocialPostsTabContents'
import useSocialClusterById from '@/views/Discover/Boards/Narratives/SocialNarrativePanel/useSocialClusterById'
import {
  SOCIAL_NARRATIVE_PANEL_DEFAULT_TAB_ID,
  SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS
} from '@/views/Discover/Boards/Narratives/constants'
import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import { useMatch, useNavigate, useSearch } from '@tanstack/react-location'
import React, { useEffect, useState } from 'react'

export const NarrativeDetails = () => {
  const [trendsSource, setTrendsSource] = useState<string>()

  const {
    params: { id }
  } = useMatch<MyLocationGenerics>()

  const {
    tab,
    redirect
  } = useSearch<MyLocationGenerics>();

  const navigate = useNavigate();

  useEffect(() => {
    const storageSource = localStorage.getItem('trendsSource')
    if (!storageSource) return

    setTrendsSource(storageSource)
  }, []);

  const [activeTabId, setActiveTabId] = React.useState<string>(tab || SOCIAL_NARRATIVE_PANEL_DEFAULT_TAB_ID)

  const { socialClusterData, socialClusterIsLoading } = useSocialClusterById(id)

  const renderTabContent = () => {
    if (!socialClusterData) return

    switch (activeTabId) {
      case SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS[0].id:
        return <SocialOverviewTabContents overview={socialClusterData?.overview} />
      case SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS[1].id:
        return <SocialPostsTabContents posts={socialClusterData?.posts} loading={socialClusterIsLoading} />
      case SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS[2].id:
        return <PlatformsOverview platformsOverview={socialClusterData?.platforms} loading={socialClusterIsLoading} />
      case SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS[3].id:
        return <ThemesOverview themesOverview={socialClusterData?.themes} loading={socialClusterIsLoading} />
      default:
        return <InfoPage message='The selected tab does not exist.' />
    }
  }

  const handleBack = () => {
    let path
    if (trendsSource === 'topic') {
      path = PATH_TOPIC_DETAILS
    } else if (trendsSource === 'trend') {
      path = PATH_TREND_DETAILS
    } else if (trendsSource === 'search') {
      path = PATH_TRENDS_SEARCH
    }
    navigate({
      to: path + (redirect ? `/${redirect}` : ''),
      search(prev?) {
        return {
          ...prev,
        }
      }
    })
  };

  return (
    <div className='w-full h-full'>
      <div className="flex pl-6 py-4 gap-4 text-xl items-center ">
        {
          trendsSource &&
          <ArrowLeftIcon
            data-testid='narrative-details-back-button'
            stroke='bold'
            className='hover:text-sightlyPurple h-7 w-7 font-bold cursor-pointer'
            onClick={handleBack} />
        }

        {socialClusterIsLoading ? (
          <div className='!font-bold'>
            <LoadingPage message="Narrative" />
          </div>
        ) : socialClusterData ? (
          <div>
            <span data-testid='narrative-details-title' className="font-bold">Narrative:</span> {socialClusterData.name}
          </div>
        ) : <div>Narrative not available</div>
        }
      </div>
      <div className={`flex ${socialClusterIsLoading ? 'h-full' : ''} min-h-[82vh] gap-3 bg-gray-200`}>
        <VerticalTabs
          active={activeTabId}
          onChange={(activeTabId: string) => {
            setActiveTabId(activeTabId)
          }}
          disabled={socialClusterIsLoading || !socialClusterData}
        >
          {SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS.map(({ name, id }) => (
            <div
              data-testid={`narrative-details-tab-${id}`}
              className="pr-6 text-sm break-words"
              key={id}
            >
              {name}
            </div>
          ))}
        </VerticalTabs>

        <div className="w-full px-2 py-4 h-full">
          {socialClusterIsLoading ?
            <div className='h-full bg-white rounded-md'>
              <LoadingPage message="Loading narrative data" />
            </div>
            : socialClusterData ? renderTabContent()
              : <div className='h-[70vh]'>
                <InfoPage message="No data to show" />
              </div>}
        </div>
      </div>
    </div>
  )
}