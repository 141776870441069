import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

interface IProps {
    /** Title of the component header. */
    title: string;
    /** Description of the component header. */
    description: string;
    /** Children component of the main component. */
    children: React.ReactNode;
}
/**
 * Generic component that can expand and collapse.
 * @prop {string} title Title of the component header.
 * @prop {string} description Description of the component header.
 * @prop {React.ReactNode} children Children component of the main component.
 */

export function ExpandSection({ title, description, children }: IProps) {
    const [isCollapsed, setIsCollapsed] = useState(true);
    return (
        <div className='border mr-[30px] rounded-lg shadow-md bg-white hover:shadow-lg transition-shadow duration-300 w-full divide-y divide-gray-300 mb-5' data-testid='data-testid-expand-section-container'>
            <div className='px-8 py-6 flex flex-row justify-between items-center' data-testid='data-testid-expand-section-header'>
                <div>
                    <div className='text-lg font-semibold' data-testid='data-testid-expand-section-title'>
                        {title}
                    </div>
                    <div className='text-sm text-gray-500 mt-2' data-testid='data-testid-expand-section-description'>
                        {description}
                    </div>
                </div>
                <div className='flex flex-row justify-between items-center cursor-pointer p-4' onClick={() => { setIsCollapsed(!isCollapsed) }} data-testid='data-testid-expand-section'>
                    <ChevronDownIcon
                        className={`text-gray-600 w-5 transform transition-transform ${isCollapsed ? "rotate-180" : ""}`}
                        data-testid='data-testid-expand-section-icon'
                    />
                </div>
            </div>
            {!isCollapsed && (
                <div className='px-8 py-6' data-testid='data-testid-expand-section-content'>
                    {children}
                </div>
            )}
        </div>
    );
};
