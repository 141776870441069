import React from 'react'
import {ConversationPanelSectionLayout} from "@/views/Discover/Boards/Narratives/PanelSectionLayout.";
import dayjs from 'dayjs';
import {Loader} from "rsuite";

type NarrativesOverviewType = {
  name: string
  maxPublishedDate: string
  postCount: number
}[]

const NarrativesOverview = ({narrativesOverview, loading}:{narrativesOverview: NarrativesOverviewType, loading: boolean}) => {

  if (loading) {
    return <Loader center/>
  }

  return (
    <ConversationPanelSectionLayout headerName="Narratives" headerCount={narrativesOverview.length}>
      <table className='w-full'>
        <thead className='h-8 text-sm font-semibold bg-panelBgGray'>
        <tr>
          <th className='pl-2 text-left rounded-l-md whitespace-nowrap'>Published Date</th>
          <th className='pl-2 text-left'>Narrative</th>
          <th className='pl-2 text-left rounded-r-md whitespace-nowrap'>Number of Posts</th>
        </tr>
        </thead>
        <tbody>
        {narrativesOverview.map((narrative) => (
          <tr className='border-b border-gray-200'>
            <td className='p-2 whitespace-nowrap'>{dayjs(narrative.maxPublishedDate).format('MM/DD/YYYY')}</td>
            <td className='p-2'>{narrative.name}</td>
            <td className='p-2'>{narrative.postCount}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </ConversationPanelSectionLayout>
  )
}

export default NarrativesOverview
