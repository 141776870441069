import React from 'react'
import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from '@tanstack/react-query'
import { listBuilderAxios,userAccountAxios } from '@/axiosInstances'
import { useNavigate } from '@tanstack/react-location'
import { rqKeys } from '@/ReactQueryKeyFactory'
import { logError } from '@/utils'
import { z, ZodError } from 'zod'
import {ClusterSchema, ClusterType, MomentSortBy, ClusterMinimalType, SelectedClusterType} from '@/views/Discover/Moments/v2/types'
import { api, RecActionFeedbackProps } from '@/views/Discover/Moments/v2/api'
import { useAlert } from '@/hooks/useAlert'
import { rqKeys as MomentViewRqKeys } from "@/views/Discover/Moments/v2/rqKeys";
import useTargetMomentsInfo from '@/views/Discover/Moments/v2/TargetMoment/hooks/useTargetMomentsInfo'
import { TargetListType } from './TargetMoment'
import { hasIoIdAndPersona } from '@/utils_ts'
import { useFlag } from '@unleash/proxy-client-react'

type GetMomentsProps = {
    brandProfileId: number | undefined
    boardIds: number[] | undefined
    sortBy: MomentSortBy
    startDate: string
    endDate: string
    actions: string[]
    activationStatus: string[]
    searchTerm: string
    aylienNews: string[]
    aylienIndustries: string[]
    page?: number
    signal?: AbortSignal | undefined // React query gives us this to use as axios cancel signal, to prevent duplicate calls,
    getAllMoments?: boolean | undefined
    pageSize?: number
    elasticSearchEnabled: boolean
}

const GetMomentsResultSchema = z.object({
    data: z.array(ClusterSchema),
    metaData: z.object({
        totalResultCount: z.number(),
        page: z.number(),
        requestedPageSize: z.number()
    })
})

const GetAllMomentsResultSchema = z.object({
    data: z.array(ClusterMinimalType),
    metaData: z.object({
        totalResultCount: z.number()
    })
})

const getMoments = async ({
    brandProfileId,
    boardIds,
    sortBy,
    startDate,
    endDate,
    actions,
    activationStatus,
    searchTerm,
    aylienIndustries,
    aylienNews,
    page,
    signal, // axios cancel
    pageSize = 20,
    elasticSearchEnabled
  }: GetMomentsProps) => {
    if (typeof brandProfileId === "undefined") {
      return Promise.reject(new Error("undefined brandProfileId"));
    }
    if (typeof boardIds === "undefined") {
      return Promise.reject(new Error("undefined boardIds"));
    }

    const locale = navigator.language;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = elasticSearchEnabled
      ? `/v1/boards/moments?brandProfileId=${brandProfileId}`
      : `/brand-profile/${brandProfileId}/moment-clusters`;
    const { data } = await (elasticSearchEnabled ? userAccountAxios : listBuilderAxios).post(
      url,
      {
        boardIds,
        locale,
        timeZone,
        startDate,
        endDate,
        actions,
        activationStatus,
        size: pageSize,
        page,
        sortBy,
        searchTerm,
        aylienNews,
        aylienIndustries,
      },
      {
        signal,
      }
    );

    try {
      return GetMomentsResultSchema.parse(data);
    } catch (error) {
      if (error instanceof ZodError) {
        logError(error, { info: 'onError in get moments query zod schema' })
        throw new Error("Failed to parse response data.");
      } else {
        throw error;
      }
    }
  };


const getAllMoments = async ({
    brandProfileId,
    boardIds,
    sortBy,
    startDate,
    endDate,
    actions,
    activationStatus,
    searchTerm,
    aylienIndustries,
    aylienNews,
    signal, //axios cancel
}: GetMomentsProps) => {
    if (typeof brandProfileId === undefined) {
        return Promise.reject(new Error('undefined brandprofileId'))
    }
    if (typeof boardIds === undefined) {
        return Promise.reject(new Error('undefined boardIds'))
    }

    const locale = navigator.language
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const url = `/brand-profile/${brandProfileId}/moment-clusters`;

    const dataToSend = {
        boardIds,
        locale,
        timeZone,
        startDate,
        endDate,
        actions,
        activationStatus,
        size: 20,
        page: 1,
        sortBy,
        searchTerm,
        aylienNews,
        aylienIndustries
    };

    const config: { signal: AbortSignal | undefined; params?: { getAllMoments?: boolean } } = {
        signal,
        params: {
            getAllMoments: true
        }
    }

    const { data } = await listBuilderAxios.post(
        url,
        dataToSend,
        config,
    )

    return GetAllMomentsResultSchema.parse(data)
}

type IProps = {
    brandProfileId: number | undefined
    boardIds: number[]
    startDate: string
    endDate: string
    searchTerm: string
    sortBy: MomentSortBy
    actions: string[]
    activationStatus: string[]
    showTargetSuccess: boolean
    pageSize?: number
}

const getLocalSelectedMoments = () => {
    const storedSelectedMoments = sessionStorage.getItem('selectedMoments')

    return storedSelectedMoments && storedSelectedMoments !== 'undefined' && JSON.parse(storedSelectedMoments)
}

const getDefaultSelectedMoments = (brandProfileId: number | undefined):any[] => {
    const selectedMomentsAll = getLocalSelectedMoments()
    return  (brandProfileId && selectedMomentsAll && selectedMomentsAll[brandProfileId] ) || []
}

export const useMoments = ({ brandProfileId, boardIds, startDate, endDate, searchTerm, sortBy, actions, activationStatus, showTargetSuccess, pageSize = 20 }: IProps) => {
    const { showAlert, renderAlert } = useAlert()

    const [selectedMoments, setSelectedMoments] = React.useState<SelectedClusterType[]>(getDefaultSelectedMoments(brandProfileId) || [])

    const [aylienNews, setAylienNews] = React.useState<string[]>([])
    const [aylienIndustries, setAylienIndustries] = React.useState<string[]>([])
    const {resetTargetMomentsInfo} = useTargetMomentsInfo()
    const elasticSearchEnabled = useFlag('enable_moments_elastic_search')

    const selectedMomentIds = React.useMemo(() => {
        // Resets the TargetMomentsSlideOver Info
        if(!selectedMoments.length) resetTargetMomentsInfo()
        return (selectedMoments && selectedMoments.map((trend: SelectedClusterType) => trend.clusterId)) || []
    }, [selectedMoments])

    const resetSelectedMoments = (brandProfileId:number | undefined) => {
        const selectedMomentsAll = getLocalSelectedMoments()
        brandProfileId && delete selectedMomentsAll[brandProfileId]
        sessionStorage.setItem('selectedMoments', JSON.stringify(selectedMomentsAll))
        setSelectedMoments([])
    }

    const handleSelectedMomentsTarget = (brandProfileId:number | undefined, targetList: Map<number, TargetListType>) => {
        // delete from storage only the ones with key and value in ioIdWithPersonas
        if (!brandProfileId){return false}
        const completeMomentTargetIds: number[] = [];

        Array.from(targetList.entries()).forEach(([key, value]) => {
            const ioIdWithPersonas = value.ioIdWithPersonas;

            if (hasIoIdAndPersona(ioIdWithPersonas)) {
                completeMomentTargetIds.push(key);
            }
        });

        const selectedMomentsAll = getLocalSelectedMoments()
        const uncompletedSelectedMoments = selectedMomentsAll[brandProfileId].filter((sm: { clusterId: number }) => !completeMomentTargetIds.includes(sm.clusterId) )
        delete selectedMomentsAll[brandProfileId]

        sessionStorage.setItem('selectedMoments', JSON.stringify(selectedMomentsAll))
        setSelectedMoments(uncompletedSelectedMoments)
    }

    const queryKey = rqKeys.moments({
        boardIds,
        brandProfileId,
        sortBy,
        startDate,
        endDate,
        actions,
        activationStatus,
        searchTerm,
        aylienIndustries,
        aylienNews,
        elasticSearchEnabled
    })

    const taxonomiesArgs = { brandProfileId, startDate, endDate, boardIds, actions, searchTerm, activationStatus }

    let taxonomies

    if (!elasticSearchEnabled) {
      taxonomies = useQuery(
        rqKeys.taxonomies(taxonomiesArgs),
        ({ signal }) => api.moments.taxonomies.get({ ...taxonomiesArgs, signal }),
        {
            onSettled: () => {
                setAylienNews([])
                setAylienIndustries([])
            },
            onError: (err) => {
                logError(err, taxonomiesArgs)
            },
            enabled: !!brandProfileId && !!boardIds && boardIds.length > 0
        }
      )
    }

    const queryClient = useQueryClient()

    const getMomentsArgs = {
        brandProfileId,
        boardIds,
        sortBy,
        startDate,
        endDate,
        actions,
        activationStatus,
        searchTerm,
        aylienIndustries,
        aylienNews,
        elasticSearchEnabled
    }

    const queryIsEnabled =
        brandProfileId !== undefined &&
        !!brandProfileId &&
        !!boardIds &&
        boardIds.length > 0 &&
        actions &&
        actions.length > 0
        const momentsQuery = useInfiniteQuery(
        queryKey,
        ({ pageParam = 1, signal }) => getMoments({ ...getMomentsArgs, page: pageParam, signal, pageSize }),
        {
            getNextPageParam: (lastPage, pages) => (lastPage.data.length < 10 ? undefined : pages.length + 1),
            enabled: queryIsEnabled,
            onError: (err) => {
                logError(err, { info: 'error getting moments' })
            }
        }
    )

    const {
        data,
        error,
        fetchNextPage,
        //   hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
        isFetched: momentsIsFetched,
        refetch
    } = momentsQuery

    const moments: ClusterType[] = []

    if (momentsQuery?.data?.pages) {
        for (const page of momentsQuery.data.pages) {
            moments.push(...page.data)
        }
    }
    React.useEffect(()=>{
        showTargetSuccess && refetch()
    },[showTargetSuccess])

    const lastMetaData = data?.pages[data.pages.length - 1].metaData

    const totalResultCount = data?.pages[data.pages.length - 1].metaData.totalResultCount || 0
    const metaData = lastMetaData || {
        totalResultCount: 0,
        requestedPageSize: 0,
        page: 0
    }
    const hasNextPage = metaData ? metaData?.totalResultCount > metaData?.page * metaData?.requestedPageSize : false

    const getMoment = (clusterId: number, pages: any) => {
        for (const page of pages) {
            for (const moment of page.data) {
                if (moment.clusterId === clusterId) return moment
            }
        }
        return queryClient.getQueryData(MomentViewRqKeys.momentInViewSlideOver(brandProfileId, clusterId))
    }

    const handleSelectMoment = (moment: SelectedClusterType) => {
        setSelectedMoments((prev: SelectedClusterType[]) => {
            return selectedMomentIds.includes(moment.clusterId)
                ? prev.filter((i: SelectedClusterType) => i.clusterId !== moment.clusterId)
                : prev.concat(moment)
        })
    }

    React.useEffect(() => {
      let selectedMomentsAll = getLocalSelectedMoments()
      // Merges previous stored value with new selectedMoments

      // Check if all moments are selected
      const areAllMomentsAreSelected = totalResultCount === selectedMomentIds.length && totalResultCount !== 0;
      setAllMomentsAreSelected(areAllMomentsAreSelected);

      if (selectedMomentsAll && brandProfileId) {
        const prevSelectedMoments: SelectedClusterType[] = selectedMomentsAll[brandProfileId]
        if (prevSelectedMoments) {
          const prevMomentMap = new Map(prevSelectedMoments.map((moment) => [moment.clusterId, moment]))
          selectedMomentsAll[brandProfileId] = selectedMoments.map((moment) => {
            const prevValue = prevMomentMap.get(moment.clusterId)
            if (prevValue) return prevValue
            return moment
          })
        } else{
            selectedMomentsAll[brandProfileId] = selectedMoments
        }
      } else selectedMomentsAll = (brandProfileId && { [brandProfileId]: selectedMoments }) || selectedMomentsAll
      sessionStorage.setItem('selectedMoments', JSON.stringify(selectedMomentsAll))
    }, [selectedMoments]);

    const selectAllOnPage = useMutation(
        () => {
            return Promise.resolve(true)
        },
        {
            onMutate: async (isSelected: boolean) => {
                if (isSelected) {
                    const currentTrends = moments?.map((i: ClusterType) => i.clusterId) || []
                    setSelectedMoments((prev: SelectedClusterType[]) => {
                        const final = prev
                            .filter((t: SelectedClusterType) => !currentTrends.includes(t.clusterId))
                            .concat(moments || [])
                        return final
                    })
                } else {
                    setSelectedMoments([])
                }
            }
        }
    )

    const navigate = useNavigate()

    const recActionFeedback = useMutation(api.moments.feedback.recommendedActionFeedback, {
        onMutate: async (args) => {
            queryClient.setQueryData(queryKey, (momentsResult: any) => {
                const newMoments = momentsResult
                const moment = getMoment(args.clusterId, newMoments.pages)
                moment.recommendedActionFeedback = args.feedback
                return newMoments
            })
        },
        onError: (err) => {
            logError(err, { info: 'error setting rec action feedback' })
        },
        onSuccess: () => {
            showAlert()
        }
    })

    const keywordsFeedback = useMutation(
        (args: RecActionFeedbackProps) => {
            const url = `/brand-profile/${brandProfileId}/moment-clusters/${args.clusterId}/feedback-keywords`
            return listBuilderAxios.post(url, {
                feedback: args.feedback
            })
        },
        {
            onMutate: async (args) => {
                queryClient.setQueryData(queryKey, (momentsResult: any) => {
                    const newMoments = momentsResult
                    const moment = getMoment(args.clusterId, newMoments.pages)
                    moment.keywordsFeedback = args.feedback
                    return newMoments
                })
            },
            onSuccess: () => {
                showAlert()
            },
            onError: (err) => {
                logError(err, { info: 'error on keyword feedback mutation' })
            }
        }
    )

    const allMomentsOnPageAreSelected = React.useMemo(() => {
        if (selectedMomentIds.length === 0) {
            return false
        }

        const notIncludedInSelectedItems = moments
            ?.map((m) => m.clusterId)
            ?.filter((id) => !selectedMomentIds.includes(id))
        if (notIncludedInSelectedItems === undefined) {
            return true
        } else if (notIncludedInSelectedItems.length > 0) {
            return false
        }
        return true
    }, [moments, selectedMomentIds])

    const [allMomentsAreSelected, setAllMomentsAreSelected] = React.useState<boolean>(false)

    const allMomentsQueryIsEnabled =
        brandProfileId !== undefined &&
        !!brandProfileId &&
        !!boardIds &&
        boardIds.length > 0 &&
        actions &&
        actions.length > 0 &&
        selectedMomentIds.length !== totalResultCount &&
        allMomentsAreSelected

    const allMomentsQuery = useQuery(
        [allMomentsAreSelected],
        () => getAllMoments({ ...getMomentsArgs }),
        {
            retry: 1,
            cacheTime: 0,
            staleTime: 0,
            refetchOnWindowFocus: false,
            enabled: allMomentsQueryIsEnabled,
            onError: (err) => {
                logError(err, { info: 'error getting moments' })
            }
        }
    )

    const {
        data: allMomentsData,
        error: allMomentsError,
        isFetching: allMomentsIsFetching,
        status: allMomentsStatus,
        isFetched: allMomentsIsFetched,
        refetch: allMomentsRefetch,
    } = allMomentsQuery

    // List of all moments, even those not printed, they have limited information ClusterMinimalType
    const allMoments = React.useMemo(() => {
        if (allMomentsIsFetched && allMomentsData && !allMomentsError){
            // Filtering of duplicated moments
            const idSet = new Set(selectedMoments.map(item => item.clusterId));
            const fetchedAllMomentsFiltered = allMomentsData.data.filter(item => !idSet.has(item.clusterId));
            let newSelectedMoments = [...selectedMoments, ...fetchedAllMomentsFiltered];

            setSelectedMoments(newSelectedMoments);
            return newSelectedMoments
        } else {
            return []
        }
    }, [allMomentsIsFetched])

    const selectAllMoments = () => {
        setAllMomentsAreSelected(true);
    }

    const handleSelectAllOnPage = (checked: boolean) => {
        selectAllOnPage.mutate(checked);
        setAllMomentsAreSelected(false);
    }

    return {
        moments,
        fetchNextPage,
        momentsIsFetched,
        isFetchingNextPage,
        isMomentsError: momentsQuery.isError,
        handleSelectMoment,
        handleSelectAllOnPage,
        selectAllMoments,
        allMomentsOnPageAreSelected,
        allMomentsAreSelected,
        setAllMomentsAreSelected,
        allMomentsIsFetching,
        allMoments,
        momentsIsLoading:
            (momentsQuery.isLoading  && momentsQuery.fetchStatus !== 'idle'),
        sortBy,
        setSortBy: (val: MomentSortBy) => {
            navigate({
                search: (prev) => {
                    return {
                        ...prev,
                        sortBy: val
                    }
                }
            })
        },
        selectedMomentIds,
        postTrendRecActionFeedback: recActionFeedback.mutate,
        postTrendKeywordsFeedback: keywordsFeedback.mutate,
        aylienNewsOptions: taxonomies?.data?.newsCategories || [],
        aylienIndustriesOptions: taxonomies?.data?.industryCategories || [],
        setAylienNews,
        setAylienIndustries,
        aylienNews,
        aylienIndustries,
        totalResultCount,
        hasNextPage,
        requestedPageSize: metaData?.requestedPageSize || 0,
        selectedMoments,
        resetSelectedMoments,
        handleSelectedMomentsTarget,
        setSelectedMoments,
        getDefaultSelectedMoments,
        invalidateMomentsQuery: () => queryClient.invalidateQueries(queryKey),
        invalidateAylienTaxonomiesQuery: () => queryClient.invalidateQueries(rqKeys.taxonomies(taxonomiesArgs)),
        renderAlert
    }
}
