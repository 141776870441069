import React from 'react';
import Table from 'rsuite/lib/Table';
import IconButton from 'rsuite/lib/IconButton';
import SightlyButton from '@/components/Sightly/SightlyButton';
import { routes } from '@/routes';
import useUsers from '@/hooks/useUsers';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { userAccountAxios } from '@/axiosInstances';
import PortalModal from '@/components/PortalModal/PortalModal';
import { useNavigate } from '@tanstack/react-location';
import useUser from '@/hooks/useUser';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { LoadingPage } from '@/components/LoadingPage';
import { TableColumnJs } from '@/utils';
import { SettingsLayout } from '@/layouts/SettingsLayout';
import { PlusIcon } from '@heroicons/react/20/solid';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { products } from '@/staticData/products';
import Tooltip from '@/components/TailwindTooltip';
import ProductLimitedAccessInfo from '@/components/ProductLimitedAccessInfo';
import { InviteUserExternal } from "@/components/InviteUserExternal";
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';
import { Panel } from 'rsuite';

const UserActionCell: React.FC<any> = ({
  rowData,
  handleDeleteUserClick,
  perms,
  userPermissions,
  userProducts,
  userCanPermissionProductQuota,
  ...props
}) => {
  const navigate = useNavigate()

  const handleEditUserClick = (userId: number | undefined) => {
    let url = `/app/settings/users/edit/${userId}`
    navigate({ to: url })
  }

  return (
    <Table.Cell
      {...props}
    >
      {userCanPermissionProductQuota({
        requiredPermissionValue: perms.USER_CREATE,
        requiredProductValue: products.USERS,
        userPermissions: userPermissions,
        userProducts: userProducts,
        checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
      }) && (
          <IconButton
            id="editUserButton"
            data-testid="table-user-edit-button"
            size="lg"
            appearance="subtle"
            onClick={() => handleEditUserClick(rowData.userId)}
            icon={<PencilSquareIcon className="h-5 w-5" />}
          />
        )}

      {userCanPermissionProductQuota({
        requiredPermissionValue: perms.USER_DELETE,
        requiredProductValue: products.USERS,
        userPermissions: userPermissions,
        userProducts: userProducts,
        checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
      }) && (
          <IconButton
            id="deleteUserButton"
            data-testid="table-user-delete-button"
            size="lg"
            appearance="subtle"
            onClick={() => handleDeleteUserClick(rowData)}
            icon={<TrashIcon className="h-5 w-5" />}
          />
        )}
    </Table.Cell>
  )
}

const InviteActionCell: React.FC<any> = ({
  rowData,
  handleDeleteUserClick,
  perms,
  userPermissions,
  userProducts,
  userCanPermissionProductQuota,
  ...props
}) => {
  return (
    <Table.Cell
      {...props}
    >
      {userCanPermissionProductQuota({
        requiredPermissionValue: perms.USER_DELETE,
        requiredProductValue: products.USERS,
        userPermissions: userPermissions,
        userProducts: userProducts,
        checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
      }) && (
          <SightlyButton
            type="cancel"
            id="cancel-invite"
            text="Cancel"
            handleClick={() => handleDeleteUserClick(rowData)}
          />
        )}
    </Table.Cell>
  )
}

export const Users: React.FC<{ users: any }> = (props) => {
  const { data: users, isLoading } = useUsers()
  const { user, currentAccount, perms, userPermissions, userProducts, subscriptionQuota } = useUser()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  let quotaIsAllowed = false;
  if (subscriptionQuota.has(products.USERS)) {
    const quota = subscriptionQuota.get(products.USERS)
    quotaIsAllowed = quota.isAllowed
  }

  const [deleteUserAlertIsOpen, setDeleteUserAlertIsOpen] = React.useState(false)
  const [userToDelete, setUserToDelete] = React.useState<any>({})

  const handleDeleteUserClick = (user: any) => {
    handleOpenDeleteUserAlert()
    setUserToDelete(user)
  }

  const handleCloseDeleteUserAlert = () => {
    setDeleteUserAlertIsOpen(false)
    setUserToDelete({})
  }

  const handleOpenDeleteUserAlert = () => {
    setDeleteUserAlertIsOpen(true)
  }

  const handleDeleteUser = async () => {
    setDeleteUserAlertIsOpen(false)
    const url = `/user/${userToDelete.userId}`
    await userAccountAxios.delete(url)
    // to refresh product quota
    await queryClient.fetchQuery(rqKeys.subscriptionQuotaQueryKey(currentAccount?.accountId, products.USERS))
    queryClient.invalidateQueries(rqKeys.usersKeys(currentAccount?.accountId))
    setUserToDelete({})
    toast.success('Success')
  }

  const [inviteUserDialogIsOpen, setInviteUserDialogIsOpen] = React.useState(false)
  const openInviteFlow = () => {
    if (user?.userType == 'Internal') {
      navigate({ to: routes.app.settings.users.create.path })
      return
    }
    setInviteUserDialogIsOpen(true)
  }
  if (isLoading) {
    return <LoadingPage message="Fetching users" />
  }

  if (props?.users?.length < 1) {
    return (
      <div className='h-[70vh] p-[8vw] text-left'
      >
        <h3 className='text-black'>
          This account has no users directly linked to it; however, users linked to any of its parent accounts will have
          access to this account.
        </h3>
      </div>
    )
  }

  const userTableData =
    users !== undefined
      ? users
        .filter((u) => !(u as any).isInvitePending)
      : undefined

  const inviteTableData =
    users !== undefined
      ? users
        .filter((u) => (u as any).isInvitePending)
      : undefined

  return (
    <SettingsLayout>
      <div className='flex flex-col w-full p-[2vw]'>
        <div className='w-full flex justify-between gap-[2vw] px-[1vw]'>
          <div className="text-gray-500 font-inter leading-5">
            Easily invite and manage roles and permissions for your team, empowering seamless collaboration and
            ensuring a secure and efficient workspace.
          </div>
          <Tooltip
            disabled={userCanPermissionProductQuota({
              requiredPermissionValue: perms.USER_CREATE,
              requiredProductValue: products.USERS,
              userPermissions: userPermissions,
              userProducts: userProducts,
              checkType: PermissionCheckType.PRODUCT_QUOTA_PERMISSION_CHECK,
              quota_is_allowed: quotaIsAllowed
            })}
            content={<ProductLimitedAccessInfo />}
          >
            <SightlyButton
              id="createUserButton"
              disabled={!userCanPermissionProductQuota({
                requiredPermissionValue: perms.USER_CREATE,
                requiredProductValue: products.USERS,
                userPermissions: userPermissions,
                userProducts: userProducts,
                checkType: PermissionCheckType.PRODUCT_QUOTA_PERMISSION_CHECK,
                quota_is_allowed: quotaIsAllowed
              })}
              text="Invite People"
              icon={<PlusIcon className="h-6 w-6" />}
              handleClick={openInviteFlow}
            />
          </Tooltip>
        </div>
        <Panel
          header={
            <div className='w-full text-xl font-semibold text-[#333d47]'>Users</div>
          }
          collapsible
          defaultExpanded={true}
          className="max-h-[60vh]"
        >
          <Table
            data-testid="existing-user-table"
            loading={isLoading}
            data={userTableData}
            height={350}
            className='overflow-y-auto'
          >
            <TableColumnJs flexGrow={1}>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.Cell dataKey="userId" />
            </TableColumnJs>

            <TableColumnJs flexGrow={1}>
              <Table.HeaderCell>First Name</Table.HeaderCell>
              <Table.Cell dataKey="firstName" />
            </TableColumnJs>

            <TableColumnJs flexGrow={1}>
              <Table.HeaderCell>Last Name</Table.HeaderCell>
              <Table.Cell dataKey="lastName" />
            </TableColumnJs>

            <TableColumnJs flexGrow={2}>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.Cell dataKey="email" />
            </TableColumnJs>

            <TableColumnJs flexGrow={1}>
              <Table.HeaderCell>Company</Table.HeaderCell>
              <Table.Cell dataKey="company" />
            </TableColumnJs>

            <TableColumnJs width={90}>
              <Table.HeaderCell>Actions</Table.HeaderCell>
              <UserActionCell
                customProps={props}
                handleDeleteUserClick={handleDeleteUserClick}
                perms={perms}
                userCanPermissionProductQuota={userCanPermissionProductQuota}
                userPermissions={userPermissions}
                userProducts={userProducts}
              />
            </TableColumnJs>
          </Table>
        </Panel>

        <Panel
          header={
            <div className='w-full text-xl font-semibold text-[#333d47]'>Pending Invites</div>
          }
          collapsible
          defaultExpanded={true}
          className="max-h-[60vh]"
        >
          <Table
            data-testid="pending-user-table"
            loading={isLoading}
            data={inviteTableData}
            height={350}
            className='overflow-y-auto'
          >
            <TableColumnJs flexGrow={1}>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.Cell dataKey="userId" />
            </TableColumnJs>

            <TableColumnJs flexGrow={1}>
              <Table.HeaderCell>First Name</Table.HeaderCell>
              <Table.Cell dataKey="firstName" />
            </TableColumnJs>

            <TableColumnJs flexGrow={1}>
              <Table.HeaderCell>Last Name</Table.HeaderCell>
              <Table.Cell dataKey="lastName" />
            </TableColumnJs>

            <TableColumnJs flexGrow={2}>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.Cell dataKey="email" />
            </TableColumnJs>

            <TableColumnJs width={90}>
              <Table.HeaderCell>Actions</Table.HeaderCell>
              <InviteActionCell
                customProps={props}
                handleDeleteUserClick={handleDeleteUserClick}
                perms={perms}
                userCanPermissionProductQuota={userCanPermissionProductQuota}
                userPermissions={userPermissions}
                userProducts={userProducts}
              />
            </TableColumnJs>
          </Table>
        </Panel>

        <InviteUserExternal
          isOpen={inviteUserDialogIsOpen}
          setIsOpen={setInviteUserDialogIsOpen}
        />

        <PortalModal
          open={deleteUserAlertIsOpen}
          handleClose={handleCloseDeleteUserAlert}
          footerButtons={[
            <SightlyButton
              type="cancel"
              id="cancel"
              handleClick={handleCloseDeleteUserAlert}
              text="Cancel"
            />,
            <SightlyButton
              id="yes"
              handleClick={handleDeleteUser}
              text="Yes, delete"
            />
          ]}
        >
          <h4>Are you sure you want to delete this user?</h4>
        </PortalModal>
      </div>
    </SettingsLayout>
  )
}
