import React from 'react';
import { SearchResultEnum } from '@/views/Trends/types';
import { capitalizeFirstLetter } from '@/utils';
import { useFlag } from '@unleash/proxy-client-react'

interface ITabCountData {
    tab: SearchResultEnum;
    count: number;
}

interface IProps {
    tabsCountData: ITabCountData[];
}

const TotalsOverview = ({ tabsCountData }: IProps) => {
    const topicsEnabled = useFlag('enable_explore_topics');
    const getClasses = (type: string) => {
        const classes = (() => {
            switch (type) {
                case 'topic':
                    return 'w-2/3 bg-[#4F46E5]';
                case 'trend':
                    return 'w-1/2 bg-[#7B61FF]';
                default:
                    return 'col-span-1 bg-[#48C294]';
            }
        })();
        return classes;
    };

    const data = topicsEnabled
        ? [tabsCountData.slice(0, 2), tabsCountData.slice(2)]
        : [tabsCountData.slice(0, 1), tabsCountData.slice(1)];

    const CountData = ({ tabCountData }: {
        tabCountData: ITabCountData;
        groupIndex: number;
    }) => (
        <div
            className={`rounded-3xl flex py-2 flex-col justify-center items-center
                ${getClasses(tabCountData.tab)}`}
            data-testid={`count-data-${tabCountData.tab}`}>
            <p className='text-sm lg:text-base text-center'>
                <span data-testid={`tab-${tabCountData.tab}`}>{capitalizeFirstLetter(tabCountData.tab)}s</span><br></br>
                <span data-testid={`count-${tabCountData.tab}`} className="text-lg lg:text-xl font-bold">{tabCountData.count}</span>
            </p> 
        </div>
    );

    return (
        <div className="w-full h-full text-white flex flex-col items-center justify-center gap-2">
            {data.map((group, groupIndex) => (
                <div
                    className={`w-full ${groupIndex % 2 === 0 ? 'flex flex-col gap-2' : 'grid grid-cols-2 gap-4'} items-center`}
                    key={`totals-overview-group-${groupIndex}`}
                    data-testid={"totals-overview-group"}
                >
                    {group.map((tabCountData) => (
                        <CountData
                            key={`totals-overview-group-${tabCountData.tab}-data`}
                            tabCountData={tabCountData}
                            groupIndex={groupIndex}
                            data-testid={`totals-overview-group-${tabCountData.tab}-data`}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
};

export default TotalsOverview;
