import React, { useState, useEffect } from 'react';
import NoImageFoundPath from '@/assets/img/NoImageFound.svg';
import { MomentPreviewType } from '@/views/Discover/Boards/types';
import HandThumbUp from '@/views/Discover/Boards/components/thumbs/hand-thumb-up.svg';
import HandThumbUpOutline from '@/views/Discover/Boards/components/thumbs/hand-thumb-up-outline.svg';
import HandThumbDown from '@/views/Discover/Boards/components/thumbs/hand-thumb-down.svg';
import HandThumbDownOutline from '@/views/Discover/Boards/components/thumbs/hand-thumb-down-outline.svg';
import Whisper from 'rsuite/lib/Whisper';
import Tooltip from 'rsuite/lib/Tooltip';
import { THUMBS_UP_LIMIT, THUMBS_DOWN_LIMIT } from '@/views/Discover/Boards/Narratives/constants';
import { useFlag } from '@unleash/proxy-client-react';

interface IProps {
  momentPreview: MomentPreviewType,
  momentsThumbUp: MomentPreviewType[],
  setMomentsThumbUp: Function
  momentsThumbDown: MomentPreviewType[],
  setMomentsThumbDown: Function
  thumbUpLimitReached: Boolean
  thumbDownLimitReached: Boolean
}

const THUMBSUP   = 'thumbsUp';
const THUMBSDOWN = 'thumbsDown';

const MomentPreviewCard: Function = ({
  momentPreview = { id: -1, name: '', image: '', summary: '' },
  momentsThumbUp,
  setMomentsThumbUp,
  momentsThumbDown,
  setMomentsThumbDown,
  thumbUpLimitReached,
  thumbDownLimitReached
}: IProps) => {
  const noImage = '/static/media/NoImageFound.8a746d82a5c36e8a94d3ea656f497279.svg';
  const imageSrc = momentPreview.image === noImage
    ? NoImageFoundPath
    : momentPreview.image;

  const thumbsUpSelected = momentsThumbUp.find(x => x.name === momentPreview.name) !== undefined;
  const thumbsDownSelected = momentsThumbDown.find(x => x.name === momentPreview.name) !== undefined;

  const [showThumbs, setShowThumbs] = useState(thumbsUpSelected || thumbsDownSelected);

  const elasticSearchEnabled = useFlag('enable_moments_elastic_search')

  useEffect(() => {
    setShowThumbs(thumbsUpSelected || thumbsDownSelected);
  }, [momentsThumbUp, momentsThumbDown])


  const handleMouseEnter = () => {
      setShowThumbs(true);
  };

  const handleMouseLeave = () => {
      setShowThumbs(thumbsUpSelected || thumbsDownSelected);
  };

  const toggleMoment = (thumbsType: string) => {
    const isInThumbsUp = momentsThumbUp.some(m => m.name === momentPreview.name);
    const isInThumbsDown = momentsThumbDown.some(m => m.name === momentPreview.name);

    if (isInThumbsUp && thumbsType === THUMBSUP) {
      // Remove from thumbs up
      setMomentsThumbUp(momentsThumbUp.filter(m => m.name !== momentPreview.name));
    } else if (isInThumbsDown && thumbsType === THUMBSDOWN) {
      // Remove from thumbs down
      setMomentsThumbDown(momentsThumbDown.filter(m => m.name !== momentPreview.name));
    } else {
      // Add to the appropriate list
      if (thumbsType === THUMBSUP) {
        if (thumbUpLimitReached) return;
        setMomentsThumbUp([ momentPreview, ...momentsThumbUp]);
        // Remove from thumbs down if present
        setMomentsThumbDown(momentsThumbDown.filter(m => m.name !== momentPreview.name));
      } else {
        if (thumbDownLimitReached) return;
        setMomentsThumbDown([ momentPreview, ...momentsThumbDown,]);
        // Remove from thumbs up if present
        setMomentsThumbUp(momentsThumbUp.filter(m => m.name !== momentPreview.name));
      }
    }
};

  return (
    <div
      data-testid="preview-moment-card"
      className="relative gap-y-2 animate-in fade-in flex items-center rounded-xl my-4 shadow bg-white mr-4"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="flex items-center flex-grow cursor-pointer hover:bg-gray-50"
      >
        <img
          data-testid="preview-moment-card-image"
          src={imageSrc}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = NoImageFoundPath
          }}
          alt={`image:${momentPreview.name}`}
          className="rounded-l-xl object-cover w-14 h-14"
        />

        <div className="flex items-center grow justify-evenly">
          <div className="flex flex-col justify-center flex-1 w-2/3 pl-4 ">
            {/* Moment Title*/}
            <div
              id={`trendTitle-${momentPreview.name}`}
              className="flex-wrap min-w-0 overflow-hidden text-sm font-semibold text-gray-900 break-words"
            >
              <span data-testid="preview-moment-card-headline" className="line-clamp-2">
                {momentPreview.name}
              </span>
            </div>
          </div>

        </div>

        <div className='pl-[20px] min-w-[120px]'>
          {showThumbs && !elasticSearchEnabled && <div className='w-[100px] '>
            <button
              onClick={ () => toggleMoment(THUMBSUP)}
              className='pr-6 moment-feedback-thumbs-up'
              data-testid="thumbs-up-button"
            >
              {
                thumbsUpSelected
                  ? <img
                    src={HandThumbUp}
                    className="w-[24px] h-[24px]"
                    />
                  : thumbUpLimitReached
                  ? <Whisper
                      delayOpen={0}
                      delayClose={0}
                      trigger='hover'
                      placement='auto'
                      speaker={
                        <Tooltip className="inline">
                          Maximum limit of {THUMBS_UP_LIMIT} thumbs up feedback reached.
                        </Tooltip>
                      }
                    >
                      <img
                        src={HandThumbUpOutline}
                        className="w-[24px] h-[24px]"
                      />
                    </Whisper>
                  : <img
                    src={HandThumbUpOutline}
                    className="w-[24px] h-[24px]"
                  />
              }
            </button>
            <button
              onClick={ () => toggleMoment(THUMBSDOWN)}
              className='pr-6 moment-feedback-thumbs-down'
              data-testid="thumbs-down-button"
            >
              {
                thumbsDownSelected
                  ? <img
                    src={HandThumbDown}
                    className="w-[24px] h-[24px]"
                  />
                  : thumbDownLimitReached
                  ? <Whisper
                      delayOpen={0}
                      delayClose={0}
                      trigger='hover'
                      placement='auto'
                      speaker={
                        <Tooltip className="inline">
                          Maximum limit of {THUMBS_DOWN_LIMIT} thumbs down feedback reached.
                        </Tooltip>
                      }
                    >
                      <img
                        src={HandThumbDownOutline}
                        className="w-[24px] h-[24px]"
                      />
                    </Whisper>
                  : <img
                    src={HandThumbDownOutline}
                    className="w-[24px] h-[24px]"
                  />
              }
            </button>
          </div>}
        </div>
      </div>
    </div>
  )
}

export default MomentPreviewCard;
