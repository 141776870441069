import { MyLocationGenerics } from '@/classes/utils'
import { InfoPage } from '@/components/InfoPage'
import { LoadingPage } from '@/components/LoadingPage'
import { SightlyInput } from '@/components/Sightly/SightlyFormElements/SightlyInput'
import SightlyGradientCard from '@/components/Sightly/SightlyGradientCard'
import Tabs from '@/components/Tabs/Tabs'
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles'
import Overview from '@/views/Trends/SearchResults/components/Overview'
import { useGenerateSummaryData } from '@/views/Trends/hooks/useAnalysis'
import { useNarrativeSearch } from '@/views/Trends/hooks/useNarratives'
import { useTopicTrendMoments } from '@/views/Trends/hooks/useTopicTrendMoments'
import { useTopicSearch } from '@/views/Trends/hooks/useTopics'
import { useTrendSearch } from '@/views/Trends/hooks/useTrends'
import { ICurrentTabData, IMoment, INarrative, ISearchResult, ITopic, ITrend, SearchResultEnum, TrendsSearchResultSortBy, categories } from '@/views/Trends/types'
import { PaperAirplaneIcon, SparklesIcon } from '@heroicons/react/20/solid'
import { useNavigate, useSearch } from '@tanstack/react-location'
import { useFlag } from '@unleash/proxy-client-react'
import React, { useEffect, useState } from 'react'

import { PATH_TRENDS_SEARCH } from '@/routes'
import { capitalizeFirstLetter, isDateBetween } from '@/utils'
import { getLast60Days } from '@/utils/dateRanges'
import { TabsFilters } from '@/views/Trends/SearchResults/components/TabsFilters'
import BrandProfileSelect from '@/views/Trends/components/BrandProfileSelect'
import ResultCardList from './SearchResults/components/ResultCardList'

export const TrendsResult = () => {
  const navigate = useNavigate();
  const trendsEnabled = useFlag('enable_trends_feature');
  const topicsEnabled = useFlag('enable_explore_topics');
  if (!trendsEnabled) navigate({ to: '/app/discover/moments/v2' });

  const tabs: SearchResultEnum[] = topicsEnabled ? Object.values(SearchResultEnum)
    : Object.values(SearchResultEnum).filter((t) => t !== SearchResultEnum.Topic)

  // When topics flag is disabled, Trends is the first tab
  const defaultActiveTab = topicsEnabled
    ? SearchResultEnum.Topic
    : SearchResultEnum.Trend;

  const { cardType } = useSearch<any>();
  const [activeTab, setActiveTab] = useState(
    cardType && tabs.includes(cardType) ? cardType as SearchResultEnum : defaultActiveTab
  );
  const [activeTabData, setActiveTabData] = useState<ICurrentTabData>()
  const [tabsCountData, setTabsCountData] = useState<{ tab: SearchResultEnum, count: number }[]>()

  const { searchTerm } = useSearch<MyLocationGenerics>();
  const [search, setSearch] = useState<string>(searchTerm ?? '');

  const {
    data: brandProfiles
  } = useBrandProfiles({
    submittedOnly: true
  });

  const analyzeBrandProfileId = localStorage.getItem('analyzeBrandProfileId')

  const [brandProfileId, setBrandProfileId] = useState<number>()

  useEffect(() => {
    if (brandProfiles && brandProfiles.length > 0) {
      if (analyzeBrandProfileId) {
        handleChangeBrandProfile(parseInt(analyzeBrandProfileId))
      } else {
        handleChangeBrandProfile(brandProfiles[0].brandProfileId)
      }
    }
  }, [brandProfiles])

  const [callSummaryOnSearch, setCallSummaryOnSearch] = useState(false);
  const [noDataForSummary, setNoDataForSummary] = useState(false);

  const [topics, setTopics] = useState<ITopic[]>([]);
  const { topicsData, topicsError, isLoadingTopics } = useTopicSearch(search);

  const [trends, setTrends] = useState<ITrend[]>([]);
  const { trendsData, trendsError, isLoadingTrends } = useTrendSearch(search);

  const [moments, setMoments] = useState<IMoment[]>([]);
  const { momentsData, momentsError, isLoadingMoments } = useTopicTrendMoments(search);

  const [narratives, setNarratives] = useState<INarrative[]>([]);
  const { narrativesData, narrativesError, isLoadingNarratives } = useNarrativeSearch(search);

  // Filters
  const [themeOptions, setThemeOptions] = useState<{ label: string; value: string }[]>()

  const [category, setCategory] = useState<number>(-1)
  const [theme, setTheme] = useState<string>('All Themes')
  const [timeRange, setTimeRange] = useState<[Date, Date]>(getLast60Days())
  const [sortBy, setSortBy] = useState<TrendsSearchResultSortBy>('createdAtDesc')

  const filterDataByDate = (data: { maxPublishedAt: string }[]) => {
    if (data) {
      const filteredData = data.filter((item) => {
        const maxPublishedDate = new Date(item.maxPublishedAt);
        return isDateBetween(maxPublishedDate, timeRange![0], timeRange![1]);
      });
      return filteredData;
    }
  };

  useEffect(() => {
    if (searchTerm !== undefined && searchTerm !== search) {
      setSearch(searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    const loading = isLoadingTopics || isLoadingTrends || isLoadingMoments || isLoadingNarratives
    const data = topicsData || trendsData || momentsData || narrativesData

    if (loading) return

    if (!data) {
      setNoDataForSummary(true);
    }

    setCallSummaryOnSearch(true)
  }, [topicsData,
    trendsData,
    momentsData,
    narrativesData,
    isLoadingTopics,
    isLoadingTrends,
    isLoadingMoments,
    isLoadingNarratives])

  useEffect(() => {
    if (
      isLoadingTopics ||
      isLoadingTrends ||
      isLoadingMoments ||
      isLoadingNarratives
    ) return

    setActiveTabData((prev) => {
      if (prev?.tab === activeTab) {
        return prev;
      }
      switch (activeTab) {
        case 'topic':
          return { tab: activeTab, data: topicsData || [] };
        case 'trend':
          return { tab: activeTab, data: trendsData || [] };
        case 'moment':
          return { tab: activeTab, data: momentsData || [] };
        case 'narrative':
          return { tab: activeTab, data: narrativesData || [] };
        default:
          return prev;
      }
    });
    const topicsTotal = filterDataByDate(topicsData!)
    const trendsTotal = filterDataByDate(trendsData!)
    const momentsTotal = filterDataByDate(momentsData!)
    const narrativesTotal = filterDataByDate(narrativesData!)

    const totals = [
      ...(topicsEnabled ? [{ tab: 'topic', query: topicsTotal }] : []),
      { tab: 'trend', query: trendsTotal },
      { tab: 'moment', query: momentsTotal },
      { tab: 'narrative', query: narrativesTotal },
    ].map(({ tab, query }) => ({
      tab: tab as SearchResultEnum,
      count: query?.length || 0,
    }));

    setTabsCountData((prev) => {
      const isEqual = JSON.stringify(prev) === JSON.stringify(totals);
      return isEqual ? prev : totals;
    });
  }, [
    timeRange,
    activeTab,
    topicsData,
    trendsData,
    momentsData,
    narrativesData,
    isLoadingTopics,
    isLoadingTrends,
    isLoadingMoments,
    isLoadingNarratives,
  ]);

  const handleChangeBrandProfile = (brandProfileId: number) => {
    setBrandProfileId(brandProfileId)
    localStorage.setItem('analyzeBrandProfileId', brandProfileId.toString());
  }

  const handleTabChange = (tab: string) => {
    setActiveTab(tab as SearchResultEnum)
    // Set the cardType URL param
    const params = new URLSearchParams(window.location.search);
    params.set('cardType', tab);
    window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
  }

  const momentsToGenerate = momentsData?.slice(0, 20) || [];
  const narrativesToGenerate = narrativesData?.slice(0, 20) || [];
  const trendsToGenerate = trendsData?.slice(0, 20) || [];
  const topicsToGenerate = topicsData?.slice(0, 20) || [];

  const { summaryData, isLoadingSummary } = useGenerateSummaryData(
    momentsToGenerate,
    narrativesToGenerate,
    trendsToGenerate,
    topicsToGenerate,
    topicsEnabled,
    callSummaryOnSearch
  );

  const sortData = (data: any[], sortBy: TrendsSearchResultSortBy) => {
    if (!data) return [];

    const sortedData = [...data];
    switch (sortBy) {
      case "createdAtDesc":
        return sortedData.sort((a, b) => new Date(b.maxPublishedAt).getTime() - new Date(a.maxPublishedAt).getTime());
      case "similarityRecommended":
      default:
        return sortedData;
    }
  };

  const filterData = (data: ISearchResult[], type: string) => {
    if (!timeRange) {
      throw new Error("timeRange cannot be null.");
    }
    let res = data.filter((d) => {
      const dateField = d.maxPublishedAt;
      if (!dateField) return true;

      const dateToCheck = new Date(dateField);
      return dateToCheck >= timeRange[0] && dateToCheck <= timeRange[1];
    });

    if (type !== SearchResultEnum.Narrative) {
      if (category !== -1) {
        const categoryCodes = new Set(categories.find((c) => c.id === category)?.codes);
        if (categoryCodes.size > 0) {
          res = res.filter((d) => {
            if ("categoryCodes" in d) {
              return d.categoryCodes.some(item => categoryCodes.has(item));
            } else if ("categories" in d) {
              return d.categories?.map((c) => c.taxonomyCode).some(item => categoryCodes.has(item)) ?? false;
            }
            return false;
          });
        }
      }
    } else if (theme !== 'All Themes') {
      res = res.filter((d) => {
        if ("themesList" in d) {
          return d.themesList?.includes(theme) ?? false;
        }
        return false;
      });
    }

    return res;
  };

  const useSortedData = (filter: number | string, setData: Function, type: string, data?: any[]) => {
    useEffect(() => {
      if (!data) return;

      const filtered = filterData(data, type);
      setData(sortData(filtered, sortBy));
    }, [filter, timeRange, sortBy, data]);
  }

  useSortedData(category, setTopics, SearchResultEnum.Topic, topicsData);
  useSortedData(category, setTrends, SearchResultEnum.Trend, trendsData);
  useSortedData(category, setMoments, SearchResultEnum.TopicTrendMoment, momentsData);
  useSortedData(theme, setNarratives, SearchResultEnum.Narrative, narrativesData);

  useEffect(() => {
    if (narrativesData) {
      const uniqueThemes = new Set<string>();

      narrativesData.forEach((d) => {
        d.themesList.forEach((theme) => uniqueThemes.add(theme));
      });

      const allThemes = {
        label: 'All Themes',
        value: 'All Themes'
      }

      const options = [allThemes, ...Array.from(uniqueThemes).map((theme) => ({
        label: theme,
        value: theme,
      }))]

      setThemeOptions(options);
    }
  }, [narrativesData]);


  const handleSearch = (searchTerm: string) => {
    navigate({
      to: PATH_TRENDS_SEARCH,
      search: { searchTerm }
    });
  }

  return (
    <div className='px-12 py-8 h-[90vh] bg-white rounded-md mx-auto flex flex-col gap-4'>
      <div className="flex gap-[2vw] justify-between items-center">
        <div className='flex gap-6' data-testid="search-bar">
          <p className='text-2xl'>Explore</p>
          <SightlyInput
            data-testid="search-input"
            id={'explore-search-input'}
            defaultValue={search || searchTerm || 'Search'}
            width={'70%'}
            height={'60%'}
            hasButton={true}
            onChange={handleSearch}
            buttonIcon={<PaperAirplaneIcon />}
          />
        </div>
        {brandProfiles && brandProfiles.length > 0 && <BrandProfileSelect
          value={brandProfileId}
          options={brandProfiles}
          label={"Brand Profile"}
          labelKey="brandProfileName"
          valueKey="brandProfileId"
          onChange={handleChangeBrandProfile}
          size='small'
        />}
      </div>
      <div className='w-full h-full flex animate-in fade-in'>
        <div className="w-2/3 flex flex-col pr-4 border-r border-gray-200">
          <div className='flex flex-col gap-2'>
            <p className='text-2xl'>{search || searchTerm}</p>
            <SightlyGradientCard>
              <p className="text-highlightPurple text-sm font-bold">
                <SparklesIcon
                  className="h-5 w-5 text-highlightPurple inline mr-2"
                  aria-hidden="true"
                />
                Summary
              </p>
              {isLoadingSummary ? (
                <div className='my-8'>
                  <LoadingPage message='Generating summary' />
                </div>
              ) : (
                <div className='my-2 h-[10vh] overflow-auto'>
                  <p className="text-sm">
                    {
                      noDataForSummary ? 'No data to generate summary.'
                        : summaryData ? summaryData.summary
                          : 'Summary could not be generated. Please refresh the page to try again. If the problem persists, please contact support.'
                    }
                  </p>
                </div>
              )}
            </SightlyGradientCard>
          </div>
          <div className='h-[70%] flex flex-col gap-6'>
            <div className="w-full flex flex-col gap-4">
              <Tabs
                width={'100%'}
                marginRight={40}
                active={activeTab}
                onChange={(active: string) => {
                  handleTabChange(active)
                }}
              >
                {tabs.map((tab) => (
                  <div
                    className="flex flex-row items-center"
                    key={tab}
                    data-testid={`tab-${tab}`}
                  >
                    <div>{capitalizeFirstLetter(tab) + 's'}</div>
                  </div>
                ))}
              </Tabs>
              <TabsFilters
                activeTab={activeTab}
                category={category}
                setCategory={setCategory}
                theme={theme}
                setTheme={setTheme}
                themeOptions={themeOptions || []}
                timeRange={timeRange}
                setTimeRange={setTimeRange}
                sortBy={sortBy}
                setSortBy={setSortBy} />
            </div>
            <div className='flex overflow-y-auto pb-[4vh]'>
              { brandProfileId &&
                (() => {
                  let results
                  let error
                  let loading
                  let type
                  switch (activeTab) {
                    case 'topic':
                      results = topics
                      error = topicsError
                      loading = isLoadingTopics
                      type = SearchResultEnum.Topic
                      break;
                    case 'trend':
                      results = trends
                      error = trendsError
                      loading = isLoadingTrends
                      type = SearchResultEnum.Trend
                      break;
                    case 'moment':
                      results = moments
                      error = momentsError
                      loading = isLoadingMoments
                      type = SearchResultEnum.TopicTrendMoment
                      break;
                    case 'narrative':
                      results = narratives
                      error = narrativesError
                      loading = isLoadingNarratives
                      type = SearchResultEnum.Narrative
                      break;
                    default:
                      return null;
                  }
                  return <ResultCardList
                    data-testid="narrative-tab-result-list"
                    results={results}
                    error={error}
                    isLoading={loading}
                    type={type}
                    brandProfileId={brandProfileId}
                  />
                })()
              }
            </div>
          </div>
        </div>
        <div className='w-1/3 flex flex-col gap-4 pl-4'>
          {(isLoadingTopics || isLoadingTrends || isLoadingMoments || isLoadingNarratives) && !activeTabData ? (
            <LoadingPage message={'Loading overview'} />
          ) : activeTabData && tabsCountData ?
            (
              <Overview
                currentTabData={activeTabData}
                tabsCountData={tabsCountData} />
            ) : (
              <InfoPage message={'No overview'} />
            )
          }
        </div>
      </div>
    </div>
  )
}
