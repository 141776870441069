import React from 'react';
import { ConversationPreviewCard } from '@/views/Discover/Boards/components/ConversationPreviewCard';
import { TotalPreviewsCard } from "@/views/Discover/Boards/components/TotalPreviewsCard";
import { ConversationPreviewType } from '@/views/Discover/Boards/types';
import { LoadingItems } from '@/components/LoadingItems'
import NoFoundMessage from './NoFoundMessage';

interface IProps {
  previews: ConversationPreviewType[];
  isLoading?: boolean;
  previewsCount: number;
  previewsCountLongRange: number;
  previewsCountMediumRange: number;
}

export const ConversationsPreviewList: Function = ({
  previews,
  isLoading,
  previewsCount,
  previewsCountLongRange,
  previewsCountMediumRange,
}: IProps) => {
  const hasPreviews = !!previews?.length;
  const filteredPreviews = previews?.filter((preview) => !!preview);
  const containerHeightClass = 'h-[calc(100%-48px)]';
  const heightClass = 'max-h-[calc(200px - 12px)]';

  if (isLoading) {
    return <div className={`flex flex-col justify-center grow mt-5 ${containerHeightClass}`}>
      <div className="h-screen">
        <LoadingItems />
      </div>
    </div>
  }

  return (
    <div data-testid="preview-conversation-list" className={`${containerHeightClass} bg-slate-50 flex flex-col`}>
      <TotalPreviewsCard
        title={'Total Narratives'}
        isLoading={isLoading}
        previewsCount={previewsCount}
        previewsCountMediumRange={previewsCountMediumRange}
        previewsCountLongRange={previewsCountLongRange}
      />

      {(hasPreviews && <div data-testid="nl-preview-conversations-list" className={`overflow-scroll bg-slate-50 h-full ${heightClass}`}>
        {filteredPreviews.map((preview) => {
          return <ConversationPreviewCard
            key={preview.name}
            preview={preview}
          />
        })}
        <p className="my-4">
          Displaying {filteredPreviews.length} narratives
        </p>
      </div>)}

      {(!hasPreviews && <div className="flex h-screen mr-8 justify-center">
        <NoFoundMessage
          title="No narratives found"
          subtitle="Please try updating with a different board prompt or adding ideas to lean into"
        />
      </div>)}
    </div>
  )
}
