import { z } from 'zod';
import { userAccountAxios } from '@/axiosInstances';

const TvIoSchema = z.object({
    OrderName: z.string(),
    CampaignType: z.string(),
    AdvertiserName: z.string(),
    AccountName: z.string(),
    InsertionOrderId: z.number(),
    IsLinkedToBrandProfile: z.boolean(),
    BrandProfilesLinked: z.array(z.number())
})

export type TvIoType = z.infer<typeof TvIoSchema>

const InsertionOrdersSchema = z.array(TvIoSchema)

export async function fetchTargetviewInsertionOrders() {

    const url = `/account/ios`
    const { data } = await userAccountAxios.get(url)
    const ios = InsertionOrdersSchema.parse(data)
    return ios
}
