import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import React from 'react'

interface CustomTooltipProps {
    active?: boolean
    payload?: any
    label?: string
    showGrowth?: boolean
    tooltipTitle?: string
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label, showGrowth = true, tooltipTitle }) => {
    if (active && payload && payload.length) {
        const postCount = payload[0].value
        const growth = payload[0].payload.growth
        let formattedDate = ''
        if (label) {
            const date = new Date(label);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed, so we add 1
            const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
            formattedDate = `${month}-${day}-${year}`;
        }
        return (
            <div className='bg-white rounded-lg text-[0.6rem]'>
                <div className='px-2 py-1.5 w-full bg-lightPurple text-badgeDarkPurple rounded-t-lg'>{formattedDate}</div>
                <div className='px-2 py-1.5 flex place-items-center gap-4'>
                    <div className='font-bold'>
                        {tooltipTitle ? (
                            <div className="text-sm font-bold">{tooltipTitle}</div>
                        )
                            : <div className="text-sm font-bold">Posts</div>
                        }
                        <div className="text-base font-bold text-[#651AEB]">{postCount}</div>
                    </div>
                    {showGrowth && (
                        <div className='font-semibold'>
                            <p>Daily % <br></br> change</p>
                            <div className="flex">
                                {growth < 0 ? (
                                    <ArrowDownIcon className="h-4 stroke-3 text-red-400 font-bold mr-1 inline"></ArrowDownIcon>
                                ) : growth > 0 ? (
                                    <ArrowUpIcon className="h-4 stroke-3 text-green-400 font-bold mr-1 inline"></ArrowUpIcon>
                                ) : (<span>-</span>)
                                }
                                <span className={`font-bold text-xs ${growth < 0 ? "text-red-400" : growth > 0 ? "text-green-400" : ""}`}>
                                    {growth && growth != 0 ? `${growth}%` : ""}
                                </span>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        )
    }

    return null
}

export default CustomTooltip
