import { SerializableMap } from '@/utils/classes/SerializableMap'
import { SerializableSet } from '@/utils/classes/SerializableSet'
import { AdGroupsType } from '@/views/Discover/Activations/v2/ActivationsManager/index'
import {
  ActivationCampaignAdGroupSchemaType,
  ActivationCampaignsSchemaType
} from '@/views/Discover/Activations/v2/activations-types'

export const selectedCampaignsToAdGroupsReducer = (
  selectedCampaignsToAdGroups: SerializableMap<number, AdGroupsType>,
  action: SelectedCampaignsToAdGroupsActionType
) => {
  switch (action.type) {
    case SELECTED_CAMPAIGNS_TO_ADGROUPS_ACTIONS.SELECT_CAMPAIGN: {
      const { activeNode, value } = action.payload
      const nextState = new SerializableMap(selectedCampaignsToAdGroups)
      let currentNode = getCurrentNode(activeNode, value)

      if (!currentNode) return selectedCampaignsToAdGroups
      if (currentNode.type === 'cid') {
        activeNode?.children?.forEach((campaign: any) => {
          if (currentNode.checked) {
            nextState.set(campaign.id, {
              keywordAdGroups: new SerializableSet<number>(),
              videoAdGroups: new SerializableSet<number>()
            })
          } else {
            nextState.delete(campaign.id)
          }
        })
      }

      if (currentNode.type === 'campaign') {
        if (currentNode.checked) {
          nextState.set(currentNode.value, {
            keywordAdGroups: new SerializableSet<number>(),
            videoAdGroups: new SerializableSet<number>()
          })
        } else {
          nextState.delete(currentNode.value)
        }
      }
      return nextState
    }

    case SELECTED_CAMPAIGNS_TO_ADGROUPS_ACTIONS.SELECT_ALL_CAMPAIGNS: {
      const { selectAll, campaignOptions } = action.payload
      const nextState = new SerializableMap(selectedCampaignsToAdGroups)
      if (selectAll) {
        const campaignValues = campaignOptions.flatMap((mcc) =>
          mcc.children.flatMap((cid) => cid.children.flatMap((campaign) => campaign.id))
        )
        campaignValues.forEach((campaignId) => {
          if (!nextState.has(campaignId)) {
            nextState.set(campaignId, {
              keywordAdGroups: new SerializableSet<number>(),
              videoAdGroups: new SerializableSet<number>()
            })
          }
        })
        return nextState
      } else {
        return new SerializableMap<number, AdGroupsType>()
      }
    }

    case SELECTED_CAMPAIGNS_TO_ADGROUPS_ACTIONS.SELECT_ADGROUP: {
      const { type, value, activeNode } = action.payload
      const nextState = new SerializableMap(selectedCampaignsToAdGroups)
      const currentNode = getCurrentNode(activeNode, value)
      if (!currentNode) return selectedCampaignsToAdGroups

      if (currentNode.type === 'campaign') {
        const campaignId = currentNode.value
        const adGroups = nextState.get(campaignId)
        if (!adGroups) return selectedCampaignsToAdGroups

        if (currentNode.checked) {
          activeNode.children.forEach((adGroup: any) => {
            type === 'keyword' && adGroups?.keywordAdGroups.add(adGroup.id)
            type === 'video' && adGroups?.videoAdGroups.add(adGroup.id)
          })
        } else {
          if (type === 'keyword') adGroups.keywordAdGroups = new SerializableSet<number>()
          if (type === 'video') adGroups.videoAdGroups = new SerializableSet<number>()
        }
        nextState.set(campaignId, adGroups)
      }

      if (currentNode.type === 'adGroup') {
        const prevAdGroups = nextState.get(activeNode?.parentNode?.id ?? 0)
        const adGroupId = currentNode.value
        if (currentNode.checked) {
          type === 'keyword' && prevAdGroups?.keywordAdGroups.add(adGroupId)
          type === 'video' && prevAdGroups?.videoAdGroups.add(adGroupId)
        } else {
          type === 'keyword' && prevAdGroups?.keywordAdGroups.delete(adGroupId)
          type === 'video' && prevAdGroups?.videoAdGroups.delete(adGroupId)
        }
      }

      return nextState
    }

    case SELECTED_CAMPAIGNS_TO_ADGROUPS_ACTIONS.SELECT_ALL_ADGROUPS: {
      const { type, selectAll, adGroupOptions } = action.payload
      const nextState = new SerializableMap(selectedCampaignsToAdGroups)
      if (!type) return nextState
      if (selectAll) {
        adGroupOptions.forEach((campaign) => {
          const campaignChildren = campaign.children.map(({ id }) => id)
          const adGroups = nextState.get(campaign.id)
          if (adGroups) {
            if (type === 'keyword') {
              adGroups.keywordAdGroups = new SerializableSet(campaignChildren)
            }
            if (type === 'video') {
              adGroups.videoAdGroups = new SerializableSet(campaignChildren)
            }
          }
        })
      } else {
        Array.from(nextState.keys()).forEach((campaignId) => {
          nextState.set(campaignId, {
            keywordAdGroups: new SerializableSet<number>(),
            videoAdGroups: new SerializableSet<number>()
          })
        })
      }
      return nextState
    }

    case SELECTED_CAMPAIGNS_TO_ADGROUPS_ACTIONS.RESET: {
      return new SerializableMap<number, AdGroupsType>()
    }

    default: {
      return selectedCampaignsToAdGroups
    }
  }
}

export enum SELECTED_CAMPAIGNS_TO_ADGROUPS_ACTIONS {
  SELECT_CAMPAIGN = 'SELECT_CAMPAIGN',
  SELECT_ALL_CAMPAIGNS = 'SELECT_ALL_CAMPAIGNS',
  SELECT_ADGROUP = 'SELECT_ADGROUP',
  SELECT_ALL_ADGROUPS = 'SELECT_ALL_ADGROUPS',
  RESET = 'RESET'
}

type SelectCampaignPayload = {
  activeNode: any
  value: any
}

type SelectAllCampaignsPayload = {
  selectAll: boolean
  campaignOptions: ActivationCampaignsSchemaType
}

type SelectAdGroupPayload = {
  type: string | undefined
  activeNode: any
  value: any
}
type SelectAllAdGroupsPayload = {
  selectAll: boolean
  type?: string
  adGroupOptions: Array<ActivationCampaignAdGroupSchemaType>
}

export type SelectedCampaignsToAdGroupsActionType =
  | { type: SELECTED_CAMPAIGNS_TO_ADGROUPS_ACTIONS.SELECT_CAMPAIGN; payload: SelectCampaignPayload }
  | { type: SELECTED_CAMPAIGNS_TO_ADGROUPS_ACTIONS.SELECT_ALL_CAMPAIGNS; payload: SelectAllCampaignsPayload }
  | { type: SELECTED_CAMPAIGNS_TO_ADGROUPS_ACTIONS.SELECT_ALL_ADGROUPS; payload: SelectAllAdGroupsPayload }
  | { type: SELECTED_CAMPAIGNS_TO_ADGROUPS_ACTIONS.SELECT_ADGROUP; payload: SelectAdGroupPayload }
  | { type: SELECTED_CAMPAIGNS_TO_ADGROUPS_ACTIONS.RESET }

type NodeType = 'cid' | 'campaign' | 'adGroup'

const getCurrentNode = (activeNode: any, value: any) => {
  let nodeType: NodeType
  if ('isCampaign' in activeNode && activeNode.isCampaign) {
    nodeType = 'campaign'
  } else if ('isAdGroup' in activeNode && activeNode.isAdGroup) {
    nodeType = 'adGroup'
  } else {
    nodeType = 'cid'
  }

  return {
    type: nodeType as NodeType,
    value: activeNode.id,
    checked: activeNode.check
  }
}
