import React from 'react'
import SightlyButton from '@/components/Sightly/SightlyButton';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { routes } from '@/routes';
import { MyLocationGenerics } from '@/classes/utils';
import { segmentsController } from '@/views/Engage/Segments/controller/SegmentController';
import { useQuery } from '@tanstack/react-query';
import { SegmentsViewTable } from '@/views/Engage/Segments/components/SegmentsTable';
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';
import { perms } from '@/staticData/permissions';
import { LoadingComponent } from '@/components/LoadingComponent';

export const ViewSegments = () => {
    const navigate = useNavigate()
    const { userPermissions } = useUser()
    const { accountId } = useSearch<MyLocationGenerics>()

    const page = 1
    const pageSize = 10

    const orderBy = 'updatedAt';
    const sortBy = 'desc';

    const {
        data: tableData
    } = useQuery(['getSegments', accountId, page, pageSize, orderBy, sortBy], segmentsController.getSegments)

    const hasTableData = tableData != undefined ? tableData['results'].length : 0

    return (
        <>
            <div className="flex justify-between mt-8 mx-8 mb-4 pb-0" data-testid="segments-table">

                <div className="sightlyPanelHeader" data-testid="segments-table-header">
                    <div className="flex flex-row">
                        Segments
                    </div>
                </div>
                {tableData === undefined ? (
                    null
                ) :
                    hasTableData ? (
                        <div>
                            <SightlyButton
                                datatestid={'button-smart-list-create-new-segment'}
                                id="createSegmentButton"
                                text="Create Segment"
                                type='purple'
                                disabled={!userCanPermissionProductQuota({
                                    requiredPermissionValue: perms.SEGMENT_CREATE,
                                    userPermissions: userPermissions,
                                    checkType: PermissionCheckType.PERMISSION_CHECK
                                })}
                                handleClick={() => {
                                    navigate({
                                        to: routes.app.segments.create.path,
                                        search: (prev) => ({ accountId: prev!.accountId })
                                    })
                                }}
                            />
                        </div>
                    ) : null}

            </div>
            {tableData === undefined ? (
                <div className="flex justify-center items-center h-52">
                    <LoadingComponent message="Loading Segments" />
                </div>
            ) :
                hasTableData
                    ?
                    <div className="mx-8" data-testid="segments-table-component">
                        <SegmentsViewTable tableData={tableData} accountId={accountId!} userPermissions={userPermissions} />
                    </div>
                    :
                    <div className="w-full flex items-center flex-col" data-testid="segments-table-create">
                        <div className="flex items-center flex-col pt-32">
                            <p>
                                <b>No Segments</b>
                            </p>
                            <p>Get started by creating a new Segment</p>
                            <div className="p-4">
                                <SightlyButton
                                    datatestid={'button-smart-list-new-segment'}
                                    id="createSegmentButton"
                                    text="+ New Segment"
                                    disabled={false}
                                    type='purple'
                                    handleClick={() => {
                                        navigate({
                                            to: routes.app.segments.create.path,
                                            search: (prev) => ({ accountId: prev!.accountId })
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>

            }
        </>
    )
}
